import axios from "./axios";

class SetupFileService {

    static Instance() {
        return new SetupFileService();
    }

    uploadSetUpFile(deviceModel, version, file) {
        return axios.post(`/api/setupfiles/update/analystapp/${deviceModel}?version=${version}`, file, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                timeout: 3600000
            }
        );
    }

    downloadSetUpFile(deviceModel) {
        return axios.get(`/api/setupfiles/download/analystapp/${deviceModel}`, { responseType: "blob", timeout: 3600000 });
    }

    getDeviceFileDetails(deviceId) {
        return axios.get(`/api/setupfiles/${deviceId}/devicefiles`);
    }

    updateDeviceFiles(deviceId, resourceType, file) {
        console.log({resourceType});
        return axios.post(`/api/setupfiles/${deviceId}/devicefile/update?resourceType=${resourceType}`, file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 3600000
        })
    }

    downloadDeviceFile(deviceId, resourceType) {
        return axios.get(`/api/setupfiles/${deviceId}/devicefiles/download?resourceType=${resourceType}`, { responseType: "blob", timeout: 3600000 });
    }

    getAllAnalystApp() {
        return axios.get("/api/setupfiles/all/analystapp");
    }

    uploadInstrumentImage(deviceModel, file){
        return axios.post(`/api/setupfiles/updateinstrumentimage/${deviceModel}`, file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    }

    getInstrumentImage(deviceModel) {
        return axios.get(`/api/setupfiles/downloadinstrumentimage/${deviceModel}`,  { responseType: "blob"})
    }

    deleteAnalystApp(deviceModel) {
        return axios.delete(`/api/setupfiles/remove/analystapp/${deviceModel}`)
    }


    // New Approach for Uploading nd Downloading Setup Files APIs

    generateDeviceSetupFileUploadLink (filename, resourceType, serialNumber) {
        return axios.get(`/api/files/generatesetupfileuploadurl/${filename}?resourceType=${resourceType}&serialNumber=${serialNumber}`);
    }

    confirmDeviceSetupFileUpload (filename, resourceType, serialNumber, fileSize) {
        return axios.get(`/api/files/confirmsetupfileupload/${filename}?resourceType=${resourceType}&serialNumber=${serialNumber}&fileSize=${fileSize}`);
    }

    generateDeviceSetupFileDownloadLink (resourceType, serialNumber) {
        return axios.get(`/api/files/generatesetupfiledownloadurl?resourceType=${resourceType}&serialNumber=${serialNumber}`);
    }

}

export default SetupFileService.Instance()