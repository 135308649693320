import React from "react";
import { useState } from "react";
import UserService from "../services/UserService";
import { CloseOutlined } from "@ant-design/icons";
import { authentication } from "../services/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { Button, Input, Typography, Select, Form, Drawer, Space, notification, Row, Col } from "antd";
import { countries, countryCodeList } from "../helpers/location";

const { Title } = Typography;
const { Option } = Select;

function SignUpForm(props) {
  const [form] = Form.useForm();
  const [OTP, setOTP] = useState("");
  const [visible, setVisible] = useState(true);
  const [OTPDrawer, setOTPDrawer] = useState(false);
  const [formData, setFormData] = useState({});
  const [resendOTP,setResendOTP] = useState(false);
  const [idDynamic,setIdDynamic]=useState("");
  const [isButtonDisabled,setIsButtonDisabled]=useState(true);
  const [code, setCode] = useState('');

  let CountryWithCode = [];
  for (let i = 0; i < countries.length; i++){
    CountryWithCode.push({...countries[i], ...countryCodeList[i]})
  }

  const close = () => {
    props.isVisibleSignUpForm();
    setVisible(false);
  };

  const showOTPDrawer = () => {
    setOTPDrawer(true);
  };

  const onOTPDrawerClose = () => {
    setOTPDrawer(false);
  };

  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {},
      },
      authentication
    );
  };

  const onSignInSubmit = (e) => {
    let userData = e;
    let number = userData.phone;
    configureCaptcha();
    const phoneNumber = "+" + code + number;
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        showOTPDrawer();
        setTimeout( () => setIsButtonDisabled(false) , 45000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitOTP = (e) => {
    let OTPNumber = e.otp;
    setOTP(e.otp);
    window.confirmationResult
      .confirm(OTPNumber)
      .then((result) => {
        if (formData !== undefined || formData !== {}) {
          UserService.signup(formData)
            .then((response) => {
              notification.success({
                type: "info",
                description: "Your account has been created successfully",
                duration: 2,
              });
              onOTPDrawerClose();
              setVisible(false);
            })
            .catch((error) => {
              console.log(error.response.data.message);
              notification.warn({
                type: "warning",
                description: error.response.data.message,
                duration: 2,
              });
            });
        }
      })
      .catch((error) => {
        console.log("Error in OTP", error);
      });
  };

  const handleSubmit = (e) => {
    let email = e.email;
    let userData = e;
    if (email !== "" || email !== undefined) {
      UserService.verifyEmail(email)
        .then((response) => {
          if (response.data === true) {
            notification.warn({
              type: "waring",
              description: "User Already Exists please update your password",
              duration: 2,
            });
          }
          if (response.data === false) {
            setFormData(userData);
            onSignInSubmit(e);
          }
        })
        .catch((error) => {
          console.log("error", error);
          notification.warn({
            type: "warning",
            description: error.response.data.message,
            duration: 2,
          });
        });
    }
  };

  function idForCaptcha(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
  const resendConfigureCaptcha = () => {
    setTimeout( () => setIsButtonDisabled(false) , 45000);
    if(resendOTP){
      window.recaptchaVerifier =null;
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      {idDynamic},
      {
        size: "invisible",
        callback: (response) => {},
      },
      authentication
    );
  };

  const getOTP = () =>{
    setResendOTP(true);
    setIsButtonDisabled(true);  
    let appVerifier;
    let  number=formData.phone;
    if(resendOTP){
      window.recaptchaVerifier =null;
      appVerifier=null;
      window.confirmationResult=null;
    resendConfigureCaptcha();
    }
    const phoneNumber = "+91" + number;
    appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setIdDynamic(idForCaptcha(5));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Drawer
        title="Sign-Up"
        visible={visible}
        onCancel={close}
        onClose={close}
        width={720}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit} className="user-form">
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Salutation
              </Title>
            }
            name="salutation"
            color="blue"
            rules={[{ required: true, message: "Please Select Salutation!" }]}
          >
            <Select allowClear="true" placeholder="Select Salutation">
              <Option value="Mr.">Mr.</Option>
              <Option value="Ms.">Ms.</Option>
              <Option value="Mrs.">Mrs.</Option>
              <Option value="Dr.">Dr.</Option>
              <Option value="Prof">Prof</Option>
              <Option value="Shri">Shri</Option>
              <Option value="Smt">Smt</Option>
              <Option value="Kum">Kum</Option>
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    First Name
                  </Title>
                }
                name="firstName"
                rules={[{ required: true, pattern: /^[A-Za-z]{3,20}$/, message: "First Name should have 3-20 letters only!" }]}
              >
                <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())} name="firstName" allowClear placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    Last Name
                  </Title>
                }
                name="lastName"
                rules={[{ required: true, pattern: /^[A-Za-z]{3,20}$/, message: "Last Name should have 3-20 letters only!" }]}
              >
                <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())} name="lastName" allowClear placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Company Name
              </Title>
            }
            name="userEnteredCompanyName"
            color="blue"
            rules={[{ required: true, message: "Please Enter Company!" }]}
          >
            <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())} placeholder="Enter Company"></Input>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    Contact Number
                  </Title>
                }
                name="phone"
                rules={[{ required: true, pattern: /^[0-9]{10,10}$/, message: "phone number should be 10 digit!" }]}
              >
                <Input allowClear placeholder="Contact Number " />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    Email
                  </Title>
                }
                name="email"
                rules={[{ required: true, message: "Please enter email!" }]}
              >
                <Input type="email" name="email" allowClear placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Country
              </Title>
            }
            name="country"
            rules={[{ required: true, message: "Please Select Country!" }]}
          >
            <Select
              showSearch
              placeholder="Select Country"
              optionFilterProp="value"
              onSelect={(_, record) => setCode(record.key)}
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {CountryWithCode.map((country) => {
                return (
                  <Select.Option key={country.code} value={country.name}>
                    {country.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    Password
                  </Title>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})/,
                    message:
                      "Password that has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character  and is at least 6 characters long!",
                  },
                ]}
              >
                <Input.Password name="password" placeholder="Enter New Password" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    Confirm Password
                  </Title>
                }
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message:
                      "Password that has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character  and is at least 6 characters long",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Password And Confirm Password do not match");
                    },
                  }),
                ]}
              >
                <Input.Password name="confirmPassword" placeholder="Confirm Password" />
              </Form.Item>
            </Col> */}
          </Row>
          <Form.Item>
            <Space>
              <div id="sign-in-button"></div>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Drawer
          title="OTP"
          visible={OTPDrawer}
          width={320}
          onClose={onOTPDrawerClose}
          onCancel={onOTPDrawerClose}
          bodyStyle={{ paddingBottom: 80 }}
          closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
        >
          <Form layout="vertical" form={form} onFinish={onSubmitOTP} className="user-form">
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Verify OTP
                </Title>
              }
              name="otp"
              rules={[{ required: true, pattern: /^[0-9]{6,6}$/, message: "OTP number should be 6 digit!" }]}
            >
              <Input allowClear placeholder="Enter OTP" />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Verify OTP
                </Button>
                <Button className="ml-2 device-form-button" onClick={onOTPDrawerClose} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
          <div id={idDynamic}></div>
            <Button type="primary"  disabled={isButtonDisabled} onClick={getOTP}>
                  Resend OTP
          </Button>
        </Drawer>
      </Drawer>
    </>
  );
}

export default SignUpForm;
