import React from "react";
import { Form, Button, Drawer, Col, Input, message, notification, Row, Checkbox, Select,Typography } from "antd";
import RoleService from "../services/RoleService";
import { EventBus } from "../components/event";
import CommonService from "../services/CommonService";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Title,Text } = Typography;


export default function AddRoleForm(props) {
  const [PrivilegeOptions, setPrivilegeOptions] = useState(props.role.privileges);
  const [PrivilegeChoice, setPrivilegeChoice] = useState(props.role.privileges);
  const [loading, setloading] = useState(false);
  const [isOpenPrivilegeCard, setisOpenPrivilegeCard] = useState(true);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [render, setRender] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllPrivileges();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [props.role]);

  const getAllPrivileges = () => {
    RoleService.getAllPrivilegeTypes()
      .then((response) => {
        let privilegeOptions = response.data;
        privilegeOptions = CommonService.getSorted(privilegeOptions || [], "name", true);
        setPrivilegeOptions(privilegeOptions);
      })
      .catch((error) => {
        notification.open({
          message: "Something went wrong ",
          discription: error,
        });
      });
  };

  const handleSubmit = (e) => {
    let role = {};
    role.name = e.name;
    role.external = e.external;
    role.id = props.role.id;
    role.privileges = PrivilegeChoice;

    setloading(true);
    RoleService.addRole(role, props.flag)
      .then((response) => {
        if (props.flag) {
          message.success("Role updated");
          close();
        } else {
          message.success("Role Added");
          close();
        }
        props.refresh();
      })
      .catch((error) => {
        let msg = "Something went wrong!";
        if (error && error.response && error.response.data && error.response.data.message) {
          msg = error.response.data.message;
          message.warn(msg);
        }
      });
  };
  const close = () => {
    props.close();
  };
  const selectAll = (checked) => {
    let privilegeOptions = PrivilegeOptions;
    let privilegeChoice = [];
    if (checked) {
      privilegeOptions.map((privilege) => {
        privilegeChoice.push(privilege.name);
        return privilegeChoice;
      });
    } else {
      privilegeChoice = [];
    }
    setPrivilegeChoice(privilegeChoice);
    setIndeterminate(false);
    setCheckAll(checked);
  };
  const handleCheckboxChange = (privilegeName, checked) => {
    let privilegeChoice = PrivilegeChoice;
    if (checked) {
      if (!privilegeChoice.includes(privilegeName)) {
        privilegeChoice.push(privilegeName);
      }
    } else {
      privilegeChoice = privilegeChoice.filter((item) => {
        return item !== privilegeName;
      });
    }
    setIndeterminate(!!privilegeChoice.length && privilegeChoice.length < PrivilegeOptions.length);
    setCheckAll(privilegeChoice.length === PrivilegeOptions.length);
    setPrivilegeChoice(privilegeChoice);
    setRender(!render);
  };

  const isChecked = (privilegeName) => {
    let privilegeChoice = PrivilegeChoice;
    if (privilegeChoice.includes(privilegeName)) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Drawer
        title={props.flag ? "Edit role" : "Add role"}
        maskClosable={false}
        visible={true}
        onClose={close}
        onCancel={close}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form form={form} className="user-form" initialValues={{ ...props.role }} onFinish={handleSubmit} layout="vertical">
          <Form.Item label={<Title level={5} style={{color:'white', marginBottom:'0'}}>Role</Title>} name="name" rules={[{ required: true, message: "Please enter Role!" }]}>
            <Input name="name" placeholder="Role Name" />
          </Form.Item>
          <Form.Item label={<Title level={5} style={{color:'white', marginBottom:'0'}}>For User</Title>} name="external" rules={[{ required: true, message: "Please Select Internal or External!" }]}>
            <Select placeholder="Select Internal or External" style={{ width: 120 }}>
              <Option value={false}>Internal</Option>
              <Option value={true}>External</Option>
            </Select>
          </Form.Item>
          {props.flag && isOpenPrivilegeCard ? (
            <Form.Item label={<Title level={4} style={{color:'white', marginBottom:'0'}}>Select privileges</Title>} name="privileges">
              <div>
                <Checkbox onClick={(e) => selectAll(e.target.checked)} indeterminate={indeterminate} checked={checkAll} >
                <Title level={5} style={{color:'white'}}> Select all </Title>
                </Checkbox>
                <Row>
                  {(PrivilegeOptions || []).map((privilege) => {
                    return (
                      <Col span={24}>
                        <Checkbox
                          key={privilege.id}
                          value={privilege.name}
                          checked={isChecked(privilege.name)}
                          onClick={(e) => handleCheckboxChange(privilege.name, e.target.checked)}
                          style={{marginBottom:'5px'}}
                        >
                         <span style={{color:'white'}}>{privilege.name}</span>
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Form.Item>
          ) : (
            <Form.Item></Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <span>&nbsp;&nbsp;</span>
            <Button type="secondary" className="user-form-button" onClick={close}>
              Close
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
