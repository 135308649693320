import { Col, Collapse, Divider, Row, Space, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import headOfficeLocationCode from "../extra/images/head_office_location.png";

const MetalPowerInfo = ({active}) => {

  const navigate = useNavigate();

  return (
    <>
        <Row gutter={16} justify='center'>
            <Col md={20} sm={22} xs={24}>
                <Collapse accordion activeKey={active} bordered={false} onChange={key => key !== undefined && navigate(`/${key}`)}>
                    <Collapse.Panel header="About Us" key="aboutus" style={{margin:'20px 0'}}>
                        <Space direction='vertical' style={{padding:'10px'}}>
                            <Typography.Paragraph>
                                Metal Power was founded with the sole objective to make the highest levels of quality not only available, but also affordable to all manner of buyers globally. Founded in the face of a rigid market structure with prices that drove away most buyers, Metal Power has always ensured that our efforts deliver the highest level of quality along with the best economy over the product lifecycle. True pioneers in the field of CCD-based Optical Emission Spectrometers, our product philosophy is driven by the focus on offering a tailored model to meet every customer need – both analytical and financial. As an outcome, we offer the world’s widest range of spectrometers – with each offering positioned to be the best-in-class in terms of features as well as on economic value.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                Founded with the vision of ensuring that the optimal spectrometer model is affordable and attainable for every single user in metals industries, Metal Power today prides itself on having delivered more than any other in this pursuit.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Profile and History
                            </Typography.Title>
                            <Typography.Paragraph>
                                Founded in 1987, Metal Power provides a comprehensive range of products, applications and services to meet the analytical needs of metal industries. Today the Company boasts over 35 years of experience serving the needs of ferrous and non-ferrous metals industries and has a truly global presence, spread across more than 35 countries in 5 continents. The common denominator across all our offerings – products, applications and services – remains our commitment to deliver the highest level of quality, reliability and uptime, while also offering the lowest cost over the product lifecycle, through low acquisition costs, backed by the highest quality of service and after-sales support to our customers – wherever they may be.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                Our solutions have helped clients improve the quality of their products while also delivering millions in ongoing cost-savings and revenue enhancements through material cost savings, elimination of rework / rejections, dramatic improvements in productivity and of course, research and development of newer materials and alloys. Today, our instruments power several leading companies across industry sectors and geographical markets.
                            </Typography.Paragraph>
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel header="Privacy Policy" key="privacy-policy" style={{margin:'20px 0'}}>
                        <Space direction='vertical' style={{padding:'10px'}}>
                            <Typography.Paragraph>
                                Metal Power Analytical Private Limited (MPA) takes the privacy of your data extremely seriously. MPA has taken multiple steps to ensure the best possible protection of your data when subscribing to and using MetaCloud. This privacy policy explains the ways in which your information is collected and used.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Intended Use
                            </Typography.Title>
                            <Typography.Paragraph>
                                MetaCloud has been designed by Metal Power Analytical Private Limited (MPA) specifically for its customers across the world to facilitate a cloud-based solution including real-time and historical data, visualization, logs, statistics and infographics of analysis results and diagnostic data of their Spectrometer/s on Mobile and Web platforms. In addition to this, MetaCloud also enables downloads of the latest software updates and integrates directly with MPA’s Salesforce CRM enabling a host of other benefits for users.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Collection of Routine Information
                            </Typography.Title>
                            <Typography.Paragraph>
                                MetaCloud tracks and stores basic information about users like First Name, Last Name, Email ID, and Contact details such as the Phone Number and Mailing Address, which is collected at the time of registration. This information is stored for routine administration and maintenance purposes. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Access to Spectrometer data
                            </Typography.Title>
                            <Typography.Paragraph>
                                MetaCloud application is paired with the User’s spectrometer/s through a QR code generated on the PC connected to the OES with a full three-step authentication process to ensure that only the intended instrument is paired with the User’s app and to minimize the chances of unauthorized access for to a User’s instrument. After authentication and pairing, Metal Power reserves the right to upload and store the analysis and diagnostics data from OES on a secure cloud platform. The cloud platform service provider selected and used by MPA is globally renowned for offering industry-leading security, privacy, scalability and stable performance and is in full compliance with the very best privacy and security standards in the world including compliance with the European Union’s General Data Protection Regulation (GDPR).
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                User Management
                            </Typography.Title>
                            <Typography.Paragraph>
                                MetaCloud assigns the first user as the Administrator for the organization and the Administrator shall have rights to add/modify/delete users through ‘The Analyst’ application installed on the PC connected to the spectrometer. Users of MetaCloud must keep the account details– including Instrument Serial Number – secure at all times and must not share these with anyone. In case of breaches on this aspect, MPA shall not be responsible for unauthorized access, loss or misuse of data stored on the MetaCloud platform.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Cookies
                            </Typography.Title>
                            <Typography.Paragraph>
                                Wherever necessary, MetaCloud uses cookies to store information about a visitor’s preferences and history in order to better serve the user and/or present the user with customized content.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Advertisement and Other Third Parties
                            </Typography.Title>
                            <Typography.Paragraph>
                                MetaCloud doesn’t have any advertising partners or carry any third-party advertisements during the usage of application. Thus MetaCloud has no access to or control over cookies, scripts and/or web beacons that may be used by third parties outside the application. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Security
                            </Typography.Title>
                            <Typography.Paragraph>
                                The security of your personal information is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use the best-available commercial security measures to protect your information, MPA cannot guarantee its absolute security. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Changes To This Privacy Policy
                            </Typography.Title>
                            <Typography.Paragraph>
                                This Privacy Policy is effective as of 27th October 2022 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                MPA reserves the right to update or change the Privacy Policy at any time and you should check this Privacy Policy periodically. If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice across all MetaCloud platforms.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Contact Information
                            </Typography.Title>
                            <Typography.Paragraph>
                                For any questions or concerns regarding the privacy policy, please send us an email to <a href = "mailto: service@metalpower.net">service@metalpower.net</a> . 
                            </Typography.Paragraph>
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel header="Contact Us" key="contactus" style={{margin:'20px 0'}}>
                        <Space direction='vertical' style={{padding:'10px', width:'100%'}} split={<Divider />} size='small'>
                            <Typography.Title level={3} style={{marginBottom:'-20px'}}>
                                Head Office
                            </Typography.Title>
                            <Row gutter={16}>
                                <Col lg={8} md={12} sm={12}>
                                    <Typography.Title style={{color:''}} level={5}>METAL POWER ANALYTICAL PVT. LTD.</Typography.Title>
                                    <Typography.Paragraph>Metal Power House, 87,</Typography.Paragraph>
                                    <Typography.Paragraph>Marol Industrial Estate, Andheri(East),</Typography.Paragraph>
                                    <Typography.Paragraph>Mumbai – 400 059</Typography.Paragraph>
                                    <Typography.Paragraph style={{marginBottom:'30px'}}>BHARAT(INDIA).</Typography.Paragraph>
                                    <Typography.Paragraph>Telephone: +91-22-40830500</Typography.Paragraph>
                                    <Typography.Paragraph>Facsimile: +91-22-40830564</Typography.Paragraph>
                                    <Typography.Paragraph>E-Mail: sales@metalpower.net</Typography.Paragraph>
                                </Col>
                                <Col lg={8} md={12} sm={12}>
                                    <Typography.Title style={{color:''}} level={5}>For Sales:</Typography.Title>
                                    <Typography.Paragraph>Email: sales@metalpower.net</Typography.Paragraph>
                                    <Typography.Paragraph style={{marginBottom:'30px'}}>Phone: +91-22-40830500</Typography.Paragraph>
                                    <Typography.Title style={{color:''}} level={5}>For Service:</Typography.Title>
                                    <Typography.Paragraph>Email: service@metalpower.net</Typography.Paragraph>
                                    <Typography.Paragraph>Phone (Domestic–Toll-free): 1800 5325 111</Typography.Paragraph>
                                    <Typography.Paragraph>Phone: +91-22-40830500</Typography.Paragraph>
                                </Col>
                                <Col lg={8} md={12} sm={12}>
                                    <img src={headOfficeLocationCode} style={{width:'100%'}}/>
                                </Col>
                            </Row>
                        </Space>
                    </Collapse.Panel>
                    <Collapse.Panel header="Terms & Conditions" key="terms-conditions" style={{margin:'20px 0'}}>
                        <Space direction='vertical' style={{padding:'10px'}}>
                            <Typography.Paragraph>
                                Welcome to the terms and conditions for MetaCloud. These Terms are between you and Metal Power Analytical Private Limited, India and govern our respective rights and obligations. Please note that your use of MetaCloud application/website and its subscription are also governed by the agreements listed and linked to below, as well as all other applicable terms, conditions, limitations, and requirements on the MetaCloud application/website, all of which (as changed over time) are incorporated into these Terms. If you sign up for / subscribe to MetaCloud, you indicate acceptance of these terms, conditions, limitations and requirements.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Description of Service
                            </Typography.Title>
                            <Typography.Paragraph>
                                Metal Power Analytical Private Limited (also known as MPA) is a private company incorporated under the Companies Act, 1956, having its registered office at 87, Metal Power House, Plot No. 14, Marol Co-operative Industrial Estate, Andheri East, Mumbai - 400 059, Maharashtra, India. MPA designs and manufacturers a wide range of spectrometers . To support its customers in leveraging the benefits of digital transformation and cloud-based solutions as well as moving to Industry 4.0, MPA has launched a  first of its kind cloud-based mobile application called MetaCloud that offers real-time and historical data, visualization, logs, statistics and infographics of analysis results and diagnostic data of their Spectrometer on Mobile and Web platforms. In addition to this, MetaCloud will also enable downloads of the latest software updates and integrate directly with MPA’s Salesforce CRM enabling a host of other benefits for users. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                General
                            </Typography.Title>
                            <Typography.Paragraph>
                                MetaCloud, in all of its forms, whether it be featured on the MetaCloud Online website or any of the MetaCloud mobile application (“apps”) services, is a product of Metal Power Analytical Private Limited (MPA). By using the MetaCloud Online website or any of the MetaCloud mobile apps services, you are agreeing to be bound by the following terms and conditions.  Metal Power reserves the right to update and change the terms and conditions that apply to all users without notice. The updated terms and conditions will only be in effect once they are published on the MetaCloud Online website or application. Continued use of the MetaCloud product after any such changes shall constitute your consent to such changes. The definition of “The User” used in this document pertains at all times to the organization that is the intended party using the MetaCloud application. The User is responsible for maintaining the security of their online account and password. Read our ‘Privacy Policy’ before using the application. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Service Availability
                            </Typography.Title>
                            <Typography.Paragraph>
                                Subject to instrument, device and/or browser compatibility, The User shall be able to access the MetaCloud services through mobile phones, tablets, computers and other Internet connected devices. Installer files / applications can be downloaded from Apple’s App Store or Google’s Play Store. The User may also access MetaCloud by visiting <b>metacloud.metalpower.net</b>. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Promotional Trial and Subscription Plans
                            </Typography.Title>
                            <Typography.Paragraph>
                                MPA offers a free subscription to every new user for a period of 30 days with full-access to the top-tier ‘Expert’ plan where users can pair their instrument to their cloud account by completing the sign-up and verification process, followed a few simple in-app steps. The User has full freedom to utilize all application features during the trial period and shall have the option to subscribe to one of the available plans listed under subscription menu upon completion of the trial period. Upon expiry of the trial period, The User will be denied access to cloud services but shall still retain the ability to subscribe to a plan and can resume the service at any future time. Previously stored data from The User’s instrument will remain available for a maximum of six-months after subscription (Free or Paid) expiry and in case of failure to renew the subscription within this period, all such data shall be deleted. All user data shall then be uploaded from the instrument at the time of sign-up in case of a subscription being bought in the future.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                Trial members may at any time choose not to continue to paid membership during or at the end of the trial period. Payment shall not be debited automatically unless a user subscribes to a plan or uploads credit card/UPI details for automatic debit.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Fees and Renewal
                            </Typography.Title>
                            <Typography.Paragraph>
                                The subscription fee for MetaCloud is listed under the ‘Subscription’ menu. From time to time, MPA may offer different subscription plans, and the fees for such plans may vary. The subscription fee is non-refundable except as expressly set forth in these Terms. Taxes are charged extra as applicable at the time and country of origin of purchase.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                The User is to provide the hardware and internet connections required to use the service and shall be solely responsible for all costs and fees associated with that usage.
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                All service charges are based on MetaCloud standard features being provided through subscription plans. Additional feature requirements may incur additional charges pre-agreed with the user. Unless otherwise notified, the monthly subscription charge is to be determined by the user’s selected plan per calendar month/year.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Subscription Cancellation and Refund Policy:
                            </Typography.Title>
                            <Typography.Paragraph>
                                The User may cancel a subscription at any time by visiting ‘Your Account’ and adjusting the subscription settings. Any amount paid for the ongoing subscription shall not be refundable and no further amount shall be charged to the customer for any further period once the subscription has been cancelled.. MPA reserves the right to contact the customer and discuss alternative plans with the User. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Security of your payment information
                            </Typography.Title>
                            <Typography.Paragraph>
                                Metal Power does not have any access to or store payment information like card details, PIN numbers and never stores any data related to this. MPA uses the services of a third-party, PayTM Payment Gateway that ensure complete security of all transactions, and ensures compliance with all privacy and security regulations. It has an SSL certificate that ensures users' transactions and data are fully secure. It uses encryption to ensure robust end-to-end data security, which keeps it secure from possible cyber threats. 
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Other Limitations
                            </Typography.Title>
                            <ul>
                                <li><Typography.Paragraph>MPA reserve the right to accept or refuse subscriptions at our discretion.</Typography.Paragraph></li>
                                <li><Typography.Paragraph>We may send you email and other communications related to your MetaCloud Subscription (regardless of any settings or preferences related to your MetaCloud account).</Typography.Paragraph></li>
                                <li><Typography.Paragraph>From time to time, MPA may choose at its sole discretion to add or remove specific MetaCloud Subscription benefits within or across tiers.</Typography.Paragraph></li>
                                <li><Typography.Paragraph>It is understood and agreed by The User that issues may occur and that in the event of any customer complaint or concern The User shall refer it, or them, to MPA either via MetaCloud’s Support section or directly over email to <a href = "mailto: service@metalpower.net">service@metalpower.net</a>. This shall not form a basis for voiding The User’s obligation to pay MPA or constitute a burden on MPA to modify terms or duration of the subscription.</Typography.Paragraph></li>
                                <li><Typography.Paragraph>MPA shall not accept responsibility or liability for any issues related to servers, hosting service or networks, including but not limited to downtime, delayed response time, network issues, service interruptions etc.</Typography.Paragraph></li>
                            </ul>
                            <Typography.Title level={5}>
                                Price Index
                            </Typography.Title>
                            <Typography.Paragraph>
                                MPA reserves the right to index all prices and charges to increase at 5% annually on January 1 each year beyond the charges that applied prior to such increase occurring and the client acknowledges that MPA is authorized to apply such increases on future monthly and any other sums payable to MPA for use of MetaCloud.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Updates
                            </Typography.Title>
                            <Typography.Paragraph>
                                MPA may roll out updates to MetaCloud across platforms which may add, modify and/or remove specific features. These updates may be pushed out automatically without notice, although Metal Power intends to notify the Client in advance updates with details on what the update includes.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Additional Charges
                            </Typography.Title>
                            <Typography.Paragraph>
                                No additional programming or support charges shall be levied by MPA to The User apart from the subscription and monthly charges unless required and agreed by The User in writing, in advance of such work. Where MPA should incur any additional charges, MPA shall notify The User of any such charges as soon as practicable thereafter and The User shall have their right to decline same.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Agreement Changes
                            </Typography.Title>
                            <Typography.Paragraph>
                                MPA may at its own discretion change these Terms & Conditions of Use and the Privacy Notice, or any aspect of MetaCloud subscriptions, without notice. If any change to these terms is found invalid, void, or for any reason unenforceable, that change is severable and does not affect the validity and enforceability of any remaining changes or conditions. YOUR CONTINUED SUBSCRIPTION AFTER MPA CHANGES THESE TERMS CONSTITUTES YOUR ACCEPTANCE OF THE CHANGES. IF YOU DO NOT AGREE TO ANY CHANGES, YOU MUST CANCEL YOUR SUBSCRIPTION.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Termination by Us
                            </Typography.Title>
                            <Typography.Paragraph>
                                MPA reserves the right to terminate the service for any reason on reasonable grounds, including if payment is not received within a maximum of 7 days of it being due. The decision to terminate shall be communicated to The User as soon as practicable after making such a decision. Further, in the event that the service is discontinued on account of failure to pay, MPA reserves the right to charge a reactivation fee if  the user requests that the service be reactivated and MPA were to agree to reactivate it. In the event of service termination The User agrees that MPA has the right to destroy any customer data unless payment for transfer of it is agreed by the Client with MPA within six months of service cancellation.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Limitation of Liability
                            </Typography.Title>
                            <Typography.Paragraph>
                                IN ADDITION TO OTHER LIMITATIONS AND EXCLUSIONS IN THE METACLOUD CONDITIONS OF USE, OUR TOTAL LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED THE LAST SUBSCRIPTION FEE YOU PAID. THIS LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND WILL SURVIVE CANCELLATION OR TERMINATION OF YOUR METACLOUD SUBSCRIPTION.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Disputes
                            </Typography.Title>
                            <Typography.Paragraph>
                                In the event of any dispute between the client and MPA, it is agreed between both parties that the maximum claim allowed against MPA shall be not more than the standard monthly cost levied by MPA to that client/user. Any other dispute or claim relating in any way to these Terms or your usage of MetaCloud will be adjudicated in Mumbai, India, and you consent to exclusive jurisdiction and venue in these courts. We each waive any right to a jury trial.
                            </Typography.Paragraph>
                            <Typography.Title level={5}>
                                Agreement on Organization behalf
                            </Typography.Title>
                            <Typography.Paragraph>
                                By completing the online order or by signing the authorisation to proceed the user is acknowledging on behalf of its organization that it has obtained authorization to enter this agreement upon these Terms and Conditions.
                            </Typography.Paragraph>
                        </Space>
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </Row>
    </>
  )
}

export default MetalPowerInfo