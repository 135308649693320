import React from "react"
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import AppHeader, { PublicHeader } from "./app_header";
import AppFooter from "./app_footer";
import { useAuth } from "../helpers/auth";

const { Content } = Layout;
const BaseLayout = () => {
    const { user } = useAuth();
    return (
        <Layout>
            {user && <AppHeader />}
            {!user && <PublicHeader />}
            
                {user &&
                        <Content style={{ minHeight: "calc(100vh - 80px)", marginTop: '64px' }} className="comp_bg">
                            <div className="main-content-wrapper" style={{margin:20}}>
                                <Outlet />
                            </div>
                        </Content>

                }
                {!user &&
                    <Content style={{ minHeight: "calc(100vh - 80px)", marginTop: '64px' }} className="comp_bg">
                        <div className="main-content-wrapper">
                            <Outlet />
                        </div>
                    </Content>
                }
            <AppFooter />
        </Layout>
    );
};

export default BaseLayout

