import React, {useEffect, useState} from "react";
import {Table} from "antd";

export default function DiagnosticAuxDetails({data}) {
    const [cards, setCards] = useState([])

    useEffect(() => {
        setCards(data.children);
    }, [data])

    const columns = [
        {
            title: "Aux Details",
            key: "auxDetails",
            dataIndex: "type"
        },
        {
            title: "Value/Status",
            key: "status",
            dataIndex: "value",
        },
    ]
    return (
        <div>
            <Table dataSource={cards} columns={columns} pagination={false} rowKey="auxDetails" rowClassName={(record, index) => index % 2 === 0 ? 'row_light' :  'row_dark'}/>
        </div>
    );
}