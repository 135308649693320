import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../helpers/auth";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Row, Col, Button, Modal, Drawer, Form, Space, notification, Typography, Input, message, Table, Card, Select, Spin } from "antd";
import DeviceService from "../services/DeviceService";
import UserService from "../services/UserService";
import { Link, useLocation } from "react-router-dom";
const { Title } = Typography;
const { confirm } = Modal;

function MyDevices() {
  const { user: activeUser, setRefreshMenu, updateToken } = useAuth();

  const location = useLocation();
  
  const [form] = Form.useForm();
  
  const privilege = activeUser.privileges;
  
  const [visible, setVisible] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [deviceDataAfterVerify, setDeviceDataAfterVerfiy] = useState([]);
  const [visibleForEmailSend, setVisibleForEmailSend] = useState(false);
  const [visibleForPairDevice, setVisibleForPairDevice] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [anOtherEmail, setAnOtherEmail] = useState(false);
  const [selectedDeviceSerialNumber, setSelectedDeviceSerialNumber] = useState('');
  const [orgEmailList, setOrgEmailList] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState({});

  useEffect(() => {
    getUserInfo();
    getActiveDevices();
    fetchOrgEmailList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [userInfo]);

  const upDateMenuItem = () => {
    setRefreshMenu(Math.random());
  };

  const fetchOrgEmailList = () => {
    UserService.getAllOrgEmails().then(res => {
      setOrgEmailList(res.data);
    })
      .catch(err => {
        console.log("Error fetching the Organization Emails");
      })
  }

  const getActiveDevices = () => {
    DeviceService.getActiveDevices().then(
      (response) => {
        if (response !== undefined) {
          getCustomDeviceNames(response.data).then(res => {
            setDeviceDataAfterVerfiy(res);          
          })
        }
      },
      (error) => {
        message.error("");
      }
    );
  };

  const getCustomDeviceNames = (devices) => {
    return Promise.all(devices.map(device => new Promise((resolve, reject) => {
      DeviceService.getMappedDeviceName(device.deviceName).then(res => {
        resolve({ ...device, deviceName: res.data.customName })
      }).catch(err => {
        reject(device)
      })
    })))



    // return devices.map(device => {
    //   return Promise.all(DeviceService.getMappedDeviceName(device.deviceName)).then(res => {
    //       return ({...device, deviceName: res.data.customName});
    //   })
    // })
    // return customDevices;
  }

  const handleSubmit = (deviceSerialNumber) => {
    let serialNumber = deviceSerialNumber.deviceSerialNumber;
    DeviceService.getVerifyDevices(serialNumber)
      .then((response) => {
        setDeviceDetails(response.data);
        let companyName = response.data.orgLegalName;
        let deviceId = response.data.id;
        confirm({
          title: "Serial No. " + serialNumber + " is linked with " + companyName,
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            UserService.assignUserTODeviceOrg(deviceId).then(
              (response) => {
                setLoading(true);
                updateToken().then(() => {
                  // console.log({res});
                  getActiveDevices();
                  upDateMenuItem();
                  setLoading(false);
                  message.success("Device added successfully");
                });
              },
              (error) => {
                message.error("Please ask your organization admin to make you active.");
              }
            );
            setVisible(false);
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      })
      .catch((error) => {
        notification.error({
          type: "info",
          description: "Device Not Exist Please try with another Serial Number",
          duration: 2,
        });
      });
  };

  const getUserInfo = () => {
    UserService.getUserProfile()
      .then((respons) => {
        setUserInfo(respons.data);
        if(respons.data.pendingStatus){
          UserService.getUserOrgAdminDetails().then(res => {
            setOrgDetails(res.data[0]);
        })
        }
      })
      .catch((error) => {
        notification.error({
          type: "warning",
          description: "Something Went Worng",
          duration: 2,
        });
      });
  };

  const downloadAnalystAp = (email) => {
    let mail = email.email;
    setRequestLoading(true);
    setVisibleForEmailSend(false);
    DeviceService.postDownloadAnalystApp(mail, userInfo, selectedDeviceSerialNumber)
      .then((response) => {
        notification.info({
          type: "info",
          description: response.data,
          duration: 2,
        });
        setRequestLoading(false)
      })
      .catch((error) => {
        notification.error({
          type: "warning",
          description: "Something Went Worng ",
          duration: 2,
        });
        setRequestLoading(false)
      });
  };

  const otherEmail = () => {
    setAnOtherEmail(false);
  };
  const suggestedEmail = () => {
    setAnOtherEmail(true);
  };

  const verifyQRCode = (qrcode) => {
    let qcode = qrcode.qrcode;
    DeviceService.getChangeDevicePairingStatus(deviceId, qcode)
      .then((response) => {
        notification.info({
          type: "info",
          description: "Pairing completed successfully",
          duration: 2,
        });
        getActiveDevices();
        setVisibleForPairDevice(false);
      })
      .catch((error) => {
        notification.error({
          type: "warning",
          description: "Invalid QR code.",
          duration: 2,
        });
      });
  };

  const showVerifyDeviceForm = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const sendForDownloadAnalystSetupForm = (serialNumber) => {
    setAnOtherEmail(false)
    setVisibleForEmailSend(true);
    setSelectedDeviceSerialNumber(serialNumber);
    getUserInfo();
  };
  const onCloseForDownloadAnalystSetupForm = () => {
    setVisibleForEmailSend(false);
  };

  const pairDevice = (deviceId) => {
    setDeviceId(deviceId);
    setVisibleForPairDevice(true);
  };

  const onClosepairDevice = () => {
    setVisibleForPairDevice(false);
  };

  const showAccessDeniedMessage = () => {
      notification.error({
          type: "info",
          description: `Please ask your Org Admin [${orgDetails.name}] to allow access from ‘The Analyst’ application.`,
          duration: 2,
          style: {padding: 25}
      });
  }

  const columns = [
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Pairing Status",
      dataIndex: "paired",
      key: "paired",
      render: (text, record) => {
        let pairedStatus;
        if (record.paired) {
          pairedStatus = "Paired";
        } else {
          pairedStatus = "Pending";
        }
        return <>{pairedStatus}</>;
      },
    },
    {
      title: "Pairing Guide",
      dataIndex: "p",
      key: "pairingGuid",
      render: (text, record) => {
        let devicId = record.id;
        return (
          <>
            Step-1:
            <Button type="link" onClick={() => userInfo && !userInfo.pendingStatus ? sendForDownloadAnalystSetupForm(record.serialNumber) : showAccessDeniedMessage()}>
              Click Here
            </Button>{" "}
            to download the new Analyst setup for your PC connected to your OES
            <br></br> Step-2: Install the new Analyst Software
            <br></br>Step-3: Click on MetaCloud icon on the Home Screen to generate the Code<br></br>
            Step-4:{" "}
            <Button type="link" onClick={() => userInfo && !userInfo.pendingStatus ? pairDevice(devicId) : showAccessDeniedMessage()}>
              Click Here
            </Button>{" "}
            to scan or enter the QR Code
            <br></br>Step-5: Let the data sync happen and you are now ready to use!
          </>
        );
      },
    },
    {
      title: "Device Information",
      dataIndex: "d",
      key: "deviceInformation",
      render: (text, record) => {
        return (
          <>
            <Link to={userInfo && !userInfo.pendingStatus ? "/admin/devices" : location.pathname} onClick={() => userInfo && userInfo.pendingStatus && showAccessDeniedMessage()}> Click Here </Link>
            <br></br>(This is inactive until successful pairing)
          </>
        );
      },
    },
  ];

  return (
    <>
      {" "}
      <div className="site-card-wrapper" style={{ margin: "30px", marginLeft: "0" }}>
        <Row gutter={2} justify='space-between'>
          <Col xs={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1 }}>
            {privilege.includes("VERIFY_DEVICE") && (
              <Button type="primary" onClick={showVerifyDeviceForm}>
                Add Device <PlusCircleOutlined />
              </Button>
            )}
          </Col>
          <Col span={2} style={{ marginRight: '30px', display: 'flex', justifyContent: 'end' }}>
            {requestLoading && <Spin />}
          </Col>
        </Row>

        <Drawer
          title="Verfiy Device"
          onClose={onClose}
          visible={visible}
          onCancel={onClose}
          closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
        >
          <Form form={form} onFinish={handleSubmit} className="user-form" layout="vertical">
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Enter Device Serial Number
                </Title>
              }
              name="deviceSerialNumber"
              rules={[{ required: true, message: "Please Enter Device Serial Number" }]}
            >
              <Input allowClear placeholder="Device Serial Number" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Verfiy
                </Button>
                <Button className="ml-2 device-form-button" onClick={onClose} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
      <Row gutter={24}>
        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 16, offset: 1 }}>
          <Card className="limitable" bodyStyle={{ padding: "0px", width: "140%" }}>
            <Table
              size="middle"
              // onChange={handleTableChange}
              loading={loading}
              columns={columns}
              dataSource={deviceDataAfterVerify}
              rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
            // pagination={{ showSizeChanger: true, total: totalUsers }}
            />
          </Card>
        </Col>
      </Row>
      <Drawer
        title="Send Link Of Analyst Software"
        onClose={onCloseForDownloadAnalystSetupForm}
        visible={visibleForEmailSend}
        onCancel={onCloseForDownloadAnalystSetupForm}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        {!anOtherEmail && (
          <Form
            form={form}
            // initialValues={{
            //   ...userInfo,
            // }}
            onFinish={downloadAnalystAp}
            className="user-form"
            layout="vertical"
          >
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Choose one of the below verified email-id's to get the link
                </Title>
              }
              name="email"
              rules={[{ required: true, message: "Please Select Email" }]}
            >
              <Select allowClear="true" placeholder="Select one of the email Id">
                {orgEmailList.map(email => {
                  return <Select.Option key={email.uid} value={email.email}>{email.email}</Select.Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
                <Button className="ml-2 device-form-button" onClick={onCloseForDownloadAnalystSetupForm} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
        {anOtherEmail && (
          <Form form={form} onFinish={downloadAnalystAp} className="user-form" layout="vertical">
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Enter New Email Id
                </Title>
              }
              name="email"
              rules={[{ required: true, message: "Please Enter Email" }]}
            >
              <Input type="email" allowClear placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
                <Button className="ml-2 device-form-button" onClick={onCloseForDownloadAnalystSetupForm} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
        {anOtherEmail ? (
          <Button type="primary" onClick={otherEmail}>
            Verified Email ID
          </Button>
        ) : (
          <Button type="primary" onClick={suggestedEmail}>
            Enter new Email ID
          </Button>
        )}
      </Drawer>
      <Drawer
        title="Pairing Device"
        onClose={onClosepairDevice}
        visible={visibleForPairDevice}
        onCancel={onClosepairDevice}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form form={form} onFinish={verifyQRCode} className="user-form" layout="vertical">
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Enter the QR Code generated from the new Analyst software in your PC
              </Title>
            }
            name="qrcode"
            rules={[{ required: true, message: "Please Enter QR Code" }]}
          >
            <Input allowClear placeholder="QR Code" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Verfiy
              </Button>
              <Button className="ml-2 device-form-button" onClick={onClosepairDevice} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
export default MyDevices;
