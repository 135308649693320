import { Button, Table, Row, Col, Card, Input, message, Select, Tooltip, Space, InputNumber } from "antd";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CrmService from "../../services/CrmService";
import NewSupportTicket from "./NewSupportTicket";
import { useAuth } from "../../helpers/auth";
import { constant } from "lodash";
import UserService from "../../services/UserService";
import DeviceService from "../../services/DeviceService";
const { Option } = Select;

function SupportView(props) {
  const { user: activeUser } = useAuth();
  const privilege = activeUser.privileges;
  const [tickets, setTickets] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalTickets, setTotalTickets] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  let deviceId = props.deviceId;
  const colors = ['#2980B9'];
  const [companyName, setCompanyName] = useState("");
  const [deviceSerialNumber, setDeviceSerialNumber] = useState("");
  const [caseNumber, setCaseNumber] = useState();

  const columns = [
    {
      title: "Company Name",
      dataIndex: "orgName",
      key: "orgName",
      hidden: userInfo.client ? true : false,
    },
    {
      title: "Case Number",
      dataIndex: "caseNumber",
      key: "caseNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Case Type",
      dataIndex: "caseType",
      key: "caseType",
    },
    {
      title: "Subject",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text, record) => {
        let dueDate = record.dueDate;
        if (dueDate) {
          dueDate = dueDate.slice(0, 10);
        }
        return <>{dueDate}</>;
      },
    },
    {
      title: "Support Engineer",
      dataIndex: "supportEngineer",
      key: "supportEngineer",
    },
    {
      title: "Scheduled On",
      dataIndex: "scheduledOn",
      key: "scheduledOn",
      render: (text, record) => {
        let scheduledOn = record.scheduledOn;
        if (scheduledOn) {
          scheduledOn = scheduledOn.slice(0, 10);
        }
        return <>{scheduledOn}</>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    if (props.deviceId) {
      let currentPage = 0;
      let deviceSerialNumber = props.deviceId;
      loadData(companyName, deviceSerialNumber, caseNumber, currentPage, pageSize);
    } else {
      loadData(companyName, deviceSerialNumber, caseNumber, currentPage, pageSize);
    }
    getUserInfo();
  }, [props.deviceId]);


  function getUserInfo() {
    UserService.getUserProfile()
      .then((req) => {
        setUserInfo(req.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  const createSupportTicket = () => {
    setShowNew(!showNew);
  };

  function loadData(company_Name, device_Serial_Number, case_Number, currentPage, pageSize) {
    setLoading(true);
    let legalName = company_Name;
    let deviceSerialNumber = device_Serial_Number;
    let caseNumber = case_Number;
    let cPage = currentPage;
    let pSize = pageSize;
    if (cPage === undefined && pSize === undefined) {
      cPage = 0;
      pSize = 10;
    }
    CrmService.loadServiceTicketsBySearchCriteria(legalName, deviceSerialNumber, caseNumber, cPage, pSize)
      .then((response) => {
        setTickets(response.data.list);
        setPageSize(response.data.pageSize);
        setTotalTickets(response.data.total);
        setCurrentPage(response.data.pageNumber);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Something went wrong");
        setLoading(false);
      });
  }

  const handleTableChange = (e) => {
    let currentPage = e.current - 1;
    let pageSize = e.pageSize;
    loadData(companyName, deviceSerialNumber, caseNumber, currentPage, pageSize);
  };


  const getdata = () => {
    showBySearchCriteria(companyName, deviceSerialNumber, caseNumber, currentPage, pageSize);
  }


  function showBySearchCriteria(company_Name, device_Serial_Number, case_Number, currentPage, pageSize) {
    setLoading(true);
    let legalName = company_Name;
    let deviceSerialNumber = device_Serial_Number;
    let caseNumber = case_Number;
    setCurrentPage(0);
    setPageSize(10);
    let cPage = 0;
    let pSize = 10;
    if (cPage === undefined && pSize === undefined) {
      cPage = 0;
      pSize = 10;
    }
    if (caseNumber === undefined || caseNumber === null) {
      caseNumber = "";
    }
    if (legalName === undefined || legalName.length === 0) {
      legalName = null;
    }
    if (deviceSerialNumber === undefined || deviceSerialNumber.length === 0) {
      deviceSerialNumber = null;
    }
 
    CrmService.loadServiceTicketsBySearchCriteria(legalName, deviceSerialNumber, caseNumber, cPage, pSize)
      .then((response) => {
        if (typeof deviceId !== "undefined") {
          setTickets(response.data.list.filter((el) => el.id === deviceId));
        } else {
          setTickets(response.data.list);
        }
        setPageSize(response.data.pageSize);
        setTotalTickets(response.data.total);
        setCurrentPage(response.data.pageNumber);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Something went wrong");
        setLoading(false);
      });
  };

  const onCompanyName = (e) => {
    setCompanyName(e.target.value);
  }

  const onDeviceSerialNumber = (e) => {
    setDeviceSerialNumber(e.target.value);
  }

  const onCaseNumber = (value) => {
    setCaseNumber(value);
  };

  const onReset = () => {
    setCompanyName("");
    setDeviceSerialNumber("");
    setCaseNumber("");
    loadData("", "", "", 1, 10);
  }

  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={2}>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 18, offset: 1 }}>
            <Space>
              {privilege.includes("CRM_TICKET_RAISE") && userInfo.roleResponses && userInfo.roleResponses[0].external &&
                <Button type="primary" onClick={createSupportTicket}>
                  Raise Service Request <PlusCircleOutlined />
                </Button>
              }
              {props.deviceId === undefined ?
                <Space>
                  {userInfo.client ? null : <span>
                    {colors.map((color) => (
                      <Tooltip title="Company name" placement="topLeft" arrowPointAtCenter color={color} key="coName">
                        <Input placeholder="Company Name" value={companyName} onChange={onCompanyName} />
                      </Tooltip>
                    ))}
                  </span>
                  }
                  {colors.map((color) => (
                    <Tooltip title="Device serial number" placement="topLeft" arrowPointAtCenter color={color} key="deNumber">
                      <Input placeholder="Device serial number" value={deviceSerialNumber} onChange={onDeviceSerialNumber} />
                    </Tooltip>
                  ))}
                  {colors.map((color) => (
                    <Tooltip title="Enter Case Number " placement="topLeft" arrowPointAtCenter color={color} key="cNumber">
                      <InputNumber placeholder="Case number" value={caseNumber} onChange={onCaseNumber} />
                    </Tooltip>
                  ))}
                  <Button type="primary" onClick={getdata}>
                    Search
                  </Button>
                  <Button type="primary" onClick={onReset}>
                    Reset
                  </Button>
                </Space>
                : null}
            </Space>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={{ span: 24}} lg={{ span: 22, offset: 1 }}>
              <Table
                scroll={{ x:true}}
                loading={loading}
                columns={columns}
                onChange={handleTableChange}
                dataSource={tickets}
                rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                pagination={{ showSizeChanger: true, total: totalTickets }}
              />
          </Col>
        </Row>

        {showNew && <NewSupportTicket createSupportTicket={createSupportTicket} loadData={loadData} deviceId={props.deviceId} />}
      </div>
    </>
  );
}

export default SupportView;
