import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Typography, Card, Form, Image } from "antd";
import { useAuth } from "../helpers/auth";
import { useNavigate } from "react-router-dom";
//import bgImg from "../extra/images/bg2.png";
import mpImg from "../extra/images/mp.png";
import ForgotPassword from "./ForgotPassword";
import SignUpForm from "./SignUpForm";

const { Text } = Typography;

function LoginView() {
  const navigate = useNavigate();
  const { doLogin } = useAuth();
  const [loginError, setLoginError] = useState();
  const [openRestPasswordForm, setOpenRestPasswordForm] = useState(false);
  const [isVisibleSignUpForm, setIsVisibleSignUpForm] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleSubmit(values) {
    if (!(values.email && values.password)) {
      setLoginError("Missing email or password");
      return;
    }
    doLogin(values.email, values.password).then(() => {
      navigate("/dashboard");
    });
  }

  const passwordRestForm = () => {
    setOpenRestPasswordForm(!openRestPasswordForm);
  };

  const signUpForm = () => {
    setIsVisibleSignUpForm(!isVisibleSignUpForm);
  };

  return (
    <>
      <Row>
        <Col
          xxl={{ span: 8, offset: 8 }}
          xl={{ span: 10, offset: 7 }}
          lg={{ span: 18, offset: 3 }}
          md={{ span: 16, offset: 4 }}
          sm={{ span: 16, offset: 4 }}
          xs={{ span: 20, offset: 2 }}
        >
          <Card
            title={
              <Text style={{ color: "#D6DBDF", fontWeight: "800" }}>
                Login to your <Image alt="" src={mpImg} /> account
              </Text>
            }
            bordered={false}
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "10px", marginTop: "10vh", backgroundColor: "#001529" }}
            type="flex"
            align="middle"
          >
            <Form
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="login-form"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Form.Item
                label={<Text style={{ color: "#AEB6BF", fontWeight: "700" }}>Email</Text>}
                name="email"
                rules={[{ required: true, message: "Please enter your Email Id !" }]}
              >
                <Input prefix={<UserOutlined style={{ color: "#D6DBDF" }} />} placeholder="Email" style={{ borderRadius: "5px" }} />
              </Form.Item>
              <Form.Item
                label={<Text style={{ color: "#AEB6BF", fontWeight: "700" }}>Password</Text>}
                name="password"
                rules={[{ required: true, message: "Please enter your Password !" }]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Password"
                  style={{ borderRadius: "5px" }}
                />
              </Form.Item>
              <Form.Item style={{ paddingTop: "0", marginTop: "-10px", marginLeft: "335px" }}>
                <a className="login-form-forgot" onClick={passwordRestForm} style={{ float: "right", color: "#0083CA" }}>
                  Forgot password?
                </a>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block style={{ borderRadius: "5px", backgroundColor: "#0083CA", fontWeight: "700" }}>
                  Login
                </Button>
              </Form.Item>
            </Form>
            {loginError && <Text type="danger">{loginError}</Text>}
            <div style={{ alignItems: "center" }}>
              <a onClick={signUpForm} style={{ color: "#0083CA" }}>
                New User? Sign-Up
              </a>
            </div>
          </Card>
        </Col>
        {openRestPasswordForm ? (
          <Col span={8}>
            <ForgotPassword passwordRestForm={passwordRestForm} />
          </Col>
        ) : null}
        {isVisibleSignUpForm ? (
          <Col span={8}>
            <SignUpForm isVisibleSignUpForm={signUpForm} />
          </Col>
        ) : null}
      </Row>
    </>
  );
}

export default LoginView;
