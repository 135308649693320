import React from "react";
import { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import UserForm from "./UserForm";
import { notification, Table, Divider, Row, Col, Card, Button, Input, Modal, message, Avatar, Image, Switch, Space, Tag, Typography } from "antd";
import UserService from "../services/UserService";
import { useAuth } from "../helpers/auth";
import "../css/Views.css";
import defaultUser from "../extra/images/defaultUser.png";
import UserEditForm from "../views/UserEditForm";
const { confirm } = Modal;

const UserImage = (props) => {
  const [image, setImage] = useState(defaultUser);
  useEffect(() => {
    if (props.id) {
      UserService.getImage(props.id)
        .then((response) => {
          const objectUrl = URL.createObjectURL(response.data);
          setImage(objectUrl);
          return objectUrl;
        })
        .catch((error) => {
          setImage(defaultUser);
          message.warn(error);
        });
    }
  }, [props.id]);
  return (
    <>
      <Avatar src={<Image src={image} style={{ width: 40 }} />} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
    </>
  );
};

function UserListView() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [user, setUser] = useState({
    lastName: "",
    firstName: "",
    email: "",
    password: "",
    roles: [],
    id: "",
  });
  const [inactiveUsers, setInactiveUses] = useState([]);
  const [tableShow, setTableShow] = useState(true);
  const [activeOrInactiveButton, setActiveOrInactiveButton] = useState(true);
  const { user: activeUser } = useAuth();
  const privilege = activeUser.privileges;
  const [currentPage, setCurrentPage] = useState(0);
  const [inactiveUsersCurrentPage, setInactiveUsersCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [userInfo, setUserInfo] = useState();

  const [searchText, setSearchText] = useState("");
  const [emailSearchText, setEmailSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageInactive, setCurrentPageInactive] = useState(0);
  const [pageSizeInactive, setPageSizeInactive] = useState(10);
  const [searchTextInactive, setSearchTextInactive] = useState("");
  const [totalPagesInactive, setTotalPagesInactive] = useState(0);

  useEffect(() => {
    if(tableShow){
      refresh(searchText, emailSearchText, currentPage, pageSize);
    }
    getInActiveUsers(searchTextInactive, emailSearchText, currentPage, pageSize);
  }, [searchText, emailSearchText]);

  useEffect(() => {
    if(!tableShow){
    getInActiveUsers(searchTextInactive, emailSearchText, currentPage, pageSize);
    }
  }, [searchTextInactive]);

  function reset(e) {
    if (e) {
      refresh(searchText, emailSearchText, currentPage, pageSize);
    } else {
      getInActiveUsers(searchTextInactive, emailSearchText, currentPageInactive, pageSizeInactive);
    }
  }

  function resetPageAndSize() {
    if(tableShow){
    setCurrentPage(0);
    setPageSize(10);
    }
    if(!tableShow){
      setCurrentPageInactive(0);
      setPageSizeInactive(10);
    }
  }

  const refresh = (searchText, emailSearchText, currentPage, pageSize) => {
    setLoading(true);
    setTableShow(true);
    let sText = searchText;
    let cPage = currentPage;
    let pSize = pageSize;
    if (sText === undefined && cPage === undefined && pSize === undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    UserService.getAllActiveUsers(sText, emailSearchText, cPage, pSize)
      .then((response) => {
        setUsers(response.data.list);
        setPageSize(response.data.pageSize);
        setTotalUsers(response.data.total);
        setCurrentPage(response.data.pageNumber);
        setLoading(false);
        setShowAddUserForm(false);
        setShowEditUserForm(false);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const handleTableChange = (e) => {
    let currentPage = e.current - 1;
    let pageSize = e.pageSize;
    refresh(searchText, emailSearchText, currentPage, pageSize);
  };

  function getInActiveUsers(searchTextInactive, emailSearchText, currentPageInactive, pageSizeInactive) {
    setLoading(true);
    setTableShow(false);
    let sText = searchTextInactive;
    let cPage = currentPageInactive;
    let pSize = pageSizeInactive;
    if (sText === undefined && cPage === undefined && pSize === undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    UserService.getAllInActiveUsers(sText, emailSearchText, cPage, pSize)
      .then((response) => {
        const numberOfInactiveUsers = response.data.list;
        if (numberOfInactiveUsers.length >= 0) {
          setInactiveUses(response.data.list);
          setTotalInactiveUsers(response.data.total);
          setCurrentPageInactive(response.data.pageNumber);
          setPageSizeInactive(response.data.pageSize);
          setTotalPagesInactive(response.data.totalPages);
          setLoading(false);
        }
        if (numberOfInactiveUsers.length === 0) {
          notification.info({
            type: "info",
            description: "No Inactive User",
            duration: 1,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        message.warn(error);
        setLoading(false);
      });
  }


  const handleTableChangeForInactiveUsers = (e) => {
    let currentPageInactive = e.current - 1;
    let pageSizeInactive = e.pageSize;
    getInActiveUsers(searchTextInactive, emailSearchText, currentPageInactive, pageSizeInactive);
  };

  function refreshUsers() {
    setActiveOrInactiveButton(true);
    refresh(searchText, emailSearchText, currentPage, pageSize);
    setTableShow(true);
    setSearchText('')
    setEmailSearchText('')
  }

  const makeActiveUser = (record) => {
    UserService.makeActiveUser(record.id)
      .then((response) => {
        if (response.status === 200) {
          notification.info({
            description: "User marked as active",
            duration: 1,
          });
          getInActiveUsers(searchTextInactive, emailSearchText, currentPageInactive, pageSizeInactive);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        notification.error({
          description: error.response.data.message,
          duration: 3,
        });
        setLoading(false);
      });
  };
  const addUser = () => {
    setShowAddUserForm(!showAddUserForm);
  };
  const editUser = (userId) => {
    setShowEditUserForm(!showEditUserForm);
    if (userId) {
      UserService.getUser(userId)
        .then((response) => {
          let user = response.data;
          setUser(user);
          setUserInfo(user);
        })
        .catch((error) => {
          notification.error({
            type: "info",
            description: { error },
            duration: 2,
          });
        });
    }
  };
  const showDeleteConfirm = (userId, refresh) => {
    confirm({
      title: "Are you sure you want to make user inactive?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        UserService.deleteUser(userId).then(
          (response) => {
            message.success("User Deactivated!");
            refresh(searchText, emailSearchText, currentPage, pageSize);
          },
          (error) => {
            message.error(error.response.data.message);
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const syncUserFromSalesForce = () => {
    UserService.syncDataFromSaleforces()
      .then((response) => {
        notification.success({
          type: "info",
          description: <>Synced</>,

          duration: 1,
        });
        refresh(searchText, emailSearchText, currentPage, pageSize);
      })
      .catch((error) => {
        notification.warn({
          type: "warning",
          description: "Something Went Wrong",
          duration: 1,
        });
      });
  };

  const verifyDeviceAccess = (devId) => {
//    console.log({devId});
    UserService.verifyPendingUser(devId).then(res => {
      notification.success({
        type: "success",
        description: "Device access granted",
        duration: 1,
      });
      refresh(searchText, emailSearchText, currentPage, pageSize);
    }).catch(err => {
      notification.error({
        type: "warning",
        description: 'Not Approved due to some error',
        duration: 1,
      });
      refresh(searchText, emailSearchText, currentPage, pageSize);
    })
  }

  const columns = [
    {
      title: "Profile Picture",
      key: "profilePictureFile",
      render: (text, record) => <UserImage id={record.profilePictureFile} key={record.id} />,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "15%",
    },
    {
      title: "Company Name",
      dataIndex: "organizationLegalName",
      key: "organizationLegalName",
      width: "25%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Company Name"
              value={searchText}
              onChange={(e) => {
                resetPageAndSize();
                setSearchText(e.target.value);                
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(searchText){
          return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{         
          return <SearchOutlined className="active"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record.organizationLegalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Signup Date",
      dataIndex: "signUpDate",
      key: "signUpDate",
      width: "20%",
      render: (signUpDate) => <Typography.Text>{signUpDate}</Typography.Text>,
      sorter: (a, b) => a.createdDate - b.createdDate,
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      key: 'phone',
      width: "13%"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Email"
              value={emailSearchText}
              onChange={(e) => {
                resetPageAndSize();
                setEmailSearchText(e.target.value);                
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(emailSearchText){
          return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{         
          return <SearchOutlined className="active"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record.email?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Device Access",
      key: "deviceAccess",
      render: (_, record) => 
            <>
              {record.pendingStatus ?
                <Button type="primary" onClick={() => verifyDeviceAccess(record.id)}>
                  Allow
                </Button> :
                <Tag color="green">Allowed</Tag>
              }
            </>
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {privilege.includes("USER_WRITE") && (
              <>
                <EditOutlined onClick={() => editUser(record.id)} />
                <Divider type="vertical" />
                <DeleteOutlined style={{ color: "#FF0000" }} onClick={() => showDeleteConfirm(record.id, refresh)} />
              </>
            )}
          </>
        );
      },
    },
  ];
  const columnsForInactiveUsers = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "20%",
    },
    {
      title: "Company Name",
      dataIndex: "organizationLegalName",
      key: "organizationLegalName",
      width: "30%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Company Name"
              value={searchTextInactive}
              onChange={(e) => {
                resetPageAndSize();
                setSearchTextInactive(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(searchTextInactive){
          return <SearchOutlined className="inactive" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{         
          return <SearchOutlined className="inactive"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record.organizationLegalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      key: 'phone',
      // width: "13%"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Email"
              value={emailSearchText}
              onChange={(e) => {
                resetPageAndSize();
                setEmailSearchText(e.target.value);                
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(emailSearchText){
          return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{         
          return <SearchOutlined className="active"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record.email?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {privilege.includes("USER_WRITE") && (
              <>
                <Button style={{ margin: "20px", marginBottom: "0" }} type="primary" onClick={() => makeActiveUser(text, record.id)}>
                  Make Active
                </Button>{" "}
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="site-card-wrapper" style={{ margin: "30px", marginLeft: "0px" }}>
      <Row gutter={24}>
        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 16, offset: 1 }}>
          <Space>
            {privilege.includes("USER_WRITE") && (
              <Button size="middle" type="primary" onClick={addUser}>
                New User <PlusCircleOutlined />
              </Button>
            )}

            {privilege.includes("CRM_DATA_IMMEDIATE") && (
              <Button size="middle" type="primary" icon={<SyncOutlined />} onClick={syncUserFromSalesForce}>
                Sync
              </Button>
            )}
            <Switch
              style={{ marginTop: "5px" }}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(e) => reset(e)}
              defaultChecked={true}
            />
          </Space>
        </Col>

        {/*
        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 16, offset: 1 }}>
          <Button onClick={(e) => reset(e)} style={{ margin: "20px", marginBottom: "0", marginLeft: "0" }} type="primary">
            {activeOrInactiveButton ? "Show Inactive Users" : "Show Active Users"}
          </Button>
        </Col>
        */}
      </Row>
      <br />
      <Row gutter={24}>
        <Col xs={{span: 24}} lg={{ span: 22, offset: 1 }}>
            {tableShow === true && activeOrInactiveButton === true ? (
              <Table
                scroll={{ x:true}}
                loading={loading}
                size="middle"
                onChange={handleTableChange}
                columns={columns}
                dataSource={users}
                rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                pagination={{ showSizeChanger: true, total: totalUsers }}
              />
            ) : (
              <Table
                loading={loading}
                size="middle"
                onChange={handleTableChangeForInactiveUsers}
                columns={columnsForInactiveUsers}
                dataSource={inactiveUsers}
                rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                pagination={{ showSizeChanger: true, total: totalInactiveUsers }}
              />
            )}
        </Col>
        {showEditUserForm ? (
          <Col span={8}>
            <UserEditForm userInfo={userInfo} user={user} refresh={refreshUsers} editUser={editUser} />
          </Col>
        ) : null}
        {showAddUserForm ? (
          <Col span={8}>
            <UserForm refresh={refreshUsers} addUser={addUser} />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
export default UserListView;