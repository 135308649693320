import axios from "./axios";

class OrganizationService {
  static Instance() {
    return new OrganizationService();
  }

  getInactives(pageNumber, size) {
    return axios.get("/api/organization/inactive?page=" + pageNumber + "&size=" + size);
  }

  makeActiveOrganization(uid) {
    return axios.put("/api/organization/makeActiveOrganization?id=" + uid);
  }

  getOrganizations(pageNumber, size) {
    return axios.get("/api/organization/list?page=" + pageNumber + "&size=" + size);
  }

  getOrganization(id) {
    return axios.get("/api/organization?id=" + id);
  }

  saveOrganization(organization) {
    if (organization.id) {
      return axios.put("/api/organization", organization);
    }
    return axios.post("/api/organization", organization);
  }

  deleteOrganization(id) {
    return axios.delete("/api/organization?id=" + id);
  }

  saveLocation(location) {
    return axios.post("/api/location", location);
  }

  getLocation() {
    return axios.get("/api/location/all/active");
  }

  loadAccounts() {
    return axios.get("/api/crm/accounts");
  }

  uploadOrgLogo(id, formData) {
    return axios.put(`/api/org/${id}/logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getImage(id) {
    return axios.get(`/api/files/downloadOrgLogo/${id}`, { responseType: "blob" });
  }

  syncDataFromSaleforces() {
    return axios.get("/api/crm/immediateDataFormSalesforces", {timeout: 60000});
  }
  getAllOrganizations() {
    return axios.get("/api/organization/allOrg");
  }

  getAllActiveOrg(searchText, pageNumber, size) {
    return axios.get("api/organization/all/AllActiveOrg?searchKey=" + searchText + "&page=" + pageNumber + "&size=" + size);
  }
  getAllInActiveOrg(searchText, pageNumber, size) {
    return axios.get("api/organization/all/AllInactiveOrg?searchKey=" + searchText + "&page=" + pageNumber + "&size=" + size);
  }
}

export default OrganizationService.Instance();
