import React from "react";
import { useState, useEffect } from "react";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import UserService from "../services/UserService";
import OrganizationService from "../services/OrganizationService";
import { Button, Input, Typography, Select, message, Form, Drawer, Space, Upload, notification } from "antd";
const { Title } = Typography;

function UserEditForm(props) {
  const [visible, setVisible] = useState(true);
  const [role, setRole] = useState([]);
  const [forPassword, setforPassword] = useState(false);
  const [form] = Form.useForm();
  const [organizations, setOrganizations] = useState([]);
  const [profilePicture, setProfilePicture] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    roleList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [props.userInfo]);

  const roleList = () => {
    UserService.getUserProfile().then(res => {
      setAdmin(res.data.roles[0] === "MetalPowerAdmin");
      if(res.data.roles[0] === "MetalPowerAdmin") {
        UserService.getRoles()
        .then((response) => {
          const roles = response.data.map((roles) => {
            return roles.name;
          });
          setRole(roles);
        })
        .catch((error) => {
          message.error(error.response);
        });
      }
    })
    OrganizationService.getOrganizations()
      .then((response) => {
        setOrganizations(response.data.list);
      })
      .catch((error) => {
        message.error("");
      });
  };

  const close = () => {
    props.editUser();
    setVisible(false);
  };

  const showforPassword = () => {
    setforPassword(true);
  };

  const onforPasswordClose = () => {
    setforPassword(false);
  };

  const updatePassword = (e) => {
    const user = { ...props.user };
    user.password = e.password;
    UserService.updatePassword(user)
      .then((res) => {
        notification.success({
          type: "info",
          description: "Password Updated !",
          duration: 2,
        });
        props.refresh();
      })
      .catch((e) => {
        notification.success({
          type: "info",
          description: "Something Went Wrong",
          duration: 2,
        });
      });
  };

  const showUploadProfilePicture = () => {
    setProfilePicture(true);
  };

  const onProfilePictureClose = () => {
    setProfilePicture(false);
  };

  const handleSubmit = (e) => {
    let user = { ...props.userInfo };
    user.roles = e.roles;
    user.lastName = e.lastName;
    user.firstName = e.firstName;
    if(admin) user.organizationUid = e.organizationLegalName;
    UserService.saveUser(user)
      .then((response) => {
        notification.success({
          type: "info",
          description: "User updated",
          duration: 2,
        });
        props.refresh();
      })
      .catch((error) => {
        notification.warn({
          type: "info",
          description: "Email can not be edit",
          duration: 2,
        });
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const uploadProfilePicture = (res) => {
    const formData = new FormData();
    const id = props.user.id;
    formData.append("profilePicture", res.profilePicture[0].originFileObj);
    UserService.uploadProfilePicture(id, formData)
      .then((res) => {
        notification.success({
          type: "info",
          description: "Profile Picture Uploaded",
          duration: 2,
        });
        onProfilePictureClose();
        props.refresh();
      })
      .catch((err) => {
        notification.warn({
          type: "warning",
          description: "Picture file type extension '.jpg , .jpeg , .png'",
          duration: 2,
        });
      });
  };

  const handleOrgSearch = (searchText) => {
    OrganizationService.getAllActiveOrg(searchText, 0, 10)
      .then((response) => {
        setOrganizations(response.data.list);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Drawer
        title={"Edit User"}
        visible={visible}
        onCancel={close}
        onClose={close}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          className="user-form"
          layout="vertical"
          initialValues={{
            ...props.userInfo,
          }}
        >
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                First Name
              </Title>
            }
            name="firstName"
            rules={[{ required: true, message: "First Name should have 3-20 letters only!" }]}
          >
            <Input name="firstName" allowClear placeholder="First Name" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Last Name
              </Title>
            }
            name="lastName"
            rules={[{ required: true, message: "Last Name should have 3-20 letters only!" }]}
          >
            <Input name="lastName" allowClear placeholder="Last Name" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Email
              </Title>
            }
            name="email"
            rules={[{ required: true, message: "Please enter email!" }]}
          >
            <Input type="email" name="email" allowClear placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Company
              </Title>
            }
            name="organizationLegalName"
            color="blue"
            rules={[{ required: true, message: "Please Select Company!" }]}
          >
            <Select allowClear="true" placeholder="Select Company" showSearch optionFilterProp="children" onSearch={handleOrgSearch} disabled={!admin}>
              {organizations.map((organization, index) => {
                return (
                  <Select.Option key={index} value={organization.id} title={organization.name}>
                    {organization.legalName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Roles
              </Title>
            }
            name="roles"
            color="blue"
            rules={[{ required: true, message: "Please select roles!" }]}
          >
            <Select mode="multiple" allowClear="true" placeholder="Select Roles" disabled={!admin}>
              {role.map((role) => (
                <Select.Option key={role.id} value={role}>
                  {role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Space>
            <Button onClick={showUploadProfilePicture}>Upload Profile Picture</Button>
            <Button onClick={showforPassword} style={{ marginLeft: "14.9px" }}>
              Update Password
            </Button>
          </Space>
          <br />

          <br />
          <div>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
        <Drawer
          title="Update Password"
          width={320}
          closable={false}
          onClose={onforPasswordClose}
          visible={forPassword}
          closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
        >
          <Form name="updatePassword" onFinish={updatePassword} layout="vertical">
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Enter New Password
                </Title>
              }
              name="password"
              rules={[
                {
                  required: true,
                  pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})/,
                  message:
                    "Password that has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character  and is at least 6 characters long!",
                },
              ]}
            >
              <Input.Password name="password" placeholder="Enter New Password" />
            </Form.Item>
            {/* <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Confirm Password
                </Title>
              }
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message:
                    "Password that has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character  and is at least 6 characters long",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password And Confirm Password do not match");
                  },
                }),
              ]}
            >
              <Input.Password name="confirmPassword" placeholder="Confirm Password" />
            </Form.Item> */}
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button className="ml-2 device-form-button" onClick={onforPasswordClose} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
        <Drawer
          title="Upload Profile Picture"
          width={320}
          closable={false}
          onCancel={onProfilePictureClose}
          onClose={onProfilePictureClose}
          visible={profilePicture}
          closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
        >
          <Form onFinish={uploadProfilePicture}>
            <Form.Item name="profilePicture" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload name="profilePicture" maxCount={1} listType="picture" beforeUpload="false">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button className="ml-2 device-form-button" onClick={onProfilePictureClose} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
      </Drawer>
    </>
  );
}

export default UserEditForm;
