import { isNull } from "lodash";
import axios from "./axios";

class DeviceDataService { 
    static Instance() {
        return new DeviceDataService();
    }

    loadData(id) {
        return axios.get(`api/device/${id}/data`)
    }

    loadHistoryData(id) {
        return axios.get(`api/device/${id}/historydata`)
    }

    loadElements(id) {
        return axios.get(`api/device/${id}/elements`)
    }

    loadBases(id) {
        return axios.get(`api/device/${id}/bases`)
    }

    loadMatrixTypes(id) {
        return axios.get(`api/device/${id}/matrixTypes`)
    }

    loadMatrixSpectrums(id, matrixId) {
        return axios.get(`api/device/${id}/matrix/${matrixId}/spectrums`)
    }

    loadBurnCounts(id) {
        return axios.get(`api/device/${id}/burncounter`)
    }

    loadMasterCards(id) {
        return axios.get(`api/device/${id}/mastercards`)
    }

    loadVersions(id) {
        return axios.get(`api/device/${id}/versions`)
    }

    loadHistoryBurnSets(id, from, to) {
        return axios.get(`api/device/${id}/history/burnsets?from=${from}&to=${to}`)
    }

    loadHistoryBurnSet(id, burnSetId) {
        return axios.get(`api/device/${id}/burnset/${burnSetId}/historydata`)
    }
    loadCertValue(id, sampleName) {
        return axios.get(`api/device/${id}/standardsample/${sampleName}`)
    }

    loadFilteredHistoryBurnSets(id, from, to, baseId, matrixId) {
        let filter = {}
        if (from) {
            filter.from = from
        }
        if (to) {
            filter.to = to
        }
        if (baseId) {
            filter.baseId = baseId
        }
        if (matrixId) {
            filter.matrixId = matrixId
        }
        return axios.post(`api/device/${id}/history/burnsets`, filter)
    }

    loadFilteredHistoryBurnsetsWithRemarks(id, from, to, baseId, matrixId,sampleName,page,size,sampleTypeId)
    {
        let filter = {}
        if(page){
            filter.page=page
        }
        if(size){
            filter.size=size
        }
        if (from) {
            filter.from = from
        }
        if (to) {
            filter.to = to
        }
        if (baseId) {
            filter.baseId = baseId
        }
        if (matrixId) {
            filter.matrixId = matrixId
        }
        if(sampleName){
            filter.sampleName=sampleName  
        }
        if(sampleTypeId){
            filter.sampleTypeId=sampleTypeId
        }
        return axios.post(`api/device/${id}/history/burnsetremarks`, filter)
    }

    loadFilteredHistoryData(id, from, to, baseId, matrixId) {
        let filter = {}
        if (from) {
            filter.from = from
        }
        if (to) {
            filter.to = to
        }
        if (baseId) {
            filter.baseId = baseId
        }
        if (matrixId) {
            filter.matrixId = matrixId
        }
        return axios.post(`api/device/${id}/historydata`, filter)
    }

    loadDiagnostics(id) {
        return axios.get(`api/device/${id}/devicediagnostics`)
    }

    loadDiagnosticData(id, from, to,) {
        return axios.get(`api/device/${id}/devicediagnosticdata?from=${from}&to=${to}`)
    }

    getFileName(fileType,sampleName,matrixId,baseId,deviceId,burnSetUid) {
        return axios.post('api/report/burnsample',
            {
                burnSetUid: burnSetUid,
                reportFileType: fileType,
                sampleName: sampleName,
                matrixId: matrixId,
                baseId: baseId,
                deviceId: deviceId
            })
    }
    getLiveDiagnosticData(id){
        return axios.get(`api/device/${id}/livediagnosticdata`)
    }

    getGradeFileName(fileType,sampleName,matrixId,baseId,deviceId,burnSetUid,grade) {
        if(isNull(grade)|| grade===""){
        return axios.post('api/report/burnsample',
            {
                burnSetUid: burnSetUid,
                reportFileType: fileType,
                sampleName: sampleName,
                matrixId: matrixId,
                baseId: baseId,
                deviceId: deviceId,
                gradeName: ""
            })
        }else{
            return axios.post('api/report/burnsample',
            {
                burnSetUid: burnSetUid,
                reportFileType: fileType,
                sampleName: sampleName,
                matrixId: matrixId,
                baseId: baseId,
                deviceId: deviceId,
                gradeName: grade
            })
        }
    }

    downloadFile(fileName) {
        return axios.get(`api/files/downloadReport/${fileName}`,{
            responseType:  'blob',
        })
    }

    downloadBrochure(fileName) {
        return axios.get(`api/files/downloadFile/${fileName}?resourceType=Product`,{
            responseType:  'blob',
        })
    }

    getHistoryFileName(fileType,sampleName,matrixId,baseId,deviceId,burnSetUid) {
        return axios.post('api/report/historysample',
            {
                burnSetUid: burnSetUid,
                reportFileType: fileType,
                sampleName: sampleName,
                matrixId: matrixId,
                baseId: baseId,
                deviceId: deviceId
            })
    }
    getGradeHistoryFileName(fileType,sampleName,matrixId,baseId,deviceId,burnSetUid,grade) {
        if(isNull(grade)|| grade===""){
        return axios.post('api/report/historysample',
            {
                burnSetUid: burnSetUid,
                reportFileType: fileType,
                sampleName: sampleName,
                matrixId: matrixId,
                baseId: baseId,
                deviceId: deviceId,
                gradeName: ""
            })
        }else{
            return axios.post('api/report/historysample',
            {
                burnSetUid: burnSetUid,
                reportFileType: fileType,
                sampleName: sampleName,
                matrixId: matrixId,
                baseId: baseId,
                deviceId: deviceId,
                gradeName: grade
            })
        }
    }

    getTypes(id){
        return axios.get(`api/device/${id}/CloudSampleTypeMaster`)
    }
    getAllGrades(id){
        return axios.get(`api/device/${id}/getallgrades`)
    }

    getOneGrade(id,baseid,matrixid,gradename){
        return axios.get(`api/device/${id}/gradebybaseidandmatrixidandgradename?baseid=${baseid}&matrixid=${matrixid}&gradename=${gradename}`)
    }
    getAllSuitableGrade(id,baseid,matrixid){
        return axios.get(`api/device/${id}/getallgradenamesbymatrixandbaseid?baseid=${baseid}&matrixid=${matrixid}`)
    }
    getLiveBurnsets(id){
        return axios.get(`api/device/${id}/burnsets`)
    }
    getLatestDiagnostic(id){
       return axios.get(`api/device/${id}/latestdiagnosticdata`)
    }
    getCloudDiagnosticData(id,from,to){
        return axios.get(`api/device/${id}/clouddiagnosticdata?from=${from}&to=${to}`)
    }
    getDiagnosticMaster(id){
        return axios.get(`api/device/${id}/CloudDiagnosticMaster`)
    }
}
export default DeviceDataService.Instance()