import React from 'react';
import './App.css';

import {Routes, Route} from "react-router-dom";
import LoginView from "./views/LoginView";
import RoleView from "./views/RoleView";
import HomeView from "./views/HomeView";
import UserListView from "./views/UserListView";
import DashboardView from "./views/DashboardView";
import OrganizationView from "./views/OrganizationView";
import AuthProvider, {ProtectedRoute} from "./helpers/auth";
import BaseLayout from "./components/baselayout";
import DeviceListView from './views/DeviceView';
import SingleDeviceView from "./views/user/SingleDeviceView";
import SupportView from "./views/crm/SupportView";
import SalesView from "./views/crm/SalesView";
import DeviceDetails from './views/DeviceDetails';
import ProductsView from './views/ProductsView';
import Faq from './views/Faq';
import { ClientSubscription } from './views/ClientSubscription';
import MyDevices from "./views/MyDevices";
import MetalPowerInfo from './views/MetalPowerInfo';
import SoftwareView from './views/SoftwareView';

function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route element={<BaseLayout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <HomeView />
                </ProtectedRoute>
              }
            />
            <Route
              path="home"
              element={
                <ProtectedRoute>
                  <HomeView />
                </ProtectedRoute>
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <DashboardView />
                </ProtectedRoute>
              }
            />
            <Route path="/product" element={<ProductsView />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/software" element={<SoftwareView />} />
            <Route path="/myDevices" element={<MyDevices />} />
            <Route path="/aboutus" element={<MetalPowerInfo active={"aboutus"}/>} />
            <Route path="/privacy-policy" element={<MetalPowerInfo active={"privacy-policy"}/>} />
            <Route path="/contactus" element={<MetalPowerInfo active={"contactus"}/>}/>
            <Route path="/terms-conditions" element={<MetalPowerInfo active={"terms-conditions"}/>}/>
            <Route path="/admin/subscription" element={<ClientSubscription />} />

            <Route path="/product/:id/details" element={<DeviceDetails />} />

            {/* user routes */}
            <Route path="/user/device/:id" element={<SingleDeviceView />} />

            {/* crm routes */}
            <Route path="/crm/tickets" element={<SupportView />} />

            {/* auth routes */}
            <Route path="login" element={<LoginView />} />
            <Route path="/crm/sales" element={<SalesView />} />

            {/* admin routes */}
            <Route path="/admin/users" element={<UserListView />} />
            <Route path="/admin/devices" element={<DeviceListView />} />
            <Route path="/admin/orgs" element={<OrganizationView />} />
            <Route path="/admin/roles" element={<RoleView />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

const NoMatch = () => {
    return (<p>There's nothing here: 404!</p>);
};

export default App

/*
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            org: null,
            loggedIn: false,
            channelConnected: false,
            showAlert: false,
            alert: {}
        };
        EventBus.subscribe('login-logout', (event) => this.refreshMenu(event))
    }

    componentDidMount() {
        this.refreshMenu()
    }

    updateOrg = () => {
        OrgService.getOrgDetails()
            .then(res => {
                const org = {...res.data}
                this.setState({org})
            })
            .catch(e => {
                message.error("Something Went Wrong")
                console.log(e)
            })
    }

    refreshMenu() {

        UserService.tokenValid()
            .then(data => {
                const loggedIn = UserService.isLoggedIn()
                if (loggedIn) {
                    this.updateOrg()
                }
                this.setState({loggedIn});
                this.setState({showAlert: true});
            })
            .catch(error => {
                localStorage.clear();
                this.setState({loggedIn: false});
                history.push("/#/login")
                console.log("Session Expired !! Login Again");
            })

    }

    componentWillUnmount() {
    }

    render() {

        const isLoggedIn = this.state.loggedIn;
        const org = this.state.org;
        const showAlert = this.state.showAlert;
        const alert = this.state.alert;
        let sideBar = isLoggedIn && org ? <Sidebar org={org}/> : null;
        let header = isLoggedIn && showAlert ? <Headbar alert={alert} isLoggedIn={isLoggedIn}/> : null;
        const footer = org ? <Footerbar org={org}/> : null;

        if (window.location.href.includes("presentation")) {
            sideBar = null;
            header = null;
        }

        return (
            <div className="App">

                <Layout style={{minHeight: '100vh'}}>

                    {sideBar}

                    <Layout>
                        {header}
                        <Content style={{margin: '16px'}}>
                            <div style={{padding: 40}}>
                                <Route path='/login' exact={true} component={LoginView}/>
                                <PrivateRoute path='/' exact={true} component={HomeView}/>
                                <PrivateRoute path='/user' exact={true} component={UserListView}/>
                                <PrivateRoute path='/roles' exact={true} component={RoleView}/>
                                <PrivateRoute path='/dashboard/' exact={true} component={DashboardView}/>
                                <PrivateRoute path='/orgs/' exact={true} component={OrganizationView}/>
                                <PrivateRoute path='/orgs/edit' exact={true} component={EditCurrentOrg}/>
                                <PrivateRoute path='/plants/' exact={true} component={PlantView}/>
                                <PrivateRoute path='/devices/' exact={true} component={MachineView}/>
                            </div>
                        </Content>
                        {footer}
                    </Layout>
                </Layout>

            </div>
        );

    }
}

export default App;
*/