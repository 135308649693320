import React from "react";
import {Affix,Image, Layout} from "antd";
import Navbar from "./Navbar";


const {Header} = Layout;

function AppHeader() {
    return (
            <Header  style={{position:'fixed',width:'100%',zIndex:'1', backgroundColor:'white', borderBottom:'2px solid white'}}>
                <div style={{float: "left",width:'40%',marginRight:'5%'}}><Image preview={false} alt="logo" src="logo.png"/></div>
               <Navbar />
               
               <Affix offsetTop={64}  >
               <div style={{margin:'0 -50px'}}>
               <div style={{height:'1px',backgroundColor:'lightgray',width:'100%'}}></div>
               </div>
               </Affix>
               
            </Header>
    )
}

export function PublicHeader() {
    return (
            <Header style={{position:'fixed',width:'100%',zIndex:'1',backgroundColor:'white', borderBottom:'2px solid grey'}}>
                <div style={{float: "left",width:'70%'}}><Image preview={false} alt="logo" src="logo.png"/></div>
               {/* <Space style={{float: "right"}}>
                    <Link to="/login" ><Button style={{borderRadius:'10px', color:'#5E5F52',fontWeight:'700' }}>Login</Button></Link>
                </Space> */}
            </Header>
    )
}


export default AppHeader