import React from "react";
import { useState, useEffect } from "react";
import UserService from "../services/UserService";
import OrganizationService from "../services/OrganizationService";
import { Button, Input, Typography, Select, message, Form, Drawer, Space, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
const { Title } = Typography;

function UserForm(props) {
  const [visible, setVisible] = useState(true);
  const [role, setRole] = useState([]);
  const [form] = Form.useForm();
  const [organizations, setOrganizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    roleList();
    orgList(searchText, currentPage, pageSize);
  }, []);

  const roleList = () => {
    UserService.getRoles()
      .then((response) => {
        const roles = response.data.map((roles) => {
          return roles.name;
        });
        setRole(roles);
      })
      .catch((error) => {
        message.error(error.response);
      });
  };

  const orgList = (searchText, currentPage, pageSize) => {
    OrganizationService.getAllActiveOrg(searchText, currentPage, pageSize)
      .then((response) => {
        setOrganizations(response.data.list);
      })
      .catch((error) => {});
  };

  const forActiveSearchText = (value) => {
    if (value !== "") {
      let currentPage = 0;
      orgList(value, currentPage, pageSize);
    } else {
      orgList(value, currentPage, pageSize);
    }
  };

  const close = () => {
    props.addUser();
    setVisible(false);
  };

  const handleSubmit = (user) => {
    UserService.saveUser(user)
      .then((response) => {
        notification.success({
          type: "info",
          description: "User created",
          duration: 2,
        });
        setVisible(false);
        props.refresh();
      })
      .catch((error) => {
        notification.error({
          type: "info",
          description: "User Exist Please try with another Email",
          duration: 2,
        });
      });
  };

  return (
    <>
      <Drawer
        title={"Add User"}
        visible={visible}
        onCancel={close}
        onClose={close}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form form={form} onFinish={handleSubmit} className="user-form" layout="vertical">
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                First Name
              </Title>
            }
            name="firstName"
            rules={[{ required: true, pattern: /^[A-Za-z]{3,20}$/, message: "First Name should have 3-20 letters only!" }]}
          >
            <Input name="firstName" allowClear placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Last Name
              </Title>
            }
            name="lastName"
            rules={[{ required: true, pattern: /^[A-Za-z]{3,20}$/, message: "Last Name should have 3-20 letters only!" }]}
          >
            <Input name="lastName" allowClear placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Email
              </Title>
            }
            name="email"
            rules={[{ required: true, message: "Please enter email!" }]}
          >
            <Input type="email" name="email" allowClear placeholder="Email" />
          </Form.Item>

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Contact No.
              </Title>
            }
            name="phone"
            rules={[{ required: true, message: "Please enter contact number!" }]}
          >
            <Input type="text" name="phone" allowClear placeholder="Contact No." />
          </Form.Item>

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Company
              </Title>
            }
            name="organizationUid"
            color="blue"
            rules={[{ required: true, message: "Please Select Company!" }]}
          >
            <Select allowClear="true" placeholder="Select Company" showSearch optionFilterProp="children" onSearch={forActiveSearchText}>
              {organizations.map((organization, index) => {
                return (
                  <Select.Option key={index} value={organization.id} title={organization.name}>
                    {organization.legalName}, {organization.address}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Roles
              </Title>
            }
            name="roles"
            color="blue"
            rules={[{ required: true, message: "Please select roles!" }]}
          >
            <Select mode="multiple" allowClear="true" placeholder="Select Roles">
              {role.map((role) => (
                <Select.Option key={role.id} value={role}>
                  {role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Password
              </Title>
            }
            name="password"
            rules={[
              {
                required: true,
                pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})/,
                message:
                  "Password that has at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character  and is at least 6 characters long",
              },
            ]}
          >
            <Input.Password allowClear placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UserForm;
