import React, { Component } from "react";
import { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Table, Divider, Row, Col, Card, Button, Input, Space, Modal, message, Upload, Avatar, Image, notification, Switch } from "antd";
import OrganizationService from "../services/OrganizationService";
import OrganizationForm from "./OrganizationFrom";
import EditCurrentOrganization from "./EditCurrentOrganization";
import "../css/Views.css";
import { useAuth } from "../helpers/auth";
import defaultOrgaisation from "../extra/images/defaultOrgaisation.png";
const { confirm } = Modal;
const OrgImage = (props) => {
  const [image, setImage] = useState(defaultOrgaisation);
  useEffect(() => {
    if (props.id) {
      OrganizationService.getImage(props.id)
        .then((response) => {
          const objectUrl = URL.createObjectURL(response.data);
          setImage(objectUrl);
          return objectUrl;
        })
        .catch((error) => {
          setImage(defaultOrgaisation);
          message.warn(error);
        });
    }
  }, [props.id]);
  return (
    <>
      <Avatar src={<Image src={image} style={{ width: 46 }} />} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
    </>
  );
};
function OrganizationView() {
  const [isLoadingOrganizationList, setIsLoadingOrganizationList] = useState(true);
  const [isOpenEditCurrentOrg, setIsOpenEditCurrentOrg] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState({});
  const [visible, setVisible] = useState(false);
  const [isOpenOrganizationFrom, setIsOpenOrganizationFrom] = useState(false);
  const [tableShow, setTableShow] = useState(true);
  const [activeOrInactiveButton, setActiveOrInactiveButton] = useState(true);
  const [inactiveOrgs, setInactivOrgs] = useState([]);
  const { user } = useAuth();
  const privilege = user.privileges;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [inactiveOrgsCurrentPage, setInactiveOrgsCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalOrgs, setTotalOrgs] = useState(0);
  const [totalInactiveOrgs, setTotalInactiveOrgs] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageInactive, setCurrentPageInactive] = useState(0);
  const [pageSizeInactive, setPageSizeInactive] = useState(10);
  const [searchTextInactive, setSearchTextInactive] = useState("");
  const [totalPagesInactive, setTotalPagesInactive] = useState(0);

  useEffect(() => {
    if(tableShow){
    refresh(searchText, currentPage, pageSize);
  }
  }, [searchText]);

  useEffect(() => {
    if(!tableShow){
    getInActiveOrganization(searchTextInactive, currentPageInactive, pageSizeInactive);
  }
  },[searchTextInactive]);

  function reset(e) {
    if (e) {
      refresh(searchText, currentPage, pageSize);
    } else {
      getInActiveOrganization(searchTextInactive, currentPageInactive, pageSizeInactive);
    }
  }

  function resetPageAndSize() {
    if(tableShow){
    setCurrentPage(0);
    setPageSize(10);
    }
    if(!tableShow){
      setCurrentPageInactive(0);
      setPageSizeInactive(10);
    }
  }

  function refresh(searchText, currentPage, pageSize) {
    setActiveOrInactiveButton(true);
    setLoading(true);
    setTableShow(true);
    let sText = searchText;
    let cPage = currentPage;
    let pSize = pageSize;
    if (sText === undefined && cPage === undefined && pSize === undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    OrganizationService.getAllActiveOrg(sText, cPage, pSize)
      .then((response) => {
        setLoading(false);
        setOrganizations(response.data.list);
        setIsLoadingOrganizationList(false);
        setIsOpenEditCurrentOrg(false);
        setIsOpenOrganizationFrom(false);
        setPageSize(response.data.pageSize);
        setTotalOrgs(response.data.total);
        setCurrentPage(response.data.pageNumber);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  }

  const handleTableChange = (e) => {
    let currentPage = e.current - 1;
    let pageSize = e.pageSize;
    refresh(searchText, currentPage, pageSize);
  };

  function getInActiveOrganization(searchTextInactive, currentPageInactive, pageSizeInactive) {
    setActiveOrInactiveButton(false);
    setLoading(true);
    setTableShow(false);
    let sText = searchTextInactive;
    let cPage = currentPageInactive;
    let pSize = pageSizeInactive;
    if (sText === undefined && cPage === undefined && pSize === undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    OrganizationService.getAllInActiveOrg(sText, cPage, pSize)
      .then((response) => {
        const numberOfInactiveOrgs = response.data.list;
        if (numberOfInactiveOrgs.length >= 0) {
          setInactivOrgs(response.data.list);
          setTotalInactiveOrgs(response.data.total);
          setCurrentPageInactive(response.data.pageNumber);
          setPageSizeInactive(response.data.pageSize);
          setTotalPagesInactive(response.data.totalPages);
          setLoading(false);
        }
        if (numberOfInactiveOrgs.length === 0) {
          notification.info({
            type: "info",
            description: "No Inactive Organization",
            duration: 1,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        message.warn("error");
        setLoading(false);
      });
  }

  const handleTableChangeForInactiveOrgs = (e) => {
    let currentPageInactive = e.current - 1;
    let pageSizeInactive = e.pageSize;
    getInActiveOrganization(searchTextInactive, currentPageInactive, pageSizeInactive);
  };

  const editOrganizationFrom = (orgId) => {
    setIsOpenEditCurrentOrg(!isOpenEditCurrentOrg);
    OrganizationService.getOrganization(orgId).then(
      (response) => {
        let organization = response.data;
        setOrganization(organization);
      },
      (error) => {
        message.error(error.response.data.message);
      }
    );
  };

  const organizationFrom = () => {
    setIsOpenOrganizationFrom(!isOpenOrganizationFrom);
  };

  const setActiveOrg = (record) => {
    OrganizationService.makeActiveOrganization(record.id)
      .then((response) => {
        if (response.status === 200) {
          notification.success({
            type: "success",
            description: "Organization marked as active",
            duration: 2,
          });
          getInActiveOrganization(searchTextInactive, currentPageInactive, pageSizeInactive);
        }
      })
      .catch((err) => {
        notification.warn({
          type: "warning",
          description: { err },
          duration: 2,
        });
      });
  };
  const showDeleteConfirm = (id, refresh) => {
    confirm({
      title: "Are you sure you want to make organization inactive?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        OrganizationService.deleteOrganization(id)
          .then((response) => {
            notification.success({
              type: "success",
              description: "Organization Deactivated!",
              duration: 2,
            });
            refresh(searchText, currentPage, pageSize);
          })
          .catch((error) => {
            notification.warn({
              type: "warning",
              description: { error },
              duration: 2,
            });
          });
        refresh(searchText, currentPage, pageSize);
      },
      onCancel() {
        notification.warn({
          type: "warning",
          description: "Cancelled",
          duration: 2,
        });
      },
    });
  };

  const syncClintFromSalesForce = () => {
    OrganizationService.syncDataFromSaleforces()
      .then((response) => {
        notification.success({
          type: "info",
          description: <>Synced</>,
          duration: 1,
        });
        refresh(searchText, currentPage, pageSize);
      })
      .catch((error) => {
        notification.warn({
          type: "warning",
          description: "Something Went Wrong",
          duration: 1,
        });
      });
  };

  const columns = [
    {
      title: "Organization Logo",
      key: "logoFileName",
      render: (text, record) => <OrgImage id={record.logoFileName} key={record.id} />,
    },
    {
      title: "Company Name",
      dataIndex: "legalName",
      key: "legalName",
      width: "30%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              value={searchText}
              autoFocus
              placeholder="Search Company Name"
              onChange={(e) => {
                resetPageAndSize();
                setSearchText(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(searchText){
          return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{         
          return <SearchOutlined className="active"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record.legalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "30%",
    },
    {
      title: "Address(Office)",
      dataIndex: "address",
      key: "address",
      width: "30%",
      render: (text, record) => {
        let address = record.address;
        if (address) {
          address = address
            .split(" ")
            .map((item) => item.replace("null", ""))
            .join(" ");
        }
        return <>{address}</>;
      },
    },
    {
      title: "CRM",
      dataIndex: "crmName",
      key: "crmName",
    },
    {
      title: "Paired Devices",
      dataIndex: "totalPairedDevices",
      key: "totalPairedDevices"
    },
    {
      title: "Unpaired Devices",
      dataIndex: "totalUnpairedDevices",
      key: "totalUnpairedDevices"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {privilege.includes("ORGANIZATION_WRITE") && (
              <>
                <EditOutlined onClick={() => editOrganizationFrom(record.id)} />
                <Divider type="vertical" />
                <DeleteOutlined style={{ color: "#ff0000" }} onClick={() => showDeleteConfirm(record.id, refresh)} />
              </>
            )}
          </>
        );
      },
    },
  ];
  const columnsForMakeaActive = [
    {
      title: "Company Name",
      dataIndex: "legalName",
      key: "legalName",
      width: "30%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
            value={searchTextInactive}
              autoFocus
              placeholder="Search Company Name"
              onChange={(e) => {
                resetPageAndSize();
                setSearchTextInactive(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(searchTextInactive){
          return <SearchOutlined className="inactive" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{         
          return <SearchOutlined className="inactive"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record.legalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "30%",
    },
    {
      title: "Address(Office)",
      dataIndex: "address",
      key: "address",
      width: "30%",
      render: (text, record) => {
        let address = record.address;
        if (address) {
          address = address
            .split(" ")
            .map((item) => item.replace("null", ""))
            .join(" ");
        }
        return <>{address}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {privilege.includes("ORGANIZATION_WRITE") && (
              <>
                <Button style={{ margin: "20px", marginBottom: "0" }} type="primary" onClick={() => setActiveOrg(text, record.id)}>
                  Make Active
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div className="site-card-wrapper" style={{ margin: "30px", marginLeft: "0" }}>
      <Row gutter={2}>
        <Col xs={{ span: 2, offset: 1 }} lg={{ span: 2, offset: 1 }}>
          <Space>
            {privilege.includes("ORGANIZATION_WRITE") && (
              <Button size="middle" type="primary" onClick={organizationFrom}>
                New Client
                <PlusCircleOutlined />
              </Button>
            )}
            {privilege.includes("CRM_DATA_IMMEDIATE") && (
              <Button size="middle" type="primary" icon={<SyncOutlined />} onClick={syncClintFromSalesForce}>
                Sync
              </Button>
            )}
            <Switch
              style={{ marginTop: "5px" }}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(e) => reset(e)}
              defaultChecked={true}
            />
          </Space>
        </Col>

        {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 16, offset: 1 }}>
          <Button onClick={(e) => reset(e)} style={{ margin: "20px", marginBottom: "0", marginLeft: "0" }} type="primary">
            {activeOrInactiveButton ? "Show Inactive Clients" : " Show Active Clients"}
          </Button>
          {/* {activeOrInactiveButton ? (
            <Button
              style={{ margin: "20px", marginBottom: "0", marginLeft: "0" }}
              type="primary"
              onClick={() => getInActiveOrganization(currentPage, pageSize)}
            >
              Show Inactive Clients
            </Button>
          ) : (
            <Button style={{ margin: "20px", marginBottom: "0", marginLeft: "0" }} type="primary" onClick={() => refresh(currentPage, pageSize)}>
              Show Active Clients
            </Button>
          )} 
        </Col>*/}
      </Row>
      <br />
      <Row gutter={24}>
        <Col xs={{ span: 24}} lg={{ span: 22, offset: 1 }}>
          {tableShow === true && activeOrInactiveButton === true ? (
            <Table
              loading={loading}
              size="middle"
              onChange={handleTableChange}
              dataSource={organizations}
              columns={columns}
              rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
              pagination={{ showSizeChanger: true, total: totalOrgs }}
            />
          ) : (
            <Table
              loading={loading}
              size="middle"
              onChange={handleTableChangeForInactiveOrgs}
              columns={columnsForMakeaActive}
              dataSource={inactiveOrgs}
              rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
              pagination={{ showSizeChanger: true, total: totalInactiveOrgs }}
            />
          )}
        </Col>
        {isOpenEditCurrentOrg ? (
          <Col span={8}>
            <EditCurrentOrganization organization={organization} editOrganizationFrom={editOrganizationFrom} refresh={refresh} />
          </Col>
        ) : null}
        {isOpenOrganizationFrom ? (
          <Col span={8}>
            <OrganizationForm organization={organization} list={organizations} organizationFrom={organizationFrom} refresh={refresh} />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
export default OrganizationView;