import React, { useEffect, useState } from "react";
import DashboardService from "../services/DashboardService";
import CrmService from "../services/CrmService";
import {  useParams } from "react-router-dom";
import { Card, Row, Col, Button, Image, Collapse, Drawer, Form, message, Input, Space, Spin, Select } from "antd";
import { Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import UserService from "../services/UserService";
import { countries, states } from "../helpers/location";

const { Panel } = Collapse;
const { Paragraph, Text, Title } = Typography;
const { Meta } = Card;

function DeviceDetails() {
  const [data, setData] = useState();
  const [ellipsis, setEllipsis] = React.useState(true);
  const [img, setImg] = useState();
  const [logoImg, setLogoImg] = useState();
  const [visible, setVisible] = useState(false);
  const [modelName, setModelName] = useState("");
  const [productCategory, setProductCategory] = useState('');
  const [isIndia, setIsIndia] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [form] = Form.useForm();

  let { id } = useParams();
  useEffect(() => {
    loadDeviceDetails();
    getUserInfo();
  }, [id]);

  const loadDeviceDetails = () => {
    DashboardService.loadSingleDeviceDetails(id).then((res) => {
      setModelName(res.data.modelName);
      if(res.data.category === "Stationary & Benchtop (OES)" || res.data.category === "Mobile Optical Emission Spectrometers (OES)") {setProductCategory("OES")}
      else if (res.data.category === "Sample Preparation Machine" || res.data.category === "Allied Equipments") {setProductCategory("Accessories")}
      DashboardService.downloadFile(res.data.modelImage).then((resp) => {
        const objectUrl = URL.createObjectURL(resp.data);
        setImg(objectUrl);
      });
      DashboardService.downloadFile(res.data.modelNameImage).then((resp) => {
        const objectUrl = URL.createObjectURL(resp.data);
        setLogoImg(objectUrl);
      });
      setData(res.data);
    });
  };

  function onFinish(values) {
     if(userInfo.phone === null || userInfo.phone === undefined){
       message.warning("Please Update your contact details first.");
       return;
     }
    values = { ...values, deviceName: modelName, title: `Enquiry for ${modelName}`, contactNumber: '5555555555' };
     CrmService.raiseSalesTicket(values)
       .then((response) => {
         message.info("Sales Enquiry Raised");
         setVisible(false);
       })
       .catch((error) => {
         message.error(error.response.data.message);
       });
  }

  const createSalesEnquire = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const selectedCountry = (key) => {
    if (key === "India") {
      setIsIndia(true);
    } else {
      setIsIndia(false);
    }
  };

  function getUserInfo() {
    UserService.getUserProfile()
      .then((req) => {
        setUserInfo(req.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    form.resetFields();
  }, [userInfo]);

  return (
    <>
      {data ? (
        <>
          <Row>
            <Col span={8} offset={1}>
              <Card
                className="card-bg"
                hoverable
                style={{ borderRadius: "1rem", marginTop: "1vh", textAlign: "center" }}
                cover={
                  logoImg ? (
                    <Image
                      preview={false}
                      style={{ borderRadius: "1rem 1rem 0 0", height: "60%", width: "60%", marginTop: "20px" }}
                      alt="example"
                      src={logoImg}
                    />
                  ) : (
                    <></>
                  )
                }
              >
                <Meta
                  title={
                    img ? (
                      <Image preview={false} style={{ borderRadius: "1rem 1rem 0 0", height: "60vh", width: "48vh" }} alt="example" src={img} />
                    ) : (
                      <div style={{ textAlign: "center", marginTop: "15vh", marginBottom: "15vh" }}>
                        <Spin size="large" />
                      </div>
                    )
                  }
                  description={
                    <Title style={{ color: "black" }} level={5}>
                      {data.category}
                    </Title>
                  }
                />
              </Card>
              <Row style={{ marginTop: "40px" }}>
                <Col span={8} offset={4}>
                  <Button type="primary" size="large" style={{ borderRadius: "0.5rem" }} onClick={createSalesEnquire}>
                    Enquire
                  </Button>
                </Col>

                <Col span={8} offset={1}>
                  <a href={data.oldPageLink}>
                    <Button type="primary" size="large" style={{ borderRadius: "0.5rem" }}>
                      Visit Website
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col span={12} offset={2}>
              <Col>
                <Title
                  level={1}
                  style={{ color: "white", fontWeight: "500", lineHeight: "1", marginBottom: "5vh" }}
                  ellipsis={
                    ellipsis
                      ? {
                          rows: 2,
                          expandable: true,
                          symbol: "more",
                        }
                      : false
                  }
                >
                  {data.title}
                </Title>

                <Collapse defaultActiveKey={["1"]} style={{ marginBottom: "5px" }}>
                  <Panel header={<Paragraph style={{ fontWeight: "700", marginBottom: "0" }}>Description</Paragraph>} key="1">
                    <p style={{ fontWeight: "600" }}>{data.description}</p>
                  </Panel>
                </Collapse>
                <Collapse style={{ marginBottom: "5px" }}>
                  <Panel header={<Paragraph style={{ fontWeight: "700", marginBottom: "0" }}>Highlights</Paragraph>} key="2">
                    <p style={{ fontWeight: "600" }}>
                      {data.highlights.split("- ").map((line, idx) => {
                        if (idx !== 0)
                          return (
                            <p key={idx}>
                              {"\u2022"} {line}
                            </p>
                          );
                      })}
                    </p>
                  </Panel>
                </Collapse>
                <Collapse style={{ marginBottom: "5px" }}>
                  <Panel header={<Paragraph style={{ fontWeight: "700", marginBottom: "0" }}>Key Features</Paragraph>} key="3">
                    <p style={{ fontWeight: "600" }}>
                      {data.keyFeatures.split("- ").map((line, idx) => {
                        if (idx !== 0)
                          return (
                            <p key={idx}>
                              {"\u2022"} {line}
                            </p>
                          );
                      })}
                    </p>
                  </Panel>
                </Collapse>
              </Col>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <div>
        <Drawer
          title={"Create Sales Enquiry"}
          visible={visible}
          onClose={close}
          closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
        >
          <Form
            name="salesEnquire"
            form={form}
            initialValues={{
              contactNumber: userInfo.phone,
              productCategory: productCategory
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            {data && (
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    Product Category
                  </Title>
                }
                name="productCategory"
                rules={[{ required: true, message: "Please Select Product Category!" }]}
              >
                {/* <Select
                  showSearch
                  placeholder="Select Product Category"
                  optionFilterProp="value"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {productCategory.map((type) => {
                    return (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select> */}
                <Input name="productCategory" disabled/>
              </Form.Item>
            )}

            {/* <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Title
                </Title>
              }
              name="title"
              rules={[{ required: true, message: "Please Enter Title!" }]}
            >
              <Input allowClear placeholder="Title" />
            </Form.Item> */}
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Model Name
                </Title>
              }
              name="deviceName"
              // rules={[{ required: true, message: "Please Select Model Name" }]}
            >
              <Input defaultValue={modelName} disabled />
            </Form.Item>
            {/* <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Contact Number
                </Title>
              }
              name="contactNumber"
              rules={[{ required: true, message: "Enter Contact Number!" }]}
            >
              <Input allowClear placeholder="Contact Number" />
            </Form.Item> */}
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Description
                </Title>
              }
              name="description"
              rules={[{ required: true, min: 5, message: "Please provide further details about your query!" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())} />
            </Form.Item>
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Country
                </Title>
              }
              name="country"
              rules={[{ required: true, message: "Please Select Country!" }]}
            >
              <Select
                onSelect={selectedCountry}
                showSearch
                placeholder="Select Country"
                optionFilterProp="value"
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {countries.map((country) => {
                  return (
                    <Select.Option key={country.code} value={country.name}>
                      {country.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {isIndia && (
              <Form.Item
                label={
                  <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                    State
                  </Title>
                }
                name="state"
                rules={[{ required: true, message: "Please Select State!" }]}
              >
                <Select
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="value"
                  filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {states.map((state) => {
                    return (
                      <Select.Option key={state.key} value={state.name}>
                        {state.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    </>
  );
}

export default DeviceDetails;
