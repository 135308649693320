import axios from "./axios";

class SubscriptionService {
    static Instance() {
        return new SubscriptionService();
    }

    getPlans() {
        return axios.get("/public/plans");
    }

    editPlan(planId, planRequest){
        return axios.post(`api/subs/tx/plans/${planId}/updateplandetails`, planRequest);
    }

    createTransaction(PLAN_ID, TXN_TOKEN, ORDER_ID, AMOUNT, USER, NUM_OF_USERS){
        return axios.post(`api/subs/tx?planId=${PLAN_ID}`,
        {
            txnToken: TXN_TOKEN,
            orderId: ORDER_ID,
            amount: AMOUNT,
            email: USER.email,
            contactNumber: USER.phone,
            maximumUsers: NUM_OF_USERS
        })
    }

    updateTransaction(TXN, TXN_RESPONSE, expiryDate, duration){
        console.log(new Date(expiryDate).toISOString());
        console.log(new Date(TXN_RESPONSE.TXNDATE).toISOString());
        TXN.paymentId = TXN_RESPONSE.TXNID;
        TXN.tax = 0.0;
        TXN.paymentGatewayCharge = 0.0;
        TXN.transactionTime = new Date(TXN_RESPONSE.TXNDATE).toISOString();
        TXN.paymentMode = TXN_RESPONSE.PAYMENTMODE;
        TXN.paymentGatewayName = TXN_RESPONSE.GATEWAYNAME;
        TXN.status = TXN_RESPONSE.STATUS;
        TXN.paymentSignature = TXN_RESPONSE.CHECKSUMHASH;
        TXN.validity = new Date(expiryDate).toISOString();
        // TXN.subscriptionId = TXN_RESPONSE.SUBS_ID;
        TXN.planTimePeriod = duration === "month" ? "Monthly" : "Yearly"
        return axios.put('api/subs/tx/', TXN);
    }

    validateCheckSum(reqBody, checksum) {
        console.log(JSON.stringify(reqBody));
        return axios.post(`api/subs/tx/validatechecksum`,
            {
                checksum: checksum,
                reqBody: JSON.stringify(reqBody)
            })
    }


    getChecksum(reqBody) {
        return axios.post('api/subs/tx/generatechecksum',
        {
            hash: JSON.stringify(reqBody),
        })
    }


    async initiateTransaction(REQUEST_BODY, SIGNATURE) {
        let data = {
            body: REQUEST_BODY,
            head: {
                "channelId": "WEB",
                "signature": SIGNATURE
            }
        }

        let headers = {
            'Content-Type': 'application/json',
        }
        
        // let res = await fetch(`https://securegw.paytm.in/subscription/create?mid=${REQUEST_BODY.mid}&orderId=${REQUEST_BODY.orderId}`,
        let res = await fetch(`https://securegw.paytm.in/theia/api/v1/initiateTransaction?mid=${REQUEST_BODY.mid}&orderId=${REQUEST_BODY.orderId}`,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
        return res.json();
    }

    getTransactionList(pageNumber, size, orgName) {
        return axios.get(`api/subs/tx/history/transactions?page=${pageNumber}&size=${size}${orgName !== "" ? `&orgName=${orgName}` : ""}`);
    }

    getSubsriptionList(pageNumber, size, orgName) {
        return axios.get(`api/subs/tx/history/subscriptions?page=${pageNumber}&size=${size}${orgName !== "" ? `&orgName=${orgName}` : ""}`);
    }

}

    

export default SubscriptionService.Instance()