import {Button, Card, Col, List, Row, Select, Space, Typography} from "antd";
import {useEffect, useState} from "react";
import DeviceDataService from "../../services/DeviceDataService";
import {Column, Line} from '@ant-design/plots';
import moment from "moment";
import insertCss from "insert-css"
import { useParams } from "react-router-dom";


const {Title}=Typography
export default function BurnChart(props) {
    let { id } = useParams();
    let deviceId = props.deviceId;
    let selectedBase = props.selectedBase;
    let selectedMatrix = props.selectedMatrix;
    let selectedSampleName=props.selectedSampleName
    let fromDate = props.fromDate;
    let toDate = props.toDate;
    let digitsAfterDecimal=props.digitsAfterDecimal;
    let type=props.name;
    
    
 
    const [matrixSpectrum, setMatrixSpectrum] = useState([])
    //const [elements, setElements] = useState([])
    const [elementMap, setElementMap] = useState({})
    const [spectrumMap, setSpectrumMap] = useState({})
    const [chartData, setChartData] = useState([])
    const [chartConfig, setChartConfig] = useState()
    const [chartConfig1, setChartConfig1] = useState()
    const [chartConfig2, setChartConfig2] = useState()
    const [chartConfig3, setChartConfig3] = useState()
    const [selectedElement, setSelectedElement] = useState([])
    const [selectedType,setSelectedType]=useState("Burn")
    const [selectedSymbol,setSelectedSymbol]=useState()
    const dateTimeFormat = 'YYYY-MM-DD hh:mm:ss a';

    const formatDate = (date) => {
        let hour = date.getHours();
        let minute = `${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`
        let second = `${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`
        let day = `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`
        let month = `${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}`
        let year = `${date.getFullYear()}`
        let amOrPm = `${hour < 12 ? 'AM' : 'PM'}`
        hour = (hour % 12) || 12;
        hour = `${hour > 9 ? hour : `0${hour}`}`;
        return {date: `${day}-${month}-${year}`, time: `${hour}:${minute} ${amOrPm}`};
    }
    
    insertCss(`
    .custom-tooltip-title {
        margin: 0px 12px;
        padding: 72px 0 8px;
        font-size: 12px;
        border-bottom-style: solid;
        border-bottom-width: thin;
        border-bottom-color: #e9e9e9;
    }

    .custom-tooltip-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 8px 12px 0 12px;
      padding-bottom: 8px;
      font-size: 40px;
      text-align: center;
      border-bottom-style: solid;
      border-bottom-width: thin;
      border-bottom-color: #e9e9e9;
      height: 70px;
    }

    .custom-tooltip-temp {
      display: flex;
      position: relative;
      align-items: center;
    }

    .custom-tooltip-temp span:first-child {
      font-size: 12px;
      position: absolute;
      top: 0px;
      color: rgba(0, 0, 0, 0.45)
    }

    .custom-tooltip-temp span:last-child {
      text-align: left;
      margin-top: 10px;
      position: relative;
      color: rgba(0, 0, 0, 0.85)
    }

    .custom-tooltip-wind {
        margin: 8px 12px 12px 12px;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.45);
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .tooltip-footer {
      margin: 8px 12px 12px 12px;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.45);
    }

    .background-image {
      background-repeat: no-repeat;
    }
`);

    function getConfig(selElement, se) {
        let maxConc = se.concentrationHigh;
        let minConc = se.concentrationLow;
        let yField = elementMap[selElement] ? elementMap[selElement].symbol : "y";
        chartData.map(data => {
            if(data[yField] > maxConc) maxConc = data[yField];
            if(data[yField] < minConc) minConc = data[yField];
        })
        return {
            height:200,
            xField:"x",
            yField:yField,
            point:{
                size: 2,
                shape: 'circle',
                style: function (item) {
                    if (item['C'] > se.concentrationHigh || item['C'] < se.concentrationLow) {
                        return {stroke:'#ff0000', fill:'#ff0000'}
                    }
                    return {stroke:'#00bb00', fill:'#00bb00'}
                }
            },
            color:'blue',
            xAxis: {
                label: {
                    offset: 10,
                    style: {
                        fill: 'black',
                        fontSize: 10,
                    },
                },
                title: {
                    text: 'Burn Time',
                    style: {
                      fontSize: 10,
                    },
                  },
            },
            yAxis: {
                label: {
                    style: {
                        fill: 'black',
                        fontSize: 10,
                    },
                    formatter: (value) => Number.parseFloat(value).toFixed(digitsAfterDecimal+2).slice(0,-2),
                },
                title: {
                    text: 'Concentration',
                    style: {
                      fontSize: 10,
                    },
                },
                min: minConc,
                max: maxConc
            },
           
            annotations: [
                {
                    type: 'text',
                    position: ['min', se.concentrationLow],
                    content: 'Low',
                    offsetY: -4,
                    style: {
                        fill:'green',
                        textBaseline: 'bottom',
                    },
                },
                {
                    type: 'line',
                    start: ['min', se.concentrationLow],
                    end: ['max', se.concentrationLow],
                    style: {
                        stroke: 'green',
                        lineDash: [2, 2],
                    },
                },
                {
                    type: 'text',
                    position: ['min', se.concentrationHigh],
                    content: 'High',
                    offsetY: -4,
                    style: {
                        fill:'red',
                        textBaseline: se.concentrationHigh === maxConc ? 'top' : 'baseline',
                    },
                },
                {
                    type: 'line',
                    start: ['min', se.concentrationHigh],
                    end: ['max', se.concentrationHigh],
                    style: {
                        stroke: 'red',
                        lineDash: [2, 2],
                    },
                },
            ],

            tooltip: {
                customContent: (title, items) => {
                    const data = items[0]?.data || {};
                    let concentration = elementMap[selElement] ? data[elementMap[selElement].symbol] : data.y
                    let {date, time} = formatDate(new Date(data.x))
                    return (
                        <List size="small" split={false}>
                            <List.Item style={{padding:'2px 0'}}>
                                <Space>
                                    <div style={{backgroundColor:'blue', height:'5px', width:'5px', borderRadius:'50%', marginTop:'-2px'}}></div>
                                    <h6 style={{fontSize:'0.8em'}}>{elementMap[selElement] ? elementMap[selElement].symbol : data.y}</h6>
                                    <h6>{concentration}</h6>
                                </Space>
                            </List.Item>
                            <List.Item style={{padding:'2px 0'}}><Space><h6>Date</h6><h6>{date}</h6></Space></List.Item>
                            <List.Item style={{padding:'2px 0'}}><Space><h6>Time</h6><h6>{time}</h6></Space></List.Item>
                            <List.Item style={{padding:'2px 0'}}><Space><h6>Upper Range</h6><h6>{se.concentrationHigh}</h6></Space></List.Item>
                            <List.Item style={{padding:'2px 0'}}><Space><h6>Lower Range</h6><h6>{se.concentrationLow}</h6></Space></List.Item>
                            <List.Item style={{padding:'2px 0'}}>
                                <Space>
                                    <h6>Check Range</h6>
                                    <h6>{se.concentrationLow < concentration && concentration < se.concentrationHigh ? 'In Limit' :
                                        concentration <= se.concentrationLow ? 'Lower Limit' : 'Upper Lmit'}</h6>
                                </Space>
                            </List.Item>
                        </List>
                    )
                },
            },
        }
    }


    const config = {
        xField: 'x',
        yField: 'y',
        seriesField: 'type',
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
          },
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                     fontSize: 10,
                 },
                autoHide: true,
                autoRotate: false,
            },
            title: {
                text: 'Elements',
                style: {
                  fontSize: 10,
                },
              }
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                     fontSize: 10,
                 },
                autoHide: true,
                autoRotate: false,
            },
            title: {
                text: 'Concentration',
                style: {
                  fontSize: 10,
                },
              },
        },
        meta: {
            type: {
                alias: 'type',
            },
            samples: {
                alias: 'Samples',
            },
        },
    };
useEffect(()=>{
DeviceDataService.loadBases(id).then(res=>{
     setSelectedSymbol(res.data.filter(el=>el.baseId===selectedBase)[0].symbol)
})
},[id,selectedBase])



    useEffect(() => {
        loadData()
    }, [selectedBase, selectedMatrix, fromDate, toDate, selectedSampleName,selectedType])

    function loadData() {
        DeviceDataService.loadElements(deviceId).then(response => {
            let elements = response.data;
              for (let i = 0; i < elements.length; i++) {
                elementMap[elements[i].elementId] = elements[i]
            }
            setElementMap(elementMap)
         //   setElements(response.data)
        
            if (selectedMatrix) {
                DeviceDataService.loadMatrixSpectrums(deviceId, selectedMatrix).then(response => {
                    let specs = response.data.filter(s => s.showSpectrum).sort(function (a,b) {return a.sequence - b.sequence})
                    for (let i=0; i< specs.length; i++) {
                        spectrumMap[specs[i].elementId] = specs[i]
                        elementMap[specs[i].elementId].specs = specs[i]
                    }
                    setMatrixSpectrum(specs)
                    setSpectrumMap(spectrumMap);

                   // let se = elementMap[selectedElement].specs
                    let data = [];
                    let element=[]
                    let meanData=[]
                    //https://charts.ant.design/en/examples/line/multiple#line-grid

                    if(type==="livedata"){
                        DeviceDataService.loadData(deviceId).then(response=>{
                            let newArray = response.data.filter(el=>el.sampleName ===selectedSampleName)
                        let results = newArray;
                        for (let i = 1; i <= results.length; i++) {
                            let bresult = results[i-1].results;
                            //   let ce = {x:moment(results[i].burnTime).format(dateTimeFormat)}
                            for (let j = 0; j < bresult.length; j++) {
                                let br = bresult[j];
                                if (br.concentration > 0 && br.concentration < 100) {
                                    data.push({type:"Burn"+i,x:elementMap[br.elementId].symbol,y:br.concentration});
                                    element.push(elementMap[br.elementId].symbol)
                                //      ce[elementMap[br.elementId].symbol] = br.concentration;
                                //    ce[elementMap[br.elementId].elementId] = br.concentration;
                                }
                            }
                            }
                            let uniqueElement= [...new Set(element)]
                            for(let i=0; i<uniqueElement.length; i++){
                                let filtdata= data.filter(el=>el.x===uniqueElement[i])
                                let sum=0;
                                if(filtdata !== [] && filtdata[0].x !== props.base){
                                    for(let j=0; j<filtdata.length; j++){
                                        sum=sum+filtdata[j].y
                                        meanData.push(filtdata[j])
                                    }
                                    meanData.push({type:"Mean",x:filtdata[0].x,y:parseFloat((sum/filtdata.length).toFixed(6))})
                                }
                            }
                            setChartData(meanData)
                            setChartConfig(config)
                        })
                    }else{
                        DeviceDataService.loadFilteredHistoryData(deviceId, fromDate, toDate, selectedBase, selectedMatrix).then(response => {
                            let newArray = response.data.filter(el=>el.sampleName ===selectedSampleName)
                            let results = newArray;
                            let burnUid=[]
                            for (let i = 0; i < results.length; i++) {
                                burnUid.push(results[i].burnSetUid)
                                let bresult = results[i].results;
                                let ce = {x:moment(results[i].burnTime).format(dateTimeFormat)}
                                ce["y"]=results[i].burnSetUid
                                for (let j = 0; j < bresult.length; j++) {
                                    let br = bresult[j];
                                    if (br.concentration > 0 && br.concentration < 100) {
                                        ce[elementMap[br.elementId].symbol] = br.concentration;
                                    //    ce[elementMap[br.elementId].elementId] = br.concentration;
                                    }
                                }
                                data.push(ce);
                            }  
                            let uniqueId = [...new Set(burnUid)]
                            let uniqueSymbol=Object.keys(data[0]).filter(el=>el !=='x'&&el !=="y")
                            let meanData=[];
                            for(let i=0;i<uniqueId.length;i++){
                                let ce={};
                                let filtResult= newArray.filter(el=>el.burnSetUid===uniqueId[i])
                                for(let k=0; k<uniqueSymbol.length;k++){
                                    let sum=0;
                                    for(let j=0; j<filtResult.length;j++){
                                        sum=sum+filtResult[j].results.filter(el=>el.symbol===uniqueSymbol[k])[0].concentration  
                                    }
                                    ce[uniqueSymbol[k]]=parseFloat((sum/filtResult.length).toFixed(6))
                                }
                                ce['x']=moment(filtResult[0].burnTime).format(dateTimeFormat)
                                meanData.push(ce)
                            }
                            if(selectedType==="Mean"){
                                setChartData(meanData)
                            }else{
                                setChartData(data)
                            }
                        //  setChartConfig(getConfig(selectedElement, se))
                        })
                    }
                })
            }
        })
    }

    

    function changeSelectedElement(eid) {
         if(selectedElement.includes(eid)){
        }else{
         selectedElement.push(eid)
        }

        if(selectedElement.length<=4){
        setSelectedElement(selectedElement)
       // let se = elementMap[eid].specs
       setChartConfig(getConfig(selectedElement[0], elementMap[selectedElement[0]].specs))
       setChartConfig1(getConfig(selectedElement[1], elementMap[selectedElement[1]].specs))
       setChartConfig2(getConfig(selectedElement[2], elementMap[selectedElement[2]].specs))
       setChartConfig3(getConfig(selectedElement[3], elementMap[selectedElement[3]].specs))
        }else{
            selectedElement.shift();
            setSelectedElement(selectedElement)
            // let se = elementMap[eid].specs
            setChartConfig(getConfig(selectedElement[0], elementMap[selectedElement[0]].specs))
            setChartConfig1(getConfig(selectedElement[1], elementMap[selectedElement[1]].specs))
            setChartConfig2(getConfig(selectedElement[2], elementMap[selectedElement[2]].specs))
            setChartConfig3(getConfig(selectedElement[3], elementMap[selectedElement[3]].specs))
        }
    }

    
    const onChangeType=(value)=>{
    setSelectedType(value)
    }
     return (
        <>
          {type!=="livedata"?
           <>
           <span style={{float:'right'}}>
           <Title level={5}>Select Burn/Mean : <Select size="small" style={{width:100}} dropdownClassName="dropdown" defaultValue={selectedType} onChange={onChangeType}>
                    <Select.Option key='Mean' value="Mean" >Mean </Select.Option>
                    <Select.Option key='Burn' value="Burn" >Burn </Select.Option>
                    </Select>
           </Title>
           </span>
          <Row>
          <Col span={24}>
                {matrixSpectrum.filter(el=>elementMap[el.elementId].symbol!==selectedSymbol).map(spec => {
                    return <Button size="small" style={{width:65, fontSize:10}} type={typeof(selectedElement.filter(el=>el===spec.elementId)[0])!= "undefined" ? "primary" : "dashed"} onClick={() => changeSelectedElement(spec.elementId)} key={spec.elementId}>{elementMap[spec.elementId].symbol}</Button>
                })}
           </Col>
            </Row>
            <Row style={{marginTop:'10px', marginBottom:'20px'}}>
            <Col span={6} >
            
            {chartConfig && <Card style={{ backgroundColor: 'white',border:'2px solid lightgrey'}}>
            <Title align="center" level={5} style={{color:'black'}}>Element- {chartConfig.yField}</Title>
                <Line data={chartData} {...chartConfig} />
                  </Card>
            }
            </Col>
            <Col span={6}>
            {chartConfig1 &&  <Card style={{ backgroundColor: 'white',border:'2px solid lightgrey'}}>
            <Title align="center" level={5} style={{color:'black'}}>Element- {chartConfig1.yField}</Title>
               <Line data={chartData} {...chartConfig1}/>
                  </Card>}
            </Col> 
            <Col span={6}>
            {chartConfig2 && <Card style={{backgroundColor: 'white',border:'2px solid lightgrey'}}>
            <Title align="center" level={5} style={{color:'black'}}>Element- {chartConfig2.yField}</Title>
                <Line data={chartData}  {...chartConfig2}/>
                  </Card>}
            </Col>
             <Col span={6}>
             {chartConfig3 &&  <Card style={{ backgroundColor: 'white',border:'2px solid lightgrey'}}>
             <Title align="center" level={5} style={{color:'black'}}>Element- {chartConfig3.yField}</Title>
                <Line data={chartData} {...chartConfig3}/>
             </Card>}
            </Col>
        </Row></>:
        <Row >
            <Col span={24} style={{ backgroundColor: 'white', padding:'40px',border:'2px solid lightgrey'}}>
                {chartConfig && <Column style={{height:'50vh'}} data={chartData} {...chartConfig}/>}
            </Col>
        </Row>
        }
        </>
    )
}
