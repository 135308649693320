export const states = [
    { name: "Andaman and Nicobar Islands"},
    { name: "Andhra Pradesh"},
    { name: "Arunachal Pradesh"},
    { name: "Assam"},
    { name: "Bihar"},
    { name: "Chandigarh"},
    { name: "Chhattisgarh"},
    { name: "Dadra and Nagar Haveli"},
    { name: "Daman and Diu"},
    { name: "Delhi"},
    { name: "Goa"},
    { name: "Gujarat"},
    { name: "Haryana"},
    { name: "Himachal Pradesh"},
    { name: "Jammu and Kashmir"},
    { name: "Jharkhand"},
    { name: "Karnataka"},
    { name: "Kerala"},
    { name: "Lakshadweep"},
    { name: "Madhya Pradesh"},
    { name: "Maharashtra"},
    { name: "Manipur"},
    { name: "Meghalaya"},
    { name: "Mizoram"},
    { name: "Nagaland"},
    { name: "Odisha"},
    { name: "Puducherry"},
    { name: "Punjab"},
    { name: "Rajasthan"},
    { name: "Sikkim"},
    { name: "Tamil Nadu"},
    { name: "Telangana"},
    { name: "Tripura"},
    { name: "Uttarakhand"},
    { name: "Uttar Pradesh"},
    { name: "West Bengal"},
]

export const countries = [
    { name: "Afghanistan", },
    { name: "Aland Islands", },
    { name: "Albania", },
    { name: "Algeria", },
    { name: "Andorra", },
    { name: "Angola", },
    { name: "Anguilla", },
    { name: "Antarctica", },
    { name: "Antigua and Barbuda", },
    { name: "Argentina", },
    { name: "Armenia", },
    { name: "Aruba", },
    { name: "Australia", },
    { name: "Austria", },
    { name: "Azerbaijan", },
    { name: "Bahamas", },
    { name: "Bahrain", },
    { name: "Bangladesh", },
    { name: "Barbados", },
    { name: "Belarus", },
    { name: "Belgium", },
    { name: "Belize", },
    { name: "Benin", },
    { name: "Bermuda", },
    { name: "Bhutan", },
    { name: "Bolivia, Plurinational State of", },
    { name: "Bonaire, Sint Eustatius and Saba", },
    { name: "Bosnia and Herzegovina", },
    { name: "Botswana", },
    { name: "Bouvet Island", },
    { name: "Brazil", },
    { name: "British Indian Ocean Territory", },
    { name: "Brunei Darussalam", },
    { name: "Bulgaria", },
    { name: "Burkina Faso", },
    { name: "Burundi", },
    { name: "Cambodia", },
    { name: "Cameroon", },
    { name: "Canada", },
    { name: "Cape Verde", },
    { name: "Cayman Islands", },
    { name: "Chad", },
    { name: "Chile", },
    { name: "China", },
    { name: "Christmas Island", },
    { name: "Cocos (Keeling) Islands", },
    { name: "Colombia", },
    { name: "Comoros", },
    { name: "Congo", },
    { name: "Congo, the Democratic Republic of the", },
    { name: "Cook Islands", },
    { name: "Costa Rica", },
    { name: "Cote d’Ivoire", },
    { name: "Croatia", },
    { name: "Cuba", },
    { name: "Curaçao", },
    { name: "Cyprus", },
    { name: "Czech Republic", },
    { name: "Denmark", },
    { name: "Djibouti", },
    { name: "Dominica", },
    { name: "Dominican Republic", },
    { name: "Ecuador", },
    { name: "Egypt", },
    { name: "El Salvador", },
    { name: "Equatorial Guinea", },
    { name: "Eritrea", },
    { name: "Estonia", },
    { name: "Ethiopia", },
    { name: "Falkland Islands (Malvinas)", },
    { name: "Faroe Islands", },
    { name: "Fiji", },
    { name: "Finland", },
    { name: "France", },
    { name: "French Guiana", },
    { name: "French Polynesia", },
    { name: "French Southern Territories", },
    { name: "Gabon", },
    { name: "Gambia", },
    { name: "Georgia", },
    { name: "Germany", },
    { name: "Ghana", },
    { name: "Gibraltar", },
    { name: "Greece", },
    { name: "Greenland", },
    { name: "Grenada", },
    { name: "Guadeloupe", },
    { name: "Guatemala", },
    { name: "Guernsey", },
    { name: "Guinea", },
    { name: "Guinea-Bissau", },
    { name: "Guyana", },
    { name: "Haiti", },
    { name: "Heard Island and McDonald Islands", },
    { name: "Holy See (Vatican City State)", },
    { name: "Honduras", },
    { name: "Hungary", },
    { name: "Iceland", },
    { name: "India", },
    { name: "Indonesia", },
    { name: "Iran, Islamic Republic of", },
    { name: "Iraq", },
    { name: "Ireland", },
    { name: "Isle of Man", },
    { name: "Israel", },
    { name: "Italy", },
    { name: "Jamaica", },
    { name: "Japan", },
    { name: "Jersey", },
    { name: "Jordan", },
    { name: "Kazakhstan", },
    { name: "Kenya", },
    { name: "Kiribati", },
    { name: "Korea, Democratic People’s Republic of", },
    { name: "Korea, Republic of", },
    { name: "Kuwait", },
    { name: "Kyrgyzstan", },
    { name: "Lao People’s Democratic Republic", },
    { name: "Latvia", },
    { name: "Lebanon", },
    { name: "Lesotho", },
    { name: "Liberia", },
    { name: "Libya", },
    { name: "Liechtenstein", },
    { name: "Lithuania", },
    { name: "Luxembourg", },
    { name: "Macao", },
    { name: "Macedonia, the former Yugoslav Republic of", },
    { name: "Madagascar", },
    { name: "Malawi", },
    { name: "Malaysia", },
    { name: "Maldives", },
    { name: "Mali", },
    { name: "Malta", },
    { name: "Martinique", },
    { name: "Mauritania", },
    { name: "Mauritius", },
    { name: "Mayotte", },
    { name: "Mexico", },
    { name: "Moldova, Republic of", },
    { name: "Monaco", },
    { name: "Mongolia", },
    { name: "Montenegro", },
    { name: "Montserrat", },
    { name: "Morocco", },
    { name: "Mozambique", },
    { name: "Myanmar", },
    { name: "Namibia", },
    { name: "Nauru", },
    { name: "Nepal", },
    { name: "Netherlands", },
    { name: "New Caledonia", },
    { name: "New Zealand", },
    { name: "Nicaragua", },
    { name: "Niger", },
    { name: "Nigeria", },
    { name: "Niue", },
    { name: "Norfolk Island", },
    { name: "Norway", },
    { name: "Oman", },
    { name: "Pakistan", },
    { name: "Palestine", },
    { name: "Panama", },
    { name: "Papua New Guinea", },
    { name: "Paraguay", },
    { name: "Peru", },
    { name: "Philippines", },
    { name: "Pitcairn", },
    { name: "Poland", },
    { name: "Portugal", },
    { name: "Qatar", },
    { name: "Reunion", },
    { name: "Romania", },
    { name: "Russian Federation", },
    { name: "Rwanda", },
    { name: "Saint Barthélemy", },
    { name: "Saint Helena, Ascension and Tristan da Cunha", },
    { name: "Saint Kitts and Nevis", },
    { name: "Saint Lucia", },
    { name: "Saint Martin (French part)", },
    { name: "Saint Pierre and Miquelon", },
    { name: "Saint Vincent and the Grenadines", },
    { name: "Samoa", },
    { name: "San Marino", },
    { name: "Sao Tome and Principe", },
    { name: "Saudi Arabia", },
    { name: "Senegal", },
    { name: "Serbia", },
    { name: "Seychelles", },
    { name: "Sierra Leone", },
    { name: "Singapore", },
    { name: "Sint Maarten (Dutch part)", },
    { name: "Slovakia", },
    { name: "Slovenia", },
    { name: "Solomon Islands", },
    { name: "Somalia", },
    { name: "South Africa", },
    { name: "South Georgia and the South Sandwich Islands", },
    { name: "South Sudan", },
    { name: "Spain", },
    { name: "Sri Lanka", },
    { name: "Sudan", },
    { name: "Suriname", },
    { name: "Svalbard and Jan Mayen", },
    { name: "Swaziland", },
    { name: "Sweden", },
    { name: "Switzerland", },
    { name: "Syrian Arab Republic", },
    { name: "Taiwan", },
    { name: "Tajikistan", },
    { name: "Tanzania, United Republic of", },
    { name: "Thailand", },
    { name: "Timor-Leste", },
    { name: "Togo", },
    { name: "Tokelau", },
    { name: "Tonga", },
    { name: "Trinidad and Tobago", },
    { name: "Tunisia", },
    { name: "Turkey", },
    { name: "Turkmenistan", },
    { name: "Turks and Caicos Islands", },
    { name: "Tuvalu", },
    { name: "Uganda", },
    { name: "Ukraine", },
    { name: "United Arab Emirates", },
    { name: "United Kingdom", },
    { name: "United States", },
    { name: "Uruguay", },
    { name: "Uzbekistan", },
    { name: "Vanuatu", },
    { name: "Venezuela, Bolivarian Republic of", },
    { name: "Vietnam", },
    { name: "Virgin Islands, British", },
    { name: "Wallis and Futuna", },
    { name: "Western Sahara", },
    { name: "Yemen", },
    { name: "Zambia", },
    { name: "Zimbabwe" },
];

export const countryCodeList = [
    { code: "93", },
    { code: "358 18", },
    { code: "355", },
    { code: "213", },
    { code: "376", },
    { code: "244", },
    { code: "1 264", },
    { code: "672", },
    { code: "1 268", },
    { code: "54", },
    { code: "374", },
    { code: "297", },
    { code: "61", },
    { code: "43", },
    { code: "994", },
    { code: "1 242", },
    { code: "973", },
    { code: "880", },
    { code: "1 246", },
    { code: "375", },
    { code: "32", },
    { code: "501", },
    { code: "229", },
    { code: "1 441", },
    { code: "975", },
    { code: "591", },
    { code: "599", },
    { code: "387", },
    { code: "267", },
    { code: "55", },
    { code: "55", },
    { code: "246", },
    { code: "673", },
    { code: "359", },
    { code: "226", },
    { code: "257", },
    { code: "855", },
    { code: "237", },
    { code: "1", },
    { code: "238", },
    { code: "1 345", },
    { code: "235", },
    { code: "56", },
    { code: "86", },
    { code: "61", },
    { code: "61", },
    { code: "57", },
    { code: "269", },
    { code: "243", },
    { code: "243", },
    { code: "682", },
    { code: "506", },
    { code: "225", },
    { code: "385", },
    { code: "53", },
    { code: "599 9", },
    { code: "357", },
    { code: "420", },
    { code: "45", },
    { code: "253", },
    { code: "1 767", },
    { code: "1 809", },
    { code: "593", },
    { code: "20", },
    { code: "503", },
    { code: "240", },
    { code: "291", },
    { code: "372", },
    { code: "251", },
    { code: "500", },
    { code: "298", },
    { code: "679", },
    { code: "358", },
    { code: "33", },
    { code: "594", },
    { code: "689", },
    { code: "262", },
    { code: "241", },
    { code: "220", },
    { code: "995", },
    { code: "49", },
    { code: "233", },
    { code: "350", },
    { code: "30", },
    { code: "299", },
    { code: "1 473", },
    { code: "590", },
    { code: "502", },
    { code: "44 1481", },
    { code: "224", },
    { code: "245", },
    { code: "592", },
    { code: "509", },
    { code: "3166-1", },
    { code: "379", },
    { code: "504", },
    { code: "36", },
    { code: "354", },
    { code: "91", },
    { code: "62", },
    { code: "98", },
    { code: "964", },
    { code: "353", },
    { code: "44 1624", },
    { code: "972", },
    { code: "39", },
    { code: "1 876", },
    { code: "81", },
    { code: "44 1534", },
    { code: "962", },
    { code: "7 6", },
    { code: "254", },
    { code: "686", },
    { code: "850", },
    { code: "82", },
    { code: "965", },
    { code: "996", },
    { code: "856", },
    { code: "371", },
    { code: "961", },
    { code: "266", },
    { code: "231", },
    { code: "218", },
    { code: "423", },
    { code: "370", },
    { code: "352", },
    { code: "853", },
    { code: "389", },
    { code: "261", },
    { code: "265", },
    { code: "60", },
    { code: "960", },
    { code: "223", },
    { code: "356", },
    { code: "596", },
    { code: "222", },
    { code: "230", },
    { code: "262", },
    { code: "52", },
    { code: "373", },
    { code: "377", },
    { code: "976", },
    { code: "382", },
    { code: "1 664", },
    { code: "212", },
    { code: "258", },
    { code: "95", },
    { code: "264", },
    { code: "674", },
    { code: "977", },
    { code: "31", },
    { code: "687", },
    { code: "64", },
    { code: "505", },
    { code: "227", },
    { code: "234", },
    { code: "683", },
    { code: "672 3", },
    { code: "47", },
    { code: "968", },
    { code: "92", },
    { code: "970", },
    { code: "507", },
    { code: "675", },
    { code: "595", },
    { code: "51", },
    { code: "63", },
    { code: "64", },
    { code: "48", },
    { code: "351", },
    { code: "974", },
    { code: "262", },
    { code: "40", },
    { code: "7", },
    { code: "250", },
    { code: "590", },
    { code: "290", },
    { code: "1 869", },
    { code: "1 758", },
    { code: "590", },
    { code: "508", },
    { code: "1 784", },
    { code: "685", },
    { code: "378", },
    { code: "239", },
    { code: "966", },
    { code: "221", },
    { code: "381", },
    { code: "248", },
    { code: "232", },
    { code: "65", },
    { code: "721", },
    { code: "421", },
    { code: "386", },
    { code: "677", },
    { code: "252", },
    { code: "27", },
    { code: "500", },
    { code: "211", },
    { code: "34", },
    { code: "94", },
    { code: "249", },
    { code: "597", },
    { code: "47", },
    { code: "268", },
    { code: "Sweden", },
    { code: "41", },
    { code: "963", },
    { code: "886", },
    { code: "992", },
    { code: "255", },
    { code: "66", },
    { code: "670", },
    { code: "228", },
    { code: "690", },
    { code: "676", },
    { code: "1 868", },
    { code: "216", },
    { code: "90", },
    { code: "993", },
    { code: "1 649", },
    { code: "688", },
    { code: "256", },
    { code: "380", },
    { code: "971", },
    { code: "44", },
    { code: "1", },
    { code: "598", },
    { code: "998", },
    { code: "678", },
    { code: "58", },
    { code: "84", },
    { code: "1", },
    { code: "681", },
    { code: "212", },
    { code: "967", },
    { code: "260", },
    { code: "263" },
];