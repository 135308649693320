import React from "react";
import { Select, Row, Col, Divider,Typography } from "antd";
import { useState, useEffect } from "react";
import { Pie } from '@ant-design/plots';
import moment from "moment";


export default function BurnPieChart(props) {
    const { Title } = Typography;
    const [chartData, setChartData] = useState()
    let sampleBurnsDetail = props.sampleBurnsDetail
    let selectedSample=props.selectedSample;
    let digitsAfterDecimal=props.digitsAfterDecimal;
    let results=[];
    let addMean={};

    for(let i=0;i<selectedSample.length;i++){
        results.push({symbol:selectedSample[i].element.props.children[0],concentration:selectedSample[i].meanh})
    }

    addMean.burnNo="Mean"
    addMean.results=results
    
   if(sampleBurnsDetail.results[0].burnNo!=="Mean") {
   sampleBurnsDetail.results.unshift(addMean)
   }


    useEffect(() => {
        selectDefaultBurnNumber()
    }, [])

    function ParseFloat(str,val) {
        str = str.toString();
        str = str.slice(0, (str.indexOf(".")) + val + 1); 
        return Number(str);   
    }

    

    const dataMap = (filtval) => {
        let data = []
        for (let i = 0; i < filtval[0].results.length; i++) {
            if (filtval[0].results[i].concentration < 0) {
                data.push({ symbol: filtval[0].results[i].symbol, concentration: 0 })
            } else {
                data.push({ symbol: filtval[0].results[i].symbol, concentration: ParseFloat(filtval[0].results[i].concentration,digitsAfterDecimal) })
            }
        }
        data = data.filter(ele => ele.symbol !== props.base)
        setChartData(data)
    }

    const selectDefaultBurnNumber = () => {
        let filtval = sampleBurnsDetail.results.filter(el => el.burnNo === sampleBurnsDetail.results[0].burnNo)
        dataMap(filtval)
    }

    const selectBurnNumber = (value) => {
        let filtval = sampleBurnsDetail.results.filter(el => el.burnNo === value)
        dataMap(filtval)
    }


    const config = {
        appendPadding: 10,
        angleField: 'concentration',
        colorField: 'symbol',
        radius: 0.9,
        theme:{
            backgroundColor:'red',
        },
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name} : {value}',
            precision: 2
        },
        interactions: [
            {
                type: 'element-selected',
              },
            {
                type: 'element-active',
            },
        ],
    };

    return (
        <>
            <Row style={{marginBottom:'20px'}}>
                <Col span={16} >
                  <Title level={5}> {sampleBurnsDetail.sampleName}
                    <Divider type="vertical" style={{ backgroundColor: '#1890ff'}} />
                    {moment(sampleBurnsDetail.burnTime).format('YYYY-MM-DD hh:mm:ss a')}
                    </Title>
                </Col>

                <Col span={8}>
                <Title level={5}>Select Mean/Burn :
                    <Select size="small" style={{marginLeft:'5px'}} dropdownClassName="dropdown" defaultValue={sampleBurnsDetail.results[0].burnNo} onChange={selectBurnNumber} placeholder='Select Burn'>
                        {sampleBurnsDetail.results.map(el => {
                        if(el.burnNo==="Mean"){
                            return <Select.Option key={el.burnNo} value={el.burnNo}>{el.burnNo}</Select.Option>
                        }else{
                            return <Select.Option key={el.burnNo} value={el.burnNo}>Burn {el.burnNo}</Select.Option>
                         } })}
                    </Select>
                    </Title>
                </Col>
            </Row>
            
            {chartData && <Pie data={chartData} {...config} />}
        </>
    )
};

