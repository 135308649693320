import { CloseOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, notification, Progress, Row, Space, Spin, Table, Tooltip, Typography, Upload, Modal, message, Image, Avatar } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import DeviceService from '../services/DeviceService';
import SetupFileService from '../services/SetupFileService';
import UserService from '../services/UserService';
import defaultInstrumentImage from "../extra/images/devices.png";

const {confirm} = Modal

const SoftwareView = () => {

  const [admin, setAdmin] = useState(false);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editSelected, setEditSelected] = useState(null);
  const [visible, setVisible] = useState(false);
  const [downloading, setDownloading] = useState(false);
  
  const extraModels = ['2in1-GP', '2in1-TP', '3in1', 'Wireless RTDS', 'Armour Safe', 'Argon Purifier +', 'Clean Sharp', 'Handyman', 'Wire Adaptors', 'Sampling Moulds'];

  useEffect(() => {
    refresh();
  }, [])

  const refresh = () => {
    setLoading(true);
    setModels([]);
    setEditSelected(null);
    setVisible(false);
    UserService.getUserProfile().then(res => {
        setAdmin(!res.data.client)
        SetupFileService.getAllAnalystApp().then(resp => {
            if(!res.data.client){
                getAllDeviceTypes(resp.data);
            }
            else {
                getClientDeviceType(resp.data);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.log(err);
        })
    }).catch(err => {
        console.log(err);
        setLoading(false);
    })
  }

  const filterOutModels = (models) => {
    return models.filter(model => !extraModels.includes(model.model))
  }

  const checkModelAvailability = (modelName, available) => {
    return available.includes(modelName);
  }

  const showNotAvailableMessage = () => {
    message.error("No Analyst App Availble for this model");
  }

  const getClientDeviceType = (available) => {
    DeviceService.listMappedDeviceName().then(res => {
        let mappedNames = res.data;
        DeviceService.getActiveDevices().then(res => {
            let tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                let customName = mappedNames.filter(name => name.legacyName === res.data[i].modelName)
                customName = customName.length === 0 ? res.data[i].modelName : customName[0].customName;
                let filt = []
                filt = tmp.filter(dat => customName === dat.model)
                if(filt.length === 0){
                    tmp.push({model: customName, available: checkModelAvailability(customName, available)});
                }
            }
            setModels(tmp);
        })
    })
  }

  const getAllDeviceTypes = (available) => {
    // console.log({available});
    DeviceService.listMappedDeviceName().then(res => {
        let tmp = res.data.map(dat => {
            return {oldName:dat.legacyName, model: dat.customName, available: checkModelAvailability(dat.customName, available)}
        })
        setModels(tmp);
    })
  }

  const downloadFile = (record) => {
    setDownloading(true);
    SetupFileService.downloadSetUpFile(record.model).then(res => {
        // console.log(res);
        const href = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        let file = res.headers['content-disposition'].split('=')[1].replaceAll('"', '');
        link.setAttribute('download', file); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloading(false);
    }).catch(error => {
        setDownloading(false);
        notification.error({
            message: error.response.data.message
        })
    })
  }

  const uploadFile = (deviceModel, version, file) => {
    setDownloading(true);
    SetupFileService.uploadSetUpFile(deviceModel, version, file).then(res => {
        // console.log(res);
        setDownloading(false);
        notification.success({
            message:'Flie Uploaded Successfully'
        })
        refresh();
    }).catch(err => {
        setDownloading(false);
        notification.error({
            message: 'Error Uploading File'
        })
    })
    setVisible(false)
  }

  const handleEditOpen = (record) => {
    setEditSelected(record);
    setVisible(true);
  }

  const handleEditClose = () => {
    setEditSelected(null);
    setVisible(false);
  }

  const showDeleteConfirm = (deviceModel) => {
    confirm({
      title: `Are you sure you want to delete Ananlyst App for ${deviceModel} ?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        SetupFileService.deleteAnalystApp(deviceModel).then(
            (response) => {
                message.success("Analyst App deleted");
                refresh();
            },
            (error) => {
                message.error(error.response.data.message);
            }
        )
      },
    });
  };

  const uploadDeviceImage = (res) => {
    const formData = new FormData();
    const modelName = editSelected.model
    formData.append("file", res.deviceImage[0].originFileObj);
    SetupFileService.uploadInstrumentImage(modelName, formData)
      .then((res) => {
        notification.success({
          type: "info",
          description: "Device Image Uploaded Successfully",
          duration: 2,
        });
        setVisible(false);
        refresh();
      })
      .catch((err) => {
        notification.warn({
          type: "warning",
          description: "Image file type extension '.jpg , .jpeg , .png'",
          duration: 2,
        });
      });
};

  const columns = [
    {
        title: 'Device Image',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (val, record) => (
            <Space>
                <InstrumentImage deviceModel={record.model} />
            </Space>
        )
    },
    {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (val, record) => (
            <Space>
                <Typography.Text>The Analyst</Typography.Text>
            </Space>
        )
    },
    // {
    //     title: 'Version',
    //     dataIndex: 'version',
    //     key: 'version',
    // },
    {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
    },
    // {
    //     title: 'Uploaded Date',
    //     dataIndex: 'uploadedDate',
    //     key: 'uploadedDate',
    // },
    {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        render: (link, record) => {
            let downloadLink = `${window.origin}/public/download/analystapp/${record.model}`
            return (
                record.available ?
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Typography.Text style={{textDecoration:'underline'}}><a href={downloadLink} target='_blank' rel="noreferrer">{downloadLink}</a></Typography.Text>
                    <CopyToClipboard text={downloadLink} style={{color:'#5837D0', marginTop:'10px'}} onCopy={() => notification.success({message:'Link copied to Clipboard', duration:2})}><CopyOutlined /></CopyToClipboard>
                </div>
                :
                "-"
            )
        }
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (_, record) => {
            return (
                <Space size='large'>
                    {admin && <Space onClick={() => handleEditOpen(record)} style={{cursor:'pointer', color:'steelblue'}}><EditOutlined /><Typography.Text>Edit</Typography.Text></Space>}
                    <Space onClick={() => record.available ? downloadFile(record) : showNotAvailableMessage()} style={{cursor:'pointer', color:'steelblue'}}><DownloadOutlined /><Typography.Text>Download</Typography.Text></Space>
                    {admin && <Space onClick={() => record.available ? showDeleteConfirm(record.model) : showNotAvailableMessage()} style={{cursor:'pointer', color:'steelblue'}}><DeleteOutlined style={{color:'red'}} /><Typography.Text>Delete</Typography.Text></Space>}
                </Space>
            )
        }
    },
  ]

  return (
    <>
        <Row justify='center' style={{marginTop:'5vh'}}>
            <Col xl={20} lg={20} md={22} sm={24} xs={24} style={{display:'flex', justifyContent:'end', height:'50px'}}>
                {downloading  && <Spin></Spin>}
            </Col>
            <Col xl={20} lg={20} md={22} sm={24} xs={24}>
                <Table
                    loading={loading}
                    size='midddle'
                    dataSource={models}
                    columns={columns}
                    rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                    pagination={{ 
                        showSizeChanger: true, 
                        defaultCurrent: 1,
                    }}
                    scroll={{x:true}}
                />
            </Col>
        </Row>
        <Drawer
            title={"Edit"}
            visible={visible}
            onCancel={handleEditClose}
            onClose={handleEditClose}
            closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
        >
            <SoftwareForm refresh={refresh} initial={editSelected} handleEdit={uploadFile} handleClose={handleEditClose} handleDeviceImageUpload={uploadDeviceImage} />
        </Drawer>
    </>
  )
}

export default SoftwareView

const SoftwareForm = ({initial, handleEdit, handleClose, refresh, handleDeviceImageUpload}) => {

    console.log({initial});

    const [form] = Form.useForm();
    const [imageUploadForm] = Form.useForm();

    const [editDevicePicture, setEditDevicePicture] = useState(false);
    
    useEffect(() => form.resetFields());

    const handleFinish = (val) => {
        const formData = new FormData();
        formData.append("file", val.file[0]['originFileObj']);
        handleEdit(val.model, val.version, formData)
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
    };

    const handleDevicePictureDrawerClose = () => {
        setEditDevicePicture(false);
        imageUploadForm.resetFields();
    }

    const handleDevicePictureDrawerOpen = () => {
        setEditDevicePicture(true);
    };

    const handleImageUpload = (res) => {
        setEditDevicePicture(false);
        imageUploadForm.resetFields();
        handleDeviceImageUpload(res);
    }

    

    return (
        <>
            <Form form={form} initialValues={initial} onFinish={handleFinish} className="analyst-form" layout="vertical">
                <Form.Item
                    label={
                        <Typography.Title level={5} style={{ color: "white", marginBottom: "0" }}>
                            Model Name
                        </Typography.Title>
                    }
                    name="model"
                    rules={[{ required: true}]}
                >
                    <Input name="model" allowClear placeholder="Model Name" disabled />
                </Form.Item>
                <Form.Item
                    label={
                        <Typography.Title level={5} style={{ color: "white", marginBottom: "0" }}>
                            Version
                        </Typography.Title>
                    }
                    name="version"
                    rules={[{ required: true}]}
                >
                    <Input name="version" allowClear placeholder="Version" />
                </Form.Item>
                <Form.Item
                    name="file"
                    label={
                        <Typography.Title level={5} style={{ color: "white", marginBottom: "0" }}>
                            Upload here
                        </Typography.Title>
                    }
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload 
                        name="file" 
                        maxCount={1} 
                        listType='picture' 
                        beforeUpload={() => false}
                        itemRender={(originNode, file, currFileList) => <div style={{color:'whitesmoke'}}><Tooltip >{originNode}</Tooltip></div>}
                    >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Space style={{marginBottom:'20px'}}>
                    <Button onClick={handleDevicePictureDrawerOpen}>Upload Device Model Image</Button>
                </Space>
                <Form.Item>
                    <Space>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button className="ml-2 device-form-button" onClick={handleClose} style={{ marginLeft: "10px" }}>
                        Close
                    </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Drawer
                title="Upload Device Model Image"
                width={320}
                closable={false}
                onCancel={handleDevicePictureDrawerClose}
                onClose={handleDevicePictureDrawerClose}
                visible={editDevicePicture}
                closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
            >
                <Form form={imageUploadForm} onFinish={handleImageUpload} >
                    <Form.Item name="deviceImage" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload name="deviceImage" maxCount={1} listType="picture" beforeUpload="false">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                    </Form.Item>
                    <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                        <Button className="ml-2 device-form-button" onClick={handleDevicePictureDrawerClose} style={{ marginLeft: "10px" }}>
                        Close
                        </Button>
                    </Space>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

const InstrumentImage = ({deviceModel}) => {
    const [image, setImage] = useState(defaultInstrumentImage);
    useEffect(() => {
      if (deviceModel) {
        SetupFileService.getInstrumentImage(deviceModel).then(res => {
            const objectUrl = URL.createObjectURL(res.data);
            setImage(objectUrl);
            return objectUrl;
        }).catch(err => {
            setImage(defaultInstrumentImage);
            message.warn(err);
        })
      }
    }, [deviceModel]);
    return (
      <>
        <Avatar src={<Image src={image} style={{ width: 40 }} />} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
      </>
    );
  };