import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

var firebaseConfig = {
	apiKey:"AIzaSyALHRtjLV1s8GdATWesVnLIlkVavtcLQYQ",
	authDomain:"metacloud-22e6a.firebaseapp.com",
	projectId: "metacloud-22e6a",
	storageBucket:"metacloud-22e6a.appspot.com",
	messagingSenderId:"700638162880",
	appId:"1:700638162880:web:bbc199d06f1637b1d5e225",
	measurementId:"G-MMJ0NX4RV3",
};
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
