import React, { useState, useEffect } from "react";
import { Button, Input, Form, Drawer, Space, message, Select, Typography, notification } from "antd";
import OrganizationService from "../services/OrganizationService";
import { CloseOutlined } from "@ant-design/icons";
const { Title } = Typography;

const OrganizationForm = (props) => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const close = () => {
    props.organizationFrom();
    setVisible(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    OrganizationService.loadAccounts().then((response) => {
      setAccounts(response.data);
    });
  }

  const onFinish = (values) => {
    for (let idx = 0; idx < accounts.length; idx++) {
      if (values.crmId === accounts[idx].Id) {
        values.crmName = accounts[idx].Name;
        break;
      }
    }
    OrganizationService.saveOrganization(values)
      .then((response) => {
        notification.success({
          type: "success",
          description: "Organization Added",
          duration: 2,
        });
        props.refresh();
      })
      .catch((err) =>
        notification.warn({
          type: "warning",
          description: err.response.data.message,
          duration: 2,
        })
      );
  };

  return (
    <>
      <Drawer
        title={"Add New Client"}
        visible={visible}
        onCancel={close}
        onClose={close}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form form={form} name="organizationForm" layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Company Legal Name
              </Title>
            }
            name="legalName"
            rules={[{ required: true, min: 3, message: "Enter Company Legal Name!" }]}
          >
            <Input placeholder="Company Legal Name" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Company Name
              </Title>
            }
            name="name"
            rules={[{ required: true, min: 3, message: "Enter Company Code!" }]}
          >
            <Input placeholder="Company Name " disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Email
              </Title>
            }
            name="email"
          >
            <Input placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Contact Number
              </Title>
            }
            name="phoneNo"
          >
            <Input allowClear placeholder="Contact Number " disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                GST Number
              </Title>
            }
            name="gstNumber"
          >
            <Input allowClear placeholder="GST Number " disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Location
              </Title>
            }
            name="location_name"
            rules={[{ required: true, min: 3, message: "Enter Location!" }]}
          >
            <Input placeholder="Location" />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Address
              </Title>
            }
            name="address"
            rules={[{ required: true, min: 3, message: "Enter Address!" }]}
          >
            <Input placeholder="Address" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Sales force Account
              </Title>
            }
            name="crmId"
          >
            <Select
              disabled
              showSearch
              placeholder="Select Salesforce account"
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {accounts.map((acc) => {
                return (
                  <Select.Option key={acc.Id} value={acc.Id}>
                    {acc.Name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" disabled>
                Save
              </Button>
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
export default OrganizationForm;
