import React, { useState, useEffect } from "react";
import { Button, Input, Typography, Select, message, Form, Drawer, Space, DatePicker } from "antd";
import DeviceService from "../services/DeviceService";
import { CloseOutlined } from "@ant-design/icons";
const { Option, OptGroup } = Select;
const { Title } = Typography;

export function DeviceForm(props) {
  const { Text } = Typography;
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [devices, setDevices] = useState([]);
  const [modelNames, setModelNames] = useState([]);

  const close = () => {
    setVisible(false);
    props.refresh();
  };

  useEffect(() => {
    getListOfDevices();
    getModelNames();
  }, []);

  const getListOfDevices = () => {
    DeviceService.getDevices()
      .then((response) => {
        let devices = response.data.filter(
          (item) => item.category === "Stationary & Benchtop (OES)" || item.category === "Mobile Optical Emission Spectrometers (OES)"
        );
        setDevices(devices);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const getModelNames = () => {
    DeviceService.getModelName()
      .then((response) => {
        let modelNames = response.data.filter((d) => d !== "" && d !== null);
        setModelNames(modelNames);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  let deviceCategory = [];
  for (let i = 0; i < devices.length; i++) {
    deviceCategory.push(devices[i].category);
  }
  let uniquedeviceCategory = [...new Set(deviceCategory)];
  let deviceDetails = [];
  let categoryDetails = [];
  for (let i = 0; i < uniquedeviceCategory.length; i++) {
    categoryDetails = [];
    for (let j = 0; j < devices.length; j++) {
      if (devices[j].category === uniquedeviceCategory[i]) {
        categoryDetails.push(devices[j]);
      }
    }
    deviceDetails.push({ category: uniquedeviceCategory[i], details: categoryDetails });
  }

  const createDevice = (e) => {
    let device = { ...e };
    if (props.device.id) {
      device.id = props.device.id;
      device.organizationUid = props.device.organizationUid;
    }
    DeviceService.createDevice(device)
      .then((response) => {
        if (device.id) {
          message.success("Device updated");
        } else {
          message.success("Device created");
        }
        setLoading(false);
        props.refresh();
      })
      .catch((error) => {
        message.warn("Device serial number must be unique");
        setLoading(false);
      });
  };

  return (
    <>
      <Drawer
        title={props.mode === "Add" ? "Add Device" : props.mode === "Edit" ? "Edit Device" : "Change Device Location"}
        centered
        maskClosable={false}
        visible={visible}
        onClose={close}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form initialValues={props.device} onFinish={createDevice} className="device-form" layout="vertical">
          {props.device.id ? (
            <Form.Item
              style={{ borderRadius: "20px" }}
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Company Name
                </Title>
              }
              name="orgLegalName"
              color="blue"
              rules={[{ required: true, message: "Please Select Company!" }]}
            >
              <Input allowClear placeholder="Company Name" disabled />
            </Form.Item>
          ) : (
            <Form.Item
              style={{ borderRadius: "20px" }}
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Select Company
                </Title>
              }
              name="organizationUid"
              color="blue"
              rules={[{ required: true, message: "Please Select Company!" }]}
            >
              <Select
                disabled
                allowClear="true"
                placeholder="Select Company"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {(props.org || []).map((organization, index) => {
                  return (
                    <Select.Option key={index} value={organization.id} title={organization.legalName}>
                      {organization.legalName}, {organization.address}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {props.device.id ? (
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Device Model
                </Title>
              }
              name="deviceName"
              rules={[{ required: true, message: "Please enter device name!" }]}
            >
              <Input allowClear placeholder="Device Model Name" />
            </Form.Item>
          ) : (
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Device Model
                </Title>
              }
              name="deviceName"
              rules={[{ required: true, message: "Please enter device name!" }]}
            >
              <Select
                disabled
                allowClear="true"
                placeholder="Select Model Name"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {(modelNames || []).map((modelName) => (
                  <Option key={modelName} value={modelName} title={modelName}>
                    {modelName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {props.device.id ? (
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Device Serial Number
                </Title>
              }
              name="serialNumber"
              rules={[{ required: true, message: "Please enter Serial Number!" }]}
            >
              <Input allowClear placeholder="Device Serial Number" disabled />
            </Form.Item>
          ) : (
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Device Serial Number
                </Title>
              }
              name="serialNumber"
              rules={[{ required: true, message: "Please enter Serial Number!" }]}
            >
              <Input allowClear placeholder="Device Serial Number" disabled />
            </Form.Item>
          )}

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Install Date
              </Title>
            }
            name="installDate"
          >
            <DatePicker disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Warranty Start Date
              </Title>
            }
            name="warrantyStartDate"
          >
            <DatePicker disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Warranty End Date
              </Title>
            }
            name="warrantyEndDate"
          >
            <DatePicker disabled />
          </Form.Item>

          <Form.Item>
            {validationError && (
              <Text type="danger">
                {validationError}
                <br />
              </Text>
            )}
            <br />
            <div>
              {props.device.id ? (
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              ) : (
                <Button type="primary" htmlType="submit" loading={loading} disabled>
                  Save
                </Button>
              )}
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
