const CONFIG = {
    style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#dfa231",
        themeColor: "#ffffff",
        headerBackgroundColor: "#96c3ffb5",
        headerColor: "#000000fc",
        errorColor: "",
        successColor: "",
        card: {
            padding: "",
            backgroundColor: ""
        }
    },
    data: {
        orderId: "",
        amount: "",
        token: "",
        tokenType: "TXN_TOKEN",
    },
    merchant: {
        mid: "MetalP89805695086008",
        name: "Metal Power",
        logo: "",
        redirect: false
    },
    root: "",
    flow: "DEFAULT"
};

export default CONFIG;