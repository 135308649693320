import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DeviceBurnCountView from "./DeviceBurnCountView";
import DeviceMasterCardView from "./DeviceMasterCardView";
import DeviceSoftwareVersionView from "./DeviceSoftwareVersionView";
import DeviceElementsView from "./DeviceElementsView";
import DeviceBasesView from "./DeviceBasesView";
import DeviceMatrixTypesView from "./DeviceMatrixTypesView";
import DeviceMatrixSpectrumView from "./DeviceMatrixSpectrumView";
import { Button, Tabs,Typography } from 'antd';
import DeviceService from "../../services/DeviceService";
import DeviceDiagnosticView from "./DeviceDiagnosticView";
import DeviceRealTimeView from "./DeviceRealTimeView";
import DeviceHistoricalView from "./DeviceHistoricalView";
import SupportView from "../crm/SupportView";
import LatestDiagnosticView from "./LatestDiagnosticView";
import './../../App.css'

const { Title } = Typography;

const { TabPane } = Tabs;

export default function UserDeviceView() {
    let { id } = useParams()
    const [device, setDevice] = useState()
    const [serialNumber,setSerialNumber]=useState("");


    useEffect(() => {
        loadDevice();
    }, [id])

    function loadDevice() {
        DeviceService.getDevice(id).then(response => {
            DeviceService.getMappedDeviceName(response.data.deviceName).then(res => {
                setDevice({...response.data, deviceName: res.data.customName});
            })
            setSerialNumber(response.data.serialNumber);
        });
    }

    return (
        <div style={{marginTop:'-20px', marginBottom:'-20px'}}>
             <Tabs defaultActiveKey="Burns" className="tab" size="small" centered>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1", fontWeight:'700',color:'white',margin:'0 2vw'}}>Real-Time</Button>} key="Burns">
               <DeviceRealTimeView/>
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700',margin:'0 2vw'}}>Historical</Button>} key="History">
                {device&& <Title level={5} style={{marginTop:'-10px'}}><span className='device'>{device.deviceName}</span> | Sr.No. : {device.serialNumber}  </Title>}
                <DeviceHistoricalView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700',margin:'0 2vw'}}>Diagnostics</Button>} key="Diagnostic">
                  {/*  <DeviceDiagnosticView />  */}
                <LatestDiagnosticView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700',margin:'0 2vw'}}>Programs</Button>} key="Matrix-Spectrum">
                {device&& <Title level={5} style={{marginTop:'-10px'}}><span className='device'>{device.deviceName}</span> | Sr.No. : {device.serialNumber}</Title>}
                <DeviceMatrixSpectrumView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700',margin:'0 2vw'}}>Support</Button>} key="Support">
                {device&& <Title level={5} style={{marginTop:'-10px'}}><span className='device'>{device.deviceName}</span> | Sr.No. : {device.serialNumber}</Title>}
                <SupportView deviceId={serialNumber}/>
                </TabPane>
                
                
              {/*  <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700'}}>Burn Counts</Button>} key="Burn-Counts">
                {device&& <Title level={5} style={{marginLeft:'10px',marginTop:'-10px'}}>Model-{device.deviceName} | Sl.No.-{device.serialNumber}</Title>}
                <DeviceBurnCountView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700'}}>Master Cards</Button>} key="Master-Cards">
                {device&& <Title level={5} style={{marginLeft:'10px',marginTop:'-10px'}}>Model-{device.deviceName} | Sl.No.-{device.serialNumber}</Title>}
                <DeviceMasterCardView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700'}}>Software Versions</Button>} key="Software-Versions">
                {device&& <Title level={5} style={{marginLeft:'10px',marginTop:'-10px'}}>Model-{device.deviceName} | Sl.No.-{device.serialNumber}</Title>}
                <DeviceSoftwareVersionView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700'}}>Elements</Button>} key="Elements">
                {device&& <Title level={5} style={{marginLeft:'10px',marginTop:'-10px'}}>Model-{device.deviceName} | Sl.No.-{device.serialNumber}</Title>}
                <DeviceElementsView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700'}}>Bases</Button>} key="Bases">
                {device&& <Title level={5} style={{marginLeft:'10px',marginTop:'-10px'}}>Model-{device.deviceName} | Sl.No.-{device.serialNumber}</Title>}
                <DeviceBasesView />
                </TabPane>
                <TabPane tab={<Button size="small" style={{ backgroundColor: "#2E86C1",color:'white', fontWeight:'700'}}>Matrix Types</Button>} key="Matrix-Types">
                {device&& <Title level={5} style={{marginLeft:'10px',marginTop:'-10px'}}>Model-{device.deviceName} | Sl.No.-{device.serialNumber}</Title>}
                <DeviceMatrixTypesView />
                </TabPane> */}
            </Tabs>
        </div>
    );
}

