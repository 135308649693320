import React, { useEffect, useState } from 'react'
import DeviceDataService from '../../services/DeviceDataService'
import { Link, useParams } from "react-router-dom";
import { Table, Select, Typography, Switch, DatePicker, Space, Button, Card, Row, Col, Collapse } from "antd";
import moment from 'moment';
import { Line } from '@ant-design/plots';
import Carousel from "react-elastic-carousel";
import { SyncOutlined } from '@ant-design/icons';
import DeviceMasterCardView from './DeviceMasterCardView';
const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;


function RealTimeDiagnosticView(props) {
    const { RangePicker } = DatePicker;
    const [data, setData] = useState([])
    const [toggleBtn, setToggleBtn] = useState(true)
    const [show, setShow] = useState(true)
    const [timeInterval, setTimeInterval] = useState()
    const [dateValue, setDateValue] = useState(moment().format('YYYY-MM-DD'))
    const [chartData1, setChartData1] = useState([])
    const [chartData2, setChartData2] = useState([])
    const [chartData3, setChartData3] = useState([])
    const [chartData4, setChartData4] = useState([])
    const [chartData5, setChartData5] = useState([])
    

    let  id  = props.id;
    let datasource = [];
    const dateFormat = 'YYYY-MM-DD';
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    const initialtime=moment().subtract(2, 'h').format(dateTimeFormat)
    const finaltime=moment().format(dateTimeFormat)

//console.log(moment().subtract(1, 'h').format(dateTimeFormat))
    useEffect(() => {
     //   loadLatestDiag()
        getDiagnostic(id, initialtime, finaltime)
    }, [id])

   /* const loadLatestDiag = () => {
        DeviceDataService.getLiveDiagnosticData(id).then(res => {
           // console.log(res.data.pairingStatus)
           

      if(res.data.pairingStatus){
            DeviceDataService.getDiagnosticMaster(id).then(response => {

                datasource.push(
                    {
                        type: 'Pairing Status',
                        value: <div style={{color:'green'}}>Connected</div>,
                    }
                )
                //........................................................................................//           
                let filtFlow = response.data.filter(el => el.diagnosticName.trim() === "Flow");

                if (res.data.diagnosticLogResponse.diagnostic1.flow === null) {
                    datasource.push(
                        {
                            type: 'Flow',
                            value: <div>-</div>,
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.flow < filtFlow[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Flow',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.flow}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.flow > filtFlow[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Flow',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.flow}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Flow',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.flow}</div>,
                        },
                    )
                }
                //...............................................................................................//       
                let filtIdealFlow = response.data.filter(el => el.diagnosticName.trim() === "IdealFlow")

                if (res.data.diagnosticLogResponse.diagnostic1.idealFlow === null) {
                    datasource.push(
                        {
                            type: 'Ideal Flow',
                            value: "-",
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.idealFlow < filtIdealFlow[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Ideal Flow',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.idealFlow}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.idealFlow > filtIdealFlow[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Ideal Flow',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.idealFlow}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Ideal Flow',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.idealFlow}</div>,
                        },
                    )
                }
                //...................................................................................................//

                let filtExtraHighPurgeFlow = response.data.filter(el => el.diagnosticName.trim() === "ExtraHighPurgeFlow")

                if (res.data.diagnosticLogResponse.diagnostic1.extraHighPurgeFlow === null) {
                    datasource.push(
                        {
                            type: 'Extra High Purge Flow',
                            value: "-",
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.extraHighPurgeFlow < filtExtraHighPurgeFlow[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Extra High Purge Flow',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.extraHighPurgeFlow}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.extraHighPurgeFlow > filtExtraHighPurgeFlow[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Extra High Purge Flow',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.extraHighPurgeFlow}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Extra High Purge Flow',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.extraHighPurgeFlow}</div>,
                        },
                    )
                }
                //.................................................................................................//

                let filtPressure = response.data.filter(el => el.diagnosticName.trim() === "Pressure")

                if (res.data.diagnosticLogResponse.diagnostic1.pressure === null) {
                    datasource.push(
                        {
                            type: 'Pressure',
                            value: "-",
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.pressure > filtPressure[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Pressure',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.pressure}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.pressure < filtPressure[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Pressure',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.pressure}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Pressure',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.pressure}</div>
                        },
                    )
                }
                //.............................................................................................//

                let filtExtraHighPurgePressure = response.data.filter(el => el.diagnosticName.trim() === "ExtraHighPurgePressure")

                if (res.data.diagnosticLogResponse.diagnostic1.extraHighPurgePressure === null) {
                    datasource.push(
                        {
                            type: 'Extra High Purge Pressure',
                            value: <div>-</div>,
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.extraHighPurgePressure > filtExtraHighPurgePressure[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Extra High Purge Pressure',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.extraHighPurgePressure}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.extraHighPurgePressure < filtExtraHighPurgePressure[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Extra High Purge Pressure',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.extraHighPurgePressure}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Extra High Purge Pressure',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.extraHighPurgePressure}</div>
                        },
                    )
                }
                //...................................................................................................//

                let filtUnitTemp = response.data.filter(el => el.diagnosticName.trim() === "ExtraHighPurgePressure")

                if (res.data.diagnosticLogResponse.diagnostic1.unitTemp === null) {
                    datasource.push(
                        {
                            type: 'Unit Temperature',
                            value: "-",
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.unitTemp < filtUnitTemp[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Unit Temperature',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.unitTemp}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.unitTemp > filtUnitTemp[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Unit Temperature',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.unitTemp}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Unit Temperature',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.unitTemp}</div>,
                        },
                    )
                }

                //.................................................................................................//

                let filtAmbientTemp = response.data.filter(el => el.diagnosticName.trim() === "AmbientTemp")

                if (res.data.diagnosticLogResponse.diagnostic1.ambientTemp === null) {
                    datasource.push(
                        {
                            type: 'Ambient Temperature',
                            value: <div>-</div>,
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.ambientTemp < filtAmbientTemp[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Ambient Temperature',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.ambientTemp}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.ambientTemp > filtAmbientTemp[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Ambient Temperature',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.ambientTemp}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Ambient Temperature',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.ambientTemp}</div>,
                        },
                    )
                }

                //...........................................................................................................//
                let filtOptics1Temp = response.data.filter(el => el.diagnosticName.trim() === "Optics1Temp")

                if (res.data.diagnosticLogResponse.diagnostic1.optics1Temp === null) {
                    datasource.push(
                        {
                            type: 'Optics1 Temperature',
                            value: "-",
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.optics1Temp > filtOptics1Temp[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Optics1 Temperature',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.optics1Temp}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.optics1Temp < filtOptics1Temp[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Optics1 Temperature',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.optics1Temp}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Optics1 Temperature',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.optics1Temp}</div>,
                        },
                    )
                }
                //....................................................................................................//

                let filtOptics2Temp = response.data.filter(el => el.diagnosticName.trim() === "Optics2Temp")

                if (res.data.diagnosticLogResponse.diagnostic1.optics2Temp === null) {
                    datasource.push(
                        {
                            type: 'Optics2 Temperature',
                            value: "-",
                        }
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.optics2Temp < filtOptics2Temp[0].minLimit) {
                    datasource.push(
                        {
                            type: 'Optics2 Temperature',
                            value: <div style={{ color: 'blue' }}>{res.data.diagnosticLogResponse.diagnostic1.optics2Temp}</div>,
                        },
                    )
                } else if (res.data.diagnosticLogResponse.diagnostic1.optics2Temp > filtOptics2Temp[0].maxLimit) {
                    datasource.push(
                        {
                            type: 'Optics2 Temperature',
                            value: <div style={{ color: 'red' }}>{res.data.diagnosticLogResponse.diagnostic1.optics2Temp}</div>,
                        },
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Optics2 Temperature',
                            value: <div style={{ color: 'green' }}>{res.data.diagnosticLogResponse.diagnostic1.optics2Temp}</div>,
                        },
                    )
                }


                //........................................................................................................//

                if (Object.values(res.data.diagnosticLogResponse.diagnostic2)[1]) {
                    datasource.push(
                        {
                            type: 'Power Source',
                            value: <div style={{ color: 'green' }}>OK</div>,
                        }
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Power Source',
                            value: <div style={{ color: 'red' }}>NOT OK</div>,
                        },
                    )
                }

                if (Object.values(res.data.diagnosticLogResponse.diagnostic2)[2]) {
                    datasource.push(
                        {
                            type: 'Aux2 -7.5V',
                            value: <div style={{ color: 'green' }}>OK</div>,
                        }
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Aux2 -7.5V',
                            value: <div style={{ color: 'red' }}>NOT OK</div>,
                        },
                    )
                }

                if (Object.values(res.data.diagnosticLogResponse.diagnostic2)[3]) {
                    datasource.push(
                        {
                            type: 'Aux3 -12V',
                            value: <div style={{ color: 'green' }}>OK</div>,
                        }
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Aux3 -12V',
                            value: <div style={{ color: 'red' }}>NOT OK</div>,
                        },
                    )
                }

                if (Object.values(res.data.diagnosticLogResponse.diagnostic2)[4]) {
                    datasource.push(
                        {
                            type: 'Aux4 +24V',
                            value: <div style={{ color: 'green' }}>OK</div>,
                        }
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Aux4 +24V',
                            value: <div style={{ color: 'red' }}>NOT OK</div>,
                        },
                    )
                }

                if (Object.values(res.data.diagnosticLogResponse.diagnostic2)[5]) {
                    datasource.push(
                        {
                            type: 'Aux5 +24V2',
                            value: <div style={{ color: 'green' }}>OK</div>,
                        }
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Aux5 +24V2',
                            value: <div style={{ color: 'red' }}>NOT OK</div>,
                        },
                    )
                }

                if (Object.values(res.data.diagnosticLogResponse.diagnostic2)[6]) {
                    datasource.push(
                        {
                            type: 'Aux6 +12V3',
                            value: <div style={{ color: 'green' }}>OK</div>,
                        }
                    )
                } else {
                    datasource.push(
                        {
                            type: 'Aux6 +12V3',
                            value: <div style={{ color: 'red' }}>NOT OK</div>,
                        },
                    )
                }
                setData(datasource)
            })
        }else{
        

                //........................................................................................// 
                
                datasource.push(
                    {
                        type: 'Pairing Status',
                        value: <div style={{color:'red'}}>Disconnected</div>,
                    }
                )          
                      datasource.push(
                        {
                            type: 'Flow',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Ideal Flow',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Extra High Purge Flow',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Pressure',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Extra High Purge Pressure',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Unit Temperature',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Ambient Temperature',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Optics1 Temperature',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Optics2 Temperature',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Power Source',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Aux3 -12V',
                            value: <div>-</div>,
                        }
                    )

                    datasource.push(
                        {
                            type: 'Aux4 +24V',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Aux5 +24V2',
                            value: <div>-</div>,
                        }
                    )
                    datasource.push(
                        {
                            type: 'Aux6 +12V3',
                            value: <div>-</div>,
                        }
                    )
                 
                    setData(datasource)
        }
         
        })
    }
*/
    const chartConfig = {
        height: 200,
        xField: "timestamp",
        yField: "value",
        seriesField: 'category',
        point: {
            size: 2,
            shape: 'circle',
        },
        color: ['red', 'blue', 'green', 'orange', 'yellow', 'purple'],
        legend: {
            itemName: {
                style: {
                    fill: "white"
                }
            }
        },
        xAxis: {
            label: {
                offset: 10,
                style: {
                    fill: 'white',
                    fontSize: 10,
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'white',
                    fontSize: 10,
                },
                formatter: (value) => Number.parseFloat(value).toFixed(2),
            }
        },

    }

    

 /*   const columns = [
        {
            title: "Parameter",
            key: "type",
            dataIndex: "type",
            width: 100,
        },
        {
            title: "Value/Status",
            key: "value",
            width: 100,
            dataIndex: "value",
        }
    ]

    function onDateChange(value) {
        setDateValue(value.format(dateFormat))
    }
    */

    const getDiagnostic = (id, from, to) => {
        let data1 = []
        let data2 = []
        let data3 = []
        let data4 = []
        let data5 = []
        let data6 = []

          DeviceDataService.getCloudDiagnosticData(id, from, to).then(res => {
           // console.log(res.data)
            let diagnostic = res.data
            for (let i = 0; i < diagnostic.length; i++) {
                data1.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.ambientTemp,
                    category: "Ambient Temp"
                })
                data2.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.pressure,
                    category: "Pressure"
                })
                data3.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.optics1Temp,
                    category: "Optics Temp One"
                })
                data3.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.optics2Temp,
                    category: "Optics Temp Two"
                })
                data4.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.unitTemp,
                    category: "Unit Temp"
                })
                data5.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.flow,
                    category: "Flow"
                })
                data6.push({
                    timestamp: moment(diagnostic[i].diagnosticEpochTime).format(dateTimeFormat),
                    value: diagnostic[i].diagnostic1.idealFlow,
                    category: "Ideal Flow"
                })
            }

            setChartData1(data1.reverse())
            setChartData2(data2.reverse())
            setChartData3(data3.reverse())
            setChartData4(data4.reverse())
            setChartData5(data5.reverse())
        })
    }

  /*  const getDateTime = () => {
           getDiagnostic(id, initialtime, finaltime)
    }
    */
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 }
    ];



    const RefreshData = () => {
        setData([])
      //  loadLatestDiag()
    }
    return (
        <div>
                    {typeof (chartData1[0] || chartData2[0] || chartData3[0] || chartData4[0] || chartData5[0]) !== "undefined" ?
                        <div className="carousel" style={{ marginBottom: '10px', margin: '-10px 10px' }}>
                            <div className="carousel-wrapper">
                                <Carousel breakPoints={breakPoints} pagination={false}>
                                    {
                                        typeof (chartData1[0]) !== "undefined" ? <>
                                            {chartConfig && <Card className="carousel-card" style={{ border: '2px solid lightGrey', backgroundColor: '#10316B' }}><Line style={{ height: '40vh', width: '22vw' }} data={chartData1} {...chartConfig} /></Card>}
                                        </> : <></>
                                    }
                                    {
                                        typeof (chartData2[0]) !== "undefined" ? <>
                                            {chartConfig && <Card className="carousel-card" style={{ border: '2px solid lightGrey', backgroundColor: '#10316B' }}><Line style={{ height: '40vh', width: '22vw' }} data={chartData2} {...chartConfig} /></Card>}
                                        </> : <></>
                                    }
                                    {
                                        typeof (chartData3[0]) !== "undefined" ? <>
                                            {chartConfig && <Card className="carousel-card" style={{ border: '2px solid lightGrey', backgroundColor: '#10316B' }}><Line style={{ height: '40vh', width: '22vw' }} data={chartData3} {...chartConfig} /></Card>}
                                        </> : <></>
                                    }
                                    {
                                        typeof (chartData4[0]) !== "undefined" ? <>
                                            {chartConfig && <Card className="carousel-card" style={{ border: '2px solid lightGrey', backgroundColor: '#10316B' }}><Line style={{ height: '40vh', width: '22vw' }} data={chartData4} {...chartConfig} /></Card>}
                                        </> : <></>
                                    }
                                    {chartConfig && chartData5[0] && <Card className="carousel-card" style={{ border: '2px solid lightGrey', backgroundColor: '#10316B' }}><Line style={{ height: '40vh', width: '22vw' }} data={chartData5} {...chartConfig} /></Card>}
                                </Carousel>
                            </div>
                        </div> : <></>}

        </div>
    )
}

export default RealTimeDiagnosticView