import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DeviceDataService from "../../services/DeviceDataService";
import {Table} from "antd";

export default function DeviceMasterCardView(props) {
    let {id} = useParams();
    const [cards, setCards] = useState([])

    useEffect(() => {
        DeviceDataService.loadMasterCards(id).then(response => {
            let data = response.data;
            data.map(item => {
                let statusString = item.slaveStatus.split(",");
                item.slaveStatus = item.enabled ? statusString[0] == 1 ? <div style={{ color: 'green' }}>Connected</div> : <div style={{ color: 'red' }}>Disconnected</div> : "NA";
                item.detectorStatus = item.enabled ? statusString[1] == 1 ? <div style={{ color: 'green' }}>Connected</div> : <div style={{ color: 'red' }}>Disconnected</div> : "NA";
                item.duplicateSlaveId = item.enabled ? statusString[2] == 1 ? <div style={{ color: 'green' }}>OK</div> : <div style={{ color: 'red' }}>NOT OK</div> : "NA";
                item.memoryStatus = item.enabled ? statusString[3] == 1 ? <div style={{ color: 'green' }}>OK</div> : <div style={{ color: 'red' }}>NOT OK</div> : "NA";
            });
            setCards(data);
        })
    }, [id])

    const columns = [
        {
            title: "Slave Card No.",
            key: "ccdNo",
            dataIndex: "ccdNo"
        },
        {
            title: "Slave Status",
            key: "slaveStatus",
            dataIndex: "slaveStatus",
            align: 'center'

        },
        {
            title: "Detector status",
            key: "detectorStatus",
            dataIndex: "detectorStatus",
            align: 'center'

        },
        {
            title: "Duplicate Slave Id",
            key: "duplicateSlaveId",
            dataIndex: "duplicateSlaveId",
            align: 'center'

        },
        {
            title: "Memory Status",
            key: "memoryStatus",
            dataIndex: "memoryStatus",
            align: 'center'

        },
       /* {
            title: "CCD Type",
            key: "ccdType",
            dataIndex: "ccdType"

        },
        {
            title: "Mode",
            key: "mode",
            dataIndex: "mode"
        },
        {
            title: "Optics#",
            key: "opticsNo",
            dataIndex: "opticsNo"
        },
        {
            title: "Data Type",
            key: "dataType",
            dataIndex: "dataType"
        },
        {
            title: "Enabled?",
            key: "enabled",
            dataIndex: "enabled",
            render: flag => {
                return <> {flag ? "Yes" : "No"}</>
            }
        },
        {
            title: "Offset",
            key: "offset",
            dataIndex: "offset"
        },
        {
            title: "Bank#",
            key: "bankNo",
            dataIndex: "bankNo"
        },
        {
            title: "Slave Id",
            key: "slaveId",
            dataIndex: "slaveId"
        },
        {
            title: "Time Gating?",
            key: "timeGating",
            dataIndex: "timeGating",
            render: flag => {
                return <> {flag ? "Yes" : "No"}</>
            }
        },  */
    ]
    return (
        <div>
            <Table dataSource={cards} columns={columns} pagination={false} rowKey="ccdNo" rowClassName={(record, index) => index % 2 === 0 ? 'row_light' :  'row_dark'}/>
        </div>
    );
}