import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, message, notification, Space, Table, Tag, Typography } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import SubscriptionService from '../../services/SubscriptionService';

const TransactionHistory = ({orgId}) => {

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setLoading(true);
    refresh();
  }, [searchText, page, pageSize])

  const refresh = () => {
    SubscriptionService.getTransactionList(page - 1, pageSize, searchText).then(res => {
//         console.log(res.data);
        setTransactions(res.data.list);
        setTotalTransactions(res.data.total);
        setLoading(false);
    }).catch(err => {
        message.error('Error Loading Transactions');
        setLoading(false);
    })
  }

  function resetPageAndSize() {
    setPage(1);
    setPageSize(10);
  }

  const columns = [
    {
        title: 'Org Name',
        dataIndex: 'orgName',
        key: 'orgName',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
              <>
                <Input
                  autoFocus
                  placeholder="Search Company Name"
                  value={searchText}
                  onChange={(e) => {
                    resetPageAndSize();
                    setSearchText(e.target.value);                
                    confirm({ closeDropdown: false });
                  }}
                  onPressEnter={() => {
                    confirm();
                  }}
                  onBlur={() => {
                    confirm();
                  }}
                ></Input>
              </>
            );
          },
          filterIcon: () => {
            if(searchText){
              return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
            }else{         
              return <SearchOutlined className="active"/>}
          },
          onFilter: (value, record) => {
            if (value) {
              return record.organizationLegalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
            }
          },
        align: 'center',
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email"
    },
    {
        title: 'Maximum Users',
        dataIndex: 'maximumUsers',
        key: 'maximumUsers',
    },
    {
        title: 'Plan',
        dataIndex: 'planName',
        key: 'planName',
        // align: 'center',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Payment Mode',
        dataIndex: 'paymentMode',
        key: 'paymentMode',
        render: (paymentMode) => {
            return <Typography.Text>{paymentMode !== null ? paymentMode : "-"}</Typography.Text>
        }
    },
    {
      title: 'Transaction Id',
      dataIndex: 'paymentId',
      key: 'paymentId',
      align: 'center',
      render: (paymentId) => {
          return(
            <Space>
              <Typography.Text>{paymentId !== null ? paymentId : "-"}</Typography.Text>
              {paymentId !== null && <CopyToClipboard text={paymentId} style={{color:'#5837D0', marginTop:'10px'}} onCopy={() => notification.success({message:'Transaction Id copied to Clipboard', duration:2})}><CopyOutlined /></CopyToClipboard>}
            </Space>

          ) 
      }
    },
    {
        title: 'Transaction Time',
        dataIndex: 'transactionTime',
        key: 'transactionTime',
        align: 'center',
        render: (transactionTime) => {
            return <Typography.Text>{transactionTime !== null ? new Date(transactionTime).toString(): "-"}</Typography.Text>
        }
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align:'center',
        render: (status) => {
            return <Tag color={status === "TXN_SUCCESS" ? 'success' : 'error'}>{status === "PENDING" ? "CANCELLED" : status}</Tag>
        }
    },

  ]

  return (
    <>
        <Table 
            loading={loading}
            scroll={{x:true}}
            size='midddle'
            dataSource={transactions}
            columns={columns}
            rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
            pagination={{ 
                showSizeChanger: true, 
                total: totalTransactions, 
                pageSize:pageSize,
                // defaultCurrent: 1,
                current: page,
                onShowSizeChange: (_, size) => setPageSize(size),
                onChange: (num, size) => {setPage(num); setPageSize(size)}
            }}
        />
    </>
  )
}

export default TransactionHistory