import React, { useEffect, useState, useRef } from "react";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, SyncOutlined, SettingOutlined, CloseOutlined, UploadOutlined, DownloadOutlined, CopyOutlined } from "@ant-design/icons";
import { DeviceForm } from "./DeviceForm";
import { Table, Divider, Row, Col, Card, Button, Modal, message, Tag, Switch, Popover, Affix, Input, Space, notification, Form, Typography, Upload, Drawer, Spin } from "antd";
import DeviceService from "../services/DeviceService";
import { SearchOutlined } from "@ant-design/icons";
import { useAuth } from "../helpers/auth";
import OrganizationService from "../services/OrganizationService";
import moment from "moment";
import UserService from "../services/UserService";
import SetupFileService from "../services/SetupFileService";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";

const { confirm } = Modal;

const FACTORY_DATABASE = 'FactoryDatabase'
const SERVICE_BACKUP = 'ServiceBackup';
const SINGLE_SUS = 'SingleSUS';
const MPA_FACTORY_1 = 'MPAFactory1';
const MPA_FACTORY_2 = 'MPAFactory2';
const MPA_FACTORY_3 = 'MPAFactory3';
const MPA_FACTORY_4 = 'MPAFactory4';
const MPA_FACTORY_5 = 'MPAFactory5';
const MPA_FACTORY_6 = 'MPAFactory6';
const MPA_FACTORY_7 = 'MPAFactory7';
const MPA_FACTORY_8 = 'MPAFactory8';
const MPA_FACTORY_9 = 'MPAFactory9';
const MPA_FACTORY_10 = 'MPAFactory10';
const MISC = 'Misc';

export default function DeviceListView() {
  const [tableShow, setTableShow] = useState(true);
  const [activeOrInactiveButton, setActiveOrInactiveButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState('');
  const [org, setOrg] = useState([]);
  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  const [showOrgDetails, setShowOrgDetails] = useState(false);
  const [mode, setMode] = useState("Add");
  const [device, setDevice] = useState({
    id: "",
    deviceName: "",
    active: "",
    serialNumber: "",
    lastSeen: "",
    online: "",
    companyName: "",
    deviceLocation: "",
  });
  const [inactiveDevice, setInactiveDevice] = useState('');
  const { user: activeUser } = useAuth();
  const privilege = activeUser.privileges;

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [devieSerialSearch, setDevieSerialSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalDevicesActive, setTotalDevicesActive] = useState([]);

  const [currentPageInactive, setCurrentPageInactive] = useState(0);
  const [pageSizeInactive, setPageSizeInactive] = useState(10);
  const [searchTextInactive, setSearchTextInactive] = useState("");
  const [devieSerialSearchInactive, setDevieSerialSearchInactive] = useState("");
  const [totalPagesInactive, setTotalPagesInactive] = useState(0);
  const [totalDevicesInactive, setTotalDevicesInactive] = useState([]);
  const [showFiles, setShowFiles] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [editSelectedFile, setEditSelectedFile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  
  useEffect(() => {
    if(tableShow){
      refresh(searchText, currentPage, pageSize);
      UserService.getUserProfile().then(res => {
        setAdmin(!res.data.client)
      })
    }
  }, [searchText, devieSerialSearch]);
  
  useEffect(() => {
    if(!tableShow){
      getInActiveDevice(searchTextInactive, currentPageInactive, pageSizeInactive);
    }
  }, [searchTextInactive, devieSerialSearchInactive]);

  function refresh(searchText, currentPage, pageSize) {
    setLoading(true);
    let sText = searchText;
    let cPage = currentPage;
    let pSize = pageSize;
    if (sText === undefined && cPage === undefined && pSize === undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    DeviceService.getAllActiveDevices(devieSerialSearch, sText, cPage, pSize)
      .then((response) => {
        DeviceService.listMappedDeviceName().then(names => {
          let customNames = {};
          names.data.forEach(name => customNames[name.legacyName] = name.customName);
          let customDevices = response.data.list.map(device => {
            return {...device, deviceName: customNames[device.deviceName]}
          })
          setDevices(customDevices);
          setPageSize(response.data.pageSize);
          setTotalDevicesActive(response.data.total);
          setCurrentPage(response.data.pageNumber);
          setTotalPages(response.data.totalPages);
          setLoading(false);
          setShowDeviceDetails(false);
          setShowOrgDetails(false);
          setTableShow(true);
          setActiveOrInactiveButton(true);
        })        
      })
      .catch((error) => {
        message.error(error.message);
        setDevices([])
      });
  }

  function resetPageAndSize() {
    if(tableShow){
    setCurrentPage(0);
    setPageSize(10);
    }
    if(!tableShow){
      setCurrentPageInactive(0);
      setPageSizeInactive(10);
    }
  }

  const forActiveSearchText = (value) => {
    if (value !== "") {
      let currentPage = 0;
      refresh(value, currentPage, pageSize);
    } else {
      refresh(value, currentPage, pageSize);
    }
  };

  const handleTableChange = (e) => {
    let currentPage = e.current - 1;
    let pageSize = e.pageSize;
    refresh(searchText, currentPage, pageSize);
  };

  function getInActiveDevice(searchTextInactive, currentPageInactive, pageSizeInactive) {
    setLoading(true);
    let sText = searchTextInactive;
    let cPage = currentPageInactive;
    let pSize = pageSizeInactive;
    if (sText === undefined && cPage === undefined && pSize === undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    setTableShow(false);
    setActiveOrInactiveButton(false);
    DeviceService.getAllInActiveDevices(devieSerialSearchInactive, sText, cPage, pSize)
      .then((response) => {
        DeviceService.listMappedDeviceName().then(names => {
          let customNames = {};
          names.data.forEach(name => customNames[name.legacyName] = name.customName);
          let customDevices = response.data.list.map(device => {
            return {...device, deviceName: customNames[device.deviceName]}
          })
          setInactiveDevice(customDevices);
          setPageSizeInactive(response.data.pageSize);
          setTotalDevicesInactive(response.data.total);
          setCurrentPageInactive(response.data.pageNumber);
          setTotalPagesInactive(response.data.totalPages);
          setLoading(false);
        })
      })
      .catch((error) => {
        message.warn(error);
        setLoading(false);
        setInactiveDevice([]);
      });
  }

  const forInactiveSearchText = (value) => {
    if (value !== "") {
      let currentPageInactive = 0;
      getInActiveDevice(value, currentPageInactive, pageSizeInactive);
    } else {
      getInActiveDevice(value, currentPageInactive, pageSizeInactive);
    }
  };

  const handleTableChangeForInActive = (e) => {
    let currentPageInactive = e.current - 1;
    let pageSizeInactive = e.pageSize;
    getInActiveDevice(searchTextInactive, currentPageInactive, pageSizeInactive);
  };

  const refreshDevices = () => {
    refresh();
  };

  const addDevice = () => {
    setMode("Add");
    setShowDeviceDetails(true);
    setDevice({
      deviceName: "",
      active: "",
      serialNumber: "",
      lastSeen: "",
      online: "",
      companyName: "",
      deviceLocation: "",
      installDate: "",
      warrantyStartDate: "",
      warrantyEndDate: "",
    });
  };

  const showDevice = (deviceId, devName) => {
    DeviceService.getDevice(deviceId).then(
      (response) => {
        let device = response.data;
        device.deviceName = devName;
        device.installDate = device.installDate ? moment(device.installDate) : "";
        device.warrantyStartDate = device.warrantyStartDate ? moment(device.warrantyStartDate) : "";
        device.warrantyEndDate = device.warrantyEndDate ? moment(device.warrantyEndDate) : "";
        setDevice(device);
        setShowDeviceDetails(true);
        setMode("Edit");
      },
      (error) => {
        message.error(error.response.data.message);
      }
    );
  };

  const showInactiveConfirm = (deviceId, refresh) => {
    confirm({
      title: "Are you sure you want to make device inactive?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        DeviceService.markInactive(deviceId).then(
          (response) => {
            message.success("Device marked as inactive!");
            refresh();
          },
          (error) => {
            message.error(error.response.data.message);
          }
        );
      },
      onCancel() {
        // console.log("Cancel");
        refresh();
      },
    });
  };

  const showActiveConfirm = (deviceId) => {
    confirm({
      title: "Are you sure you want to make device active?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        DeviceService.markActive(deviceId)
          .then((response) => {
            message.success("Device marked as active!");
            getInActiveDevice();
          })
          .catch((error) => {
            message.error(error.response.data.message);
          });
      },
      onCancel() {
        // console.log("Cancel");
        refresh();
      },
    });
  };

  const getDeviceToken = (deviceId) => {
    if (deviceId) {
      DeviceService.getDeviceToken(deviceId)
        .then((response) => {
          notification.success({
            type: "info",
            description: (
              <>
                <Tag color="success">Token</Tag>
                <br /> {response.data} <br />
                <Tag color="success"> Device Id</Tag> <br /> {deviceId}
              </>
            ),

            duration: 4,
          });
        })
        .catch((error) => {
          notification.warn({
            type: "warning",
            description: "Something Went Wrong",
            duration: 2,
          });
        });
    }
  };

  const syncDevicesFromSalesforces = () => {
    DeviceService.syncDataFromSaleforces()
      .then((response) => {
        notification.success({
          type: "info",
          description: <>Synced</>,
          duration: 1,
        });
        refresh();
      })
      .catch((error) => {
        notification.warn({
          type: "warning",
          description: "Something Went Wrong",
          duration: 1,
        });
      });
  };

  const columnsForActiveDevices = [
    {
      title: "Company Name",
      dataIndex: "orgLegalName",
      key: "orgLegalName",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            {admin && <Input
              autoFocus
              placeholder="Search Company Name"
              value={searchText}
              onChange={(e) => {
                resetPageAndSize();
                setSearchText(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>}
          </>
        );
      },
      filterIcon: () => {
        if(admin){
          if(searchText){
            return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
          }else{         
            return <SearchOutlined className="active"/>}
        }
      },
      onFilter: (value, record) => {
        if (value) {
          return record?.orgLegalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Device Model",
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      title: "Device Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Serial Number"
              value={devieSerialSearch}
              onChange={(e) => {
                resetPageAndSize();
                setDevieSerialSearch(e.target.value); 
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(devieSerialSearch){
          return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{
          return <SearchOutlined className="active"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record?.serialNumber?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Dispatched On",
      dataIndex: "dispatchDate",
      key: "dispatchDate",
      render: (text, record) => {
        let dispatchDate = record.dispatchDate;
        if (dispatchDate) {
          dispatchDate = dispatchDate.slice(0, 10);
        }
        return <>{admin ? dispatchDate : record.paired  ? dispatchDate : "-"}</>;
      },
    },
    {
      title: "Commissioned On",
      dataIndex: "installDate",
      key: "commissionedOndon",
      render: (text, record) => {
        let installDate = record.installDate;
        if (installDate) {
          installDate = installDate.slice(0, 10);
        }
        return <>{admin ? installDate :  record.paired ? installDate : "-"}</>;
      },
    },
    {
      title: "Warranty End Date",
      dataIndex: "warrantyEndDate",
      key: "warrantyEndDate",
      render: (text, record) => {
        let warrantyEndDate = record.warrantyEndDate;
        if (warrantyEndDate) {
          warrantyEndDate = warrantyEndDate.slice(0, 10);
        }
        return <>{admin ? warrantyEndDate : record.paired ? warrantyEndDate : "-"}</>;
      },
    },
    {
      title: "Next Renewal on",
      dataIndex: "renewalDate",
      key: "renewalDate",
      render: (text, record) => {
        let nextRenewalon = record.nextRenewalon;
        if (nextRenewalon) {
          nextRenewalon = nextRenewalon.slice(0, 10);
        }
        return <>{admin ? nextRenewalon : record.paired ? nextRenewalon : "-"}</>;
      },
    },
    {
      title: "Contract Type",
      dataIndex: "contractType",
      key: "contractType",
      render: (contractType, record) => {
        return <>{admin ? contractType : record.paired ? contractType : "-"}</>
      }
    },
    {
      title: "Software Version",
      dataIndex: "softwareVersion",
      key: "softwareVersion",
      render: (softwareVersion, record) => {
        return <>{admin ? softwareVersion : record.paired ? softwareVersion : "-"}</>
      }
    },
    {
      title: "Instrument Age",
      dataIndex: "instrumentAge",
      key: "instrumentAge",
      render: (instrumentAge, record) => {
        return <>{admin ? instrumentAge : record.paired ? instrumentAge : "-"}</>
      }
    },
    {
      title: "Support Engineer",
      dataIndex: "supportEngineer",
      key: "supportEngineer",
      render: (supportEngineer, record) => {
        return <>{admin ? supportEngineer : record.paired ? supportEngineer : "-"}</>
      }
    },
    {
      title: "Support Engineer Phone",
      dataIndex: "engineerPhone",
      key: "engineerPhone",
      render: (engineerPhone, record) => {
        return <>{admin ? engineerPhone : record.paired ? engineerPhone : "-"}</>
      }
    },
    {
      title: "Support Manager",
      dataIndex: "supportManager",
      key: "supportManager",
      render: (supportManager, record) => {
        return <>{admin ? supportManager : record.paired ? supportManager : "-"}</>
      }
    },
    {
      title: "Support Manager Phone",
      dataIndex: "managerPhone",
      key: "managerPhone",
      render: (managerPhone, record) => {
        return <>{admin ? managerPhone : record.paired ? managerPhone : "-"}</>
      }
    },
    {
      title: "Online Status",
      dataIndex: "online",
      key: "online",
      render: (text, record) => (
        <span>
          {text} {record.online ? <Tag color="green">Online</Tag> : <Tag color="red">Offline</Tag>}
        </span>
      ),
    },
    {
      title: "Device Credentials",
      dataIndex: "device_token",
      key: "device_token",
      render: (text, record) => {
        return (
          <>
            {privilege.includes("DEVICE_WRITE") && (
              <>
                <Button type="primary" onClick={() => getDeviceToken(record.id)}>
                  Download
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      refresh: { refreshDevices },
      render: (text, record) => {
        return (
          <>
            {privilege.includes("DEVICE_WRITE") && (
              <>
                <Space direction="vertical">
                  <EditOutlined onClick={() => showDevice(record.id, record.deviceName)} />
                  {admin && <DeleteOutlined style={{ color: "#ff0000" }} onClick={() => showInactiveConfirm(record.id, refresh)} />}
                  <DownloadOutlined onClick={() => handleSelectedDevice(record)}/>
                </Space>
              </>
            )}
          </>
        );
      },
    },
  ];

  const columnsForInactiveDevices = [
    {
      title: "Company Name",
      dataIndex: "orgLegalName",
      key: "orgLegalName",
      width: "30%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            {admin && <Input
              autoFocus
              placeholder="Search Company Name"
              value={searchTextInactive}
              onChange={(e) => {
                resetPageAndSize();
                setSearchTextInactive(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>}
          </>
        );
      },
      filterIcon: () => {
        if(admin){
          if(searchTextInactive){
            return <SearchOutlined className="inactive" style={{ fontSize: '20px', color: '#f5222d' }}/>
          }else{         
            return <SearchOutlined className="inactive"/>
          }
        }
      },
      onFilter: (value, record) => {
        return <> record.orgLegalName.toLowerCase().includes(value.toLowerCase());</>;
      },
    },
    {
      title: "Device Model",
      dataIndex: "deviceName",
      key: "deviceName",
      width: "20%",
    },
    {
      title: "Device Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Serial Number"
              value={devieSerialSearchInactive}
              onChange={(e) => {
                resetPageAndSize();
                setDevieSerialSearchInactive(e.target.value); 
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        if(devieSerialSearchInactive){
          return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
        }else{
          return <SearchOutlined className="active"/>}
      },
      onFilter: (value, record) => {
        if (value) {
          return record?.serialNumber?.toLowerCase()?.includes(value.toLowerCase()) || "";
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {privilege.includes("DEVICE_WRITE") && (
              <>
                <Button style={{ margin: "20px", marginBottom: "0" }} type="primary" onClick={() => showActiveConfirm(record.id, refresh)}>
                  Make Active
                </Button>{" "}
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleSelectedDevice = (record) => {
    setLoading(true);
    setSelectedDevice(record);
    setShowFiles(true);
    let devFiles = [
      {fileName: "Factory database", uploadedDate: '-', fileSize: '-', fileType: FACTORY_DATABASE},
      {fileName: "Service Backup", uploadedDate: '-', fileSize: '-', fileType: SERVICE_BACKUP},
      {fileName: "Single SUS", uploadedDate: '-', fileSize: '-', fileType: SINGLE_SUS},
      {fileName: "MPA Factory 1", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_1},
      {fileName: "MPA Factory 2", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_2},
      {fileName: "MPA Factory 3", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_3},
      {fileName: "MPA Factory 4", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_4},
      {fileName: "MPA Factory 5", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_5},
      {fileName: "MPA Factory 6", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_6},
      {fileName: "MPA Factory 7", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_7},
      {fileName: "MPA Factory 8", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_8},
      {fileName: "MPA Factory 9", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_9},
      {fileName: "MPA Factory 10", uploadedDate: '-', fileSize: '-', fileType: MPA_FACTORY_10},
      {fileName: "Misc", uploadedDate: '-', fileSize: '-', fileType: MISC},

    ];
    SetupFileService.getDeviceFileDetails(record.id).then(res => {
      res.data.map(fileInfo => {
        if(fileInfo.resourceType === FACTORY_DATABASE){
          devFiles[0].uploadedDate = fileInfo.uploadDate;
          devFiles[0].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === SERVICE_BACKUP){
          devFiles[1].uploadedDate = fileInfo.uploadDate;
          devFiles[1].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === SINGLE_SUS){
          devFiles[2].uploadedDate = fileInfo.uploadDate;
          devFiles[2].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_1){
          devFiles[3].uploadedDate = fileInfo.uploadDate;
          devFiles[3].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_2){
          devFiles[4].uploadedDate = fileInfo.uploadDate;
          devFiles[4].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_3){
          devFiles[5].uploadedDate = fileInfo.uploadDate;
          devFiles[5].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_4){
          devFiles[6].uploadedDate = fileInfo.uploadDate;
          devFiles[6].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_5){
          devFiles[7].uploadedDate = fileInfo.uploadDate;
          devFiles[7].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_6){
          devFiles[8].uploadedDate = fileInfo.uploadDate;
          devFiles[8].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_7){
          devFiles[9].uploadedDate = fileInfo.uploadDate;
          devFiles[9].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_8){
          devFiles[10].uploadedDate = fileInfo.uploadDate;
          devFiles[10].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_9){
          devFiles[11].uploadedDate = fileInfo.uploadDate;
          devFiles[11].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MPA_FACTORY_10){
          devFiles[12].uploadedDate = fileInfo.uploadDate;
          devFiles[12].fileSize = parseFloat(fileInfo.fileSize);
        }
        else if(fileInfo.resourceType === MISC){
          devFiles[13].uploadedDate = fileInfo.uploadDate;
          devFiles[13].fileSize = parseFloat(fileInfo.fileSize);
        }
      })
      setDeviceData(devFiles);
      setLoading(false)
    })
    .catch(err => {
      setLoading(false);
      notification.error({
        message: "Error Loading Files Info"
      })
      setLoading(false)
    })
  }

  const handleFileViewClose = () => {
    setSelectedDevice(null);
    setShowFiles(false);
    setDeviceData([])
  }

  const deviceFilesColumns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      width: '15%'
    }
  ]

  admin && deviceFilesColumns.push({
    title: "Link",
    dataIndex: "link",
    key: "link",
    render: (link, record) => {
      let downloadLink = `${window.origin}/public/device/${selectedDevice.serialNumber}/download?resourceType=${record.fileType}`
      return (
          record.uploadedDate !== "-" ?
          <div style={{display:'flex', justifyContent:'space-between'}}>
              <Typography.Text style={{textDecoration:'underline'}}><a href={downloadLink} target='_blank' rel="noreferrer">{downloadLink}</a></Typography.Text>
              <CopyToClipboard text={downloadLink} style={{color:'#5837D0', marginTop:'10px'}} onCopy={() => notification.success({message:'Link copied to Clipboard', duration:2})}><CopyOutlined /></CopyToClipboard>
          </div>
          :
          "-"
      )
    }
  })

    deviceFilesColumns.push({
      title: "Uploaded Date",
      dataIndex: "uploadedDate",
      key: "uploadedDate",
      render: (uploadedDate) => {
        return uploadedDate === "-" ? uploadedDate : new Date(uploadedDate).toLocaleString()
      },
      width:'20%',
      align:'center'
    },
    {
      title: "Size",
      dataIndex: "fileSize",
      key: 'fileSize',
      render: (fileSize) => {
        let GBSize = fileSize / 1024; 
        return fileSize === "-" ? fileSize : GBSize < 1 ? `${fileSize.toFixed(2)} MB` : `${GBSize.toFixed(2)} GB`;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: 'center',
      render: (_, record) => {
        return (
          <Space size='large'>
              {admin && <Space onClick={() => handleEditSelectedFile(record)} style={{cursor:'pointer', color:'steelblue'}}><EditOutlined /><Typography.Text>Edit</Typography.Text></Space>}
              <Space onClick={() => downloadSelectedFile(record)} style={{cursor:'pointer', color:'steelblue'}}><DownloadOutlined /><Typography.Text>Download</Typography.Text></Space>
          </Space>
        )
      }
    })

  const handleEditSelectedFile = (record) => {
    let rec = record;
    rec.deviceId = selectedDevice.id;
    rec.modelName = selectedDevice.modelName;
    rec.fileType = record.fileType
    setEditSelectedFile(rec);
    setVisible(true);
  }

  const handleEditFileClose = () => {
    setEditSelectedFile(null);
    setVisible(false)
  }

  const uploadSelectedFile = (deviceId, file, fileType) => {
    setVisible(false);
    setFileLoading(true);
    SetupFileService.generateDeviceSetupFileUploadLink(file.name, fileType, selectedDevice.serialNumber).then(res => {
      axios.put(res.data, file.originFileObj).then(res => {
        SetupFileService.confirmDeviceSetupFileUpload(file.name, fileType, selectedDevice.serialNumber, file.size/1048576).then(res => {
          notification.success({
            message: 'File Uploaded Successfully.',
            duration: 2
          })
          handleSelectedDevice(selectedDevice);
          setFileLoading(false);
        }).catch(err => {
          notification.info({
            message: 'Unable to update status. Please try again!',
            duration: 2
          })
          setFileLoading(false);
        })
      }).catch(err => {
        setFileLoading(false);
        notification.error({
          message: 'Error Uploading the file!',
          duration: 2
        })
      })
    }).catch(err => {
      setFileLoading(false);
      notification.error({
        message: 'Error Generating the Upload Link!',
        duration: 2
      })
    })
    setEditSelectedFile(null);
  }

  const downloadSelectedFile = (record) => {
    if(record.uploadedDate === "-"){
      notification.error({
        message:'File Not Available',
        duration: 2
      })
      return;
    }
    setFileLoading(true);
    SetupFileService.generateDeviceSetupFileDownloadLink(record.fileType, selectedDevice.serialNumber).then(response => {
      window.open(response.data, 'download');
      setFileLoading(false);
    }).catch(err => {
      setFileLoading(false);
      notification.error({
        message:'Error Downloading Setup File!',
        duration: 2
      })
    })
  }

  return (
    <div className="site-card-wrapper" style={{ margin: "30px", marginLeft: "0px" }}>
      {!showFiles ?
      <>
        <Row>
          <Col span={8}>
            <Space>
              {privilege.includes("DEVICE_WRITE") && (
                <Button size="middle" type="primary" onClick={addDevice}>
                  New Device <PlusCircleOutlined />
                </Button>
              )}

              {privilege.includes("CRM_DATA_IMMEDIATE") && (
                <Button size="middle" type="primary" icon={<SyncOutlined />} onClick={syncDevicesFromSalesforces}>
                  Sync
                </Button>
              )}

              {activeOrInactiveButton ? (
                <Switch

                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={(event) => getInActiveDevice(searchTextInactive, currentPageInactive, pageSizeInactive)}
                  defaultChecked
                />
              ) : (
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  defaultChecked="false"
                  onChange={(event) => refresh(searchText, currentPage, pageSize)}
                />
              )}
            </Space>
          </Col>



          {/* <Col xs={{ span: 5, offset: 1 }} lg={{ span: 16, offset: 1 }}>
            {activeOrInactiveButton ? (
              <Button style={{ margin: "20px", marginBottom: "0", marginLeft: "0" }} type="primary" onClick={getInActiveDevice}>
                Show Inactive Devices
              </Button>
            ) : (
              <Button style={{ margin: "20px", marginBottom: "0" }} type="primary" onClick={refresh}>
                Show Active Devices
              </Button>
            )}
          </Col>
          */}
        </Row>
        <br />

        <Row gutter={24}>
          <Col>
            <Card className="limitable" bodyStyle={{ padding: "0px", width: "100%" }}>
              {tableShow ? (
                <Table
                  loading={devices === ''}
                  dataSource={devices}
                  size="middle"
                  scroll={{ x: true }}
                  onChange={handleTableChange}
                  columns={columnsForActiveDevices}
                  rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                  pagination={{ showSizeChanger: true, total: totalDevicesActive }}
                />
              ) : (
                <Table
                  loading={inactiveDevice === ''}
                  dataSource={inactiveDevice}
                  size="middle"
                  onChange={handleTableChangeForInActive}
                  columns={columnsForInactiveDevices}
                  rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                  pagination={{ showSizeChanger: true, total: totalDevicesInactive }}
                />
              )}
            </Card>
          </Col>

          {showDeviceDetails ? (
            <Col span={8}>
              <DeviceForm device={device} showDeviceDetails={showDeviceDetails} refresh={refreshDevices} mode={mode} org={org} />
            </Col>
          ) : null}

          {showOrgDetails ? (
            <Col span={8}>
              <DeviceForm device={device} showOrgDetails={showOrgDetails} refresh={refreshDevices} org={org} />
            </Col>
          ) : null}
        </Row>
      </> : 
      <>
        <Row >
          <Col span={24} style={{display:'flex', justifyContent:'end'}}>
          {fileLoading ? 
            <Spin></Spin> :
            <CloseOutlined style={{fontSize:'20px', background:'whitesmoke', padding:'15px', borderRadius:'50%'}} onClick={handleFileViewClose}/>
          }
          </Col>
        </Row>
        <Row justify="center" style={{marginTop:'20px'}}>
            <Col xl={20} lg={20} md={20} sm={24} xs={24}>
              <Table 
                loading={loading}
                size='midddle'
                dataSource={deviceData}
                columns={deviceFilesColumns}
                rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                scroll={{x:true}}
              />
            </Col>
        </Row>
      </>
      }
      <Drawer
          title={"Edit"}
          visible={visible}
          onCancel={handleEditFileClose}
          onClose={handleEditFileClose}
          closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
          <DeviceFileEditForm initial={editSelectedFile} handleEdit={uploadSelectedFile} handleClose={handleEditFileClose} />
      </Drawer>
    </div>
  );
}

const DeviceFileEditForm = ({initial, handleEdit, handleClose}) => {

  const [form] = Form.useForm();
  
  useEffect(() => form.resetFields());
  
  const handleFinish = (val) => {
      handleEdit(initial.deviceId, val.file[0], initial.fileType);
  }

  const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
  };

  return (
      <>
          <Form form={form} initialValues={initial} onFinish={handleFinish} className="analyst-form" layout="vertical">
              <Form.Item
                  label={
                      <Typography.Title level={5} style={{ color: "white", marginBottom: "0" }}>
                          File Name
                      </Typography.Title>
                  }
                  name="fileName"
                  rules={[{ required: true}]}
              >
                  <Input name="fileName" allowClear placeholder="File Name" disabled />
              </Form.Item>
              <Form.Item
                  label={
                      <Typography.Title level={5} style={{ color: "white", marginBottom: "0" }}>
                          Model Name
                      </Typography.Title>
                  }
                  name="modelName"
                  rules={[{ required: true}]}
              >
                  <Input name="modelName" allowClear placeholder="Model Name" disabled/>
              </Form.Item>
              <Form.Item
                  name="file"
                  label={
                      <Typography.Title level={5} style={{ color: "white", marginBottom: "0" }}>
                          Upload here
                      </Typography.Title>
                  }
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
              >
                  <Upload 
                    name="file" 
                    maxCount={1} 
                    listType='picture' 
                    beforeUpload={() => false}
                    itemRender={(originNode, file, currFileList) => <div style={{color:'whitesmoke'}}>{originNode}</div>}
                  >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
              </Form.Item>
              <Form.Item>
                  <Space>
                  <Button type="primary" htmlType="submit">
                      Save
                  </Button>
                  <Button className="ml-2 device-form-button" onClick={handleClose} style={{ marginLeft: "10px" }}>
                      Close
                  </Button>
                  </Space>
              </Form.Item>
          </Form>
      </>
  )
}