import { Button, Table, Row, Col, Card, message, Input } from "antd";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CrmService from "../../services/CrmService";
import NewSalesTicket from "./NewSalesTicket";
import { useAuth } from "../../helpers/auth";
import UserService from "../../services/UserService";

function SalesView() {
  const { user: activeUser } = useAuth();
  const privilege = activeUser.privileges;
  const [tickets, setTickets] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalTickets, setTotalTickets] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const columns = [
    {
      title: "Company Name",
      dataIndex: "orgName",
      key: "orgName",
      hidden: userInfo.client ? true : false,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Company Name"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSearchText(e.target.value);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return <> record.orgName.toLowerCase().includes(value.toLowerCase());</>;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Created Date",
      dataIndex: "createDate",
      key: "createdDate",
      render: (text, record) => {
        let createDate = record.createDate;
        if (createDate) {
          createDate = createDate.slice(0, 10);
        }
        return <>{createDate}</>;
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    if (searchText !== "") {
      let currentPage = 0;
      loadData(searchText, currentPage, pageSize);
    } else {
      loadData(searchText, currentPage, pageSize);
    }
    // loadData();
    getUserInfo();
  }, [searchText]);

  function getUserInfo() {
    UserService.getUserProfile()
      .then((req) => {
        setUserInfo(req.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  const createSalesTicket = () => {
    setShowNew(!showNew);
  };

  //   CrmService.loadSalesTickets()
  //   .then((response) => {
  //     console.log("sales response", response);
  //     setTickets(response.data);
  //   })

  function loadData(searchText, currentPage, pageSize) {
    setLoading(true);
    let sText = searchText;
    let cPage = currentPage;
    let pSize = pageSize;
    if (sText == undefined && cPage == undefined && pSize == undefined) {
      sText = "";
      cPage = 0;
      pSize = 10;
    }
    CrmService.loadSalesTickets(sText, cPage, pSize)
      .then((response) => {
        setTickets(response.data.list);
        setPageSize(response.data.pageSize);
        setTotalTickets(response.data.total);
        setCurrentPage(response.data.pageNumber);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  }

  const handleTableChange = (e) => {
    let currentPage = e.current - 1;
    let pageSize = e.pageSize;
    loadData(searchText, currentPage, pageSize);
  };

  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={2}>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 16, offset: 1 }}>
            {privilege.includes("CRM_TICKET_RAISE") && userInfo.roleResponses && userInfo.roleResponses[0].external && (
              <Button  type="primary" onClick={createSalesTicket}>
                Raise Sales Request <PlusCircleOutlined />
              </Button>
            )}
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={{ span: 24}} lg={{ span: 22, offset: 1 }}>
              <Table
                loading={loading}
                scroll={{ x: true }}
                onChange={handleTableChange}
                pagination={{ showSizeChanger: true, total: totalTickets }}
                rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
                columns={columns}
                dataSource={tickets}
              />
          </Col>
        </Row>
        {showNew && <NewSalesTicket createSalesTicket={createSalesTicket} loadData={loadData} />}
      </div>
    </>
  );
}

export default SalesView;
