import axios from "./axios";
import { authHeader } from "../helpers/auth-header";
import { config } from "../helpers/config";
import { history } from "../helpers/history";
import { EventBus } from "../components/event";

class UserService {
  static Instance() {
    return new UserService();
  }
  getUsers(pageNumber, size) {
    return axios.get("/api/user/?page=" + pageNumber + "&size=" + size);
  }

  getUser(userId) {
    return axios.get("/api/user/" + userId);
  }

  getMenu() {
    return axios.get("/api/user/menu");
  }

  updatePassword(user) {
    return axios.put("/api/user/update/password", user);
  }

  saveUser(user) {
    if (user.id) {
      return axios.put("/api/user/", user);
    }
    return axios.post("/api/user/", user);
  }

  deleteUser(userId) {
    return axios.delete("/api/user/" + userId);
  }

  getRoles() {
    return axios.get("/api/user/get/roles");
  }

  tokenValid() {
    return axios.get("/api/user/tokenCheck");
  }

  login(username, password) {
    return axios.post("/login", { username, password });
  }

  getMenuItems() {
    return axios.get("/api/user/menu/list");
  }

  getUserTypes() {
    return axios.get("/api/user/roles");
  }

  resetPassword(email) {
    return axios.put("/forgot-password", email);
  }
  getInActiveUsers(pageNumber, size) {
    return axios.get("/api/user/inactive?page=" + pageNumber + "&size=" + size);
  }
  getUserProfile() {
    return axios.get("/api/user/profile");
  }
  makeActiveUser(id) {
    return axios.put("/api/user/makeActiveUser?id=" + id);
  }

  uploadProfilePicture(id, formData) {
    return axios.put(`/api/user/${id}/profilepicture`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getImage(id) {
    return axios.get(`/api/files/downloadProfilePicture/${id}`, { responseType: "blob" });
  }

  syncDataFromSaleforces() {
    return axios.get("/api/crm/immediateDataFormSalesforces", {timeout: 60000});
  }
  signup(user) {
    return axios.post("/public/signup", user);
  }
  verifyEmail(email) {
    return axios.get("/public/verifyEmail?email=" + email);
  }

  getAllActiveUsers(searchText, emailSearchText, pageNumber, size) {
    return axios.get("/api/user/all/AllActiveUsers?searchKey=" + searchText + "&email=" + emailSearchText + "&page=" + pageNumber + "&size=" + size);
  }
  getAllInActiveUsers(searchText, emailSearchText, pageNumber, size) {
    return axios.get("/api/user/all/AllInactiveUsers?searchKey=" + searchText + "&email=" + emailSearchText+ "&page=" + pageNumber + "&size=" + size);
  }
  assignUserTODeviceOrg(deviceId) {
    return axios.post("/api/user/assignusertodeviceorg?deviceId=" + deviceId);
  }

  getAllOrgEmails() {
    return axios.get("/api/user/emails");
  }

  verifyPendingUser(id) {
    return axios.post("/api/user/verifypendinguser?id=" + id);
  }

  updateUserToken() {
    return axios.get("/api/user/updatelogintoken");
  }

  getUserOrgAdminDetails() {
    return axios.get("/api/user/orgadmindetails");
  }
}

export default UserService.Instance();

//TODO valiate token,
// https://medium.com/@siddharthac6/json-web-token-jwt-the-right-way-of-implementing-with-node-js-65b8915d550e