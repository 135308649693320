import { useState, useEffect } from "react";
import { Button, Form, Input, message, Typography, Drawer, Space, Radio, Select, DatePicker } from "antd";
import CrmService from "../../services/CrmService";
import DeviceService from "../../services/DeviceService";
import { CloseOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";
import moment from 'moment';
const { Title } = Typography;

function NewSupportTicket(props) {
  const [priority] = useState(["High", "Medium", "Low"]);
  const [issueType] = useState(["Maintenance", "Issue", "Courtesy", "Training", "Other"]);
  const [visible, setVisible] = useState(true);
  const [devices, setDevices] = useState([]);
  const [isNewInstallation, setNewInstallation] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [form] = Form.useForm();

  let deviceId = props.deviceId;
  //  console.log(deviceId)
  function onFinish(values) {
    values.deviceId = values.deviceSerialNumber;
    if(userInfo.phone === null || userInfo.phone === undefined){
      message.warning("Please Update your contact details first.")
      return;
    }
    values = {...values, contactNumber: userInfo.phone}
    values.deviceSerialNumber = devices.find((item) => item.deviceId === values.deviceSerialNumber).serialNumber || "";
    values.type = "Service";
    CrmService.raiseTicket(values)
      .then((req) => {
        props.loadData();
        message.info("Service Ticket Raised");
        setVisible(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  }

  useEffect(() => {
    DeviceService.getActiveDevices()
      .then((req) => {
        setDevices(req.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
    getUserInfo();
  }, []);

  function getUserInfo() {
    UserService.getUserProfile()
      .then((req) => {
        setUserInfo(req.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  useEffect(() => {
    form.resetFields();
  }, [userInfo]);

  const close = () => {
    setVisible(false);
    props.createSupportTicket();
  };

  const hideIssueType = (e) => {
    if (e.target.value === "New Installation") {
      setNewInstallation(!isNewInstallation);
    } else {
      setNewInstallation(true);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  return (
    <div>
      <Drawer
        title={"Create Service Request"}
        visible={visible}
        onClose={close}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form
          form={form}
          initialValues={{
            suppliedPhone: userInfo.phone,
          }}
          name="ticket"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          {/* <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select a record type
              </Title>
            }
            name="type"
            rules={[{ required: true, message: "Select case type!" }]}
          >
            <Radio.Group onChange={hideIssueType}>
              <Radio style={{ color: "white" }} value="Service">
                Service
              </Radio>

              {typeof deviceId === "undefined" ? (
                <Radio style={{ color: "white" }} value="New Installation">
                  New Installation
                </Radio>
              ) : (
                <></>
              )}
            </Radio.Group>
          </Form.Item> */}
          <Form.Item
            style={{ borderRadius: "20px" }}
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Device
              </Title>
            }
            name="deviceSerialNumber"
            color="blue"
            rules={[{ required: true, message: "Please Select Device!" }]}
          >
            {typeof deviceId === "undefined" ? (
              <Select allowClear="true" placeholder="Select Device">
                {devices.map((device) => (
                  <Select.Option key={device.id} value={device.deviceId}>
                    <Space>
                      {device.assetName},{device.serialNumber}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select allowClear="true" placeholder="Select Device">
                {devices
                  .filter((el) => el.id === deviceId)
                  .map((device) => (
                    <Select.Option key={device.id} value={device.deviceId}>
                      <Space>
                        {device.assetName},{device.serialNumber}
                      </Space>
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          {/* {isNewInstallation && (
            <Form.Item
              style={{ borderRadius: "20px" }}
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Select Priority
                </Title>
              }
              name="priority"
              color="blue"
              rules={[{ required: true, message: "Please Select Priority!" }]}
            >
              <Select allowClear="true" placeholder="Select Priority">
                {priority.map((priority) => (
                  <Select.Option key={priority} value={priority}>
                    {priority}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )} */}
          {isNewInstallation && (
            <Form.Item
              style={{ borderRadius: "20px" }}
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Select Issue Type
                </Title>
              }
              name="issueType"
              color="blue"
              rules={[{ required: true, message: "Please Select Issue Type!" }]}
            >
              <Select allowClear="true" placeholder="Select Issue Type">
                {issueType.map((issueType) => (
                  <Select.Option key={issueType} value={issueType}>
                    {issueType}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Title
              </Title>
            }
            name="title"
            rules={[{ required: true, min: 4, message: "Enter title!" }]}
          >
            <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())} name="title" allowClear placeholder="Title" />
          </Form.Item>
          {/* <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Contact Number
              </Title>
            }
            name="suppliedPhone"
            rules={[{ required: true, message: "Enter Contact Number!" }]}
          >
            <Input allowClear placeholder="Contact Number" />
          </Form.Item> */}
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Visit required on
              </Title>
            }
            name="visitOn"
            rules={[{ required: true, message: "Select Date" }]}
          >
            <DatePicker disabledDate={disabledDate}/>
          </Form.Item>

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Description
              </Title>
            }
            name="description"
            rules={[{ required: true, min: 5, message: "Enter description!" }]}
          >
            <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())} name="description" allowClear placeholder="Description" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default NewSupportTicket;
