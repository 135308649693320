import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DeviceDataService from "../../services/DeviceDataService";
import { Affix, Button, Card, Col, Row, Table, Tooltip, Typography } from "antd";
//import DeviceService from "../../services/DeviceService";
import { FileExcelTwoTone, FilePdfTwoTone, FundTwoTone, PieChartTwoTone, ProjectTwoTone, RightSquareTwoTone, SyncOutlined, UpSquareTwoTone } from "@ant-design/icons";
import * as FileSaver from 'file-saver'
import BurnPieChart from "./DeviceBurnHistoryPieChartView";
import BurnChart from "./DeviceBurnHistoryChartView";
import DeviceService from "../../services/DeviceService";
import moment from "moment";
import momentTZ from 'moment-timezone';




const { Title } = Typography;
export default function DeviceRealTimeView() {
    let { id } = useParams();
    //const [device, setDevice] = useState()
    // const [data, setData] = useState([])
    //  const [burnset, setBurnset] = useState()
    const [samples, setSamples] = useState([])
    const [selectedSample, setSelectedSample] = useState([])
    const [selectedSampleh, setSelectedSampleh] = useState([])
    //  const [selectedSampleG, setSelectedSampleG] = useState([])
    //  const [selectedSamplehG, setSelectedSamplehG] = useState([])
    // const [sampleMap, setSampleMap] = useState({})
    const [columns, setColumns] = useState([])
    const [columnsh, setColumnsh] = useState([])
    // const [columnsG, setColumnsG] = useState([])
    // const [columnshG, setColumnshG] = useState([])
    const [horizontal, setHorizontal] = useState(true)
    const [vertical, setVertical] = useState(false)
    // const [horizontalGrade, setHorizontalGrade] = useState(false)
    // const [verticalGrade, setVerticalGrade] = useState(false)
    const [fileExcel, setFileExcel] = useState("EXCEL_VERTICAL")
    const [filePdf, setFilePdf] = useState("PDF_VERTICAL")
    const [burnData, setBurnData] = useState()
    // const [toggleBtn, setToggleBtn] = useState(true)
    // const [setTableHide, tableHide] = useState(false)
    // const [suitableGrade, setSuitableGrade] = useState();
    // const [gradeName, setGradeName] = useState()
    //  const [keyDatas, setKeyDatas] = useState()
    //  const [gradeSampleName, setGradeSampleName] = useState()
    const [show, setShow] = useState(false)
    const [pieShow, setPieShow] = useState(false)
    const [DecimalDigit, setDecimalDigit] = useState()
    const [sampleBurnsDetail, setSampleBurnsDetail] = useState()
    const [chartBase, setChartBase] = useState()
    const [chartMatrix, setChartMatrix] = useState()
    const [chartSampleName, setChartSampleName] = useState()
    //const [burnsetid, setBurnsetid] = useState()
    const [liveData, setLiveData] = useState()
    const [base, setBase] = useState()
    const [matrix, setMatrix] = useState()
    const [device, setDevice] = useState()

    //const [sampleType, setSampleType] = useState()
    const dateTimeFormat = 'YYYY-MM-DD hh:mm A';


    let zone_name =  momentTZ.tz.guess();
    let timezone = momentTZ.tz(zone_name).zoneAbbr() 
  
    useEffect(() => {
        RefreshData();
    }, [id])


    function loadDevice() {
        DeviceService.getDevice(id).then(response => {
//            console.log({response});
            DeviceService.getMappedDeviceName(response.data.deviceName).then(res => {
                setDevice({...response.data, deviceName: res.data.customName});
            })
        });
    }

    /*  function loadBurnsets() {
          DeviceDataService.getLiveBurnsets(id).then(res => {
              setBurnset(res.data)
          })
      }
  */
    function liveDiagnosticData() {
        DeviceDataService.getLiveDiagnosticData(id).then(res => {
            setLiveData(res.data)
        })
    }

    function loadBurnSample() {
        DeviceDataService.loadData(id).then(response => {
            //  let sortdata =response.data.reverse()
            let sortdata = response.data.sort((a, b) => {
                return a.burnNo - b.burnNo //this will sort according to .burnNo ascending
            });
            {/* 
           let sortdata = response.data.reverse()
            sortdata.sort((a, b) => new Date(b.burnTime).getTime() - new Date(a.burnTime).getTime())

            sortdata.filter(el => el.sampleName === sortdata[0].sampleName)
            let filtData = []
            for (let i = 0; i < sortdata[0].burnNo; i++) {
                filtData.push(sortdata[i])
            }     
        */}
            let filteredData = sortdata
            //   let filteredData =sortdata.filter(el=>el.sampleName===sortdata[0].sampleName).sort((a, b) => parseInt(a.sampleId) - parseInt(b.sampleId))
            //  filteredData.push(sortdata[0])
            //sampleName
            // let filteredData =sortdata.filter(el=>el.sampleName==="T-11313")

            let namemap = {}
            for (let i = 0; i < filteredData.length; i++) {
                let smple = filteredData[i];
                if (namemap[smple.sampleName]) {
                    namemap[smple.sampleName].results.push(smple)
                } else {
                    namemap[smple.sampleName] = {
                        name: smple.sampleName,
                        results: [smple],
                        matrixId: smple.matrixId,
                        baseId: smple.baseId
                    }
                }
            }
          

            // setSampleMap(namemap)
            // setData(filteredData)
            //  setSampleType(Object.values(Object.values(namemap)[0])[1][0].sampleTypeName)
            DeviceDataService.loadMatrixTypes(id).then(res => {
                const fil = res.data.filter(el => el.matrixId === Object.values(Object.values(namemap)[0])[2])
                setMatrix(Object.values(fil[0])[1])
            })
            DeviceDataService.loadBases(id).then(resp => {
                const fil = resp.data.filter(el => el.baseId === Object.values(Object.values(namemap)[0])[3])
                setBase(Object.values(fil[0])[0])
                selectSampleFunc(Object.values(namemap)[0].name, [Object.values(namemap)[0].results[0].baseId, Object.values(namemap)[0].results[0].matrixId, Object.values(namemap)[0].results[0].grade, Object.values(namemap)[0].results[0].burnSetUid], Object.values(namemap), namemap, Object.values(fil[0])[0])
            })
            setSamples(Object.values(namemap))
        })
    }




    /* function loadDevice() {
         DeviceService.getDevice(id).then(response => {
             setDevice(response.data);
         });
     } */

    function certfun(text, digitsAfterDecimal) {
        if (text === 0) {
            return text = "-"
        }
        else {
            return text.toFixed(digitsAfterDecimal)
        }
    }

    function accfun(text, digitsAfterDecimal) {
        if (typeof (text) === "number") {
            return text.toFixed(digitsAfterDecimal)
        }
        else {
            return text
        }
    }


    function sdfun(text, digitsAfterDecimal) {
       let num=0;
        if (text === 0 || text.toFixed(digitsAfterDecimal) === num.toFixed(digitsAfterDecimal)) {
            return text = "-"
        }
        else {
            return <div style={{ color: "green" }}>{text.toFixed(digitsAfterDecimal)}</div>
        }
    }

    function rsdfun(text, digitsAfterDecimal) {
        let num=0;
        if (text === 0 || text.toFixed(digitsAfterDecimal) === num.toFixed(digitsAfterDecimal)) {
            return text = "-"
        }
        else {
            return <div style={{ color: '#32CD32' }}>{text.toFixed(digitsAfterDecimal)}</div>
        }
    }

    function selectSample(value, certResponse, grade, sampless, namemaps, currentBase) {
        const result = sampless[0];
        // setMatId(result.matrixid)
        setBurnData(result)
        setColumns([])
        setSelectedSample([])
        //  setSelectedSampleG([])
        setColumnsh([])
        setSelectedSampleh([])
        //  setSelectedSamplehG([])
        DeviceDataService.loadMatrixSpectrums(id, result.matrixId).then(res => {
            var digitsAfterDecimal = res.data[0].digitsAfterDecimal
            setDecimalDigit(digitsAfterDecimal)
            //  let GradeData = grade
            let sampleData = [];
            let sampleDatah = [];
            //  let sampleDataG = [];
            // let sampleDatahG = [];
            let cols = [];
            let colsh = [];
            // let colsG = [];
            //  let colshG = [];
            let sum = 0;
            //   let n = 0;
            let sqsum = 0;

            cols.push({
                title: 'Element',
                dataIndex: 'element',
                key: 'element',
                fixed: 'left',
                width: 100,
                render: val => val
            });
            cols.push({
                title: 'ElementId',
                dataIndex: 'elementId',
                key: 'elementId'
            });
            /*  colsG.push({
                  title: 'Elements',
                  dataIndex: 'element',
                  key: 'element',
                  render: val => <div style={{ width: '120px' }}>{val}</div>
              });
  */
            let sample = namemaps[value];
            setSampleBurnsDetail(sample)
            setChartSampleName(sample.name)
            setChartMatrix(sample.matrixId)
            setChartBase(sample.baseId)


            //we need same no of rows as # of elements in burn result, so we push that many rows
            //based on burn -
            //TODO ordering based on matrix
            //TODO coloring, show and decimals based on matrix

            let modResults = [];
            let baseEle = null;

            for (let i = 0; i < sample.results[0].results.length; i++) {
                let  ele = sample.results[0].results[i];
                if(ele.symbol !== base){
                    modResults.push(ele);
                }
                else {
                    baseEle = ele
                }
            }

            if(baseEle !== null) modResults.push(baseEle);

            sample.results[0].results = modResults;
            
            for (let i = 0; i < sample.results[0].results.length; i++) {
                sampleData.push({
                    'elementId': sample.results[0].results[i].elementId
                })
            }


            for (let i = 0; i < sample.results[0].results.length; i++) {
                const result = res.data.filter(({ elementId }) => elementId === sampleData[i].elementId);
                // const a = result[0].concentrationHigh
                // const b = result[0].concentrationLow
                sampleData[i].element = <div>{sample.results[0].results[i].symbol} {"(%)"} </div>/*<div style={{ display: 'flex', justifyContent: 'space-around' }}><span style={{ color: 'blue' }}>{b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</span><span style={{ color: 'red' }}>{a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</span></div>*/
                /*
                 sampleDataG.push({
                     'element': sampleData[i].element,
                 })
                 */
            }

            /* 
             colsG.push({
                  title: 'MIN',
                  dataIndex: 'min',
                  key: 'min',
                  render: val => <div style={{ width: '120px' }}>{val}</div>
              });
              colsG.push({
                  title: 'Max',
                  dataIndex: 'max',
                  key: 'max',
                  render: val => <div style={{ width: '120px' }}>{val}</div>
              });
              
  
              for (let i = 0; i < sampleData.length; i++) {
                  if (GradeData !== "No Grade") {
                      const result = GradeData.GradeDetails.find(({ ElementID }) => ElementID === sampleData[i].elementId);
                      if (result) {
                          sampleDataG[i].min = result.LowConc.toFixed(digitsAfterDecimal + 2).slice(0, -2);
                          sampleDataG[i].max = result.HighConc.toFixed(digitsAfterDecimal + 2).slice(0, -2);
                      }
                      else {
                          sampleDataG[i].min = "-";
                          sampleDataG[i].max = "-";
                      }
                  } else {
                      sampleDataG[i].min = "-";
                      sampleDataG[i].max = "-";
                  }
              }
  */
            //.......................................... horizontal...........................................................//

            colsh.push({
                title: 'Elements',
                dataIndex: 'elements',
                key: 'elements',
                fixed: 'left',
                width: 100,
                render: val => <div>{val}</div>
            })

            /*   colshG.push({
                   title: 'Burn/Elements',
                   dataIndex: 'elements',
                   key: 'elements',
                   render: val => <div style={{ width: '120px' }}>{val}</div>
               })
   */
            let colh = result.results[0].results

            for (let i = 0; i < colh.length; i++) {
                colsh.push(
                    {
                        title: sampleData[i].element,
                        dataIndex: colh[i].symbol,
                        key: colh[i].symbol,
                        width: 100,
                    }
                )
                /*  colshG.push(
                      {
                          title: sampleData[i].element,
                          dataIndex: colh[i].symbol,
                          key: colh[i].symbol,
                      }
                  )
                  */
            }
            //...............................................end...................................................//

            for (let i = 0; i < sample.results.length; i++) {
                let burn = sample.results[i];
                cols.push({
                    title: 'Burnh' + (i + 1),
                    dataIndex: 'burnh' + (i + 1),
                    key: 'burnh' + (i + 1),
                    render: val => val.toFixed(digitsAfterDecimal)
                })

                //now we push all n values together
                for (let j = 0; j < burn.results.length; j++) {
                    const result = burn.results.find(({ elementId }) => elementId === sampleData[j].elementId)
                    /*   if (result.concentration < 0) {
                           sampleData[j]['burnh' + (i + 1)] = 0
                       } else {   */
                    sampleData[j]['burnh' + (i + 1)] = result.concentration
                    /* } */
                }
            }

            let BaseElementId;

            for (let i = 0; i < sample.results.length; i++) {
                let burn = sample.results[i];
                cols.push({
                    title: 'Burn' + (i + 1),
                    dataIndex: 'burn' + (i + 1),
                    key: 'burn' + (i + 1),
                    render: val => <span>{val}</span>
                })

                /*    colsG.push({
                        title: 'Burn' + (i + 1),
                        dataIndex: 'burn' + (i + 1),
                        key: 'burn' + (i + 1),
                        render: val => <div style={{ width: '120px' }}>{val}</div>
                    })
    */
                for (let j = 0; j < burn.results.length; j++) {
                    const result = res.data.find(({ elementId }) => elementId === sampleData[j].elementId);
                    const burnresult = burn.results.find(({ elementId }) => elementId === sampleData[j].elementId)
                    // console.log(result);
                    // console.log(burnresult);
                    if(burnresult.symbol !== currentBase){
                    if (result.checkWithCustom) {
                        const a = result.customHigh
                        const b = result.customLow
                        const c = burnresult.concentration
                        if (result.showLowerClipping && result.showUpperClipping) {
                            if (c >= b && c <= a) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            }
                            if (c < b) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                                // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            }
                            if (c > a) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                                //   sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            }


                        } else if (result.showLowerClipping) {
                            if (c >= b) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            }
                            else {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                                // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            }

                        } else if (result.showUpperClipping) {
                            if (c <= a) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            } else {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                                //   sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            }
                        } else {
                            //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                            //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                            sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                        }
                    } else {
                        const a = result.concentrationHigh
                        const b = result.concentrationLow
                        const c = burnresult.concentration
                        if (result.showLowerClipping && result.showUpperClipping) {
                            if (c >= b && c <= a) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            }
                            if (c < b) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                                // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            }
                            if (c > a) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                                //   sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            }


                        } else if (result.showLowerClipping) {
                            if (c >= b) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            }
                            else {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                                // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            }

                        } else if (result.showUpperClipping) {
                            if (c <= a) {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            } else {
                                //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                                sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                                //   sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            }
                        } else {
                            //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                            //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                            sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                        }
                    }
                    }else{
                        BaseElementId = burnresult.elementId
                        const c = burnresult.concentration
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                    }





                 /*  const a = result.concentrationHigh
                    const b = result.concentrationLow
                    const c = burnresult.concentration

                    
                    if (c >= b && c <= a) {
                        //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                        //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                    }
                    if (c < b) {
                        //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                        // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>

                    }
                    if (c > a) {
                        //  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                        //   sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                    }
                    */
                }
            
            }
            /* } */

            cols.push({
                title: 'Meanh',
                dataIndex: 'meanh',
                key: 'meanh',
                render: val => val.toFixed(digitsAfterDecimal)
            });


            for (let i = 0; i < sampleData.length; i++) {
                let n = 0;
                for (let j = 2; j <= sample.results.length + 1; j++) {
                    if (Object.values(sampleData[i])[j] !== 0) {
                        sum = sum + Object.values(sampleData[i])[j]
                        n = n + 1
                    } else {
                        sum = sum + Object.values(sampleData[i])[j]
                        n = n
                    }
                }
                if (n >= 1) {
                    sampleData[i].meanh = sum / n;
                } else {
                    sampleData[i].meanh = sum;
                }
                sum = 0;
            }

            cols.push({
                title: 'Mean',
                dataIndex: 'mean',
                key: 'mean',
                width: 100,
                fixed: 'left',
                render: val => <span>{val}</span>
            });

            /* 
              colsG.push({
                   title: 'Mean',
                   dataIndex: 'mean',
                   key: 'mean',
                   render: val => <div style={{ width: '120px' }}>{val}</div>
               });
           */
          
            for (let j = 0; j < sampleData.length; j++) {
                const result = res.data.find(({ elementId }) => elementId === sampleData[j].elementId);
                // console.log({result});
                // console.log(sampleData[j]);
                if(result.elementId !== BaseElementId){
                if (result.checkWithCustom) {
                    const a = result.customHigh
                    const b = result.customLow
                    const c = sampleData[j].meanh
                    if (result.showLowerClipping && result.showUpperClipping) {
                        if (c >= b && c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c < b) {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c > a) {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }


                    } else if (result.showLowerClipping) {
                        if (c >= b) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        else {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }

                    } else if (result.showUpperClipping) {
                        if (c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        } else {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                    } else {
                        sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                    }
                } else {
                    const a = result.concentrationHigh
                    const b = result.concentrationLow
                    const c = sampleData[j].meanh
                    if (result.showLowerClipping && result.showUpperClipping) {
                        if (c >= b && c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c < b) {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c > a) {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }


                    } else if (result.showLowerClipping) {
                        if (c >= b) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        else {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }

                    } else if (result.showUpperClipping) {
                        if (c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        } else {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                    } else {
                        sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                    }
                }}else {
                    let c = sampleData[j].meanh
                    sampleData[j].mean = <div style={{ color: "black" }}>{c.toFixed(digitsAfterDecimal)}</div>
                }

                /*    const a = result.concentrationHigh
                      const b = result.concentrationLow
                      const c = sampleData[j].meanh
      
                      if (c >= b && c <= a) {
                          sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                      }
                      if (c < b) {
                          sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                      }
                      if (c > a) {
                          sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                      }
                      */
            }

            //.....................................horizontal......................................
            /*
                        sampleDatahG.push(
                            {
                                'elements': "MIN",
                            },
                            {
                                'elements': "MAX",
                            }
                        )
            
                        const minG = sampleDatahG.find(({ elements }) => elements === "MIN")
                        const maxG = sampleDatahG.find(({ elements }) => elements === "MAX")
            
                        for (let j = 0; j < colh.length; j++) {
                            minG[colh[j].symbol] = sampleDataG[j].min
                            maxG[colh[j].symbol] = sampleDataG[j].max
                        }
            
                  */
            for (let i = 0; i < result.results.length; i++) {
                let burn = result.results[i]
                sampleDatah.push({
                    'elements': 'Burn' + (i + 1)
                })
                /*   sampleDatahG.push({
                       'elements': 'Burn' + (i + 1)
                   })
   */
                const burnhor = sampleDatah.find(({ elements }) => elements === 'Burn' + (i + 1))
                //   const burnhorG = sampleDatahG.find(({ elements }) => elements === 'Burn' + (i + 1))


                for (let j = 0; j < colh.length; j++) {
                    burnhor[result.results[0].results[j].symbol] = Object.values(sampleData[j])[result.results.length + 2 + i]
                    //   burnhorG[result.results[0].results[j].symbol] = Object.values(sampleData[j])[result.results.length + 2 + i]
                }

            }

            sampleDatah.push(
                {
                    'elements': "Mean",
                }
            )
            /*   sampleDatahG.push(
                   {
                       'elements': "Mean",
                   }
               ) */

            const meanhor = sampleDatah.find(({ elements }) => elements === "Mean")
            // const meanhorG = sampleDatahG.find(({ elements }) => elements === "Mean")


            for (let j = 0; j < colh.length; j++) {
                meanhor[result.results[0].results[j].symbol] = sampleData[j].mean
                //   meanhorG[result.results[0].results[j].symbol] = sampleDataG[j].mean
            }


            //.......................................end............................................

            /* if (sample.results.length > 1) { */
            for (let i = 0; i < sampleData.length; i++) {
                if (certResponse !== "No CertVal") {
                    const result = certResponse.data.find(({ elementId }) => elementId === sampleData[i].elementId);
                    if (result) {
                        if (result.conc !== 0) {
                            sampleData[i].certval = result.conc;
                            sampleData[i].acceptance = 3 * result.sd;
                            // sampleDataG[i].certval = result.conc;

                            if (sampleData[i].acceptance === 0) {
                                sampleData[i].remarks = "-"
                            }
                            else {
                                let a = sampleData[i].certval + sampleData[i].acceptance
                                let b = sampleData[i].certval - sampleData[i].acceptance

                                if (sampleData[i].meanh === 0) {
                                    sampleData[i].remarks = "-"
                                } else {
                                    if (sampleData[i].meanh >= b && sampleData[i].meanh <= a) {
                                        sampleData[i].remarks = "OK"
                                    }
                                    else {
                                        sampleData[i].remarks = "NOT OK"
                                        sampleData[i].mean.props.style.backgroundColor = "yellow"
                                    }
                                }
                            }

                        }
                        else {
                            sampleData[i].certval = result.conc;
                            //  sampleDataG[i].certval = result.conc;
                            sampleData[i].acceptance = "-";
                            sampleData[i].remarks = "-";
                        }
                    } else {
                        sampleData[i].certval = 0;
                        // sampleDataG[i].certval = 0;
                        sampleData[i].acceptance = "-";
                        sampleData[i].remarks = "-";
                    }
                } else {
                    sampleData[i].certval = 0;
                    //  sampleDataG[i].certval = 0;
                    sampleData[i].acceptance = "-";
                    sampleData[i].remarks = "-";
                }
            }


            cols.push({
                title: 'Cert. Val.',
                dataIndex: 'certval',
                key: 'certval',
                render: certval => certfun(certval, digitsAfterDecimal)
            });

            cols.push({
                title: 'SD',
                dataIndex: 'sd',
                key: 'sd',
                width: 100,
                fixed: 'left',
                render: sd => sdfun(sd, digitsAfterDecimal)
            });


            cols.push({
                title: 'RSD',
                dataIndex: 'rsd',
                key: 'rsd',
                render: rsd => rsdfun(rsd, digitsAfterDecimal)
            });

            /*   colsG.push({
                   title: 'Cert. Val.',
                   dataIndex: 'certval',
                   key: 'certval',
                   render: certval => certfun(certval, digitsAfterDecimal)
               });
   
               colsG.push({
                   title: 'SD',
                   dataIndex: 'sd',
                   key: 'sd',
                   render: sd => sdfun(sd, digitsAfterDecimal)
               });
   
   
               colsG.push({
                   title: 'RSD',
                   dataIndex: 'rsd',
                   key: 'rsd',
                   render: rsd => rsdfun(rsd, digitsAfterDecimal)
               });
   
   */

            for (let i = 0; i < sampleData.length; i++) {
                let n = 0;
                for (let j = 2; j <= sample.results.length + 1; j++) {
                    let rowarr = Object.values(sampleData[i])[j]
                    if (rowarr !== 0) {
                        sqsum = sqsum + Math.pow(rowarr - sampleData[i].meanh, 2)
                        n = n + 1
                    } else {
                        sqsum = sqsum
                        n = n
                    }
                }

                if (sampleData[i].mean.props.children.includes("<") || sampleData[i].mean.props.children.includes(">") || sampleData[i].meanh === 0) {
                    sampleData[i].sd = 0
                    sampleData[i].rsd = 0
                    //    sampleDataG[i].sd = 0
                    //   sampleDataG[i].rsd = 0
                }
                else {
                    if (n > 1) {
                        sampleData[i].sd = Math.sqrt(sqsum / (n - 1));
                        sampleData[i].rsd = ((Math.sqrt(sqsum / (n - 1))) * 100) / sampleData[i].meanh
                        //  sampleDataG[i].sd = Math.sqrt(sqsum / (n - 1));
                        // sampleDataG[i].rsd = ((Math.sqrt(sqsum / (n - 1))) * 100) / sampleData[i].meanh
                    } else {
                        sampleData[i].sd = Math.sqrt(sqsum);
                        sampleData[i].rsd = ((Math.sqrt(sqsum)) * 100) / sampleData[i].meanh
                        //  sampleDataG[i].sd = Math.sqrt(sqsum);
                        // sampleDataG[i].rsd = ((Math.sqrt(sqsum)) * 100) / sampleData[i].meanh
                    }
                }
                sqsum = 0;
            }
                  

            cols.push({
                title: 'Acceptance(3s)',
                dataIndex: 'acceptance',
                key: 'acceptance',
                render: acc => accfun(acc, digitsAfterDecimal)
            });

            cols.push({
                title: 'Remarks',
                dataIndex: 'remarks',
                key: 'remarks',
            });

            /*   colsG.push({
                   title: 'Remarks',
                   dataIndex: 'remarks',
                   key: 'remarks',
               });
   
   
               for (let i = 0; i < sampleData.length; i++) {
                   if (GradeData !== "No Grade") {
                       const result = GradeData.GradeDetails.find(({ ElementID }) => ElementID === sampleData[i].elementId);
                       if (result) {
                           let a = result.LowConc;
                           let b = result.HighConc;
                           let c = sampleData[i].meanh
                           if (c === 0) {
                               sampleDataG[i].remarks = "-"
                           } else {
                               if (c >= a && c <= b) {
                                   sampleDataG[i].remarks = <div style={{ color: "#32CD32" }}>OK</div>
                               } else {
                                   sampleDataG[i].remarks = <div style={{ color: "red" }}>NOT OK</div>
                               }
                           }
                       }
                       else {
                           sampleDataG[i].remarks = "-";
                       }
   
                   } else {
                       sampleDataG[i].remarks = "-";
                   }
               }
   */
            //...................................................horizontal...................................

            sampleDatah.push(
                {
                    'elements': "Cert. Val.",
                },
                {
                    'elements': "SD",
                },
                {
                    'elements': "RSD",
                },
                {
                    'elements': "Acceptance",
                },
                {
                    'elements': "Remarks",
                }
            )

            /*   sampleDatahG.push(
                   {
                       'elements': "Cert. Val.",
                   },
                   {
                       'elements': "SD",
                   },
                   {
                       'elements': "RSD",
                   },
                   {
                       'elements': "Remarks",
                   }
               )
   */
            const acceptanceh = sampleDatah.find(({ elements }) => elements === "Acceptance")
            const remarksh = sampleDatah.find(({ elements }) => elements === "Remarks")
            const sdh = sampleDatah.find(({ elements }) => elements === "SD")
            const rsdh = sampleDatah.find(({ elements }) => elements === "RSD")
            const certvalh = sampleDatah.find(({ elements }) => elements === "Cert. Val.")

            /*  const remarkshG = sampleDatahG.find(({ elements }) => elements === "Remarks")
              const sdhG = sampleDatahG.find(({ elements }) => elements === "SD")
              const rsdhG = sampleDatahG.find(({ elements }) => elements === "RSD")
              const certvalhG = sampleDatahG.find(({ elements }) => elements === "Cert. Val.")
  */

            for (let j = 0; j < colh.length; j++) {

                if (sampleData[j].certval === 0) {
                    certvalh[result.results[0].results[j].symbol] = "-"
                    //   certvalhG[result.results[0].results[j].symbol] = "-"
                }
                else {
                    certvalh[result.results[0].results[j].symbol] = sampleData[j].certval.toFixed(digitsAfterDecimal)
                    //   certvalhG[result.results[0].results[j].symbol] = sampleData[j].certval.toFixed(digitsAfterDecimal + 2).slice(0, -2)
                }

                if (typeof (sampleData[j].acceptance) === "number") {
                    acceptanceh[result.results[0].results[j].symbol] = sampleData[j].acceptance.toFixed(digitsAfterDecimal)
                }
                else {
                    acceptanceh[result.results[0].results[j].symbol] = sampleData[j].acceptance
                }
                     let numb=0;
                if (sampleData[j].sd === 0 || sampleData[j].sd.toFixed(digitsAfterDecimal) === numb.toFixed(digitsAfterDecimal)) {
                    sdh[result.results[0].results[j].symbol] = "-"
                    //  sdhG[result.results[0].results[j].symbol] = "-"
                }
                else {
                    sdh[result.results[0].results[j].symbol] = <div style={{ color: 'green' }}>{sampleData[j].sd.toFixed(digitsAfterDecimal)}</div>
                    //  sdhG[result.results[0].results[j].symbol] = <div style={{ color: '#32CD32' }}>{sampleData[j].sd.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                }

                if (sampleData[j].rsd === 0 || sampleData[j].sd.toFixed(digitsAfterDecimal) === numb.toFixed(digitsAfterDecimal)) {
                    rsdh[result.results[0].results[j].symbol] = "-"
                    //   rsdhG[result.results[0].results[j].symbol] = "-"
                }
                else {
                    rsdh[result.results[0].results[j].symbol] = <div style={{ color: '#32CD32' }}>{sampleData[j].rsd.toFixed(digitsAfterDecimal)}</div>
                    //   rsdhG[result.results[0].results[j].symbol] = <div style={{ color: '#32CD32' }}>{sampleData[j].rsd.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                }

                remarksh[result.results[0].results[j].symbol] = sampleData[j].remarks
                //   remarkshG[result.results[0].results[j].symbol] = sampleData[j].remarks

            }

            //................................................end.................................................//

            // .....................................................................................................//
            let burnsList = cols.filter(el => !el.dataIndex.includes("burnh") && el.dataIndex.includes("burn"))
            let updatedColumns = [];
            let burnsListh = sampleDatah.filter(el => el.elements.includes("Burn"))
            let updatedSelectedSampleh = []
            updatedColumns.push(cols.filter(el => el.title === "Element")[0])
            updatedColumns.push(cols.filter(el => el.title === "Mean")[0])
            updatedColumns.push(cols.filter(el => el.title === "SD")[0])
            for (let i = 0; i < burnsList.length; i++) {
                updatedColumns.push(burnsList[i])
            }

            updatedSelectedSampleh.push(sampleDatah.filter(el => el.elements === "Mean")[0])
            updatedSelectedSampleh.push(sampleDatah.filter(el => el.elements === "SD")[0])
            for (let i = 0; i < burnsListh.length; i++) {
                updatedSelectedSampleh.push(burnsListh[i])
            }
            //.........................................................................................................//
            setSelectedSampleh(updatedSelectedSampleh)
         
            setColumns(updatedColumns)
            // setColumnsG(colsG)
            setSelectedSample(sampleData)
            // setSelectedSampleG(sampleDataG)
            setColumnsh(colsh)
            // setColumnshG(colshG)
             //  setSelectedSamplehG(sampleDatahG)
            /* } else {
                 setColumns(cols)
                 setColumnsG(colsG)
                 setSelectedSample(sampleData)
                 setSelectedSampleG(sampleDataG)
                 setColumnsh(colsh)
                 setColumnshG(colshG)
                 setSelectedSampleh(sampleDatah)
                 setSelectedSamplehG(sampleDatahG)
             } */
        })
    }


    const verticalfun = () => {
        setVertical(true)
        setHorizontal(false)
        //  setVerticalGrade(false)
        //  setHorizontalGrade(false)
        setFileExcel("EXCEL_VERTICAL")
        setFilePdf("PDF_VERTICAL")
    }

    const horizontalfun = () => {
       if(!horizontal){
        RefreshData()
       }
        setHorizontal(true)
        setVertical(false)
        // setVerticalGrade(false)
        // setHorizontalGrade(false)
        setFileExcel("EXCEL_HORIZONTAL")
        setFilePdf("PDF_HORIZONTAL")
    }

    /*  const verticalGradefun = () => {
          setVertical(false)
          setHorizontal(false)
          setVerticalGrade(true)
          setHorizontalGrade(false)
          setFileExcel("EXCEL_VERTICAL")
          setFilePdf("PDF_VERTICAL")
      }
  
      const horizontalGradefun = () => {
          setVertical(false)
          setHorizontal(false)
          setVerticalGrade(false)
          setHorizontalGrade(true)
          setFileExcel("EXCEL_HORIZONTAL")
          setFilePdf("PDF_HORIZONTAL")
      }
  */
    const downloadExcelFile = () => {
        DeviceDataService.getFileName(fileExcel, burnData.name, burnData.matrixId, burnData.results[0].baseId, burnData.results[0].device, burnData.results[0].burnSetUid).then(response => {
            DeviceDataService.downloadFile(response.data.fileName).then(res => {
                var blob = new Blob([res.data], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, "Burn.xlsx");
            })
        })
    }

    const downloadPdfFile = () => {
        DeviceDataService.getFileName(filePdf, burnData.name, burnData.matrixId, burnData.results[0].baseId, burnData.results[0].device, burnData.results[0].burnSetUid).then(response => {
            DeviceDataService.downloadFile(response.data.fileName).then(res => {
                var blob = new Blob([res.data], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, "Burn.pdf");
            })
        })
    }

    /* const downloadGradeExcelFile = () => {
         DeviceDataService.getGradeFileName(fileExcel, burnData.name, burnData.matrixId, burnData.results[0].baseId, burnData.results[0].device, burnData.results[0].burnSetUid, gradeName).then(response => {
             DeviceDataService.downloadFile(response.data.fileName).then(res => {
                 var blob = new Blob([res.data], { type: 'application/octet-stream' });
                 FileSaver.saveAs(blob, "GradeBurn.xlsx");
             })
         })
     }
 
     const downloadGradePdfFile = () => {
         DeviceDataService.getGradeFileName(filePdf, burnData.name, burnData.matrixId, burnData.results[0].baseId, burnData.results[0].device, burnData.results[0].burnSetUid, gradeName).then(response => {
             DeviceDataService.downloadFile(response.data.fileName).then(res => {
                 var blob = new Blob([res.data], { type: 'application/octet-stream' });
                 FileSaver.saveAs(blob, "GradeBurn.pdf");
             })
         })
     }
 */
    const selectSampleFunc = (value, keyData, sampless, namemaps, currentBase) => {
        //  setShow(false)
        setPieShow(false)
        //  let keyData = key.key.split(",")
        //  setGradeName(keyData[2])
        //  setBurnsetid(keyData[3])
        // if (keyData[2] === "" || keyData[2] === null) {
        //   setToggleBtn(true)
        //  setVertical(true)
        //  setHorizontal(false)
        //    setVerticalGrade(false)
        //   setHorizontalGrade(false)
        //  } else {
        // setToggleBtn(false)
        //  setVertical(false)
        //  setHorizontal(false)
        //   setVerticalGrade(true)
        //   setHorizontalGrade(false)
        // }
        // setGradeSampleName(value)
        //  setKeyDatas(keyData)
        /*  DeviceDataService.getAllSuitableGrade(id, keyData[0], keyData[1]).then(res => {
              setSuitableGrade(res.data)
          })
          */
        DeviceDataService.loadCertValue(id, value).then(resp => {
            //  DeviceDataService.getOneGrade(id, keyData[0], keyData[1], keyData[2]).then(res => {
            //     selectSample(value, resp, res.data, sampless, namemaps)
            //  }).catch((error) => {
            selectSample(value, resp, "No Grade", sampless, namemaps, currentBase)
            // })
        }).catch((error) => {
            // DeviceDataService.getOneGrade(id, keyData[0], keyData[1], keyData[2]).then(res => {
            //    selectSample(value, "No CertVal", res.data, sampless, namemaps)
            //  }).catch((error) => {
            selectSample(value, "No CertVal", "No Grade", sampless, namemaps, currentBase)
            // })
        })
    }



    /* const selectSampleGradeFunc = (value) => {
         setGradeName(value)
         DeviceDataService.loadCertValue(id, gradeSampleName).then(resp => {
             DeviceDataService.getOneGrade(id, keyDatas[0], keyDatas[1], value).then(res => {
                 selectSample(gradeSampleName, resp, res.data)
             }).catch((error) => {
                 selectSample(gradeSampleName, resp, "No Grade")
             })
         }).catch((error) => {
             DeviceDataService.getOneGrade(id, keyDatas[0], keyDatas[1], keyDatas[2]).then(res => {
                 selectSample(gradeSampleName, "No CertVal", res.data)
             }).catch((error) => {
                 selectSample(gradeSampleName, "No CertVal", "No Grade")
             })
         })
     }
 */
    const showchart = () => {
        setPieShow(false)
        setShow(true)
    }
    const showPieChart = () => {
        setShow(false)
        setPieShow(true)
    }
    /* const selectDefaultSample = (value) => {
         selectSampleFunc(value.name, { key: `${value.baseId},${value.matrixId},${value.results[0].grade},${value.results[0].burnSetUid}` })
         setBurnsetid(value.results[0].burnSetUid)
         return value.name
     }*/


    /* if (burnsetid && burnset) {
         const result = burnset.find(el => el.id === burnsetid);
     }
 */
    const RefreshData = () => {
        setColumnsh([])
        setSelectedSampleh([])
        setColumns([])
        setSelectedSample([])
        loadDevice()
        loadBurnSample()
        setSamples([])
        liveDiagnosticData();
    }



    /* let burnsList=columns.filter(el => !el.dataIndex.includes("burnh")&&el.dataIndex.includes("burn"))
   
     let updatedColumns=[];
     updatedColumns.push(columns.filter(el=>el.title==="Element")[0])
     updatedColumns.push(columns.filter(el=>el.title==="Mean")[0])
     updatedColumns.push(columns.filter(el=>el.title==="SD")[0])
 
     for(let i=0; i<burnsList.length; i++){
         updatedColumns.push(burnsList[i])
     }
    
 */
   

    return (
        <>
            {typeof (samples[0]) !== "undefined" ?
                <div>
                    <Title level={5} style={{ marginBottom: '-10px', marginTop: '-10px' }}>
                        {device && <Title level={5} style={{ color: 'black', marginTop: '0px' }}><span className="device">{device.deviceName}</span> | Sr.No. : {device.serialNumber} | {base} | {matrix}   <> {!show && !pieShow && selectedSample && <span style={{ float: 'right' }}>{selectedSample.length !== 0 ? <Button size="small" type="primary" icon={<SyncOutlined />} onClick={RefreshData}>Refresh</Button> : <Button size="small" type="primary" icon={<SyncOutlined spin />} >Refresh</Button>}</span>}</></Title>}
                        <Title level={5} style={{ color: 'white', marginTop: '0px' }} id="breadcrumb">
                            <span onClick={() => {setPieShow(false); setShow(false)}}>{samples[0].name} </span>
                            {show && <span>Bar Graph</span>} {pieShow && <span>Pie Chart</span>}
                        </Title>

                        {!show && !pieShow && <>
                            { /*  {toggleBtn ? <> */}
                            <Link to="" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Excel" color="#1abc9c">
                                    <Button type="link" icon={<FileExcelTwoTone style={{ fontSize: 24 }} onClick={downloadExcelFile} />} />
                                </Tooltip>
                            </Link>
                            <Link to="" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Pdf" color="#1abc9c">
                                    <Button type="link" icon={<FilePdfTwoTone style={{ fontSize: 24 }} onClick={downloadPdfFile} />} />
                                </Tooltip>
                            </Link>

                            <Link to="#" style={{ fontSize: 24, float: 'right' }} >
                                <Tooltip placement="top" title="Horizontal" color="#1abc9c">
                                    <Button type="link" onClick={horizontalfun} icon={<RightSquareTwoTone style={{ fontSize: 24 }} />} />
                                </Tooltip>
                            </Link>
                            <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Vertical" color="#1abc9c">
                                    <Button type="link" onClick={verticalfun} icon={<UpSquareTwoTone style={{ fontSize: 24 }} />} />
                                </Tooltip>
                            </Link>
                            {/*    </> : <> 
                            <Link to="" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Excel Grade">
                                    <Button type="link" icon={<FileExcelTwoTone style={{ fontSize: 24 }} onClick={downloadGradeExcelFile} />} />
                                </Tooltip>
                            </Link>
                            <Link to="" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Pdf Grade">
                                    <Button type="link" icon={<FilePdfTwoTone style={{ fontSize: 24 }} onClick={downloadGradePdfFile} />} />
                                </Tooltip>
                            </Link>
                            <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Horizontal Grade">
                                    <Button type="link" onClick={horizontalGradefun} icon={<RightSquareTwoTone style={{ fontSize: 24 }} />} />
                                </Tooltip>
                            </Link>
                            <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Vertical Grade">
                                    <Button type="link" onClick={verticalGradefun} icon={<UpSquareTwoTone style={{ fontSize: 24 }} />} />
                                </Tooltip>
                            </Link>
                            {suitableGrade && <Link to="#" style={{ marginLeft: '5px', float: 'right' }}>
                                <Select size="small" value={gradeName} dropdownClassName="dropdown" onChange={selectSampleGradeFunc} placeholder='Grade'>
                                    {suitableGrade.map(el => {
                                        return <Select.Option key={el} value={el}>{el}</Select.Option>
                                    })}
                                </Select>
                            </Link>
                            }
                        </>}*/}

                        </>}
                        {!show && !pieShow && <> {/*<Link to="#" style={{ float: 'right' }}>
                    {toggleBtn ?
                        <Switch checkedChildren="CRM" unCheckedChildren="Grade" defaultChecked onChange={() => setToggleBtn(!toggleBtn)} onClick={() => {
                            setVerticalGrade(true);
                            setVertical(false);
                            if (horizontal) {
                                setVerticalGrade(false)
                                setHorizontalGrade(true)
                                setHorizontal(false)
                            }
                        }
                        } /> :
                        <Switch checkedChildren="CRM" unCheckedChildren="Grade" onChange={() => setToggleBtn(!toggleBtn)} onClick={() => {
                            setVertical(true);
                            setVerticalGrade(false);
                            if (horizontalGrade) {
                                setVertical(false)
                                setHorizontal(true)
                                setHorizontalGrade(false)
                            }
                        }
                        } />
                    }
                </Link>  */}
                            <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Bar Graph" color="#1abc9c">
                                    <Button type="link" icon={<ProjectTwoTone rotate="180" style={{ fontSize: 24 }} onClick={showchart} />} />
                                </Tooltip>
                            </Link>
                            <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                                <Tooltip placement="top" title="Pie Chart" color="#1abc9c">
                                    <Button type="link" icon={<PieChartTwoTone style={{ fontSize: 24 }} onClick={showPieChart} />} />
                                </Tooltip>
                            </Link></>}
                    </Title>
                   
                
                    <Affix offsetTop={64}>
                    {!show && !pieShow && vertical && selectedSample && <Table style={{ marginBottom: '20px'}} scroll={{ x: 'max-content', y: 300}} columns={columns} dataSource={selectedSample} rowKey="element" pagination={false} rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} />}
                    {!show && !pieShow && horizontal && selectedSampleh && <Table style={{ marginBottom: '20px'}} scroll={{ x: 'max-content', y: 300 }} columns={columnsh} dataSource={selectedSampleh} rowKey="element" rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} pagination={false} />}
                  </Affix>

                    {/*  {!show && !pieShow && verticalGrade && selectedSampleG && <Table scroll={{ x: 1500 }} columns={columnsG} dataSource={selectedSampleG} rowKey="element" pagination={false} rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} />}  
                {!show && !pieShow && horizontalGrade && selectedSamplehG && <Table scroll={{ x: 1500 }} columns={columnshG} dataSource={selectedSamplehG} rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} pagination={false} />}  */}

                    {/*    <Affix offsetBottom={0}>

                {!show && !pieShow && liveData && <Row style={{ marginTop: '20px' }} justify="space-around" align="middle">
                    <Col span={4}>
                        <Card bordered align="center" style={{ backgroundColor: 'rgb(50, 15, 92)', color: 'white' }}>
                            <Title level={5}>Pairing Status</Title>
                            {liveData.pairingStatus === false ? <Title style={{ color: 'white' }} level={4}>Disconnected</Title> : <Title style={{ color: 'white' }} level={4}>Connected</Title>}
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card bordered align="center" style={{ backgroundColor: '#229954', color: 'white' }}>
                            <Title level={5}>Ambient Temperature</Title>
                            {liveData.ambientTemperature ? <Title style={{ color: 'white' }} level={4}>{liveData.ambientTemperature}</Title> : <Title style={{ color: 'white' }} level={4}>-</Title>}
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card bordered align="center" style={{ backgroundColor: '#4A235A', color: 'white' }}>
                            <Title level={5}>Unit Temperature</Title>
                            {liveData.unitTemperature ? <Title style={{ color: 'white' }} level={4}>{liveData.unitTemperature}</Title> : <Title style={{ color: 'white' }} level={4}>-</Title>}
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card bordered align="center" style={{ backgroundColor: '#1f618d', color: 'white' }}>
                            <Title level={5}>Burn Count</Title>
                            {liveData.burnCount ? <Title style={{ color: 'white' }} level={4}>{liveData.burnCount}</Title> : <Title style={{ color: 'white' }} level={4}>-</Title>}
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card bordered align="center" style={{ backgroundColor: '#B7242A', color: 'white' }}>
                            <Title level={5}>Last Analysis Time</Title>
                            {liveData.lastAnalysisTime ? <Title style={{ color: 'white' }} level={4}>{moment(liveData.lastAnalysisTime).format(dateTimeFormat)}</Title> : <Title style={{ color: 'white' }} level={4}>-</Title>}
                        </Card>
                    </Col>
                </Row>
                }
</Affix>  */}
                     {show &&
                        <BurnChart base={base} name="livedata" selectedBase={chartBase} digitsAfterDecimal={DecimalDigit} selectedSampleName={chartSampleName} selectedMatrix={chartMatrix} deviceId={id} />
                    }
                    {pieShow &&
                        <Card style={{ backgroundColor: '#E8DAEF' }}>
                            <BurnPieChart base={base} sampleBurnsDetail={sampleBurnsDetail} digitsAfterDecimal={DecimalDigit} selectedSample={selectedSample} />
                        </Card>
                    }
                    {selectedSample&&selectedSampleh&& <Affix offsetBottom={0}>
                     {!show && !pieShow && liveData && <Card>
                            <Card.Grid bordered={false} align="center" style={{backgroundColor:'#EAF2F8', width: "30%", padding: '0', paddingTop: '1px' }}>
                                <span style={{fontSize:'12px', color:'grey'}}>Pairing Status : </span>
                                {liveData.pairingStatus === false ? <span style={{color:'red'}}>Disconnected</span> : <span style={{color:'#229954'}}>Connected</span>}
                            </Card.Grid>
                            <Card.Grid align="center" style={{ backgroundColor:'#FEF9E7',width: "25%", padding: '0', paddingTop: '1px' }}>
                                <span style={{fontSize:'12px',color:'grey'}}>Ambient Temperature : </span>
                                {liveData.pairingStatus === true ? <span>{liveData.diagnosticLogResponse.diagnostic1.ambientTemp}</span> : <span>NA</span>}
                            </Card.Grid>
                            <Card.Grid align="center" style={{backgroundColor:'#EAF2F8', width: "25%", padding: '0', paddingTop: '1px' }}>
                                <span style={{fontSize:'12px',color:'grey'}}>Unit Temperature : </span>
                                {liveData.pairingStatus === true ? <span>{Number.parseFloat(liveData.diagnosticLogResponse.diagnostic1.unitTemp).toFixed(2)}</span> : <span>NA</span>}
                            </Card.Grid>
                            <Card.Grid align="center" style={{backgroundColor:'#FEF9E7', width: "20%", padding: '0', paddingTop: '1px' }}>
                                <span style={{fontSize:'12px',color:'grey'}}>Burn Count : </span>
                                {liveData.burnCount ? <span>{liveData.burnCount}</span> : <span>NA</span>}

                            </Card.Grid>
                            <Card.Grid align="center" style={{backgroundColor:'#F9EBEA ', width: "100%", padding: '0', paddingTop: '1px' }}>
                                <span style={{fontSize:'12px',color:'grey'}}>Last Analysis Time : </span>
                                {liveData.lastAnalysisTime ? <span>{moment.utc(liveData.lastAnalysisTime).local().format(dateTimeFormat)} ({timezone})</span> : <span>NA</span>}
                            </Card.Grid>
                        </Card>}
                    </Affix>}
                </div> : <><Button size="small" style={{ float: 'right' }} type="primary" icon={<SyncOutlined />} >Refresh</Button></>}
        </>
    );
}