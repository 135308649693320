import { useState, useEffect } from "react";
import { Button, Form, Input, message, Drawer, Select, Typography, Space } from "antd";
import CrmService from "../../services/CrmService";
import DeviceService from "../../services/DeviceService";
import { CloseOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";
import { countries, states } from "../../helpers/location";
const { Option, OptGroup } = Select;
const { Title } = Typography;

function NewSalesTicket(props) {
  const [visible, setVisible] = useState(true);
  const [devices, setDevices] = useState([]);
  const [modelName, setModelName] = useState([]);
  const productCategory = ["AMC", "Spares", "Consumables", "Accessories", "OES"];
  const [isIndia, setIsIndia] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    DeviceService.getDevices()
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => {
        message.error(error);
      });
    getModelName();
    getUserInfo();
  }, []);

  const getModelName = () => {
    DeviceService.getModelName()
      .then((response) => {
        setModelName(response.data);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  let deviceCategory = [];
  for (let i = 0; i < devices.length; i++) {
    deviceCategory.push(devices[i].category);
  }
  let uniquedeviceCategory = [...new Set(deviceCategory)];
  let deviceDetails = [];
  let categoryDetails = [];
  for (let i = 0; i < uniquedeviceCategory.length; i++) {
    categoryDetails = [];
    for (let j = 0; j < devices.length; j++) {
      if (devices[j].category === uniquedeviceCategory[i]) {
        categoryDetails.push(devices[j]);
      }
    }
    deviceDetails.push({ category: uniquedeviceCategory[i], details: categoryDetails });
  }

  function onFinish(values) {
    if(userInfo.phone === null || userInfo.phone === undefined){
      message.warning("Please Update your contact details first.")
      return;
    }
    values = {...values, contactNumber: userInfo.phone}
    CrmService.raiseSalesTicket(values)
      .then((response) => {
        props.loadData();
        message.info("Sales Ticket Raised");
        setVisible(false);
      })
      .catch((error) => {
        if (error.response.data.status == 400) {
          message.warn("Currently service is not available in your country. We have got your details we will contact you soon.");
        } else {
          message.error(error.response.data.message);
        }
      });
  }

  function getUserInfo() {
    UserService.getUserProfile()
      .then((req) => {
        setUserInfo(req.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }
  useEffect(() => {
    form.resetFields();
  }, [userInfo]);

  const close = () => {
    setVisible(false);
    props.createSalesTicket();
  };

  const selectedCountry = (key) => {
    if (key === "India") {
      setIsIndia(true);
    } else {
      setIsIndia(false);
    }
  };

  return (
    <div>
      <Drawer
        title={"Create Sales Request"}
        visible={visible}
        onClose={close}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form
          name="ticket"
          form={form}
          initialValues={{
            contactNumber: userInfo.phone,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Product Category
              </Title>
            }
            name="productCategory"
            rules={[{ required: true, message: "Please Select Product Category!" }]}
          >
            <Select
              showSearch
              placeholder="Select Product Category"
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {productCategory.map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Title
              </Title>
            }
            name="title"
            rules={[{ required: true, message: "Please Enter Title!" }]}
          >
            <Input allowClear placeholder="Title" />
          </Form.Item>
          {/* <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Select Model
              </Title>
            }
            name="deviceName"
            rules={[{ required: true, message: "Please Select Model Name!" }]}
          >
            <Select allowClear="true" placeholder="Select Model">
              {(modelName || []).map((modelName) => (
                <Option key={modelName} value={modelName}>
                  {modelName}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          {/* <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Contact Number
              </Title>
            }
            name="contactNumber"
            rules={[{ required: true, pattern: /^[0-9]{10,10}$/, message: "Enter Contact Number!" }]}
          >
            <Input allowClear placeholder="Contact Number" />
          </Form.Item> */}

          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Description
              </Title>
            }
            name="description"
            rules={[{ required: true, min: 5, message: "Provide Description About Model Name!" }]}
          >
            <Input onInput={e => e.target.value = e.target.value.replace(e.target.value[0], e.target.value[0].toUpperCase())}/>
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Country
              </Title>
            }
            name="country"
            rules={[{ required: true, message: "Please Select Country!" }]}
          >
            <Select
              onSelect={selectedCountry}
              showSearch
              placeholder="Select Country"
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {countries.map((country) => {
                return (
                  <Select.Option key={country.name} value={country.name}>
                    {country.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {isIndia && (
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  State
                </Title>
              }
              name="state"
              rules={[{ required: true, message: "Please Select State!" }]}
            >
              <Select
                showSearch
                placeholder="Select State"
                optionFilterProp="value"
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {states.map((state) => {
                  return (
                    <Select.Option key={state.name} value={state.name}>
                      {state.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default NewSalesTicket;
