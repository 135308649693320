import axios from "./axios";

class CrmService {
  static Instance() {
    return new CrmService();
  }

  loadTickets(searchText, pageNumber, size) {
    return axios.get("/api/crm/service?searchKey=" + searchText + "&page=" + pageNumber + "&size=" + size);
  }

  raiseTicket(ticket) {
    return axios.post("/api/crm/service", ticket);
  }

  loadSalesTickets(searchText, pageNumber, size) {
    return axios.get("/api/crm/sales?searchKey=" + searchText + "&page=" + pageNumber + "&size=" + size);
  }

  raiseSalesTicket(ticket) {
    return axios.post("/api/crm/sales", ticket);
  }

  loadServiceTicketsBySearchCriteria(legalName, deviceSerialNumber, caseNumber, page, size) {
    let filter = {}
    if (legalName) {
        filter.legalName = legalName
    }
    if (deviceSerialNumber) {
        filter.deviceSerialNumber = deviceSerialNumber
    }
    if (caseNumber) {
        filter.caseNumber = caseNumber
    }
    if(page){
      filter.page=page
  }
  if(size){
      filter.size=size
  }
    return axios.post(`/api/crm/loadServiceTicketsBySearchCriteria/`, filter, {timeout: 90000})
}
}

export default CrmService.Instance();
