import React,{useEffect, useState} from 'react'
import {Card, Col, Row,DatePicker, Button} from "antd";
import DeviceDataService from "../../services/DeviceDataService";
import {Line} from '@ant-design/plots';
import moment from "moment";
import Carousel from "react-elastic-carousel";


function Diagnostic(props) {
    const { RangePicker } = DatePicker;
   let id=props.id
  //  let fromDate = "2021-01-01 12:00:00";
  //  let toDate = "2021-12-31 12:00:00";
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    const [chartData1, setChartData1] = useState([])
    const [chartData2, setChartData2] = useState([])
    const [chartData3, setChartData3] = useState([])
    const [chartData4, setChartData4] = useState([])
    const [chartData5, setChartData5] = useState([])
    const [data,setData]=useState()
    const [fromDate, setFromDate] = useState("2021-01-01 12:00:00")
    const [toDate, setToDate] = useState("2021-12-31 12:00:00")
   
    const dateFormat = 'YYYY-MM-DD';
    const chartConfig = {
        height: 200,
        xField: "timestamp",
        yField: "value",
        seriesField: 'category',
        point: {
            size: 2,
            shape: 'circle',
        },
        color: ['red', 'blue', 'green', 'orange', 'yellow', 'purple'],
        legend: {
            itemName: {
                style:{
                 fill:"white"
                }
            }
        },
        xAxis: {
            label: {
                offset: 10,
                style: {
                    fill: 'white',
                    fontSize: 10,
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill : 'white',
                    fontSize: 10,
                },
                formatter: (value) => Number.parseFloat(value).toFixed(2),
            }
        },
       
    }


    useEffect(() => {
        function loadData() {
            let data1 = []
            let data2 = []
            let data3 = []
            let data4 = []
            let data5 = []
            DeviceDataService.loadDiagnosticData(id, fromDate, toDate).then(response => {
                 //logAmbientTemps
                for (let i=0;i<response.data.logAmbientTemps.length;i++) {
                    let avalue = response.data.logAmbientTemps[i];
                    data1.push({
                        timestamp :moment(avalue.timestamp).format(dateTimeFormat),
                        value: avalue.temp,
                        category: "Ambient Temp"
                    })
                }
    
                //logPressures
                for (let i=0;i<response.data.logPressures.length;i++) {
                    let avalue = response.data.logPressures[i];
                    data2.push({
                        timestamp :moment(avalue.timestamp).format(dateTimeFormat),
                        value: avalue.pressure,
                        category: "Pressure"
                    })
                }
    
                //logTemps
                for (let i=0;i<response.data.logTemps.length;i++) {
                    let avalue = response.data.logTemps[i];
                    data3.push({
                        timestamp : moment(avalue.timestamp).format(dateTimeFormat),
                        value: avalue.oneavg,
                        category: "Avg Temp One"
                    })
                    data3.push({
                        timestamp : moment(avalue.timestamp).format(dateTimeFormat),
                        value: avalue.twoavg,
                        category: "Avg Temp Two"
                    })
                 
                }
    
                //logUnitTemps
                for (let i=0;i<response.data.logUnitTemps.length;i++) {
                    let avalue = response.data.logUnitTemps[i];
                    data4.push({
                        timestamp : moment(avalue.timestamp).format(dateTimeFormat),
                        value: avalue.temp,
                        category: "Unit Temp"
                    })
                }
    
                  //logFlows
                  for (let i=0;i<response.data.logFlows.length;i++) {
                    let avalue = response.data.logFlows[i];
                    data5.push({
                        timestamp :moment(avalue.timestamp).format(dateTimeFormat),
                        value: avalue.flow,
                        category: "Flow"
                    })
                }
                data1.sort((a,b) => {return a.timestamp - b.timestamp})
                setChartData1(data1)
                setChartData2(data2)
                setChartData3(data3)
                setChartData4(data4)
                setChartData5(data5)
            })
        }
        loadData()
    }, [id, fromDate, toDate])


    function onDateChange(value) {
        setData(value)
         }

    const fetchData=()=>{
        setFromDate(data[0].format(dateTimeFormat))
        setToDate(data[1].format(dateTimeFormat))
    }

    function disabledDate(current) {
        return current && current > moment().endOf('day');
      }
    
      const breakPoints = [
          { width: 1, itemsToShow: 1 },
          { width: 550, itemsToShow: 2, itemsToScroll: 2 },
          { width: 768, itemsToShow: 3 },
          { width: 1200, itemsToShow: 4 }
        ];
    return (
        <>
    {typeof(chartData1[0]||chartData2[0]||chartData3[0]||chartData4[0]||chartData5[0]) !== "undefined" ? <Row style={{marginBottom:'-30px',display:`${props.display}`}}>
        <Col xs={20} span={20} >
        <RangePicker format={dateFormat}  disabledDate={disabledDate} onChange={onDateChange} className="date-picker" />
        <Button onClick={fetchData} style={{ backgroundColor: "#2E86C1", marginLeft: '5px', color: 'white', fontWeight: '700' }}>Fetch</Button><br /><br /><br />
        </Col>
        </Row>:<></>
    }

    {typeof(chartData1[0]||chartData2[0]||chartData3[0]||chartData4[0]||chartData5[0]) !== "undefined" ?
    <div className="carousel" style={{marginBottom:'10px'}}>
      <div className="carousel-wrapper">
      <Carousel breakPoints={breakPoints} pagination={false}>
          {
            
           typeof(chartData1[0]) !== "undefined" ?<>
                {chartConfig && <Card className="carousel-card" style={{border:'2px solid lightGrey',backgroundColor:'#10316B'}}><Line style={{ height: '30vh', width:'22vw' }} data={chartData1} {...chartConfig}/></Card>}
            </>:<></>
          }  
          {
            typeof(chartData2[0]) !== "undefined"?  <>
                {chartConfig && <Card className="carousel-card" style={{border:'2px solid lightGrey',backgroundColor:'#10316B'}}><Line style={{ height: '30vh', width:'22vw' }} data={chartData2} {...chartConfig}/></Card>}
            </>:<></>
            }
          {
            typeof(chartData3[0]) !== "undefined"?   <>
                {chartConfig && <Card className="carousel-card" style={{border:'2px solid lightGrey',backgroundColor:'#10316B'}}><Line style={{ height: '30vh', width:'22vw' }} data={chartData3} {...chartConfig}/></Card>}
            </>:<></>
            }
           {
            typeof(chartData4[0]) !== "undefined"?   <>
                {chartConfig && <Card className="carousel-card" style={{border:'2px solid lightGrey',backgroundColor:'#10316B'}}><Line style={{ height: '30vh', width:'22vw' }} data={chartData4} {...chartConfig}/></Card>}
            </>:<></>
            }
                {chartConfig && chartData5[0]&&<Card className="carousel-card" style={{border:'2px solid lightGrey',backgroundColor:'#A9DFBF'}}><Line style={{ height: '30vh', width:'22vw' }} data={chartData5} {...chartConfig}/></Card>}
            </Carousel>
            </div>
            </div>:<></>}
        </>
    )
}

export default Diagnostic