import React from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { message } from "antd";
import UserService from "../services/UserService";
import { useState } from "react";

const AuthContext = React.createContext(null);

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

export function serverAuth(username, password) {
  return UserService.login(username, password);
}

export function updateLocalToken(){
  return UserService.updateUserToken();
}

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("mpuser")));
  const [refreshMenu, setRefreshMenu] = useState(0);

  async function handleLogin(username, password) {
    serverAuth(username, password).then(
      (response) => {
        let user = response.data;
        //TODO check token valid

        if (user) {
          console.info("User logged in now redirecting to dashboard");
          user.isLoggedIn = true;
          setUser(user);
          localStorage.setItem("mpuser", JSON.stringify(user));
          const origin = location.state?.from?.pathname || "/dashboard";
          navigate(origin);
        }
      },
      (error) => {
        message.warn("Please enter correct user id and password");
      }
    );
  }

  const handleLogout = () => {
    localStorage.removeItem("mpuser");
    setUser(null);
    navigate("/login");
  };

  const handleUpdateToken = () => {
    updateLocalToken().then(
      (res) => {
      let user = res.data;
      if (user) {
        user.isLoggedIn = true;
        setUser(user);
        localStorage.setItem("mpuser", JSON.stringify(user));
        const origin = location.state?.from?.pathname || "/dashboard";
        navigate(origin);
      }
      },
      (error) => {
        message.warn("Unable to refresh your account. Please login again.");
      }
    )
  }

  const value = {
    user,
    doLogin: handleLogin,
    doLogout: handleLogout,
    updateToken: handleUpdateToken,
    refreshMenu,
    setRefreshMenu,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function ProtectedRoute({ children }) {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    console.warn("User details missing, redirecting to login", user);
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  console.info("User ok, rendering view", children.type);
  return children;
}

export default AuthProvider;
