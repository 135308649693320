import { message } from "antd";
import axios from "axios";

function forceLogout() {
    localStorage.clear();
    window.location = '/';
}

class HttpService {
    static Instance() {
        let ax = new axios.create({
            baseURL: "/",
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        ax.interceptors.request.use(config => {
            let user = JSON.parse(localStorage.getItem('mpuser'));
            if (user && user.token) {
                config.headers.Authorization = "Bearer " + user.token
            }
            return config
        });

        ax.interceptors.response.use(undefined, (err) => {
          // console.log("interceptor active", err);
          if (err.response.config.url.includes("/login")) return Promise.reject(err);
          if (err.response.status === 401) {
            console.log("forcing logout on 401!!");
            return forceLogout();
          }
          if (err.response.status === 412){
            console.log("User already logged in at another device");
            message.error('User already logged in at another device. Please login again')
            return forceLogout();
          }
          if (err.response.status !== 403) {
            return Promise.reject(err);
          }
        });
        return ax;
    }
}

//https://gist.github.com/alfonmga/96474f6adb6ed8dee8bc8bf8627c0ae1
export default HttpService.Instance()