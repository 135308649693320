import React from "react";
import { useState, useEffect } from "react";
import { Button, Input, Form, Drawer, Space, message, Select, Typography, Upload, notification } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import OrganizationService from "../services/OrganizationService";
const { Title } = Typography;

function EditCurrentOrganization(props) {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState([]);
  const [orgLogo, setOrgLogo] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [props.organization]);

  const close = () => {
    props.editOrganizationFrom();
    setVisible(false);
  };

  const onFinish = (values) => {
    let editOrgData = props.organization;
    editOrgData.phoneNo = values.phoneNo;
    editOrgData.gstNumber = values.gstNumber;
    editOrgData.location_name = values.location_name;
    editOrgData.address = values.address;
    OrganizationService.saveOrganization(editOrgData)
      .then((response) => {
        message.success("Client Updated");
        props.refresh();
      })
      .catch((err) => {
        message.warn(err.response.data.message);
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const uploadOrgLogo = (res) => {
    const orgLogo = new FormData();
    const id = props.organization.id;
    orgLogo.append("logoFile", res.logoFile[0].originFileObj);
    OrganizationService.uploadOrgLogo(id, orgLogo)
      .then((res) => {
        notification.success({
          type: "info",
          description: "Organization Logo Uploaded",
          duration: 2,
        });
        closeOrgLogo();
        props.refresh();
      })
      .catch((err) => {
        notification.warn({
          type: "warning",
          description: "Picture file type extension '.jpg , .jpeg , .png'",
          duration: 2,
        });
        console.log(err);
      });
  };

  const onOrgLogo = () => {
    setOrgLogo(true);
  };

  const closeOrgLogo = () => {
    setOrgLogo(false);
  };

  return (
    <>
      <Drawer
        title={"Edit Client"}
        visible={visible}
        onCancel={close}
        onClose={close}
        closeIcon={<CloseOutlined style={{ fontSize: "14px", color: "white" }} />}
      >
        <Form
          form={form}
          className="user-form"
          initialValues={{
            ...props.organization,
          }}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Company Legal Name
              </Title>
            }
            name="legalName"
            rules={[{ required: true, min: 3, message: "Please Enter Company Legal Name!" }]}
          >
            <Input name="legalName" placeholder="Company Legal Name" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Email
              </Title>
            }
            name="email"
          >
            <Input placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Contact Number
              </Title>
            }
            name="phoneNo"
          >
            <Input allowClear placeholder="Contact Number " disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                GST Number
              </Title>
            }
            name="gstNumber"
          >
            <Input allowClear placeholder="GST Number " disabled />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Location
              </Title>
            }
            name="location_name"
            rules={[{ required: true, min: 3, message: " Please Enter Location!" }]}
          >
            <Input placeholder="Location" />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Address
              </Title>
            }
            name="address"
            rules={[{ required: true, min: 3, message: "Please Enter Address!" }]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Sales force Account
              </Title>
            }
            name="crmId"
          >
            <Select
              disabled
              showSearch
              placeholder="Select Salesforce account"
              optionFilterProp="value"
              filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Select.Option key={props.organization.crmId} value={props.organization.crmId}>
                {props.organization.crmName}
              </Select.Option>
            </Select>
          </Form.Item>
          <Button onClick={onOrgLogo}>Upload Profile Picture</Button>
          <br />
          <br />
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {" "}
                Save{" "}
              </Button>
              <Button className="ml-2 device-form-button" onClick={close} style={{ marginLeft: "10px" }}>
                Close
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Drawer
          title="Upload Organization Logo"
          width={320}
          closable={false}
          onCancel={closeOrgLogo}
          onClose={closeOrgLogo}
          visible={orgLogo}
          closeIcon={<CloseOutlined style={{ fontSize: "20px", color: "white" }} />}
        >
          <Form onFinish={uploadOrgLogo}>
            <Form.Item name="logoFile" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload name="logoFile" maxCount={1} listType="picture" beforeUpload="false">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button className="ml-2 device-form-button" onClick={closeOrgLogo} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
      </Drawer>
    </>
  );
}

export default EditCurrentOrganization;
