import React,{useEffect, useState} from 'react'
import { Avatar, Badge, Button, Card, Col, Divider, Drawer, Form, Input, InputNumber, List, message, Modal, Row ,Select,Space,Spin,Table,Typography} from 'antd'
import SubscriptionService  from '../services/SubscriptionService';
import UserService from '../services/UserService';
import CONFIG from './merchant-config';
import {CheckOutlined, CloseOutlined, GlobalOutlined} from '@ant-design/icons'
import TransactionHistory from './user/TransactionHistory';
import SubscriptionHistory from './user/SubscriptionHistory';
const { Meta } = Card;
const {Title, Paragraph}=Typography

const PAYMENT_TYPE = 'Payment'
// const PAYMENT_TYPE = 'NATIVE_SUBSCRIPTION'
const STAGE = 'DEFAULT'

export const ClientSubscription = () => {
  const[type1,setType1]=useState("primary")
  const[type2,setType2]=useState("")
   const[type3,setType3]=useState("")
   const [month,setMonth]=useState("month")
   const [planData,setPlanData]=useState()
   const [amount, setAmount]=useState()
   const [inpValue,setInpValue]=useState(1)
   const { Option } = Select;
  const [viewAll, setViewAll] = useState(false)
  const [user, setUser] = useState(null)
  const [admin, setAdmin] = useState(false)
  const [open, setOpen] = useState(false);
  const [planToEdit, setPlanToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [showSubscriptionsHistory, setShowSubscriptionsHistory] = useState(false);

  
  useEffect(() => {
    SubscriptionService.getPlans().then(res=>{
      setPlanData(res.data)
      setAmount([Math.ceil(res.data[1].amount), Math.ceil(res.data[0].amount)])
    })
    UserService.getUserProfile().then(res => {
      setUser(res.data)
      setAdmin(!res.data.client)
    })
  }, [])

  useEffect(() => {}, [planData])
  
  const callType1=()=>{
   setInpValue(1)
   setType1("primary")
   setType2("")
   setType3("")
   setMonth("month")
   setAmount([Math.ceil(planData[1].amount), Math.ceil(planData[0].amount)])
  }
 
    const callType3=()=>{
   setInpValue(1)
      setType3("primary")
      setType1("")
       setType2("")
       setMonth("year")
       setAmount([
        Math.ceil(12*planData[1].amount - (12*planData[1].amount*planData[1].yearlyDiscount/100)),
        Math.ceil(12*planData[0].amount - 12*planData[0].amount*planData[0].yearlyDiscount/100)
      ])
      }



const onChange=(value)=>{
  if(value > 0){
  setInpValue(value)
  const filtdiscount= planData[0].discounts.filter(el=>el.minimumUser<=value && value<= el.maximumUser)
 let discount=filtdiscount[0].discount
if(month==="month"){
 setAmount([
  Math.ceil(value*planData[1].amount-value*planData[1].amount*discount/100),
  Math.ceil(value*planData[0].amount-value*planData[0].amount*discount/100)
])
}else{
  setAmount([
    Math.ceil(12*value*planData[1].amount - 12*value*planData[1].amount*discount/100 - 12*value*planData[1].amount*planData[1].yearlyDiscount/100),
    Math.ceil(12*value*planData[0].amount - 12*value*planData[0].amount*discount/100 - 12*value*planData[0].amount*planData[0].yearlyDiscount/100)
  ])
}
  }
}

  const getSubscriptionDates =  () => {
    let startDate = new Date().toISOString().split('T')[0];
    let endDate = month === "month" ? new Date(new Date().setMonth(new Date().getMonth() + 1)) : new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    return [startDate, endDate.toISOString().split('T')[0]]
  }

  const initPaytmSubscription = async (plan, amount) => {
    if(user.phone === undefined || user.phone === null){
      throwMessage("ERROR", "Please update your phone number to continue")
    }else{
      setLoading(true)
      let subscriptionDates = getSubscriptionDates();
      initTransaction(amount, subscriptionDates, user, plan);
    }
  }

  const initTransaction = (amount, subscriptionDates, profile, plan) => {
    let reqBody = {
        requestType: PAYMENT_TYPE,
        mid: CONFIG.merchant.mid,
        orderId: "GENERATED_ORDER_ID",
        websiteName: STAGE,
        // subscriptionAmountType: 'FIX',
        // subscriptionFrequencyUnit: month === "month" ? "MONTH" : "YEAR",
        // subscriptionStartDate: subscriptionDates[0],
        // subscriptionExpiryDate: subscriptionDates[1],
        // subscriptionEnableRetry: "1",
        // subscriptionGraceDays: '3',
        userInfo: {
            custId: profile.id,
            email: profile.email,
            mobile: profile.phone
        },
        txnAmount: {
            value: amount,
            currency: plan.currency
        }
    }
    SubscriptionService.getChecksum(reqBody).then(res => {
      reqBody.orderId = res.data.orderId
      SubscriptionService.initiateTransaction(reqBody, res.data.checksum).then(initRes => {
        if(initRes.body.resultInfo.resultStatus === 'S'){
          SubscriptionService.createTransaction(plan.id, initRes.body.txnToken, res.data.orderId, amount, user, inpValue).then(txnRes => {
            loadScript(initRes.body.txnToken, amount, plan, res.data.orderId, txnRes.data);
          }).catch(err => {
            throwMessage("ERROR", err.message);
            setLoading(false);
          })
        }else{
          throwMessage("ERROR", initRes.body.resultInfo.resultMsg)
          setLoading(false);
        }
      }).catch(err => {
        throwMessage("ERROR", err.message)
        setLoading(false);
      })
    }).catch(err => {
      throwMessage("ERROR", err.message)
      setLoading(false);
    })
  }

  const throwMessage = (type, msg) => {
    if(type === 'ERROR') message.error(msg);
    else if(type === 'SUCCESS') message.success(msg)
  }

  const loadScript = async (txnToken, txnAmount, plan, orderId, txnRes) => {
    let paytmScript = document.createElement('script');
    paytmScript.id = "paytmScript"
    paytmScript.type = "application/javascript"
    paytmScript.crossOrigin = "anonymous"
    paytmScript.src = "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/" + CONFIG.merchant.mid + ".js";
    paytmScript.async = true;
    let headEle = document.getElementsByTagName('head')[0];
    headEle.appendChild(paytmScript);
    paytmScript.onload = () => onScriptLoad(txnToken, orderId, txnAmount, plan, txnRes);
  }

  const onScriptLoad = (TOKEN, ORDER_ID, AMOUNT, plan, txnRes) => {
    let config = CONFIG
    config.handler = {
      "notifyMerchant": function(eventName,data){
//        console.log("notifyMerchant handler function called");
//        console.log("eventName => ",eventName);
//        console.log("data => ",data);
        if(eventName === "APP_CLOSED") setLoading(false);
      },
      'transactionStatus': function (paymentStatus){
        SubscriptionService.updateTransaction(txnRes, paymentStatus, getSubscriptionDates()[1], month).then(res => {
        })
        setLoading(false)
        paymentStatus && window.Paytm.CheckoutJS.close();
      }
    }
    config.data.orderId = ORDER_ID;
    config.data.token = TOKEN;
    config.data.amount = AMOUNT;

    if(window.Paytm && window.Paytm.CheckoutJS){
        window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                window.Paytm.CheckoutJS.invoke();
            })
            .catch(function onError(error){
              throwMessage('ERROR', error)
              setLoading(false);
            });
        });
    }
  }

  const editPlan = (plan) => {
    setPlanToEdit(plan);
    setOpen(true);
  }

  const handleEdit = (planRequest, id) => {
    SubscriptionService.editPlan(id, planRequest).then(res =>{
      setOpen(false);
      setPlanToEdit(null)
      SubscriptionService.getPlans().then(res=>{
        setPlanData(res.data)
        setAmount([res.data[1].amount,res.data[0].amount])
      })
    })
  }

  const handleTransactionShow = () => {
    setShowSubscriptionsHistory(false);
    setShowTransactionHistory(true);
  }

  const handleSubscriptionShow = () => {
    setShowSubscriptionsHistory(true);
    setShowTransactionHistory(false);
  }

  const handlePlansShow = () => {
    setShowSubscriptionsHistory(false);
    setShowTransactionHistory(false);
  }

  return (
    <>
      {loading &&
        <Row align='middle' justify='center' style={{position:'absolute', height:'100vh', width:'100vw', background:'rgba(250, 249, 246, 0.8)', zIndex:'10'}}>
          <Col style={{display:'flex', justifyContent:'center'}}><Spin /></Col>
        </Row>
      }
   {planData&&amount&& <>
      <Row gutter={32} justify="center" style={{marginBottom:'20px', padding: '0 50px'}}>
        <Col span={18} style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
          {!showTransactionHistory && !showSubscriptionsHistory && 
            <Space>
              <Typography.Text>Enter no. of users :</Typography.Text>
              <InputNumber value={inpValue} onChange={onChange} style={{padding:0, marginLeft:'10px'}}/>
              <Button type={type1} onClick={callType1} size='large' style={{borderRadius:'20px'}}>Bill Monthly</Button>
              <Button type={type3} onClick={callType3} size='large' style={{borderRadius:'20px'}}>Bill Yearly</Button>

            </Space>
          }
        </Col>
        <Col span={6} style={{display:'flex', justifyContent:'right'}}>
          <Space>
            {!showTransactionHistory &&<Button type='default' onClick={handleTransactionShow} shape='round'>Transaction History</Button>}
            {!showSubscriptionsHistory && <Button type='default' onClick={handleSubscriptionShow} shape='round'>Subscription History</Button>}
            {(showTransactionHistory || showSubscriptionsHistory) && <Button type='default' onClick={handlePlansShow} shape='round'>View Plans</Button>}
          </Space>
        </Col>
      </Row> 
    {!showTransactionHistory && !showSubscriptionsHistory &&
    <Row gutter={[40, 32]}  justify='center' style={{maxWidth:'100%', marginLeft: 0, marginRight: 0}}>
        <Col xl={8} lg={12} md={12} sm={20} xs={24}>
          <Badge.Ribbon color="#CF0A0A" text="Current Plan" style={{display:user && user.subscription === null ? 'block': 'none', width:'100px', height:'30px'}}>
            <Card style={{textAlign:'center',borderRadius:'10%',backgroundColor:'whitesmoke', border:'1px solid rgba(95, 141, 78, 0.8)', overflow:'hidden'}} bodyStyle={{padding:'0px'}}>
              <Title level={3} style={{background:'rgba(95, 141, 78, 0.4)'}}>{planData[2].name}</Title>
              <Meta 
                title={<Title level={1} style={{fontSize:'70px', display:'inline', color:'rgba(95, 141, 78, 1)'}}>FREE </Title>}
                description={<Paragraph style={{marginTop:'-10px',  color:'black', marginBottom:'30px'}}>...</Paragraph>}
              />
              <List
                size='small'
                loadMore={<div style={{ height: 32, lineHeight: '32px', marginBottom:7}}>
                    <Paragraph style={{cursor:'pointer', textDecoration:'underline', color:'grey'}} onClick={() => setViewAll(!viewAll)}>View {viewAll ? "less" : "more"}</Paragraph>
                  </div>
                }
                dataSource={viewAll ? planData[2].features : planData[2].features.slice(0, 6)}
                renderItem={(item) => (
                  <List.Item style={{display:'flex', justifyContent:'left', marginLeft:'20px'}}>
                    <Space align='center'>
                      <Avatar 
                        style={{background:'transparent'}} 
                        size='small' 
                        icon={item.enabled ? <CheckOutlined style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>}/>
                      <Title level={5} >{item.feature}</Title>
                    </Space>
                  </List.Item>
                )}
              >
              </List>
              <Button 
                size='large'
                style={{width:'60%', marginBottom:'30px', backgroundColor:'rgba(95, 141, 78, 0.8)', color: 'whitesmoke'}}
                shape="round"
                disabled={true}
                // type= "primary"
                onClick={() => admin?editPlan(planData[1]):initPaytmSubscription(planData[1], amount[0])}
              >
                {admin ? "Edit Plan" : "DEFAULT"}
              </Button>
            </Card>
          </Badge.Ribbon>
        </Col>
        <Col xl={8} lg={12} md={12} sm={20} xs={24}>
          <Badge.Ribbon color="#CF0A0A" text="Current Plan" style={{display:user && user.subscription !== null && user.subscription.planName === "PROFESSIONAL" ? 'block': 'none', width:'100px', height:'30px'}}>
            <Card style={{textAlign:'center',borderRadius:'10%',backgroundColor:'whitesmoke', border:'1px solid rgba(167, 199, 231, 0.8)', overflow:'hidden'}} bodyStyle={{padding:'0px'}}>
              <Title level={3} style={{background:'rgba(0, 102, 178, 0.4)'}}>{planData[1].name}</Title>
              <Meta 
                title={<Title level={1} style={{fontSize:'70px', display:'inline', color:'#0066b2'}}>{amount[0]} </Title>}
                description={<Paragraph style={{marginTop:'-10px',  color:'black', marginBottom:'30px'}}>{planData[1].currency} / {month}</Paragraph>}
              />
              <List
                size='small'
                loadMore={<div style={{textAlign: 'center', height: 32, lineHeight: '32px', marginBottom:7}}>
                    <Paragraph style={{cursor:'pointer', textDecoration:'underline', color:'grey'}} onClick={() => setViewAll(!viewAll)}>View {viewAll ? "less" : "more"}</Paragraph>
                  </div>
                }
                dataSource={viewAll ? planData[1].features : planData[1].features.slice(0, 6)}
                renderItem={(item) => (
                  <List.Item style={{display:'flex', justifyContent:'left', marginLeft:'20px'}}>
                    <Space align='center'>
                      <Avatar 
                        style={{background:'transparent'}} 
                        size='small' 
                        icon={item.enabled ? <CheckOutlined style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>}/>
                      <Title level={5} >{item.feature}</Title>
                    </Space>
                  </List.Item>
                )}
              >
              </List>
              <Button 
                size='large'
                style={{width:'60%', marginBottom:'30px'}}
                shape="round"
                type= "primary"
                onClick={() => admin?editPlan(planData[1]):initPaytmSubscription(planData[1], amount[0])}
              >
                {admin ? "Edit Plan" : "Buy Now"}
              </Button>
            </Card>
          </Badge.Ribbon>
        </Col>
        <Col xl={8} lg={12} md={12} sm={20} xs={24}>
          <Badge.Ribbon color="#CF0A0A" text="Current Plan" style={{display:user && user.subscription !== null && user.subscription.planName === "EXPERT" ? 'block': 'none', width:'100px', height:'30px'}}>
            <Card style={{textAlign:'center',borderRadius:'10%',backgroundColor:'whitesmoke', border:'1px solid rgba(227, 139, 41, 0.35)', overflow:'hidden'}} bodyStyle={{padding:'0px'}}>
              <Title level={3} style={{background:'rgba(227, 139, 41, 0.4)'}}>{planData[0].name}</Title>
              <Meta 
                title={<Title level={1} style={{fontSize:'70px', display:'inline', color:'#EC9B3B'}}>{amount[1]} </Title>}
                description={<Paragraph style={{marginTop:'-10px',  color:'black', marginBottom:'30px'}}>{planData[0].currency} / {month}</Paragraph>}
              />
              <List
                size='small'
                loadMore={<div style={{textAlign: 'center', height: 32, lineHeight: '32px', marginBottom:7}}>
                    <Paragraph style={{cursor:'pointer', textDecoration:'underline', color:'grey'}} onClick={() => setViewAll(!viewAll)}>View {viewAll ? "less" : "more"}</Paragraph>
                  </div>
                }
                dataSource={viewAll ? planData[0].features : planData[0].features.slice(0, 6)}
                renderItem={(item) => (
                  <List.Item style={{display:'flex', justifyContent:'left', marginLeft:'20px'}}>
                    <Space align='center'>
                      <Avatar 
                        style={{background:'transparent'}} 
                        size='small' 
                        icon={item.enabled ? <CheckOutlined style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>}/>
                      <Title level={5} >{item.feature}</Title>
                    </Space>
                  </List.Item>
                )}
              >
              </List>
              <Button 
                size='large'
                style={{width:'60%', marginBottom:'30px', backgroundColor:'#EC9B3B', color:'whitesmoke'}}
                shape="round"
                onClick={() => admin?editPlan(planData[0]):initPaytmSubscription(planData[0], amount[1])}
              >
                {admin ? "Edit Plan" : "Buy Now"}
              </Button>
            </Card>
          </Badge.Ribbon>
        </Col>
    </Row>
    }
    {showTransactionHistory && 
      <Row justify='center'>
        <Col xl={22} sm={22} xs={24}>
          <TransactionHistory orgId={user && user.organizationUid}/>
        </Col>
      </Row>
    }
    {showSubscriptionsHistory &&
      <Row justify='center'>
        <Col xl={22} sm={22} xs={24}>
          <SubscriptionHistory orgId={user && user.organizationUid}/>
        </Col>
      </Row>
    }
   {/* <Table style={{marginTop:'30px'}} dataSource={dataSource} columns={columns} /> */}
    </>}
    <Drawer
      title="Edit Plan"
      placement="right"
      onClose={() => setOpen(false)}
      visible={open}
      width={400}
    >
      <PlanEditForm plan={planToEdit} cancel={() => {setOpen(false); setPlanToEdit(null)}} submit={handleEdit}/>
    </Drawer>
    </>
  )
}

const PlanEditForm = ({plan, cancel, submit}) => {

  const [initValues, setInitValues] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    if(plan){
      plan.discounts.sort((a, b) => a.minimumUser - b.minimumUser);
      setInitValues({
        currency: plan.currency,
        amount: plan.amount,
        "0": plan.discounts[0].discount,
        "1": plan.discounts[1].discount,
        "2": plan.discounts[2].discount,
        "3": plan.discounts[3].discount,
        validForDays: plan.validForDays,
        yearlyDiscount: plan.yearlyDiscount,
      });
    }
  }, [plan])

  useEffect(()=>{
    form.resetFields();
  })

  const handleSubmit = (val) => {
    let newPlan = {
      name: plan.name,
      currency: plan.currency,
      planTimePeriod: plan.planTimePeriod,
      validForDays: plan.validForDays,
      yearlyDiscount: val.yearlyDiscount,
      amount: val.amount,
      userDiscounts: [
        {minimumUsers:plan.discounts[0].minimumUser, maximumUsers: plan.discounts[0].maximumUser, discount: val["0"]},
        {minimumUsers:plan.discounts[1].minimumUser, maximumUsers: plan.discounts[1].maximumUser, discount: val["1"]},
        {minimumUsers:plan.discounts[2].minimumUser, maximumUsers: plan.discounts[2].maximumUser, discount: val["2"]},
        {minimumUsers:plan.discounts[3].minimumUser, maximumUsers: plan.discounts[3].maximumUser, discount: val["3"]},
      ]
    }
    submit(newPlan, plan.id)
  }

  return (
    <>
      {plan && 
      <Form initialValues={initValues} form={form} onFinish={handleSubmit}>
        <Row gutter={16} justify="center">
          <Col><Typography.Title level={4} style={{color:'whitesmoke'}}>{plan.name}</Typography.Title></Col>
        </Row>
        <Divider />
        <Row style={{marginTop:5, marginLeft:5}} gutter={[16, 16]} justify='start'>
          <Col span={24}>
            <Form.Item
              name="amount"
              label={
                <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                  Price
                </Title>
              }
              rules={[{ required: true}]}
            >
              <Input type='number' min={0}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Typography.Title level={5} style={{color:'whitesmoke'}}>Discounts % :-</Typography.Title>
          </Col>
          {plan.discounts.map((discount, idx) => {
            return (
              <Col span={24}>
                <Form.Item
                  name={`${idx}`}
                  label={
                    <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                      User {discount.minimumUser} to {discount.maximumUser}
                    </Title>
                  }
                  rules={[{ required: true}]}
                >
                  <Input type='number' min={0}/>
                </Form.Item>
              </Col>
            )
          })}
          <Col span={24}>
          <Form.Item
            name='yearlyDiscount'
            label={
              <Title level={5} style={{ color: "white", marginBottom: "0" }}>
                Yearly discount
              </Title>
            }
            rules={[{ required: true}]}
          >
            <Input type='number' min={0}/>
          </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button className="ml-2 device-form-button" onClick={cancel} style={{ marginLeft: "10px" }}>
                  Close
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    }
    </>
  )
}
