import React from "react";
import { useState, useEffect } from "react";
import UserService from "../services/UserService";
import bgImg from "../extra/images/bg2.png";
import { CloseOutlined } from "@ant-design/icons";

import { Button, Input, Typography, Card, Image, Select, message, Form, Drawer, Space, notification } from "antd";

const { Text } = Typography;
const { Title } = Typography;

function ForgotPassword(props) {
  const [visible, setVisible] = useState(true);

  const close = () => {
    props.passwordRestForm();
    setVisible(false);
  };
  const handleSubmit = (e) => {
    UserService.resetPassword(e)
      .then((response) => {
        notification.success({
          type: "info",
          description: "Password reset request was sent successfully.Please check your email.",
          duration: 2,
        });
        setVisible(false);
      })
      .catch((error) => {
        notification.warn({
          type: "warning",
          description: "Your Email Not Found",
          duration: 2,
        });
      });
  };
  return (
    <>
      <Drawer
        width={600}
        closeIcon={<CloseOutlined style={{ fontSize: "20px", color: "white" }} />}
        visible={visible}
        onCancel={close}
        onClose={close}
      >
        <Card
          bordered
          style={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
            backgroundImage: "linear-gradient(#5B2C6F,#1F618D)",
            marginTop: "25vh",
          }}
          type="flex"
          align="middle"
        >
          <Form onFinish={handleSubmit} className="user-form" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Form.Item
              label={
                <Title level={5} style={{ color: "white", paddingTop: "24.89px", marginTop: "-10px" }}>
                  Please enter your registered email address. An email notification with a password reset code will then be sent to you:
                </Title>
              }
              name="email"
              rules={[
                {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please Enter your Email !",
                },
              ]}
            >
              <Input placeholder="Email" style={{ borderRadius: "5px", marginTop: "22px" }} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block style={{ borderRadius: "5px", backgroundColor: "#0083CA", fontWeight: "700" }}>
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </>
  );
}

export default ForgotPassword;
