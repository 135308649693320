import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DeviceService from "../../services/DeviceService";
import DeviceDataService from "../../services/DeviceDataService";
import { Typography, Button, DatePicker,  Select, Table, Card, Tooltip, Affix } from "antd";
import moment from "moment";
import { FileExcelTwoTone, FilePdfTwoTone, FundTwoTone,  PieChartTwoTone, RightSquareTwoTone, SyncOutlined, UpSquareTwoTone } from "@ant-design/icons";
import BurnChart from "./DeviceBurnHistoryChartView";
import BurnPieChart from "./DeviceBurnHistoryPieChartView"
import '../../css/Views.css'
import * as FileSaver from 'file-saver'
import { setNestedObjectValues } from "formik";


const { RangePicker } = DatePicker;
const { Title } = Typography;

export default function DeviceHistoricalView(props) {
    let { id } = useParams();
   // const [device, setDevice] = useState()
    const [burnsets, setBurnSets] = useState([])
    const [burnResult, setBurnResult] = useState()
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [bases, setBases] = useState([])
   // const [matrices, setMatrices] = useState([])
    const [filteredMatrices, setFilteredMatrices] = useState([])
    const [filteredSample, setFilteredSample] = useState([])
    const [selectedBase, setSelectedBase] = useState()
    const [selectedMatrix, setSelectedMatrix] = useState()
    const [selectSample, setSelectSample] = useState()
    const [newSample,setNewSample]=useState()
    const [fetchedSample,setFetchedSample]=useState()
    const [chartBase, setChartBase] = useState()
    const [chartMatrix, setChartMatrix] = useState()
    const [show, setShow] = useState(false)
    const [lineShow, setLineShow] = useState(false)
    const [pieShow, setPieShow] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [showBurnsets, setShowBurnsets] = useState(true)
    const [selectedSample, setSelectedSample] = useState([])
    //const [selectedSampleG, setSelectedSampleG] = useState([])
    const [selectedSampleh, setSelectedSampleh] = useState([])
   // const [selectedSamplehG, setSelectedSamplehG] = useState([])
    const [column, setColumn] = useState([])
   // const [columnGrade, setColumnGrade] = useState([])
    const [columnh, setColumnh] = useState([])
    //const [columnhG, setColumnhG] = useState([])
    const [baseMap, setBaseMap] = useState({})
    const [matrixMap, setMatrixMap] = useState({})
    const [chartSampleName, setChartSampleName] = useState()
    const [burnHistoryData, setBurnHistoryData] = useState()
    const [digitsAfterDecimal, setDigitsAfterDecimal] = useState()
   // const [suitableGrade, setSuitableGrade] = useState();
  //  const [burnSetUId, setBurnSetUId] = useState()
    const [gradeName, setGradeName] = useState()
    const [sampleBurnsDetail, setSampleBurnsDetail] = useState()
   // const [toggleBtn, setToggleBtn] = useState(true)
    const [horizontal, setHorizontal] = useState(true)
    const [vertical, setVertical] = useState(false)
    //const [horizontalGrade, setHorizontalGrade] = useState(false)
   // const [verticalGrade, setVerticalGrade] = useState(false)
   // const [elements, setElements] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [loading, setLoading] = useState(false)
    const [burnsetColumn, setBurnsetColumn] = useState()
    const [defaultMatrix, setDefaultMatrix] = useState()
    const [defaultBase, setDefaultBase] = useState()
    const [sampleType, setSampleType]=useState()
    const [selectedType, setSelectedType]=useState()
    const [statusValue,setStatusValue]=useState()
    const [selectedBaseName, setSelectedBaseName] = useState();

    const dateFormat = 'YYYY-MM-DD';
    const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
    const dateNewFormat='YYYY-MM-DD';
    const timeNewFormat='hh:mm:ss A';


    const columns = [
        {
            title: 'Name',
            dataIndex: 'sampleName',
            key: 'sampleName',
            width:150,
            fixed:'left',
            render: value => <div>{value}</div>
        },
        {
            title: 'Date',
            dataIndex: 'burnDate',
            key: 'burnDate',
            width:120,
            render: value => <div>{moment(value).format(dateNewFormat)}</div>
        },
        {
            title: 'Time',
            dataIndex: 'burnTime',
            key: 'burnTime',
            width:120,
            render: value => <div>{moment(value).format(timeNewFormat)}</div>
        },
        /*   
        {
               title: "Base",
               key: "baseId",
               dataIndex: "baseId",
               render: baseId => {
                   return <span>{baseMap[baseId] ? baseMap[baseId].symbol : "N/A"}</span>
               }
           },
           {
               title: "Matrix",
               key: "matrixId",
               dataIndex: "matrixId",
               render: matrixId => {
                   return <span>{matrixMap[matrixId] ? matrixMap[matrixId].name : "N/A"}</span>
               },
           },
           */
        {
            title: "Status",
            key: "crmRemark",
            dataIndex: "crmRemark",
            width:100,
            render: val => {
                if (val === "NOTOK") {
                    return <div>Fail</div>
                } else if (val === "OK") {
                    return <div>Pass</div>
                } else {
                    return <div>-</div>
                }
            }
        },
        /*  {
              title: "Grade Remarks",
              key: "Grade remarks",
              dataIndex: "gradeRemark",
          } */
    ]

    useEffect(() => {
        setLoading(true)
        DeviceDataService.getTypes(id).then(res=>{
            setSampleType(res.data)
        })
        setLoading(true)
    }, [])
    

    useEffect(() => {
        setLoading(true)
        setColumn([])
        loadDeviceData();
        setShowTable(false);
        setLoading(false)
    }, [id])
    


    function loadBurnSets(page, pageSize) {
        setLoading(true)
        if(selectedType==="All"){
            DeviceDataService.loadFilteredHistoryBurnsetsWithRemarks(id, fromDate, toDate, selectedBase, selectedMatrix, selectSample, page, pageSize).then(response => {
                  burnsetsfun(response.data)
                setTotalPages(response.data.total)
                setLoading(false)
            })
        }else{
        DeviceDataService.loadFilteredHistoryBurnsetsWithRemarks(id, fromDate, toDate, selectedBase, selectedMatrix, selectSample, page, pageSize,selectedType).then(response => {
             burnsetsfun(response.data)
            setTotalPages(response.data.total)
            setLoading(false)
        })
    }
    }


    function loadFirstBurnSets(page, pageSize, selectedBase, selectedMatrix) {
            setLoading(true)
            if(selectedType==="All"){
            DeviceDataService.loadFilteredHistoryBurnsetsWithRemarks(id, fromDate, toDate, selectedBase, selectedMatrix, selectSample, page, pageSize).then(response => {
            burnsetsfun(response.data)
            setTotalPages(response.data.total)
            setLoading(false)
        })
    }else{
        DeviceDataService.loadFilteredHistoryBurnsetsWithRemarks(id, fromDate, toDate, selectedBase, selectedMatrix, selectSample, page, pageSize,selectedType).then(response => {
            burnsetsfun(response.data)
            setTotalPages(response.data.total)
            setLoading(false)
        })  
    }
    }

    const burnsetsfun = (data) => {
        let datalist = []
        let matrixId = data.list[0].matrixId;
        DeviceDataService.loadMatrixSpectrums(id, matrixId).then(res => {
            res.data.sort((a, b) => a.sequence - b.sequence)
            let baseSampleData = {};
            for(let i = 0; i < data.list.length; i++){
                let tempResults = [];
                for (let j = 0; j < res.data.length; j++) {
                    let  ele = data.list[i].meanResults.filter(x => x.elementId === res.data[j].elementId)[0]
                    if(ele !== undefined){
                        if(ele.symbol === selectedBaseName){
                            baseSampleData = ele;
                        }
                        else{
                            tempResults.push(ele);
                        }
                    }
                }
                tempResults.push(baseSampleData);
                data.list[i].meanResults = tempResults;
            }

            let seq=["Al","Si","Cu","Mg","Mn","Ti","Zn","Ni","Pb","Sn","Cr","Zr","Ca","V","Bi","B","Co","Sb","As","P","S","C","Se","Mo","Ce","Te","W","Nb","N","Ta","La","O"]
        if (data.list.length > 0) {

            if(data.list[0].baseId===46&&data.list[0].matrixId===215){
                for (let j = 0; j < data.list[0].meanResults.length; j++) {
                   let filtSymbol= data.list[0].meanResults.filter(el=>el.symbol===seq[j])
                    columns.push({
                        title: filtSymbol[0].symbol + " (%) ",
                        dataIndex: filtSymbol[0].symbol,
                        key: filtSymbol[0].symbol,
                        width:100,
                        render: val => {
                            if(data.list[0].meanResults[j].symbol !== selectedBaseName){
                                if (typeof (val) === "undefined") {
                                    return <div style={{ width: "80px" }}>-</div>
                                } else {
                                    let newVal = val.toString();
                                    if (newVal.includes("&")) {
                                        newVal = newVal.replace('&', '')
                                        if (newVal.includes("<")) {
                                            return <div style={{ color: "blue", width: "80px" }}><span style={{ backgroundColor: 'yellow' }}>{newVal}</span></div>
                                        } else if (newVal.includes(">")) {
                                            return <div style={{ color: "red", width: "80px" }}><span style={{ backgroundColor: 'yellow' }}>{newVal}</span></div>
                                        } else {
                                            return <div style={{ color: "purple", width: "80px" }}><span style={{ backgroundColor: 'yellow' }}>{newVal}</span></div>
                                        }
                                    } else {
                                        if (newVal.includes("<")) {
                                            return <div style={{ color: "blue", width: "80px" }}>{newVal}</div>
                                        } else if (newVal.includes(">")) {
                                            return <div style={{ color: "red", width: "80px" }}>{newVal}</div>
                                        } else {
                                            return <div style={{ color: "purple", width: "80px" }}>{newVal}</div>
                                        }
                                    }
                                }
                            }
                            else {
                                if (typeof (val) === "undefined") {
                                    return <div style={{ width: "80px" }}>-</div>
                                } else{
                                    let newVal = val.toString();
                                    if (newVal.includes("&")) {
                                        newVal = newVal.replace('&', '')
                                    }
                                    if (newVal.includes("<")) {
                                        newVal = newVal.replace("<", "");
                                    }
                                    else if (newVal.includes(">")) {
                                        newVal = newVal.replace(">", "");
                                    }
                                    return <div style={{ width: "80px" }}>{newVal}</div>
                                }
                            }
                        }
                    })
                }
                

            }else{
            for (let j = 0; j < data.list[0].meanResults.length; j++) {
                if(data.list[0].meanResults[j].symbol !== undefined){
                columns.push({
                    title: data.list[0].meanResults[j].symbol + " (%) ",
                    dataIndex: data.list[0].meanResults[j].symbol,
                    key: data.list[0].meanResults[j].symbol,
                    width:100,
                    render: val => {
                        if(data.list[0].meanResults[j].symbol !== selectedBaseName){
                            if (typeof (val) === "undefined") {
                                return <div style={{ width: "80px" }}>-</div>
                            } else {
                                let newVal = val.toString();
                                if (newVal.includes("&")) {
                                    newVal = newVal.replace('&', '')
                                    if (newVal.includes("<")) {
                                        return <div style={{ color: "blue", width: "80px" }}><span style={{ backgroundColor: 'yellow' }}>{newVal}</span></div>
                                    } else if (newVal.includes(">")) {
                                        return <div style={{ color: "red", width: "80px" }}><span style={{ backgroundColor: 'yellow' }}>{newVal}</span></div>
                                    } else {
                                        return <div style={{ color: "purple", width: "80px" }}><span style={{ backgroundColor: 'yellow' }}>{newVal}</span></div>
                                    }
                                } else {
                                    if (newVal.includes("<")) {
                                        return <div style={{ color: "blue", width: "80px" }}>{newVal}</div>
                                    } else if (newVal.includes(">")) {
                                        return <div style={{ color: "red", width: "80px" }}>{newVal}</div>
                                    } else {
                                        return <div style={{ color: "purple", width: "80px" }}>{newVal}</div>
                                    }
                                }
                            }
                        }
                        else {
                            if (typeof (val) === "undefined") {
                                return <div style={{ width: "80px" }}>-</div>
                            } else{
                                let newVal = val.toString();
                                if (newVal.includes("&")) {
                                    newVal = newVal.replace('&', '')
                                }
                                if (newVal.includes("<")) {
                                    newVal = newVal.replace("<", "");
                                }
                                else if (newVal.includes(">")) {
                                    newVal = newVal.replace(">", "");
                                }
                                return <div style={{ width: "80px" }}>{newVal}</div>
                            }
                        }
                    }
                })}
            }
        }
        }
        setBurnsetColumn(columns)


let filtcrmData;
        if (statusValue === "Fail") {
            filtcrmData=data.list.filter(el=>el.crmRemark==="NOTOK")
            } else if (statusValue === "Pass") {
                filtcrmData=data.list.filter(el=>el.crmRemark==="OK")
            } else if(statusValue === "-"){
                filtcrmData=data.list.filter(el=>el.crmRemark==="NA")
            } else if(statusValue === "All"){
                filtcrmData=data.list
            }else{
                filtcrmData=data.list
            }

        for (let i = 0; i < filtcrmData.length; i++) {
              datalist.push({
                id: filtcrmData[i].id,
                sampleName: filtcrmData[i].sampleName,
                burnDate:filtcrmData[i].burnTime,
                burnTime: filtcrmData[i].burnTime,
                baseId: filtcrmData[i].baseId,
                matrixId: filtcrmData[i].matrixId,
                crmRemark: filtcrmData[i].crmRemark,
                gradeRemark: filtcrmData[i].gradeRemark
            })

            for (let j = 0; j < data.list[i].meanResults.length; j++) {
                if (data.list[i].meanResults[j].remark === "NOTOK") {
                    datalist[i][data.list[i].meanResults[j].symbol] = data.list[i].meanResults[j].concentration + "&"
                } else {
                    datalist[i][data.list[i].meanResults[j].symbol] = data.list[i].meanResults[j].concentration
                }
            }
        }
        setBurnSets(datalist)
        })
    }


    function loadBurnSet(burnsetId) {
        DeviceDataService.loadHistoryBurnSet(id, burnsetId).then(response => {
            setGradeName(response.data.grade)
          //  if (response.data.grade === null || response.data.grade === "") {
              //  setToggleBtn(true)
            //    setVertical(false)
             //   setHorizontal(true)
              //  setVerticalGrade(false)
              //  setHorizontalGrade(false)
           // } else {
               // setToggleBtn(false)
             //   setVertical(false)
             //   setHorizontal(false)
              //  setVerticalGrade(true)
              //  setHorizontalGrade(false)
           // }
            setSampleBurnsDetail(response.data)
           /* DeviceDataService.getAllSuitableGrade(id, response.data.baseId, response.data.matrixId).then(res => {
                setSuitableGrade(res.data)
            }) */
            DeviceDataService.loadCertValue(id, response.data.sampleName).then(resp => {
                // DeviceDataService.getOneGrade(id, response.data.baseId, response.data.matrixId, response.data.grade).then(res => {
                //     setBurnResult(process(response.data, res.data, resp))
                // }).catch((error) => {
                    setBurnResult(process(response.data, "No Grade", resp))
                // })
            }).catch((error) => {
                // DeviceDataService.getOneGrade(id, response.data.baseId, response.data.matrixId, response.data.grade).then(res => {
                //     setBurnResult(process(response.data, res.data, "No CertVal"))
                // }).catch((error) => {
                    setBurnResult(process(response.data, "No Grade", "No CertVal"))
                // })
            })
        })
    }

 {/*   function loadBurnSetGrade(grade) {
        setGradeName(grade)
        DeviceDataService.loadHistoryBurnSet(id, burnSetUId).then(response => {
            DeviceDataService.loadCertValue(id, response.data.sampleName).then(resp => {
                DeviceDataService.getOneGrade(id, response.data.baseId, response.data.matrixId, grade).then(res => {
                    setBurnResult(process(response.data, res.data, resp))
                }).catch((error) => {
                    setBurnResult(process(response.data, "No Grade", resp))
                })
            }).catch((error) => {
                DeviceDataService.getOneGrade(id, response.data.baseId, response.data.matrixId, grade).then(res => {
                    setBurnResult(process(response.data, res.data, "No CertVal"))
                }).catch((error) => {
                    setBurnResult(process(response.data, "No Grade", "No CertVal"))
                })
            })
        })

    }
*/}

    function loadDeviceData() {
        DeviceDataService.loadBases(id).then(response => {
            let bases = response.data;
            for (let i = 0; i < bases.length; i++) {
                baseMap[bases[i].baseId] = bases[i]
            }
            setBaseMap(baseMap)
            setBases(response.data)

            DeviceDataService.loadMatrixTypes(id).then(resp => {
                 let filteredMatrix = resp.data.filter(m => m.baseId === response.data[0].baseId)
                setSelectedBase(response.data[0].baseId)
                setSelectedBaseName(response.data[0].symbol)
                setSelectedMatrix(filteredMatrix[0].matrixId)
                setFilteredMatrices(filteredMatrix)
                setDefaultBase(response.data[0].symbol)
                setDefaultMatrix(filteredMatrix[0].name)
                loadFirstBurnSets(0, 10, response.data[0].baseId, filteredMatrix[0].matrixId)
                DeviceDataService.loadHistoryData(id).then(res => {
                    setFilteredSample(res.data.filter(m => m.matrixId === filteredMatrix[0].matrixId && m.baseId === response.data[0].baseId))
                })
            })
        })


        DeviceDataService.loadMatrixTypes(id).then(response => {
            if (selectedBase) {
                setFilteredMatrices(response.data.filter(m => m.baseId === selectedBase))
            } else {
                setFilteredMatrices(response.data)
            }
            let matrices = response.data;
            for (let i = 0; i < matrices.length; i++) {
                matrixMap[matrices[i].matrixId] = matrices[i]
            }
            setMatrixMap(matrixMap)
          //  setMatrices(response.data)
        })

        DeviceDataService.loadHistoryData(id).then(response => {

            if (selectedBase) {
                setFilteredSample(response.data.filter(m => m.baseId === selectedBase))
            }
            else if (selectedMatrix) {
                setFilteredSample(response.data.filter(m => m.matrixId === selectedMatrix))
            }
            else if (selectedBase && selectedMatrix) {
                setFilteredSample(response.data.filter(m => m.matrixId === selectedMatrix && m.baseId === selectedBase))
            }
            else {
                setFilteredSample(response.data)
            }
        })

      /*  DeviceService.getDevice(id).then(response => {
            setDevice(response.data);
        }); */
        setLoading(false)
    }



    function onDateChange(value) {
        if(value===null){
            setFromDate()
            setToDate()
        }else{
             setFromDate(value[0].format(dateNewFormat)+"T00:00:01")
            setToDate(value[1].format(dateNewFormat)+"T23:59:59")
        }
    }

    function onRowClick(event, record, index) {
        setDefaultBase(baseMap[record.baseId].symbol)
        setDefaultMatrix(matrixMap[record.matrixId].name)
        setShow(false)
        setPieShow(false)
        setShowTable(true)
        setChartBase(record.baseId)
        setChartMatrix(record.matrixId)
        setChartSampleName(record.sampleName)
      //  setBurnSetUId(record.id)
        loadBurnSet(record.id)
    }

    function fetchData() {
      //  setNewSample(newSample)
        setFetchedSample(newSample)
        setShow(false)
        setPieShow(false)
        setShowTable(false)
        loadBurnSets(0, 10)
     //  { newSample&&DeviceDataService.loadHistoryData(id).then(response => {
     //   const filteredGraphSample=response.data.filter(el=>el.sampleName===newSample && el.baseId===selectedBase && el.matrixId===selectedMatrix)
    //    })}
    }

    function fetchRowData(){
        setFetchedSample(newSample)
        setShow(false)
        setPieShow(false)
        setShowTable(false)
    }

    function certfun(text, digit) {
        if (text === 0) {
            return text = "-"
        }
        else {
            return text.toFixed(digit)
        }
    }

    function accfun(text, digit) {
        if (typeof (text) === "number") {
            return text.toFixed(digit)
        }
        else {
            return text
        }
    }


    function sdfun(text, digit) {
        let num=0;
        if (text === 0 || text.toFixed(digit) === num.toFixed(digit)) {
            return text = "-"
        }
        else {
            return <div style={{ color: 'green' }}>{text.toFixed(digit)}</div>
        }
    }
    function rsdfun(text, digit) {
        if (text === 0) {
            return text = "-"
        }
        else {
            return <div style={{ color: 'green' }}>{text.toFixed(digit)}</div>
        }
    }

    function process(data, grade, certResponse) {
        let GradeData = grade
        let sample = data;
        let sampleData = [];
        let sampleDatah = [];
       // let sampleDataG = [];
      //  let sampleDatahG = [];
        let cols = [];
       // let colsG = [];
        let colsh = [];
       // let colshG = [];
        let sum = 0;
        let sqsum = 0;

        setBurnHistoryData(data)
        setColumn([])
       // setColumnGrade([])
        setSelectedSample([])
       // setSelectedSampleG([])
        setColumnh([])
        setSelectedSampleh([])
      //  setColumnhG([])
       // setSelectedSamplehG([])

        DeviceDataService.loadMatrixSpectrums(id, data.matrixId).then(res => {
            res.data.sort((a, b) => a.sequence - b.sequence)
            setDigitsAfterDecimal(res.data[0].digitsAfterDecimal)
            let digit = res.data[0].digitsAfterDecimal
            if (digit < 0) digit = 4;
            cols.push({
                title: 'Element',
                dataIndex: 'element',
                key: 'element',
                width: 100,
                fixed:'left',
                render: val => val
            });
            cols.push({
                title: 'ElementId',
                dataIndex: 'elementId',
                key: 'elementId',
                width: 100
            });

        /*    colsG.push({
                title: 'Element',
                dataIndex: 'element',
                key: 'element',
                width: 120,
                render: val => <div style={{ width: '120px' }}>{val}</div>
            });
*/
            //we need same no of rows as # of elements in burn result, so we push that many rows
            //based on burn -
            //TODO ordering based on matrix
            //TODO coloring, show and decimals based on matrix

            let modResults = [];
            let baseSampleData = {};
            for (let i = 0; i < res.data.length; i++) {
                let  ele = sample.results[0].results.find(x => x.elementId === res.data[i].elementId)
                if(ele !== undefined){
                    if(ele.symbol === selectedBaseName){
                        baseSampleData = ele;
                    }
                    else {
                        modResults.push(ele);
                    }
                }
            }
            modResults.push(baseSampleData);
            sample.results[0].results = modResults;

            for (let i = 0; i < sample.results[0].results.length; i++) {
                sampleData.push({
                    'elementId': sample.results[0].results[i].elementId
                })
            }

            for (let i = 0; i < sample.results[0].results.length; i++) {
                const result = res.data.filter(({ elementId }) => elementId === sampleData[i].elementId);
                const a = result[0].concentrationHigh
                const b = result[0].concentrationLow
                sampleData[i].element = <div>{sample.results[0].results[i].symbol} {"(%)"} </div>/*<div style={{ display: 'flex', justifyContent: 'space-around' }}><span style={{ color: 'blue' }}>{b.toFixed(digit + 2).slice(0, -2)}</span><span style={{ color: 'red' }}>{a.toFixed(digit + 2).slice(0, -2)}</span></div>*/
               /*
                sampleDataG.push({
                    'element': sampleData[i].element,
                })
                */
            }


      /*      colsG.push({
                title: 'MIN',
                dataIndex: 'min',
                key: 'min',
                width: 100
            });
            colsG.push({
                title: 'MAX',
                dataIndex: 'max',
                key: 'max',
                width: 100
            });


            for (let i = 0; i < sampleData.length; i++) {
                if (GradeData !== "No Grade") {
                    const result = GradeData.GradeDetails.find(({ ElementID }) => ElementID === sampleData[i].elementId);
                    if (result) {
                        sampleDataG[i].min = result.LowConc.toFixed(digit);
                        sampleDataG[i].max = result.HighConc.toFixed(digit);
                    }
                    else {
                        sampleDataG[i].min = "-";
                        sampleDataG[i].max = "-";
                    }
                } else {
                    sampleDataG[i].min = "-";
                    sampleDataG[i].max = "-";
                }
            }
*/
            //.......................................horizontal......................................................

            colsh.push(
                {
                    title: 'Elements',
                    dataIndex: 'elements',
                    key: 'elements',
                    fixed:'left',
                    width: 100,
                }
            )
          /*  colshG.push(
                {
                    title: 'Burn/Elements',
                    dataIndex: 'elements',
                    key: 'elements',
                    width: 150
                }
            )
*/
            for (let i = 0; i < sample.results[0].results.length; i++) {
                colsh.push(
                    {
                        title: sampleData[i].element,
                        dataIndex: sample.results[0].results[i].symbol,
                        key: sample.results[0].results[i].symbol,
                        width: 100,
                    }
                )
           /*     colshG.push(
                    {
                        title: sampleData[i].element,
                        dataIndex: sample.results[0].results[i].symbol,
                        key: sample.results[0].results[i].symbol,
                        width: 120
                    }
                )
                */
            }

            //..................................................end..............................................

            for (let i = 0; i < sample.results.length; i++) {
                let burn = sample.results[i];
                cols.push({
                    title: 'Burnh' + (i + 1),
                    dataIndex: 'burnh' + (i + 1),
                    key: 'burnh' + (i + 1),
                    width: 100,
                    render: val => val.toFixed(digit)
                })

                //now we push all n values together
                for (let j = 0; j < burn.results.length; j++) {
                    const result = burn.results.find(({ elementId }) => elementId === sampleData[j].elementId)
                   /* if (result.concentration < 0) {
                        sampleData[j]['burnh' + (i + 1)] = 0;
                    } else { */
                        sampleData[j]['burnh' + (i + 1)] = result.concentration
                   /* }*/
                }
            }

            for (let i = 0; i < sample.results.length; i++) {
                let burn = sample.results[i];
                cols.push({
                    title: 'Burn' + (i + 1),
                    dataIndex: 'burn' + (i + 1),
                    key: 'burn' + (i + 1),
                    width: 100
                })

              /*  colsG.push({
                    title: 'Burn' + (i + 1),
                    dataIndex: 'burn' + (i + 1),
                    key: 'burn' + (i + 1),
                    width: 100
                })

              */
                for (let j = 0; j < burn.results.length; j++) {
                    const result = res.data.find(({ elementId }) => elementId === sampleData[j].elementId);
                    const burnresult = burn.results.find(({ elementId }) => elementId === sampleData[j].elementId)

                    if(burnresult.symbol !== selectedBaseName){
                    if (result.checkWithCustom) {
                        const a = result.customHigh
                        const b = result.customLow
                        const c = burnresult.concentration
                        if (result.showLowerClipping && result.showUpperClipping) {
                            if (c >= b && c <= a) {
                                   sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                                 }
                            if (c < b) {
                                   sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                                 }
                            if (c > a) {
                                     sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                                  }


                        } else if (result.showLowerClipping) {
                            if (c >= b) {
                                  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                                    }
                            else {
                                  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                                  }

                        } else if (result.showUpperClipping) {
                            if (c <= a) {
                                    sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                                   } else {
                                  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                                  }
                        } else {
                              sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                               }
                    } else {
                        const a = result.concentrationHigh
                        const b = result.concentrationLow
                        const c = burnresult.concentration
                        if (result.showLowerClipping && result.showUpperClipping) {
                            if (c >= b && c <= a) {
                                 sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                                                         }
                            if (c < b) {
                                  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                                  }
                            if (c > a) {
                                  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                             }


                        } else if (result.showLowerClipping) {
                            if (c >= b) {
                                  sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                                   }
                            else {
                                   sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                                 }

                        } else if (result.showUpperClipping) {
                            if (c <= a) {
                                    sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                                  } else {
                                    sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                                 }
                        } else {
                         sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                              }
                    }}else{
                        const c = burnresult.concentration
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                    }

                    
                 /*   const a = result.concentrationHigh
                    const b = result.concentrationLow
                    const c = burnresult.concentration

                    if (c >= b && c <= a) {
                       // sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit + 2).slice(0, -2)}</div>
                       // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit + 2).slice(0, -2)}</div>
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                     //   sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                    }

                    if (c < b) {
                       // sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit + 2).slice(0, -2)}</div>
                        //sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit + 2).slice(0, -2)}</div>
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                      //  sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                    }
                    if (c > a) {
                       // sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit + 2).slice(0, -2)}</div>
                       // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit + 2).slice(0, -2)}</div>
                        sampleData[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                       // sampleDataG[j]['burn' + (i + 1)] = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                    }
                    */
                }
            }

            cols.push({
                title: 'Meanh',
                dataIndex: 'meanh',
                key: 'meanh',
                render: val => val.toFixed(digit),
                width: 100
            });


            for (let i = 0; i < sampleData.length; i++) {
                let n = 0;
                for (let j = 2; j <= sample.results.length + 1; j++) {
                    if (Object.values(sampleData[i])[j] !== 0) {
                        sum = sum + Object.values(sampleData[i])[j]
                        n = n + 1
                    } else {
                        sum = sum + Object.values(sampleData[i])[j]
                        n = n
                    }
                }
                if (n >= 1) {
                    sampleData[i].meanh = sum / n;
                } else {
                    sampleData[i].meanh = sum;
                }
                sum = 0;
            }

            cols.push({
                title: 'Mean',
                dataIndex: 'mean',
                key: 'mean',
                width: 100
            });
         
         /* 
           colsG.push({
                title: 'Mean',
                dataIndex: 'mean',
                key: 'mean',
                width: 100
            });
              */

            for (let j = 0; j < sampleData.length; j++) {
                const result = res.data.find(({ elementId }) => elementId === sampleData[j].elementId);

                if(sampleData[j].element.props.children[0] !== selectedBaseName){
                if (result.checkWithCustom) {
                    const a = result.customHigh
                    const b = result.customLow
                    const c = sampleData[j].meanh
                    if (result.showLowerClipping && result.showUpperClipping) {
                        if (c >= b && c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c < b) {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c > a) {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }


                    } else if (result.showLowerClipping) {
                        if (c >= b) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        else {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }

                    } else if (result.showUpperClipping) {
                        if (c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        } else {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                    } else {
                        sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                    }
                } else {
                    const a = result.concentrationHigh
                    const b = result.concentrationLow
                    const c = sampleData[j].meanh
                    if (result.showLowerClipping && result.showUpperClipping) {
                        if (c >= b && c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c < b) {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        if (c > a) {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }


                    } else if (result.showLowerClipping) {
                        if (c >= b) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                        else {
                            sampleData[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "blue" }}>{"<" + b.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }

                    } else if (result.showUpperClipping) {
                        if (c <= a) {
                            sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        } else {
                            sampleData[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digit)}</div>
                            //  sampleDataG[j].mean = <div style={{ color: "red" }}>{">" + a.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                        }
                    } else {
                        sampleData[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digit)}</div>
                        //  sampleDataG[j].mean = <div style={{ color: "purple" }}>{c.toFixed(digitsAfterDecimal + 2).slice(0, -2)}</div>
                    }
                }}else {
                    const c = sampleData[j].meanh
                    sampleData[j].mean = <div style={{ color: "black" }}>{c.toFixed(digit)}</div>
                }





             //  const a = result.concentrationHigh
               // const b = result.concentrationLow
               // const c = sampleData[j].meanh
                /* if (c === 0) {
                     sampleData[j].mean = <div>-</div>
                     sampleDataG[j].mean = <div>-</div>
                 } else {  */
               // if (c >= b && c <= a) {
                 //   sampleData[j].mean = <span style={{ color: "purple" }}>{c.toFixed(digit)}</span>
                  //  sampleDataG[j].mean = <span style={{ color: "purple" }}>{c.toFixed(digit)}</span>
              //  }
              //  if (c < b) {
                //    sampleData[j].mean = <span style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</span>
                  //  sampleDataG[j].mean = <span style={{ color: "blue" }}>{"<" + b.toFixed(digit)}</span>
               // }
              //  if (c > a) {
              //      sampleData[j].mean = <span style={{ color: "red" }}>{">" + a.toFixed(digit)}</span>
                //sampleDataG[j].mean = <span style={{ color: "red" }}>{">" + a.toFixed(digit)}</span>
              //  }
           
            }
            /*  }*/


            //.................................................Horizontal.......................................................

          /*  sampleDatahG.push(
                {
                    'elements': "MIN",
                },
                {
                    'elements': "MAX",
                }
            )

            const minG = sampleDatahG.find(({ elements }) => elements === "MIN")
            const maxG = sampleDatahG.find(({ elements }) => elements === "MAX")


            for (let j = 0; j < sample.results[0].results.length; j++) {
                minG[sample.results[0].results[j].symbol] = sampleDataG[j].min
                maxG[sample.results[0].results[j].symbol] = sampleDataG[j].max
            }
*/
            for (let i = 0; i < sample.results.length; i++) {
                let burn = sample.results[i];
                sampleDatah.push({
                    'elements': 'Burn' + (i + 1)
                })
              /*  sampleDatahG.push({
                    'elements': 'Burn' + (i + 1)
                })
*/

                const burnhor = sampleDatah.find(({ elements }) => elements === 'Burn' + (i + 1))
             //   const burnhorG = sampleDatahG.find(({ elements }) => elements === 'Burn' + (i + 1))

                for (let j = 0; j < burn.results.length; j++) {
                    burnhor[sample.results[0].results[j].symbol] = Object.values(sampleData[j])[sample.results.length + 2 + i]
                 //   burnhorG[sample.results[0].results[j].symbol] = Object.values(sampleData[j])[sample.results.length + 2 + i]
                }
            }

            sampleDatah.push(
                {
                    'elements': "Mean",
                }
            )

          /*  sampleDatahG.push(
                {
                    'elements': "Mean",
                }
            ) */

            const meanhor = sampleDatah.find(({ elements }) => elements === "Mean")
           // const meanhorG = sampleDatahG.find(({ elements }) => elements === "Mean")

            for (let j = 0; j < sample.results[0].results.length; j++) {
                meanhor[sample.results[0].results[j].symbol] = sampleData[j].mean
              //  meanhorG[sample.results[0].results[j].symbol] = sampleDataG[j].mean
            }

            //...................................................end.......................................................

          /*  if (sample.results.length > 1) { */
                for (let i = 0; i < sampleData.length; i++) {
                    if (certResponse !== "No CertVal") {
                        const result = certResponse.data.find(({ elementId }) => elementId === sampleData[i].elementId);
                        if (result) {
                            if (result.conc !== 0) {
                                sampleData[i].certval = result.conc;
                                sampleData[i].acceptance = 3 * result.sd;
                             //   sampleDataG[i].certval = result.conc;
                                if (sampleData[i].acceptance === 0) {
                                    sampleData[i].remarks = "-"
                                }
                                else {
                                    let a = sampleData[i].certval + sampleData[i].acceptance
                                    let b = sampleData[i].certval - sampleData[i].acceptance
                                    if (sampleData[i].meanh === 0) {
                                        sampleData[i].remarks = "-"
                                    } else {
                                        if (sampleData[i].meanh >= b && sampleData[i].meanh <= a) {
                                            sampleData[i].remarks = "OK"
                                        }
                                        else {
                                            sampleData[i].remarks = "NOT OK"
                                            sampleData[i].mean.props.style.backgroundColor = "yellow"
                                        }  
                                    }
                                }

                            }
                            else {
                                sampleData[i].certval = result.conc;
                             //   sampleDataG[i].certval = result.conc;
                                sampleData[i].acceptance = "-";
                                sampleData[i].remarks = "-";
                            }
                        } else {
                            sampleData[i].certval = 0;
                           // sampleDataG[i].certval = 0;
                            sampleData[i].acceptance = "-";
                            sampleData[i].remarks = "-";
                        }
                    } else {
                        sampleData[i].certval = 0;
                     //   sampleDataG[i].certval = 0;
                        sampleData[i].acceptance = "-";
                        sampleData[i].remarks = "-";
                    }
                }
                cols.push({
                    title: 'Cert. Val.',
                    dataIndex: 'certval',
                    key: 'certval',
                    render: certval => certfun(certval, digit),
                    width: 100
                });

                cols.push({
                    title: 'SD',
                    dataIndex: 'sd',
                    key: 'sd',
                    render: sd => sdfun(sd, digit),
                    width: 100
                });


                cols.push({
                    title: 'RSD',
                    dataIndex: 'rsd',
                    key: 'rsd',
                    render: rsd => rsdfun(rsd, digit),
                    width: 100
                });

               /* colsG.push({
                    title: 'Cert. Val.',
                    dataIndex: 'certval',
                    key: 'certval',
                    render: certval => certfun(certval, digit),
                    width: 100
                });

                colsG.push({
                    title: 'SD',
                    dataIndex: 'sd',
                    key: 'sd',
                    render: sd => sdfun(sd, digit),
                    width: 100
                });

                colsG.push({
                    title: 'RSD',
                    dataIndex: 'rsd',
                    key: 'rsd',
                    render: rsd => rsdfun(rsd, digit),
                    width: 100
                });

               */
                for (let i = 0; i < sampleData.length; i++) {
                    let n = 0;
//                    console.log({sampleResult: sample.results});
//                    console.log({burns: Object.values(sampleData[i])});

                    for (let j = 2; j < 2 + sample.results.length; j++) {
                        let rowarr = Object.values(sampleData[i])[j]
                        // console.log({rowarr});
                        // console.log({meanh: sampleData[i].meanh});
                        // console.log({sampleData: sampleData[i]});
                        if (rowarr > 0) {
                            // sqsum = sqsum + Math.pow(rowarr - sampleData[i].meanh, 2)
                            sqsum = sqsum + ((rowarr - sampleData[i].meanh) * (rowarr - sampleData[i].meanh))
                            n = n + 1
                        } 
                        // else {
                            // sqsum = sqsum
                            // n = n
                        // }
                    }

                    if (sampleData[i].mean.props.children.includes("<") || sampleData[i].mean.props.children.includes(">") || sampleData[i].meanh === 0) {
                        sampleData[i].sd = 0
                        sampleData[i].rsd = 0
                       // sampleDataG[i].sd = 0
                       // sampleDataG[i].rsd = 0
                    }
                    else {
                        if (n > 1) {
                            // console.log({sd: Math.sqrt(sqsum / (n - 1))}, "n>1");
                            // console.log({rsd: ((Math.sqrt(sqsum / (n - 1))) * 100) / sampleData[i].meanh}, "n>1");
                            sampleData[i].sd = Math.sqrt(sqsum / (n-1));
                            // sampleData[i].rsd = ((Math.sqrt(sqsum / n)) * 100) / sampleData[i].meanh
                            sampleData[i].rsd = (sampleData[i].sd / sampleData[i].meanh * 100)
                         //   sampleDataG[i].sd = Math.sqrt(sqsum / (n - 1));
                          //  sampleDataG[i].rsd = ((Math.sqrt(sqsum / (n - 1))) * 100) / sampleData[i].meanh

                        } else {
                            sampleData[i].sd = Math.sqrt(sqsum);
                            sampleData[i].rsd = (sampleData[i].sd / sampleData[i].meanh * 100)
                          //  sampleDataG[i].sd = Math.sqrt(sqsum);
                          //  sampleDataG[i].rsd = ((Math.sqrt(sqsum)) * 100) / sampleData[i].meanh

                        }
                    }
                    sqsum = 0;
                }
                cols.push({
                    title: 'Acceptance(3s)',
                    dataIndex: 'acceptance',
                    key: 'acceptance',
                    render: acc => accfun(acc, digit),
                    width: 150
                });
                cols.push({
                    title: 'Remarks',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    width: 100
                });
              /*  colsG.push({
                    title: 'Remarks',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    width: 100
                });
*/

           /*     for (let i = 0; i < sampleData.length; i++) {
                    if (GradeData !== "No Grade") {
                        const result = GradeData.GradeDetails.find(({ ElementID }) => ElementID === sampleData[i].elementId);
                        if (result) {
                            let a = result.LowConc;
                            let b = result.HighConc;
                            let c = sampleData[i].meanh
                            if (c === 0) {
                                sampleDataG[i].remarks = "-"
                            } else {
                                if (c >= a && c <= b) {
                                    sampleDataG[i].remarks = <div style={{ color: "#32CD32" }}>OK</div>
                                } else {
                                    sampleDataG[i].remarks = <div style={{ color: "red" }}>NOT OK</div>
                                }
                            }
                        }
                        else {
                            sampleDataG[i].remarks = "-";
                        }

                    } else {
                        sampleDataG[i].remarks = "-";
                    }
                }
*/
                // .........................................horizontal.............................................

                sampleDatah.push(
                    {
                        'elements': "Cert. Val.",
                    },
                    {
                        'elements': "SD",
                    },
                    {
                        'elements': "RSD",
                    },
                    {
                        'elements': "Acceptance",
                    },
                    {
                        'elements': "Remarks",
                    }
                )
            /*    sampleDatahG.push(
                    {
                        'elements': "Cert. Val.",
                    },
                    {
                        'elements': "SD",
                    },
                    {
                        'elements': "RSD",
                    },
                    {
                        'elements': "Remarks",
                    }
                )
*/
                const acceptanceh = sampleDatah.find(({ elements }) => elements === "Acceptance")
                const remarksh = sampleDatah.find(({ elements }) => elements === "Remarks")
                const sdh = sampleDatah.find(({ elements }) => elements === "SD")
                const rsdh = sampleDatah.find(({ elements }) => elements === "RSD")
                const certvalh = sampleDatah.find(({ elements }) => elements === "Cert. Val.")
               /* const remarkshG = sampleDatahG.find(({ elements }) => elements === "Remarks")
                const sdhG = sampleDatahG.find(({ elements }) => elements === "SD")
                const rsdhG = sampleDatahG.find(({ elements }) => elements === "RSD")
                const certvalhG = sampleDatahG.find(({ elements }) => elements === "Cert. Val.")

                
                                    for (let j = 0; j < sample.results[0].results.length; j++) {
                                        meanhor[sample.results[0].results[j].symbol] = sampleData[j].mean
                                    }
                */
                for (let j = 0; j < sample.results[0].results.length; j++) {
                    if (sampleData[j].certval === 0) {
                        certvalh[sample.results[0].results[j].symbol] = "-"
                     //   certvalhG[sample.results[0].results[j].symbol] = "-"
                    }
                    else {
                        certvalh[sample.results[0].results[j].symbol] = sampleData[j].certval.toFixed(digit)
                      //  certvalhG[sample.results[0].results[j].symbol] = sampleData[j].certval.toFixed(digit)
                    }

                    if (typeof (sampleData[j].acceptance) === "number") {
                        acceptanceh[sample.results[0].results[j].symbol] = sampleData[j].acceptance.toFixed(digit)
                    }
                    else {
                        acceptanceh[sample.results[0].results[j].symbol] = sampleData[j].acceptance
                    }
                  let numb=0; 
                    if(sampleData[j].element.props.children[0] !== selectedBaseName){
                        if (sampleData[j].sd === 0 || sampleData[j].sd.toFixed(digit) === numb.toFixed(digit)) {
                            sdh[sample.results[0].results[j].symbol] = "-"
                        //  sdhG[sample.results[0].results[j].symbol] = "-"
                        }
                        else {
                            sdh[sample.results[0].results[j].symbol] = <div style={{ color: 'green' }}>{sampleData[j].sd.toFixed(digit)}</div>
                        //  sdhG[sample.results[0].results[j].symbol] = <div style={{ color: '#32CD32' }}>{sampleData[j].sd.toFixed(digit)}</div>
                        }
                    }else{
                        sdh[sample.results[0].results[j].symbol] = <div style={{ color: 'black' }}>{sampleData[j].sd.toFixed(digit)}</div>
                    }

                    if(sampleData[j].element.props.children[0] !== selectedBaseName){
                        if (sampleData[j].rsd === 0 || sampleData[j].sd.toFixed(digit) === numb.toFixed(digit)) {
                            rsdh[sample.results[0].results[j].symbol] = "-"
                        //   rsdhG[sample.results[0].results[j].symbol] = "-"
                        }
                        else {
                            rsdh[sample.results[0].results[j].symbol] = <div style={{ color: 'green' }}>{sampleData[j].rsd.toFixed(digit)}</div>
                        //  rsdhG[sample.results[0].results[j].symbol] = <div style={{ color: '#32CD32' }}>{sampleData[j].rsd.toFixed(digit)}</div>
                        }
                    }
                    else{
                        rsdh[sample.results[0].results[j].symbol] = <div style={{ color: 'black' }}>{sampleData[j].rsd.toFixed(digit)}</div>
                    }
                    remarksh[sample.results[0].results[j].symbol] = sampleData[j].remarks
                   // remarkshG[sample.results[0].results[j].symbol] = sampleDataG[j].remarks
                }

                //................................................end..................................
                setColumn(cols)
              //  setColumnGrade(colsG)
                setSelectedSample(sampleData)
               // setSelectedSampleG(sampleDataG)
                setColumnh(colsh)
              //  setColumnhG(colshG)
                setSelectedSampleh(sampleDatah)
               // setSelectedSamplehG(sampleDatahG)
                setLoading(false)
            /*} 
            else {
                setColumn(cols)
                setColumnGrade(colsG)
                setSelectedSample(sampleData)
                setSelectedSampleG(sampleDataG)
                setColumnh(colsh)
                setColumnhG(colshG)
                setSelectedSampleh(sampleDatah)
                setSelectedSamplehG(sampleDatahG)
            }*/
        })

        return {
            sampleName: data.sampleName,
            cols: cols,
            data: sampleData,
            deviceId: id
        }
    }


    function onBaseChange(baseId, option) {
        setSelectedType();
        setStatusValue();
        setSelectSample()
        setNewSample()
        setFetchedSample(null)
        setSelectedBase(baseId)
        setSelectedBaseName(option.children)
        DeviceDataService.loadMatrixTypes(id).then(response => {
            let filteredMat=response.data.filter(m => m.baseId === baseId)
            
            if (baseId) {
                setDefaultMatrix()
               setSelectedMatrix(filteredMat[0].matrixId)
                setDefaultMatrix(filteredMat[0].name)
                setFilteredMatrices(filteredMat)
                  // setDefaultMatrix(response.data.filter(m => m.baseId === baseId)[0].name)
              // onMatrixChange(response.data.filter(m => m.baseId === baseId)[0].matrixId) 
            } else {
                setFilteredMatrices(response.data)
                //setDefaultMatrix(response.data[0].name)
            }
           // setMatrices(response.data)
        })
     
        DeviceDataService.loadHistoryData(id).then(response => {
            if (baseId) {
                setFilteredSample(response.data.filter(m => m.baseId === baseId))
            } else {
                setFilteredSample(response.data)
            }
        })
      
    }
   

    function onMatrixChange(matrixId) {
        /*    if (matrixId === "all") {
                setSelectedMatrix()
                DeviceDataService.loadHistoryData(id).then(response => {
                    setFilteredSample(response.data)
                })
            } else {  */
        setSelectSample()
         setNewSample()
        setSelectedType();
        setStatusValue();
        setFetchedSample(null)
        setSelectedMatrix(matrixId)
        DeviceDataService.loadHistoryData(id).then(response => {
            if (matrixId) {
                setFilteredSample(response.data.filter(m => m.matrixId === matrixId))
            } else {
                setFilteredSample(response.data)
            }
        })
        /* }*/
    }

    function onSampleChange(sample) {
        if (sample === "all") {
            setSelectSample()
            setNewSample()
        } else {
            setSelectSample(sample)
            setNewSample(sample)
        }
    }

    

    const showlinechart=()=>{
        setShowTable(false)
        setPieShow(false)
        setLineShow(true)
        setShowBurnsets(false)
    }

    const showchart = () => {
        setShowTable(false)
        setPieShow(false)
        setShow(true)
    }
    const showPieChart = () => {
        setShowTable(false)
        setShow(false)
        setPieShow(true)
    }

    function disabledDate(current) {
        return current && current > moment().endOf('day');
    }


   const RefreshData =()=>{
    setLoading(true)
    setColumn([])
    setSelectedBase()
    setSelectedBaseName()
    setSelectedMatrix()
    setFilteredMatrices()
    setDefaultBase()
    setDefaultMatrix()
    loadDeviceData();
    setShowTable(false);
    setLoading(false)
   }

   
   

    return (
        <>
       { showBurnsets && <span style={{ float: 'right' }}>{!loading?<Button size="small" type="primary" icon={<SyncOutlined />} onClick={RefreshData}>Refresh</Button>: <Button size="small" type="primary" icon={<SyncOutlined spin />} >Refresh</Button>}</span>}
        <div >
            {showBurnsets && <span style={{marginBottom:'-10px'}}>
                {typeof (defaultBase) !== "undefined" ? <Select style={{ width: 150 }} dropdownClassName="dropdown" defaultValue={defaultBase} onChange={onBaseChange} placeholder='Select base'>
                    {bases.map(base => {
                        return <Select.Option key={base.baseId} value={base.baseId}>{base.symbol}</Select.Option>
                    })}
                </Select> : <></>}
                {typeof (defaultMatrix) !== "undefined" ? <Select style={{ width: 150 }} dropdownClassName="dropdown" defaultValue={defaultMatrix} onChange={onMatrixChange} placeholder='Select matrix'>
                    {/* <Select.Option key="all" value="all">All</Select.Option> */}
                    {filteredMatrices.map(matrix => {
                        return <Select.Option key={matrix.matrixId} value={matrix.matrixId}>{matrix.name}</Select.Option>
                    })}
                </Select> : <></>}
                <Select showSearch style={{ width: 150 }} optionFilterProp="children" defaultValue={fetchedSample}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownClassName="dropdown" value={selectSample}  
                    onChange={(value)=>{
                        if (value === "all") {
            setSelectSample()
            setNewSample()
        } else {
            setSelectSample(value)
            setNewSample(value)
        }
                    }
                    
                    } placeholder='Select sample'>
                    <Select.Option key="all" value="all">All</Select.Option>
                    {filteredSample.map(item => item.sampleName)
                        .filter((value, index, self) => self.indexOf(value) === index).map(sample => {
                            return <Select.Option key={sample} value={sample} >{sample}</Select.Option>
                        })}
                </Select>
               {sampleType&& <Select  placeholder='Select type' onChange={(value)=> setSelectedType(value)} value={selectedType} style={{ width: 150 }}>
               <Select.Option key="All" value="All">All</Select.Option>
               {sampleType.map(item=>{
                return <Select.Option key={item.id} value={item.id}>{item.sampleTypeName}</Select.Option>
               })}

                </Select>}
                <Select style={{ width: 150 }} dropdownClassName="dropdown" onChange={(value)=>setStatusValue(value)} value={statusValue} placeholder='Select Status'>
                <Select.Option key="All" value="All">All</Select.Option>
                    <Select.Option key="Pass" value="Pass">Pass</Select.Option>
                    <Select.Option key="Fail" value="Fail">Fail</Select.Option>
                    <Select.Option key="-" value="-">NA</Select.Option>
                </Select>
                <RangePicker format={dateFormat} disabledDate={disabledDate} onChange={onDateChange}  className="date-picker" />
                <Button onClick={fetchData} style={{ backgroundColor: "#2E86C1", marginLeft: '5px', color: 'white', fontWeight: '700' }}>Fetch</Button>
            </span>}

            <Title level={5} style={{ marginBottom: '-10px'}}>
                    <Title level={5} style={{ color: 'white' , marginTop: '10px'}} id="breadcrumb">
                        <span onClick={() => {setShowBurnsets(true); setLineShow(false); setShowTable(false); setPieShow(false); setShow(false); setChartSampleName(''); setLoading(false) }}>Samples</span>
                        {chartSampleName && <span onClick={() => { setShowTable(true); setPieShow(false); setShow(false) }}>{chartSampleName}</span>}
                        {show && <span>Line Graph</span>} {pieShow && <span>Pie Chart</span>}
                        {lineShow &&fetchedSample&&<span>{fetchedSample} (Line Graph)</span>}
                    </Title>
                 
                 
               { showBurnsets&& fetchedSample && <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Line Graph" color="#1abc9c">
                            <Button type="link" icon={<FundTwoTone style={{ fontSize: 24}} onClick={showlinechart} />} />
                        </Tooltip>
                    </Link>}

                    {lineShow &&fetchedSample&&
                                <BurnChart name="historydata" selectedBase={selectedBase} digitsAfterDecimal={digitsAfterDecimal} selectedSampleName={newSample} selectedMatrix={selectedMatrix} deviceId={id} fromDate={fromDate} toDate={toDate} />
                            }


                    {burnsetColumn && showBurnsets && <Table scroll={{ x: 'max-content', y:350}} loading={loading} pagination={{ total: totalPages, onChange: (page, pageSize) => { loadBurnSets(page - 1, pageSize) } }} columns={burnsetColumn} dataSource={burnsets} rowClassName={(record, index) => index % 2 === 0 ? 'burnsets_row_light' : 'burnsets_row_dark'} rowKey="id"
                        onRow={(record, index) => {
                            return {
                                onClick: event => {
                                    onRowClick(event, record, index);
                                    setShowBurnsets(false)
                                },
                              //  onClick: event => {
                                //    onSingleClick(event, record, index);
                               //     setShowBurnsets(true)
                               //     fetchRowData()
                               // }
                            }
                        }} />

                    }
                

                
                    {burnResult &&
                        <>
                            {showTable &&
                                <BurnTable
                                    data={burnResult}
                                    key={burnResult.deviceId}
                                    column={column}
                                  //  selectedSampleG={selectedSampleG}
                                 //   columnGrade={columnGrade}
                                    burnHistoryData={burnHistoryData}
                                    selectedSample={selectedSample}
                                    columnh={columnh}
                                   // columnhG={columnhG}
                                    selectedSampleh={selectedSampleh}
                                   // selectedSamplehG={selectedSamplehG}
                                    showchart={showchart}
                                    showPieChart={showPieChart}
                                    id={id}
                                   // suitableGrade={suitableGrade}
                                    //loadBurnSetGrade={loadBurnSetGrade}
                                    gradeName={gradeName}
                                    setShow={setShow}
                                   // toggleBtn={toggleBtn}
                                   // setToggleBtn={setToggleBtn}
                                    horizontal={horizontal}
                                    setHorizontal={setHorizontal}
                                    vertical={vertical}
                                    setVertical={setVertical}
                                   // horizontalGrade={horizontalGrade}
                                   // setHorizontalGrade={setHorizontalGrade}
                                    //verticalGrade={verticalGrade}
                                   // setVerticalGrade={setVerticalGrade}
                                />
                            }
                            {show &&
                                <BurnChart name="historydata" selectedBase={chartBase} digitsAfterDecimal={digitsAfterDecimal} selectedSampleName={chartSampleName} selectedMatrix={chartMatrix} deviceId={id} fromDate={fromDate} toDate={toDate} />
                            }
                            {pieShow &&
                                <Card style={{ backgroundColor: '#E8DAEF', marginTop: '10px' }}>
                                    <BurnPieChart base={selectedBaseName} sampleBurnsDetail={sampleBurnsDetail} digitsAfterDecimal={digitsAfterDecimal} selectedSample={selectedSample} />
                                </Card>
                            }
                        </>
                    }
                </Title>
            
        </div>
        </>
    )
}


function BurnTable(props) {
    const [fileExcel, setFileExcel] = useState("EXCEL_VERTICAL")
    const [filePdf, setFilePdf] = useState("PDF_VERTICAL")


    let cols = props.column;
    //let colsG = props.columnGrade
    let data = props.selectedSample;
   // let dataG = props.selectedSampleG;
    let colsh = props.columnh;
   // let colshG = props.columnhG;
    let datah = props.selectedSampleh;
    //let datahG = props.selectedSamplehG;
   // let suitableGrade = props.suitableGrade;
    let burnHistoryData = props.burnHistoryData
    let id = props.id
   // let loadBurnSetGrade = props.loadBurnSetGrade
   // let gradeName = props.gradeName
  //  let toggleBtn = props.toggleBtn
  //  let setToggleBtn = props.setToggleBtn
    let horizontal = props.horizontal
    let setHorizontal = props.setHorizontal
    let vertical = props.vertical
    let setVertical = props.setVertical
   // let horizontalGrade = props.horizontalGrade
   // let setHorizontalGrade = props.setHorizontalGrade
   // let verticalGrade = props.verticalGrade
  //  let setVerticalGrade = props.setVerticalGrade
   // let setShowTable = props.setShowTable


    const verticalfun = () => {
        setVertical(true)
        setHorizontal(false)
       // setVerticalGrade(false)
      //  setHorizontalGrade(false)
        setFileExcel("EXCEL_VERTICAL")
        setFilePdf("PDF_VERTICAL")
    }

    const horizontalfun = () => {
        setVertical(false)
        setHorizontal(true)
       // setVerticalGrade(false)
       // setHorizontalGrade(false)
        setFileExcel("EXCEL_HORIZONTAL")
        setFilePdf("PDF_HORIZONTAL")
    }

   /* const verticalGradefun = () => {
        setVertical(false)
        setHorizontal(false)
        setVerticalGrade(true)
        setHorizontalGrade(false)
        setFileExcel("EXCEL_VERTICAL")
        setFilePdf("PDF_VERTICAL")
    }

    const horizontalGradefun = () => {
        setVertical(false)
        setHorizontal(false)
        setVerticalGrade(false)
        setHorizontalGrade(true)
        setFileExcel("EXCEL_HORIZONTAL")
        setFilePdf("PDF_HORIZONTAL")
    }
*/

    const downloadExcelFile = () => {
        DeviceDataService.getHistoryFileName(fileExcel, burnHistoryData.sampleName, burnHistoryData.matrixId, burnHistoryData.baseId, id, burnHistoryData.id).then(response => {
            DeviceDataService.downloadFile(response.data.fileName).then(res => {
                var blob = new Blob([res.data], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, "Burn.xlsx");
            })
        })
    }

    const downloadPdfFile = () => {
        DeviceDataService.getHistoryFileName(filePdf, burnHistoryData.sampleName, burnHistoryData.matrixId, burnHistoryData.baseId, id, burnHistoryData.id).then(response => {
            DeviceDataService.downloadFile(response.data.fileName).then(res => {
                var blob = new Blob([res.data], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, "Burn.pdf");
            })
        })
    }


  /*  const downloadGradeExcelFile = () => {
        DeviceDataService.getGradeHistoryFileName(fileExcel, burnHistoryData.sampleName, burnHistoryData.matrixId, burnHistoryData.baseId, id, burnHistoryData.id, gradeName).then(response => {
            DeviceDataService.downloadFile(response.data.fileName).then(res => {
                var blob = new Blob([res.data], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, "GradeBurn.xlsx");
            })
        })
    }

    const downloadGradePdfFile = () => {
        DeviceDataService.getGradeHistoryFileName(filePdf, burnHistoryData.sampleName, burnHistoryData.matrixId, burnHistoryData.baseId, id, burnHistoryData.id, gradeName).then(response => {
            DeviceDataService.downloadFile(response.data.fileName).then(res => {
                var blob = new Blob([res.data], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, "GradeBurn.pdf");
            })
        })
    }
*/


    return (
        <span>
                {/*{toggleBtn ? <> */}
                    <Link to="" style={{ fontSize: 24, float: 'right'}}>
                        <Tooltip placement="top" title="Excel" color="#1abc9c">
                            <Button type="link" icon={<FileExcelTwoTone style={{ fontSize: 24 }} onClick={downloadExcelFile} />} />
                        </Tooltip>
                    </Link>
                    <Link to="" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Pdf" color="#1abc9c">
                            <Button type="link" icon={<FilePdfTwoTone style={{ fontSize: 24 }} onClick={downloadPdfFile} />} />
                        </Tooltip>
                    </Link>

                    <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Horizontal" color="#1abc9c">
                            <Button type="link" onClick={horizontalfun} icon={<RightSquareTwoTone style={{ fontSize: 24 }} />} />
                        </Tooltip>
                    </Link>
                    <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Vertical" color="#1abc9c">
                            <Button type="link" onClick={verticalfun} icon={<UpSquareTwoTone style={{ fontSize: 24 }} />} />
                        </Tooltip>
                    </Link>
               {/* </> : <>
                    <Link to="" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Excel Grade">
                            <Button type="link" icon={<FileExcelTwoTone style={{ fontSize: 24 }} onClick={downloadGradeExcelFile} />} />
                        </Tooltip>
                    </Link>
                    <Link to="" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Pdf Grade">
                            <Button type="link" icon={<FilePdfTwoTone style={{ fontSize: 24 }} onClick={downloadGradePdfFile} />} />
                        </Tooltip>
                    </Link>
                   <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Horizontal Grade">
                            <Button type="link" onClick={horizontalGradefun} icon={<RightSquareTwoTone style={{ fontSize: 24 }} />} />
                        </Tooltip>
                    </Link>
                    <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                        <Tooltip placement="top" title="Vertical Grade">
                            <Button type="link" onClick={verticalGradefun} icon={<UpSquareTwoTone style={{ fontSize: 24 }} />} />
                        </Tooltip>
                    </Link>
                    <Link to="#" style={{ marginTop: '-10px', marginLeft: '5px', float: 'right' }}>
                        <Select size="small" value={gradeName} dropdownClassName="dropdown" onChange={loadBurnSetGrade} placeholder='Grade'>
                            {suitableGrade.map(el => {
                                return <Select.Option key={el} value={el}>{el}</Select.Option>
                            })}
                        </Select>
                    </Link> 
                </>
                }  


             <Link to="#" style={{ marginTop: '-8px', float: 'right' }}>
                    {toggleBtn ?
                        <Switch checkedChildren="CRM" unCheckedChildren="Grade" defaultChecked onChange={() => setToggleBtn(!toggleBtn)} onClick={() => {
                            setVerticalGrade(true);
                            setVertical(false)
                            props.setShow(false)
                            if (horizontal) {
                                setVerticalGrade(false)
                                setHorizontalGrade(true)
                                setHorizontal(false)
                            }
                        }} /> :
                        <Switch checkedChildren="CRM" unCheckedChildren="Grade" onChange={() => setToggleBtn(!toggleBtn)} onClick={() => {
                            setVertical(true);
                            setVerticalGrade(false)
                            props.setShow(false)
                            if (horizontalGrade) {
                                setVertical(false)
                                setHorizontal(true)
                                setHorizontalGrade(false)
                            }
                        }} />
                    }
                </Link> */}

                <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                    <Tooltip placement="top" title="Line Graph" color="#1abc9c">
                        <Button type="link" icon={<FundTwoTone style={{ fontSize: 24}} onClick={props.showchart} />} />
                    </Tooltip>
                </Link>
                <Link to="#" style={{ fontSize: 24, float: 'right' }}>
                    <Tooltip placement="top" title="Pie Chart" color="#1abc9c">
                        <Button type="link" icon={<PieChartTwoTone style={{ fontSize: 24 }} onClick={props.showPieChart} />} />
                    </Tooltip>
                </Link>
            <div style={{marginTop:'-10px'}}>
            {vertical && <Table size="small" style={{marginBottom:'20px'}}  scroll={{ x: 'max-content', y: 400 }} pagination={false} columns={cols.filter(el => !el.dataIndex.includes("burnh") && !el.dataIndex.includes("meanh") && !el.dataIndex.includes("elementId"))} dataSource={data} rowKey="element" rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} />}
            {horizontal && <Table size="small" style={{marginBottom:'20px'}} scroll={{ x: 'max-content', y: 400 }} pagination={false} columns={colsh} dataSource={datah} rowKey="element" rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} />}
          {/*
          {verticalGrade && <Table size="small" pagination={false} columns={colsG} dataSource={dataG} rowKey="element" rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} />}
          {horizontalGrade && <Table size="small" pagination={false} columns={colshG} dataSource={datahG} rowKey="element" rowClassName={(record, index) => index % 2 === 0 ? 'burn_row_light' : 'burn_row_dark'} />}
            */}
     </div>
        </span>

    )
}




