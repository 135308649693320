import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DeviceDataService from "../../services/DeviceDataService";
import {Table,Select,Typography} from "antd";
const { Title } = Typography;
const {Option} = Select;

export default function DeviceMatrixSpectrumView(props) {
    let {id} = useParams();

    const [spectrums, setSpectrums] = useState([])
    const [spectrumId, setSpectrumId] = useState([])
    const [elementMap, setElementMap] = useState({})
    const [selectMatrix,setSelectMatrix]=useState()
    const [bases,setBases]=useState([])
    const [baseSymbol,setBaseSymbol]=useState()

    useEffect(() => {
        DeviceDataService.loadElements(id).then(response => {
            let elements = response.data;
            for (let i = 0; i < elements.length; i++) {
                elementMap[elements[i].elementId] = elements[i]
            }
            setElementMap(elementMap)
        });

        DeviceDataService.loadMatrixTypes(id).then(response => {
            DeviceDataService.loadBases(id).then(res=>{
           /* let matid = []
            response.data.map(el => {
                matid.push(el.matrixId)
            })
            */
            setBases(res.data)
            let filtBase=res.data.filter(el=>el.baseId===response.data[0].baseId)
            setBaseSymbol(filtBase[0].symbol)
            setSelectMatrix(response.data[0].name)
            setSpectrumId(response.data)
        })
            DeviceDataService.loadMatrixSpectrums(id, response.data[0].matrixId).then(res => {
                setSpectrums(res.data)
            })
        })
    }, [id])


    function handleChange(value,key) {
        let keyData = key.key.split(",")
         let filtBase=bases.filter(el=>el.baseId===parseInt(keyData[1]))
         setBaseSymbol(filtBase[0].symbol)
        DeviceDataService.loadMatrixSpectrums(id, value).then(response => {
            setSpectrums(response.data)
        })
    }


    const columns = [
        {
            title: "Element",
            key: "element",
            dataIndex: "elementId",
            width:100,
            render: elementId => {
                return <>{elementMap[elementId] === undefined ? "-" : elementMap[elementId].symbol}</>
            }
        },
        {
            title: "Low",
            key: "concentrationLow",
            width:100,
            dataIndex: "concentrationLow",
        },
        {
            title: "High",
            key: "concentrationHigh",
            width:100,
            dataIndex: "concentrationHigh",
        },
        {
            title: "#Decimals",
            key: "decimals",
            width:100,
            dataIndex: "decimals",
        },
     /*   {
            title: "CE",
            key: "ce",
            width:60,
            dataIndex: "ce",
        }, */
        {
            title: "Sequence",
            key: "sequence",
            width:100,
            dataIndex: "sequence",
        },
        {
            title: "Custom Low",
            key: "customLow",
            width:120,
            dataIndex: "concentrationLow",
        },
        {
            title: "Custom High",
            key: "customHigh",
            width:120,
            dataIndex: "concentrationHigh",
        },
        {
            title: "Lower Clipping",
            key: "showLowerClipping",
            width:100,
            dataIndex: "showLowerClipping",
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        },
        {
            title: "Upper Clipping",
            key: "showUpperClipping",
            width:100,
            dataIndex: "showUpperClipping",
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        },
        {
            title: "Check Custom",
            key: "checkWithCustom",
            dataIndex: "checkWithCustom",
            width:100,
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        },
        {
            title: "Digits after decimal",
            key: "digitsAfterDecimal",
            width:120,
            dataIndex: "digitsAfterDecimal",
        },
     /*   {
            title: "Percentage",
            key: "percentage",
            dataIndex: "percentage",
            width:120,
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        }, 
        {
            title: "Direct Base",
            key: "directBase",
            dataIndex: "directBase",
            width:100,
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        },
        {
            title: "Sol in Sol?",
            key: "solInSol",
            dataIndex: "solInSol",
            width:100,
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        },
        {
            title: "#SUS",
            key: "noOfSUSusedForSTD",
            dataIndex: "noOfSUSusedForSTD",
            width:100,
        },  */
        {
            title: "Show",
            key: "showSpectrum",
            dataIndex: "showSpectrum",
            width:100,
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        },
        {
            title: "Show on Report",
            key: "showOnReport",
            dataIndex: "showOnReport",
            width:100,
            render: show => {
                return <> {show ? "Yes" : "No"}</>
            }
        }
    ]
   
    return (
        <div>
         <Title level={5}><span>Matrix : {selectMatrix&& <Select placeholder="Select Matrix" dropdownClassName="dropdown" style={{width: 150,marginBottom:'10px'}}  onChange={(value, key)=>handleChange(value, key)} defaultValue={selectMatrix}>
                {
                    spectrumId.map(el => {
                        return (
                            <Option key={[el.matrixId,el.baseId]} value={el.matrixId}>{el.name}</Option>
                        )
                    })
                }
            </Select>}
            </span>
            {baseSymbol&& <span style={{marginLeft:'20px'}}>
           | Base : <span>{baseSymbol}</span>
          </span>}
          </Title>
        
            <Table style={{marginBottom:'20px'}} scroll={{ x:'max-content',y:600 }} dataSource={spectrums} columns={columns} pagination={false} rowClassName={(record, index) => index % 2 === 0 ? 'row_light' :  'row_dark'}/>
        </div>
    );
}