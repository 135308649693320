import axios from "./axios";

class DashboardService {

    static Instance() {
        return new DashboardService();
    }

    loadSummary() {
        return axios.get('/api/dashboard/summary');
    }
    loadDeviceDetails(){
        return axios.get('/api/devicetype')
    }
    loadDeviceData(id){
        return axios.get(`api/device/${id}/devicetotaldata`)
    }
    loadSingleDeviceDetails(modelName){
        return axios.get(`/api/devicetype/${modelName}`)

    }
    downloadFile(fileName) {
        return axios.get(`api/files/downloadProductFile/${fileName}`,{
            responseType:  'blob',
        })
    }
    loadDashboardData(id,initialDate,lastDate){
        return axios.get(`api/device/${id}/dashboard?from=${initialDate}&to=${lastDate}`)
    }
    loadFaq(){
        return axios.get("api/faq")
    }
}

export default DashboardService.Instance()