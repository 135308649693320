import axios from "./axios";
import { authHeader } from "../helpers/auth-header";
import { config } from "../helpers/config";

class RoleService {
  static Instance() {
    return new RoleService();
  }
  getAllPrivilegeTypes() {
    return axios.get("/api/privilege/get/list");
  }

  addRole(role, flag) {
    if (flag) {
      return axios.put("/api/user/role", role);
    }
    return axios.post("/api/user/role", role);
  }

  getRole(RoleId) {
    return axios.get("/api/user/role/" + RoleId);
  }

  removeRole(roleId) {
    return axios.delete("/api/user/role/" + roleId);
  }
}

export default RoleService.Instance();
