import axios from "./axios";

class DeviceService {
  static Instance() {
    return new DeviceService();
  }

  getActiveDevices() {
    return axios.get("api/device/all/active");
  }
  
  getInActiveDevices() {
    return axios.get("api/device/all/inactive");
  }

  getDevice(id) {
    return axios.get("/api/device?id=" + id);
  }

  createDevice(device) {
    if (device.id) {
      return axios.put("/api/device", device);
    }
    return axios.post("/api/device", device);
  }

  markActive(id) {
    return axios.post("/api/device/markActive/?deviceId=" + id);
  }

  markInactive(id) {
    return axios.post("/api/device/markInactive/?deviceId=" + id);
  }

  getDeviceToken(id) {
    return axios.get(`/api/device/getToken/${id}`);
  }

  getDevices() {
    return axios.get("/api/devicetype");
  }

  getModelName() {
    return axios.get("/api/device/modelNames");
  }
  syncDataFromSaleforces() {
    return axios.get("/api/crm/immediateDataFormSalesforces", {timeout: 60000});
  }

  getAllActiveDevices(deviceSerial, searchText, pageNumber, size) {
    return axios.get("api/device/all/Allactive?searchKey=" + searchText + "&page=" + pageNumber + "&size=" + size + "&deviceSerial=" + deviceSerial);
  }
  getAllInActiveDevices(deviceSerial, searchText, pageNumber, size) {
    return axios.get("api/device/all/Allinactive?searchKey=" + searchText + "&page=" + pageNumber + "&size=" + size + "&deviceSerial=" + deviceSerial);
  }

  getVerifyDevices(deviceSerialNumber) {
    return axios.get("/api/device/verifydeviceserialnumber?serialnumber=" + deviceSerialNumber);
  }

  getChangeDevicePairingStatus(id, qrcode) {
    return axios.get(`/api/device/${id}/verifyqrcode?qrCode=` + qrcode);
  }

  postDownloadAnalystApp(email, user, serialNumber) {
    return axios.post("/public/sendsetupdownloadlink?email=" + email + "&serialNumber=" + serialNumber, user);
  }
  
  getMappedDeviceName(deviceName) {
    return axios.get("/api/devicetype/mappednames/" + deviceName);
  }

  listMappedDeviceName() {
    return axios.get("/api/devicetype/mappednames");
  }

  listMappedDeviceNameFromLegacyDeviceNames(modelNames) {
    console.log({modelNames});
    return axios.get("/api/devicetype/mappednames/get", modelNames);
  }

  // getClientModelNames () {
  //   return axios.get("/api/device/modelNames");
  // }

  uploadDeviceModelImage(modelName, file) {
    return axios.post(`/api/devicetype/${modelName}/updatemodelimage`, file, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
  }

}

export default DeviceService.Instance();
