import React, { useState, useEffect } from "react";
//import AnimatedTitle from "./AnimatedTitle";
import { Column } from '@ant-design/plots';
import { Button, Card, Cascader, Col, Image, notification, Row, Spin, Typography} from "antd";
import DashboardService from "../services/DashboardService";
import DeviceService from "../services/DeviceService";
import moment from 'moment';
import DeviceDataService from "../services/DeviceDataService";
import Diagnostic from "./user/Diagnostic";
import {buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import VisibilitySensor from "react-visibility-sensor";
import ReactSpeedometer from "react-d3-speedometer";
import Carousel from "react-elastic-carousel";
import RealTimeDiagnosticView from "./user/RealTimeDiagnosticView";
import { useNavigate } from "react-router-dom";
import mpaLogo from '../extra/images/MetaCloud_New_Logo.jpg'
import UserService from "../services/UserService";

const attachDeviceText = "You have no devices attached. Click below to add a device.";
const viewPlanText = "You don't have any plan, please choose your plan";

const { Meta } = Card;

export default function DashboardView() {
    const dateFormat = 'YYYY-MM-DD';
    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState()
    //const [filteredYear, setFilteredYear] = useState(2021)
    const [monthlySamples, setMonthlySamples] = useState([])
    const [baseSamples, setBaseSamples] = useState([])
    const [matrixSamples, setMatrixSamples] = useState([])
    const [avgBurnsSamples, setAvgBurnsSamples] = useState([])
    //const [bases, setBases] = useState([])
    const [initialDate, setInitialDate] = useState(moment(new Date(new Date().getFullYear(), 0, 1)).format(dateFormat))
    const [lastDate, setLastDate] = useState(moment(new Date(new Date().getFullYear(), 11, 31)).format(dateFormat))
    const [type1, setType1] = useState("")
    const [type2, setType2] = useState("")
    const [type3, setType3] = useState("primary")
    const [deviceData, setDeviceData] = useState()
    const [loading , setLoading] = useState(false);
    const [userPendingStatus, setUserPendingStatus] = useState(true);
    const [orgDetails, setOrgDetails] = useState({});
    const [subscription, setSubscription] = useState("");
    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    let monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


    useEffect(() => {
        refresh();
        fetchUserPendingStatus();
    }, [initialDate, lastDate, deviceId])

    useEffect(() => {}, [loading])

    // useEffect(() => {
    //     setLoading(true)
    //     let monthwisesamples = []
    //     let basewisesample = []
    //     let matrixwisesample=[]
    //     let avgBurns = []
    //     DashboardService.loadDeviceData(deviceId).then(res => {
    //            setDeviceData(res.data)
    //     });
    //     DashboardService.loadDashboardData(deviceId, initialDate, lastDate).then(res => {
    //          DeviceDataService.loadBases(deviceId).then(resp => {
    //          //   setBases(resp.data)
    //             let a = 0;
    //             for (let i = 0; i < Object.keys(res.data.baseCountResponses).length; i++) {
    //                 const baseresult = resp.data.find(({ baseId }) => baseId === parseInt(Object.keys(res.data.baseCountResponses)[i]));
    //                 if (typeof (baseresult) === "undefined") {
    //                     a++
    //                     basewisesample.push({ type: `NA${a}`, samples: Object.values(res.data.baseCountResponses)[i] })
    //                 } else {
    //                     basewisesample.push({ type: baseresult.symbol, samples: Object.values(res.data.baseCountResponses)[i] })
    //                 }
    //             }

    //             DeviceDataService.loadMatrixTypes(deviceId).then(ress => {
    //                 let a = 0;
    //                 for (let i = 0; i < Object.keys(res.data.matrixCountResponses).length; i++) {
    //                     const matrixresult = ress.data.filter(({ baseId,matrixId }) => baseId === Object.values(res.data.matrixCountResponses[i])[1] && matrixId===Object.values(res.data.matrixCountResponses[i])[0])[0];
    //                     const matrixbaseresult = resp.data.filter(({ baseId }) => baseId === Object.values(res.data.matrixCountResponses[i])[1])[0];
    //                     if (typeof (matrixresult) === "undefined") {
    //                         a++
    //                         matrixwisesample.push({ type: `NA${a}`, samples: Object.values(res.data.matrixCountResponses[i])[2] })
    //                     } else {
    //                         matrixwisesample.push({ type: matrixresult.name+` (${matrixbaseresult.symbol})`, samples:  Object.values(res.data.matrixCountResponses[i])[2] })
    //                     }
    //                 }
    //                  setMatrixSamples(matrixwisesample)
    //                 a = 0
    //             })

    //             setBaseSamples(basewisesample)
    //             a = 0
    //         })

           

    //         for (let i = 0; i < res.data.monthlysamples.length; i++) {
    //             monthwisesamples.push({ type: monthArr[i], samples: res.data.monthlysamples[i] })
    //         }
    //         setMonthlySamples(monthwisesamples)
    //         for (let i = 0; i < Object.keys(res.data.averageSampleCountResponses).length; i++) {
    //             avgBurns.push({ type: Object.keys(res.data.averageSampleCountResponses)[i], burns: Object.values(res.data.averageSampleCountResponses)[i] })
    //         }
    //          setAvgBurnsSamples(avgBurns)
    //     })
    //     setLoading(false)
    // }, [initialDate, lastDate, deviceId])


    const fetchUserPendingStatus = () => {
        UserService.getUserProfile().then(res => {
            setUserPendingStatus(res.data.pendingStatus)
            if(res.data.pendingStatus) {
                UserService.getUserOrgAdminDetails().then(res => {
                    setOrgDetails(res.data[0]);
                })
            }
        })
    }

    const refresh = () => {
        setLoading(true);
        let monthwisesamples = []
        let basewisesample = []
        let matrixwisesample=[]
        let avgBurns = []
        UserService.getUserProfile().then(res => {
            let user = res.data;
            setUser(user);
            setSubscription(user.subscription)
            if(user.subscription !== null || !user.client){
                DeviceService.getActiveDevices().then((response) => {
                    DeviceService.listMappedDeviceName().then(names => {
                        let customNames = {};
                        names.data.forEach(name => customNames[name.legacyName] = name.customName);
                        let customDevices = response.data.map(device => {
                          return {...device, deviceName: customNames[device.deviceName]}
                        })
                        setDevices(customDevices);
                    })
                    setDeviceId(response.data[0].id)
                     DashboardService.loadDeviceData(response.data[0].id).then(res => {
                        setDeviceData(res.data)
                    })
                    DashboardService.loadDashboardData(response.data[0].id, initialDate, lastDate).then(res => {
                        DeviceDataService.loadBases(response.data[0].id).then(resp => {
                          //  setBases(resp.data)
                            for (let i = 0; i < Object.keys(res.data.baseCountResponses).length; i++) {
                                const baseresult = resp.data.find(({ baseId }) => baseId === parseInt(Object.keys(res.data.baseCountResponses)[i]));
                                basewisesample.push({ type: baseresult.symbol, samples: Object.values(res.data.baseCountResponses)[i] })
                            }
                            DeviceDataService.loadMatrixTypes(response.data[0].id).then(ress => {
                               //  let a = 0;
                                 for (let i = 0; i < Object.keys(res.data.matrixCountResponses).length; i++) {
                             
        
                                    const matrixresult = ress.data.filter(({ baseId,matrixId }) => baseId === Object.values(res.data.matrixCountResponses[i])[0] && matrixId===Object.values(res.data.matrixCountResponses[i])[1])[0];
                                    const matrixbaseresult = resp.data.filter(({ baseId }) => baseId === Object.values(res.data.matrixCountResponses[i])[0])[0];     
                                        matrixwisesample.push({ type: matrixresult.name+` (${matrixbaseresult.symbol})`, samples:  Object.values(res.data.matrixCountResponses[i])[2] })
                                    
                                }
                                 setMatrixSamples(matrixwisesample)
                             //   a = 0
                            })
            
        
                            setBaseSamples(basewisesample)
                        })
                        for (let i = 0; i < res.data.monthlysamples.length; i++) {
                            monthwisesamples.push({ type: monthArr[i], samples: res.data.monthlysamples[i] })
                        }
                        setMonthlySamples(monthwisesamples)
                      
                        for (let i = 0; i < Object.keys(res.data.averageSampleCountResponses).length; i++) {
                            avgBurns.push({ type: Object.keys(res.data.averageSampleCountResponses)[i], burns: Object.values(res.data.averageSampleCountResponses)[i] })
                        }
                         setAvgBurnsSamples(avgBurns)
                         setLoading(false)
                    })
                }
        
                ).catch(err => {
                    setLoading(false);
                });
            }
            else{
                setLoading(false);
            }
        })
        
    };
  
    const monthFunction = () => {
        setType1("primary")
        setType2("")
        setType3("")
         setInitialDate(moment(firstDay).format(dateFormat))
        setLastDate(moment(lastDay).format(dateFormat))
    }


    const quarterFunction = () => {
        setType1("")
        setType2("primary")
        setType3("")
        var now = new Date();
        var quarter = Math.floor((now.getMonth() / 3));
        var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
        var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
         setInitialDate(moment(firstDate).format(dateFormat))
        setLastDate(moment(endDate).format(dateFormat))
    }

    const yearFunction = () => {
        setType1("")
        setType2("")
        setType3("primary")
        let firstDate = new Date(new Date().getFullYear(), 0, 1);
        let endDate = new Date(new Date().getFullYear(), 11, 31)
        setInitialDate(moment(firstDate).format(dateFormat))
        setLastDate(moment(endDate).format(dateFormat))
    }


    let deviceModel = [];
    for (let i = 0; i < devices.length; i++) {
        deviceModel.push(devices[i].deviceName);
    }

    let uniqueModel = [...new Set(deviceModel)];

    let sortedDevices = [];
    for (let i = 0; i < uniqueModel.length; i++) {
        const fi = devices.filter((el) => el.deviceName === uniqueModel[i]);
        sortedDevices.push({ model: uniqueModel[i], details: fi });
    }

   
    const options=[]
for(let i=0;i<sortedDevices.length;i++){
    options.push({value:sortedDevices[i].details[0].deviceName,label:sortedDevices[i].details[0].deviceName})
    let child=[]
    for(let j=0;j<sortedDevices[i].details.length;j++){
child.push({value:sortedDevices[i].details[j].id,label:sortedDevices[i].details[j].serialNumber})
    }
    options[i].children=child

}


  /*  const options = [
        (sortedDevices || []).map((item, index) => (
            (item.details || []).map((element, index) => (
                {
                    value: element.id,
                    label: element.deviceName,
                    children: [
                        {
                            value: element.id,
                            label: element.serialNumber,
                        },
                    ],
                }
            ))
        ))
    ];

console.log(options)
    const arrangedoptions = []
    for (let i = 0; i < options[0].length; i++) {
        arrangedoptions.push(options[0][i][0])
    }

console.log(arrangedoptions)
*/
    const onChange = (value) => {
        setDeviceId(value[1])
    };


   // const textColor = '#AAA'

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 }
      ];

    const showAccessDeniedMessage = () => {
        if(userPendingStatus) {
            notification.error({
                type: "info",
                description: `Please ask your Org Admin [${orgDetails.name}] to allow access from ‘The Analyst’ application.`,
                duration: 2,
                style: {padding: 25}
            });
        }
    }

     return (
        <div>
            { /*    <AnimatedTitle />*/}
            <div >
            {!loading ?
                deviceData ? <Row  gutter={[16,8]} justify="center">
                    <Col xl={5} lg={5} md={12} sm={12} xs={24}>
                    <Card style={{backgroundColor:'#A9CCE3'}} >
                    <Row gutter={[8,8]}>
                        <Col onClick={showAccessDeniedMessage} span={24} > {options.length !== 0 && <Cascader style={{width:'100%'}} allowClear={false} options={options} defaultValue={[options[0].value, options[0].children[0].value]} onChange={onChange} disabled={userPendingStatus} placeholder="Please select" />}
                        </Col>
                        <Col span={24}><Button disabled={userPendingStatus} type={type1} style={{width:'100%'}}  onClick={userPendingStatus ? showAccessDeniedMessage : monthFunction}>This Month</Button></Col>
                        <Col span={24}><Button disabled={userPendingStatus} type={type2} style={{width:'100%'}}  onClick={userPendingStatus ? showAccessDeniedMessage : quarterFunction}>This Quarter</Button></Col>
                        <Col span={24}><Button disabled={userPendingStatus} type={type3} style={{width:'100%'}}  onClick={userPendingStatus ? showAccessDeniedMessage : yearFunction}>This Year</Button></Col>
                    </Row>
                   </Card>
                    </Col>
                    {deviceData&& <> <Col xl={6} lg={6} md={12} sm={12} xs={24} style={{display:'flex', justifyContent:'center', marginBottom:'-12vh'}}>
                        <ReactSpeedometer
                            maxValue={1000}
                            value={1000}
                            needleColor="red"
                            startColor="#229954"
                            segments={10}
                            endColor="#CB4335"
                           // textColor="white"
                            currentValueText="Local Storage: 1 GB"
                            width={300}
                            // fluidWidth={true}
                            needleHeightRatio={0.9}
                            needleTransitionDuration={2000}
                        /> 
                    </Col>
                     <Col xl={6} lg={6} md={12} sm={12} xs={24} style={{display:'flex', justifyContent:'center', marginBottom:'-12vh'}}>
                        <ReactSpeedometer
                            maxValue={1000}
                            value={`${parseFloat(deviceData.totalBurns*10/1000000).toFixed(2)}`*1000}
                            needleColor="red"
                            startColor="#229954"
                            segments={10}
                            endColor="#CB4335"
                           // textColor="white"
                            currentValueText={`Used Space: ${parseFloat(deviceData.totalBurns*10/1000000).toFixed(2)} GB`}
                            needleTransitionDuration={2000}
                        />
                    </Col> 
                    <Col xl={6} lg={6} md={12} sm={12} xs={24} style={{display:'flex', justifyContent:'center', marginBottom:'-12vh'}}>
                        <ReactSpeedometer
                            maxValue={1000}
                            value={`${1-parseFloat(deviceData.totalBurns*10/1000000).toFixed(2)}`*1000}
                            needleColor="red"
                            startColor="#229954"
                            segments={10}
                            endColor="#CB4335"
                          //  textColor="white"
                            currentValueText={`Left Space: ${1-parseFloat(deviceData.totalBurns*10/1000000).toFixed(2)} GB`}
                            needleTransitionDuration={2000}
                        />
                    </Col></>}
                </Row> : 
                <Card loading={loading} style={{marginTop:'150px'}} bordered={false}>
                        <Row justify="center" align="middle">
                            <Col span={8} style={{display:'flex', justifyContent:'center', marginBottom:'50px'}}>
                                <Image preview={false} alt="logo" src={mpaLogo} />
                            </Col>
                            <Col span={24} style={{justifyContent:'center', display:'flex'}}>
                                <Typography.Title level={4}>
                                    {user && user.roles.includes('New User') ? attachDeviceText : subscription !== null ? attachDeviceText : viewPlanText}
                                </Typography.Title>
                            </Col>
                            <Col span={24} style={{justifyContent:'center', display:'flex', marginTop:'50px'}}>
                                <Button 
                                    size="large" 
                                    shape='round' 
                                    type="primary" 
                                    onClick={() => user && user.roles.includes('New User') ? navigate('/myDevices') : subscription !== null ? navigate('/myDevices') : navigate("/admin/subscription")}
                                >
                                    {user && user.roles.includes('New User') ? "Add Device" : subscription !== null ? "Add Device" : "View Plans"}
                                </Button>
                            </Col>
                        </Row>
                </Card> :
                <>
                    <Card loading={loading}  bordered={false}></Card>
                    <Card  bordered={false}>
                        <Row justify="center">
                            <Col span={8} style={{display:'flex', justifyContent:'center'}}>
                                <Image preview={false} alt="logo" src={mpaLogo} />
                            </Col>
                        </Row>
                    </Card>
                    <Card loading={loading}  bordered={false}></Card>
                </>
                }
                
               {/* {deviceData&& <div className="carousel" style={{marginTop:'-12vh',marginBottom:'15px'}}> */}
               {deviceData&& <div className="carousel" style={{marginBottom:'15px'}}>
              <div className="carousel-wrapper">
             {baseSamples&&monthlySamples&&avgBurnsSamples&&matrixSamples&&<Carousel breakPoints={breakPoints} pagination={false} >
             <Card className="carousel-card" style={{border:'2px solid lightGrey'}}>
                                <SampleMonth monthlySamples={monthlySamples} />
                                <Meta style={{ textAlign: 'center', marginTop: '10px' }} title={<div>Samples : Month-wise</div>} />
                        </Card>
                        <Card  className="carousel-card" style={{border:'2px solid lightGrey'}}>
                                <SampleBase baseSamples={baseSamples} />
                                <Meta style={{ textAlign: 'center', marginTop: '10px' }} title={<div>Samples : Base-wise</div>} />
                            </Card>
                            <Card  className="carousel-card" style={{border:'2px solid lightGrey'}}>
                                <SampleMatrix matrixSamples={matrixSamples} />
                                <Meta style={{ textAlign: 'center', marginTop: '10px' }} title={<div>Samples : Matrix-wise</div>} />
                            </Card>
                            <Card  className="carousel-card" style={{border:'2px solid lightGrey'}}>
                                <SampleAngBurns avgBurnsSamples={avgBurnsSamples} />
                                <Meta style={{ textAlign: 'center', marginTop: '10px' }} title={<div>Average Burns : sample-wise</div>} />
                            </Card>
        </Carousel>}
      </div>
    </div>}

             
                {deviceData&&deviceId && <RealTimeDiagnosticView id={deviceId} display="none" />}
                {deviceData && <Row style={{ marginBottom: '30px' }}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card align="center" style={{backgroundColor:'#B7242A'}}>
                            <div style={{ width: 120, height: 120 }}>
                                <VisibilitySensor>
                                    {({ isVisible }) => {
                                        const totalSample = isVisible ? deviceData.totalSamples : 0;
                                        return (
                                            <AnimatedProgressProvider
                                                valueStart={0}
                                                valueEnd={totalSample}
                                                duration={3}
                                                easingFunction={easeQuadInOut}
                                            >
                                                {value => {
                                                    const roundedValue = Math.round(value);
                                                    return (

                                                        <CircularProgressbarWithChildren
                                                            value={value}
                                                            text={`${roundedValue}`}
                                                            maxValue={`${deviceData.totalSamples}`}
                                                            styles={buildStyles({ pathTransition: "none", textColor: "white", pathColor: "#229954", trailColor: "white"})}
                                                        >
                                                            <div style={{ marginTop: 50,color:'white' }}>
                                                                <strong>Samples</strong>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    );
                                                }}
                                            </AnimatedProgressProvider>
                                        );
                                    }}
                                </VisibilitySensor>
                            </div>
                        </Card>
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={12} xs={12} >
                        <Card align="center" style={{backgroundColor:'#4A235A'}}>
                            <div style={{ width: 120, height: 120 }}>
                                <VisibilitySensor>
                                    {({ isVisible }) => {
                                        const totalBurns = isVisible ? deviceData.totalBurns : 0;
                                        return (
                                            <AnimatedProgressProvider
                                                valueStart={0}
                                                valueEnd={totalBurns}
                                                duration={3}
                                                easingFunction={easeQuadInOut}
                                            >
                                                {value => {
                                                    const roundedValue = Math.round(value);
                                                    return (
                                                        <CircularProgressbarWithChildren
                                                            value={value}
                                                            text={`${roundedValue}`}
                                                            maxValue={`${deviceData.totalBurns}`}
                                                            styles={buildStyles({ pathTransition: "none", textColor: "white", pathColor: "#229954", trailColor: "white" })}
                                                        >
                                                            <div style={{ marginTop: 50,color:'white' }}>
                                                                <strong>Burns</strong>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    );
                                                }}
                                            </AnimatedProgressProvider>
                                        );
                                    }}
                                </VisibilitySensor>
                            </div>
                        </Card>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card align="center" style={{backgroundColor:'#1f618d'}}>
                            <div style={{ width: 120, height: 120 }}>
                                <VisibilitySensor>
                                    {({ isVisible }) => {
                                        const totalBases = isVisible ? deviceData.totalBases : 0;
                                        return (
                                            <AnimatedProgressProvider
                                                valueStart={0}
                                                valueEnd={totalBases}
                                                duration={3}
                                                easingFunction={easeQuadInOut}
                                            >
                                                {value => {
                                                    const roundedValue = Math.round(value);
                                                    return (
                                                        <CircularProgressbarWithChildren
                                                            value={value}
                                                            text={`${roundedValue}`}
                                                            maxValue={`${deviceData.totalBases}`}
                                                            styles={buildStyles({ pathTransition: "none" , textColor: "white", pathColor: "#229954", trailColor: "white"})}
                                                        >
                                                            <div style={{ marginTop: 50,color:'white' }}>
                                                                <strong>Bases</strong>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    );
                                                }}
                                            </AnimatedProgressProvider>
                                        );
                                    }}
                                </VisibilitySensor>
                            </div>
                        </Card>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Card align="center" style={{backgroundColor:'rgb(50, 15, 92)'}}>
                            <div style={{ width: 120, height: 120 }}>
                                <VisibilitySensor>
                                    {({ isVisible }) => {
                                        const totalMatrixs = isVisible ? deviceData.totalMatrixs : 0;
                                        return (
                                            <AnimatedProgressProvider
                                                valueStart={0}
                                                valueEnd={totalMatrixs}
                                                duration={3}
                                                easingFunction={easeQuadInOut}
                                            >
                                                {value => {
                                                    const roundedValue = Math.round(value);
                                                    return (
                                                        <CircularProgressbarWithChildren
                                                            value={value}
                                                            text={`${roundedValue}`}
                                                            maxValue={`${deviceData.totalMatrixs}`}
                                                            styles={buildStyles({ pathTransition: "none" , textColor: "white", pathColor: "#229954", trailColor: "white"})}
                                                        >
                                                            <div style={{ marginTop: 50 ,color:'white'}}>
                                                                <strong>Matrix</strong>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    );
                                                }}
                                            </AnimatedProgressProvider>
                                        );
                                    }}
                                </VisibilitySensor>
                            </div>
                        </Card>
                    </Col>
                </Row>
                }
            </div>
        </div>
    );
}



const SampleMonth = (props) => {
    let monthlySamples = props.monthlySamples;
    const config = {
        xField: 'type',
        yField: 'samples',
        color: '#F1948A  ',
       /* label: {
            position: 'middle',
            style: {
                fill: 'white',
                opacity: 1,
            },
        }, */
        xAxis: {
            label: {
                style: {
                  fill: 'black',
                    fontSize: 10,
                },
                autoHide: true,
                autoRotate: false,
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontSize: 10,
                }
            },
        },
        meta: {
            type: {
                alias: 'type',
            },
            samples: {
                alias: 'samples',
            },
        },
    };
    return <Column style={{ height: '20vh', width:'21vw' }}  data={monthlySamples} {...config} />;
};

const SampleBase = (props) => {
    let baseSamples = props.baseSamples;
    const config = {
        xField: 'type',
        yField: 'samples',
        color: '#BF7AC5',
      /*  label: {
            position: 'middle',
            style: {
                fill: 'white',
                opacity: 1,
            },
        }, */
        xAxis: {
            label: {
                style: {
                   fill: 'black',
                    fontSize: 10,
                },
                autoHide: true,
                autoRotate: false,
            },
        },
        yAxis: {
            label: {
                style: {
                   fill: 'black',
                    fontSize: 10,
                }
            },
        },
        meta: {
            type: {
                alias: 'type',
            },
            samples: {
                alias: 'Samples',
            },
        },
    };
    return <Column style={{ height: '20vh', width:'21vw' }} data={baseSamples} {...config} />;
};


const SampleMatrix = (props) => {
    let matrixSamples = props.matrixSamples;
    const config = {
        xField: 'type',
        yField: 'samples',
        color: '#A9DFBF ',
      /*  label: {
            position: 'middle',
            style: {
                fill: 'white',
                opacity: 1,
            },
        }, */
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontSize: 10,
                },
                autoHide: true,
                autoRotate: false,
            },
        },
        yAxis: {
            label: {
                style: {
                  fill: 'black',
                    fontSize: 10,
                }
            },
        },
        meta: {
            type: {
                alias: 'type',
            },
            samples: {
                alias: 'Samples',
            },
        },
    };
    return <Column style={{ height: '20vh' , width:'21vw'}} data={matrixSamples} {...config} />;
};

const SampleAngBurns = (props) => {
    let avgBurnsSamples = props.avgBurnsSamples;
     const config = {
        xField: 'type',
        yField: 'burns',
        color: '#F7DC6F ',
      /*  label: {
            position: 'middle',
            style: {
                fill: 'white',
                opacity: 1,
            },
        }, */
        xAxis: {
            label: {
                style: {
                   fill: 'black',
                    fontSize: 10,
                },
                autoHide: true,
                autoRotate: false,
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontSize: 10,
                }
            },
        },
        meta: {
            type: {
                alias: 'Sample Name',
            },
            burns: {
                alias: 'Average burns',
            },
        },
    };
    return <Column style={{ height: '20vh', width:'21vw' }} data={avgBurnsSamples} {...config} />;
};



