import React from "react";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, message, Modal, Row, Table, Tag } from "antd";
import RoleService from "../services/RoleService";
import UserService from "../services/UserService";
import Column from "antd/lib/table/Column";
import { EventBus } from "../components/event";
import AddRoleForm from "./RoleForm";
import "../css/Views.css";
import { useAuth } from "../helpers/auth";



const { confirm } = Modal;
let flag = false;
export default function RoleView() {
  const [formVisible, setformVisible] = useState(false);
  const [roles, setroles] = useState([]);
  const [mode, setmode] = useState("Add");
  const [role, setrole] = useState({ id: "", name: "", privileges: [] });
  const [loading, setloading] = useState(false);
  EventBus.subscribe("role-refresh", (event) => refresh());
  const { user: activeUser } = useAuth();
  const privilege = activeUser.privileges;

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    UserService.getRoles().then((request) => {
      let Roles = request.data;
      setroles(Roles);
      setloading(false);
    });
  };

  const close = () => {
    setformVisible(false);
  };
  const showDeleteConfirm = (roleId, refresh) => {
    confirm({
      title: "Are you sure you want to Delete this Role",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        RoleService.removeRole(roleId)
          .then(() => {
            refresh();
            message.success("Deleted Successfully!");
          })
          .catch((error) => {
            let msg = "Something went wrong!";
            if (error && error.response && error.response.data && error.response.data.message) {
              msg = error.response.data.message;
              message.warn(msg);
            }
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const addNewRole = () => {
    flag = false;
    setformVisible(true);
    setrole((role.id = ""));
    setrole((role.name = ""));
    setrole((role.privileges = []));
    setmode("Add");
  };
  const editRole = (RoleId) => {
    flag = true;
    RoleService.getRole(RoleId.id).then(
      (response) => {
        let role = response.data;
        setrole(role);
        setformVisible(true);
        setmode("Edit");
      },
      (error) => {
        message.error(error.response.data.message);
      }
    );
  };

  return (
    <div className="site-card-wrapper" style={{ margin: "30px", marginLeft:'0' }}>
      <Row gutter={2}>
        <Col xs={{ span:2, offset: 1 }} lg={{ span: 2, offset:1}}>
          {privilege.includes("ROLE_WRITE") && (
            <Button type="primary" onClick={addNewRole}>
              New Role <PlusCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col xs={{ span: 24}} lg={{ span: 22, offset: 1 }}>
          <Card className="limitable" bodyStyle={{ padding: "0px", width: "100%" }}>
            <Table
              dataSource={roles}
              pagination={false}
              scroll={{ x: true }}
              rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
            >
              <Column align="center" title="Role" dataIndex="name" key="name" render={(name) => name} />
              <Column
                title="Privileges"
                dataIndex="privileges"
                render={(privileges) => (
                  <>
                    {privileges.sort().map((privilege) => {
                      return (
                        <Tag color="blue" style={{ backgroundColor: "#E8DAEF", margin: "2px" }} key={privilege}>
                          {privilege}
                        </Tag>
                      );
                    })}
                  </>
                )}
              />
              <Column
                title="Actions"
                render={(text, record) => {
                  return <>
                    {privilege.includes("ROLE_WRITE") && !['MetalPowerAdmin', 'New User', 'Org Admin', 'Org User', 'Pending User'].includes(record.name) ? (
                      <>
                        <EditOutlined onClick={() => editRole(text, record.id)} />
                        <Divider type="vertical" />
                        <DeleteOutlined style={{ color: "#ff0000" }} onClick={() => showDeleteConfirm(record.id, refresh)} />
                      </>
                    ) : <StopOutlined style={{color:'red'}}/>}
                  </>
                }}
              />
            </Table>
          </Card>
        </Col>
        {formVisible ? (
          <Col span={8}>
            <AddRoleForm role={role} flag={flag} close={close} refresh={refresh} />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
