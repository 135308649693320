import { SearchOutlined } from '@ant-design/icons';
import { Input, message, Table, Tag, Typography } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import SubscriptionService from '../../services/SubscriptionService';

const SubscriptionHistory = ({orgId}) => {

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setLoading(true);
    SubscriptionService.getSubsriptionList(page - 1, pageSize, searchText).then(res => {
        setSubscriptions(res.data.list);
        setTotalSubscriptions(res.data.total)
        setLoading(false);
    }).catch(err => {
        message.error('Error Loading Transactions');
        setLoading(false);
    })
  }, [searchText, page, pageSize])

  function resetPageAndSize() {
    setPage(1);
    setPageSize(10);
  }

  const columns = [
    {
        title: 'Org Name',
        dataIndex: 'orgName',
        key: 'orgName',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
              <>
                <Input
                  autoFocus
                  placeholder="Search Company Name"
                  value={searchText}
                  onChange={(e) => {
                    resetPageAndSize();
                    setSearchText(e.target.value);                
                    confirm({ closeDropdown: false });
                  }}
                  onPressEnter={() => {
                    confirm();
                  }}
                  onBlur={() => {
                    confirm();
                  }}
                ></Input>
              </>
            );
          },
          filterIcon: () => {
            if(searchText){
              return <SearchOutlined className="active" style={{ fontSize: '20px', color: '#f5222d' }}/>
            }else{         
              return <SearchOutlined className="active"/>}
          },
          onFilter: (value, record) => {
            if (value) {
              return record.organizationLegalName?.toLowerCase()?.includes(value.toLowerCase()) || "";
            }
          },
        // align: 'center',
    },
    {
        title: 'Plan',
        dataIndex: 'planName',
        key: 'planName',
        // align: 'center',
    },
    {
        title: 'Maximum Users',
        dataIndex: 'maximumUsers',
        key: 'maximumUsers',
    },
    {
        title: 'Time Period',
        dataIndex: 'planTimePeriod',
        key: 'planTimePeriod',
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
    },
    {
        title: 'Valid Till',
        dataIndex: 'validTill',
        key: 'ValidTill',
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align:'center',
        render: (_, record) => {
            let active = new Date(record.validTill).getTime() > new Date().getTime()
            return <Tag color={active ? 'success' : 'error'}>{active ? "ACTIVE" : "EXPIRED"}</Tag>
        }
    },

  ]

  return (
    <>
        <Table
            loading={loading}
            size='midddle'
            dataSource={subscriptions}
            columns={columns}
            rowClassName={(record, index) => (index % 2 === 0 ? "row_light" : "row_dark")}
            pagination={{ 
                showSizeChanger: true, 
                total: totalSubscriptions, 
                pageSize:pageSize,
                defaultCurrent: 1,
                current: page,
                onShowSizeChange: (_, size) => setPageSize(size),
                onChange: (num, size) => {setPage(num); setPageSize(size)}
            }}
        />
    </>
  )
}

export default SubscriptionHistory