import React, { useEffect, useState } from 'react'
import DashboardService from '../services/DashboardService'
import { Collapse, Table, Typography } from "antd";
import DeviceService from "../services/DeviceService";
import UserService from "../services/UserService";
const { Panel } = Collapse;
const { Title } = Typography;
function Faq() {
  const [data, setData] = useState();
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    DashboardService.loadFaq().then((res) => {
      DeviceService.getActiveDevices().then((response) => {
        let usersdevice = response.data;
        let faqdata = res.data;
        let modelArray = [];
        let modifiedData = [];
        let filteredModifiedData = [];
        for (let i = 0; i < faqdata.length; i++) {
          modifiedData.push({ modelName: faqdata[i].modelName });
          let questions = [];
          if (faqdata[i].questions.length >= 0) {
            for (let j = 0; j < faqdata[i].questions.length; j++) {
              let a = faqdata[i].questions[j].possibleCauses.split("\n");
              let b = faqdata[i].questions[j].remedy.split("\n");
              let Tabledata = [];
              for (let k = 0; k < a.length; k++) {
                Tabledata.push({
                  key: a[k],
                  causes: a[k],
                  remedy: b[k],
                });
              }
              questions.push({ question: faqdata[i].questions[j].question, description: faqdata[i].questions[j].description, Tabledata: Tabledata });
            }
            modifiedData[i]["questions"] = questions;
          } else {
            modifiedData[i]["questions"] = [];
          }
        }
        for (let i = 0; i < usersdevice.length; i++) {
          let result = usersdevice[i].modelName.replace(/^M-/,'Metavision-');
          modelArray.push(result);
        }
        let filteredModel = modelArray.filter(function (el) {
          return el != null;
        });
        for (let i = 0; i < filteredModel.length; i++) {
          if (modifiedData.filter((el) => el.modelName === filteredModel[i]).length !== 0) {
            filteredModifiedData.push(modifiedData.filter((el) => el.modelName === filteredModel[i])[0]);
          }
        }
        let uniqueData = [...new Set(filteredModifiedData)];
        UserService.getUserProfile().then((resp) => {
          if (resp.data.organizationName === "Metal Power") {
            setData(modifiedData);
          } else {
            setData(uniqueData);
          }
        });
      });
    });
  };
  const columns = [
    {
      title: "Possible Causes",
      dataIndex: "causes",
      key: "causes",
    },
    {
      title: "How to check and remedy the issue",
      dataIndex: "remedy",
      key: "remedy",
    },
  ];
  return (
    <>
      {data && (
        <>
          {data.map((el) => {
            return (
              <div key={el.modelName} style={{ border: "2px solid lightgrey", padding: "20px", marginBottom: "20px" }}>
                <Title level={2} align="center" style={{ color: "black" }}>
                  {el.modelName}
                </Title>
                <Collapse>
                  {el.questions.map((element) => {
                    return (
                      <Panel key={element.id} header={element.question} style={{ marginBottom: "10px" }}>
                        <p style={{ color: "grey" }}>{element.description}</p>
                        {element.Tabledata.length > 1 ? <Table pagination={false} columns={columns} dataSource={element.Tabledata} /> : <></>}
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
export default Faq