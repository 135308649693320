import React, { useEffect, useState } from "react";
import {
  GlobalOutlined,
  IdcardOutlined,
  BarcodeOutlined,
  DeploymentUnitOutlined,
  EditOutlined,
  UserOutlined,
  DesktopOutlined,
  DashboardOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  FileTextOutlined,
  MessageOutlined,
  SafetyCertificateOutlined,
  ContactsOutlined,
  PoweroffOutlined,
  SnippetsOutlined
} from "@ant-design/icons";

import { AutoComplete, Avatar, Col, Image, Input, Menu, message, Modal, notification, Progress, Row, Space, Typography } from "antd";
import { Link, useLocation, useNavigate} from "react-router-dom";
import commonService from "../services/CommonService";
import UserService from "../services/UserService";
import { useAuth } from "../helpers/auth";
import DeviceService from "../services/DeviceService";
import defaultUser from "../extra/images/mp_user_logo_hd.png";
import { set } from "lodash";

const UserImage = (props) => {
  const [image, setImage] = useState(defaultUser);
  useEffect(() => {
    if (props.id) {
      UserService.getImage(props.id)
        .then((response) => {
          const objectUrl = URL.createObjectURL(response.data);
          setImage(objectUrl);
          return objectUrl;
        })
        .catch((error) => {
          setImage(defaultUser);
          message.warn(error);
        });
    }
  }, [props.id]);
  return (
    <>
      <Avatar shape="square" style={{background:'transparent'}} icon={<Image preview={false} src={image}/>} size={props.size}/>
    </>
  );
};

const { SubMenu } = Menu;
export default function Sidebar() {
  const { doLogout, refreshMenu , user:authUser} = useAuth();
  const location = useLocation();
  //const [loaded, setLoaded] = useState(false);
  const [menu, setMenu] = useState({});
  const [hover, setHover] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [data, setData] = useState();
  const [internal, setInternal] = useState(false);
  const [current, setCurrent] = useState(location.pathname === "/dashboard" || location.pathname === "/home" ? "/" : location.pathname);
  const [userImage, setUserImage] = useState()
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orgdetails, setOrgDetails] = useState({});
  /* const onClick = (e) => {
    setCurrent(e.key);
  };
  */

  useEffect(() => {
    UserService.getUserProfile().then((res) => {
      setInternal(res.data.organizationName === "Metal Power");
      setUserImage(res.data.profilePictureFile);
      setUser(res.data);
      if(res.data.pendingStatus) {
        UserService.getUserOrgAdminDetails().then(res => {
          setOrgDetails(res.data[0]);
        })
      }
    });

    UserService.getMenu().then((response) => {
      let menu = response.data;
      menu.items = commonService.getSorted(menu.items || [], "seq", true);

      menu.items.forEach((menuItem) => {
        if (menuItem.submenu != null) {
          menuItem.submenu = commonService.getSorted(menuItem.submenu || [], "seq", true);
        }
      });
      setMenu(menu);
    });
  }, [refreshMenu, authUser]);

  useEffect(()=>{
    setCurrent(location.pathname === "/dashboard"|| location.pathname === "/home" ? "/" : location.pathname);
  },[location.pathname, authUser])



  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    DeviceService.getActiveDevices().then(
      (res) => {
        DeviceService.listMappedDeviceName().then(names => {
          let customNames = {};
          names.data.forEach(name => customNames[name.legacyName] = name.customName);
          let customDevices = res.data.map(device => {
            return {...device, deviceName: customNames[device.deviceName]}
          })
          setDevices(customDevices);
        })
      },
      (error) => {
        message.error(error.message);
      }
    );
  };

  let deviceModel = [];
  let orgList = ["All Company"];
  for (let i = 0; i < devices.length; i++) {
    deviceModel.push(devices[i].deviceName);
    orgList.push(devices[i].orgLegalName);
  }

  let uniqueModel = [...new Set(deviceModel)];
  let uniqueOrg = [...new Set(orgList)];
  let sortedDevices = [];
  for (let i = 0; i < uniqueModel.length; i++) {
    const fi = devices.filter((el) => el.deviceName === uniqueModel[i]);
    sortedDevices.push({ model: uniqueModel[i], details: fi });
  }

  let filterSortDevices = [];
  const filteredDevices = (value) => {
    setFiltering(false);
    if (value === "All Company") {
      setData(sortedDevices);
    } else {
      for (let i = 0; i < uniqueModel.length; i++) {
        const fi = devices.filter((el) => el.deviceName === uniqueModel[i]);
        const di = fi.filter((el) => el.orgLegalName === value);
        if (di.length >= 1) {
          filterSortDevices.push({ model: di[0].deviceName, details: di });
        }
      }
      setData(filterSortDevices);
    }
    setFiltering(true);
  };

  const showLogoutModal = (val) => {
    setIsModalOpen(val)
  }

  const toggleHover = () => {
    setHover(!hover);
  };

  const renderItem = (title) => ({
    value: title,
    label: <div>{title}</div>,
  });

  const options = [];
  uniqueOrg.map((el) => {
    return options.push(renderItem(el));
  });

  const showAccessDeniedMessage = () => {
    if(user.pendingStatus) {
        notification.error({
            type: "info",
            description: `Please ask your Org Admin [${orgdetails.name}] to allow access from ‘The Analyst’ application.`,
            duration: 2,
            style: {padding: 25}
        });
    }
  }

  return (
    <>
    <Menu theme="light" mode="horizontal" selectedKeys={[current]} style={{ display: "flex", justifyContent: "space-around" }}>
      <Menu.Item key="/" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
        <Link to="/">
          <span className="nav-text" style={{ fontWeight: "600" }}>
            Home
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/product" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
        <Link to="/product">
          <span className="nav-text" style={{ fontWeight: "600" }}>
            Product
          </span>
        </Link>
      </Menu.Item>

      {!internal && (
        <Menu.Item key="/myDevices" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <Link to="/myDevices">
            <span className="nav-text" style={{ fontWeight: "600" }}>
              My Devices
            </span>
          </Link>
        </Menu.Item>
      )}

      <SubMenu
        onTitleClick={showAccessDeniedMessage}
        key="device"
        title={
          <span className="submenu-title-wrapper" style={{ fontWeight: "600" }}>
            Devices
          </span>
        }
      >
        {user && !user.pendingStatus &&
        <>
         {internal ? (
          <AutoComplete
            allowClear
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={100}
            options={options}
            onSelect={filteredDevices}
            filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          >
            <Input size="medium" placeholder="Select Company" />
          </AutoComplete>
        ) : (
          <></>
        )}

        {filtering
          ? (data || []).map((item) => (
              <SubMenu key={item.id} title={item.model}>
                {(item.details || []).map((element) => (
                  <Menu.Item key={"user/device/" + element.id}>
                    <Link to={"user/device/" + element.id}>
                      <span className="nav-text">{element.serialNumber}</span>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))
          : (sortedDevices || []).map((item) => (
              <SubMenu key={item.id} title={item.model}>
                {(item.details || []).map((element) => (
                  <Menu.Item key={"user/device/" + element.id}>
                    <Link to={"user/device/" + element.id}>
                      <span className="nav-text">{element.serialNumber}</span>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </>
        }
      </SubMenu>

      {(menu.items || []).map((item, index) =>
        item.submenu !== null ? (
          <SubMenu key={item.key} title={<span style={{ fontWeight: "600" }}>{item.title}</span>} onTitleClick={showAccessDeniedMessage}>
            {user && !user.pendingStatus &&
            <>
              {(item.submenu || []).map((subitem, index) => (
                <Menu.Item key={subitem.link}>
                  <Link to={subitem.link}>
                    <Icon type={subitem.icon} />
                    <span className="nav-text">{subitem.title}</span>
                  </Link>
                </Menu.Item>
              ))}
            </>
            }
          </SubMenu>
        ) : (
          <Menu.Item key={item.link}>
            <Link to={item.link}>
              <span className="nav-text" style={{ fontWeight: "600" }}>
                {item.title}
              </span>
            </Link>
          </Menu.Item>
        )
      )}

      {user && (!user.client || (user.client && user.salesForceUser)) && <Menu.Item key="/software" onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={showAccessDeniedMessage}>
        <Link to={user && !user.pendingStatus ? "/software": location.pathname}>
          <span className="nav-text" style={{ fontWeight: "600" }}>
            Software
          </span>
        </Link>
      </Menu.Item>}

      {user && !user.roles.includes('New User') && <Menu.Item key="/faq" onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={showAccessDeniedMessage}>
        <Link to={user && !user.pendingStatus ? "/faq": location.pathname}>
          <span className="nav-text" style={{ fontWeight: "600" }}>
            FAQ
          </span>
        </Link>
      </Menu.Item>}

      <SubMenu key={"profile"} title={<UserImage id={userImage} key={userImage} size={{ xs: 24, sm: 25, md: 30, lg: 30, xl: 35, xxl: 40 }}/>}>
        <Menu.Item key="aboutus">
          <Link to={"/aboutus"}>
            <Icon />
            <span className="nav-text">About Us</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="terms-conditions">
          <Link to={"/terms-conditions"}>
            <Icon type="SnippetsOutlined"/>
            <span className="nav-text">Terms & Conditions</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="privacy">
          <Link to={"/privacy-policy"}>
            <Icon type={"SafetyCertificateOutlined"} />
            <span className="nav-text">Privacy Policy</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="contactus">
          <Link to={"/contactus"}>
            <Icon type={"ContactsOutlined"} />
            <span className="nav-text">Contact Us</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="8" onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={() => showLogoutModal(true)}>
          <Icon type={"PoweroffOutlined"} />
          <span className="nav-text">Logout</span>
        </Menu.Item>
      </SubMenu>

    </Menu>
    <Modal visible={isModalOpen} onOk={doLogout} onCancel={() => showLogoutModal(false)} okText="Logout" cancelText="Close">
      <Row gutter={16}>
        <Col span={24} style={{display:'flex', justifyContent:'center'}}>
          <UserImage id={userImage} key={userImage} size={{ xs: 60, sm: 60, md: 60, lg: 60, xl: 60, xxl: 60 }}/>
        </Col>
        <Col span={24} style={{display:'flex', justifyContent:'center'}}>
          <Typography.Title level={5}>{user&&user.fullName}</Typography.Title>
        </Col>
        <Col span={24} style={{display:'flex', justifyContent:'center'}}>
          <Space>
            <Typography.Paragraph>Email: </Typography.Paragraph>
            <Typography.Paragraph>{user&&user.email}</Typography.Paragraph>
          </Space>
        </Col>
      </Row>
    </Modal>
    </>
  );
}

function Icon(props) {
  switch (props.type) {
    case "deployment-unit":
      return <DeploymentUnitOutlined />;
    case "barcode":
      return <BarcodeOutlined />;
    case "edit":
      return <EditOutlined />;
    case "IdcardOutlined":
      return <IdcardOutlined />;
    case "GlobalOutlined":
      return <GlobalOutlined />;
    case "DesktopOutlined":
      return <DesktopOutlined />;
    case "DashboardOutlined":
      return <DashboardOutlined />;
    case "UserAddOutlined":
      return <UserAddOutlined />;
    case "UserSwitchOutlined":
      return <UserSwitchOutlined />;
    case "MessageOutlined":
      return <MessageOutlined />;
    case "FileTextOutlined":
      return <FileTextOutlined />;
    case "SafetyCertificateOutlined":
      return <SafetyCertificateOutlined />
    case "ContactsOutlined":
      return <ContactsOutlined />
    case "PoweroffOutlined":
      return <PoweroffOutlined />
    case "SnippetsOutlined":
      return <SnippetsOutlined />
  }
  return <UserOutlined />;
}  
